import React, { useCallback, useState } from 'react';
import MainImage from "core/Components/Item/MainImage";
import ThumbnailContainer from "core/Components/Item/ThumbnailContainer";
import Thumbnail from "core/Components/Item/Thumbnail";
import GalleryThemeProvider from "core/Components/Item/GalleryThemeProvider";
import { NOOP } from "core/constants";

type Props = {
    imagesAndVideos: [ImageAndVideo],
    onVideoClick?: (url: string) => void,
};

const Gallery: React.FC<Props> = ({ imagesAndVideos, onVideoClick = NOOP }) => {
    const [mainImage, setMainImage] = useState<ImageAndVideo>(imagesAndVideos[0]);

    const onThumbnailClick = useCallback(
        ({ imageAndVideo }: { imageAndVideo: ImageAndVideo }) => {
            setMainImage(imageAndVideo);
        },
        [setMainImage]
    );

    return (
        <GalleryThemeProvider>
            {mainImage && (
                <MainImage
                    image={mainImage.image}
                    {...onVideoClick !== NOOP && mainImage.videoPath  && {
                        onVideoClick: () => onVideoClick(mainImage.videoPath || '')
                    }}
                />
            )}
            {imagesAndVideos.length > 1 && (
                <ThumbnailContainer>
                    {imagesAndVideos.map((imageAndVideo: ImageAndVideo, index) => (
                        <Thumbnail
                            key={`gallery-thumbnail-${index}`}
                            image={imageAndVideo.image}
                            onClick={() => onThumbnailClick({ imageAndVideo })}
                            isVideo={!!imageAndVideo.videoPath}
                        />
                    ))}
                </ThumbnailContainer>
            )}
        </GalleryThemeProvider>
    );
};

export default Gallery;