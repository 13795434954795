import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const InputNumberContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        height: 42px;
        margin: 0 18.775px;

        ${theme.breakpoints.up('5xl')} {
            margin: 0 28.12px 0 32px;
            height: 54px;
        }

        ${theme.breakpoints.between('lg', '4xl')} {
            margin: 0 17.56px 0 19.99px;
            height: 42px;
        }

        ${theme.breakpoints.only('md')} {
            margin: 0 17.02px;
            height: 30px;
        }

        ${theme.breakpoints.down('sm')} {
            margin: 0 12.08px;
            height: 38px;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            appearance: none;
        }

        /* Firefox  */
        input[type='number'] {
            appearance: textfield;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default InputNumberContainer;