import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    useBackground?: boolean,
    backgroundColour?: string,
    leftSectionColour?: string,
    rightSectionColour?: string,
};

const VideoViewContainer: React.FC<Props> = ({
    useBackground = false,
    backgroundColour,
    leftSectionColour,
    rightSectionColour,
}) => {
    const theme = useTheme();

    const style = css`
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${theme.zIndex.zIndexTwo};
        
        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `}
        
        ${theme.breakpoints.down("sm")} {
            flex-direction: column-reverse;
        }
    `;

    const leftStyle = css`
        width: 50%;

        ${leftSectionColour && css`
            background-color: ${leftSectionColour};
        `}

        ${theme.breakpoints.down("sm")} {
            width: 100%;
            height: 50%;
        }
    `;

    const rightStyle = css`
        width: 50%;

        ${rightSectionColour && css`
            background-color: ${rightSectionColour};
        `}

        ${theme.breakpoints.down("sm")} {
            width: 100%;
            height: 50%;
        }
    `;


    return (
        useBackground ? (
            <div css={style}>
                <div css={leftStyle} />
                <div css={rightStyle} />
            </div>
        ) : null
    );
};

export default VideoViewContainer;