import React, { useRef } from "react";
import { css, useTheme } from "@emotion/react";

import useElementDimensions from "core/hooks/useElementDimensions";
import ImageContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Image/ImageContainer";
import CardImage from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Image/CardImage";
import CardTextContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardTextContainer";
import CardHeader from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardHeader";
import CardButtonContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Button/CardButtonContainer";
import CardRoundButton from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Button/CardRoundButton";
import CardDescription from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardDescription";
import CardVideoButton from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Button/CardVideoButton";
import HeaderContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/HeaderContainer";

type Props = {
    card: ImageVerticalCard,
    setVideoPath: (videoPath: string) => void,
};

const CardWithImage: React.FC<Props> = ({ card, setVideoPath }) => {
    const theme = useTheme();
    const headerRef = useRef<any>();
    const headerHeight = useElementDimensions(headerRef.current).height;
    const hasVideoButton = !!card.videoPath;

    const onClickVideoHandler = () => {
        setVideoPath(card.videoPath);
    };

    const cardButtonContainerComponentStyle = css`
        ${!card.image && css`
            ${theme.breakpoints.down('md')} {
                display: flex;
                flex: 1;
                align-items: flex-end;
            }
        `};
    `;

    return (
        <>
            <CardTextContainer card={card}>
                <HeaderContainer card={card} headerHeight={headerHeight}>
                    <CardHeader card={card} headerRef={headerRef} />
                </HeaderContainer>

                {card.text && (
                    <CardDescription card={card}/>
                )}

                {(!hasVideoButton && card.buttonText) && (
                    <CardButtonContainer hasCardImage={!!card.image} css={cardButtonContainerComponentStyle}>
                        <CardRoundButton card={card} className={card.trackingButtonName}>
                            {card.buttonText}
                        </CardRoundButton>
                    </CardButtonContainer>
                )}

                {hasVideoButton && (
                    <CardButtonContainer hasCardImage={!!card.image}>
                        <CardVideoButton onClick={onClickVideoHandler} card={card} className={card.trackingButtonName} />
                    </CardButtonContainer>
                )}
            </CardTextContainer>

            {card.image && (
                <ImageContainer>
                    <CardImage card={card} />
                </ImageContainer>
            )}
        </>
    );
}

export default CardWithImage;
