import React, { memo, useCallback, useEffect, useState } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import window from 'core/includes/window';
import VideoPlaceholderWithFocusPoint from "core/Components/Video/VideoPlaceholderWithFocusPoint";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import Wrapper from "core/Components/Wrapper";
import ContentSection from "core/Components/ContentSection";

type Props = {
    videoPath: string,
    image: string | Image,
    useFocusPoint?: boolean,
    className?: string,
};

const VideoWithPlaceholder: React.FC<Props> = ({
    videoPath,
    image,
    useFocusPoint = false,
    className = '',
}) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        width: 100%;
        height: 100%;
    `;

    const videoSectionStyle = css`
        position: absolute;
        width: 100%;
        z-index: ${theme.zIndex.zIndexOne};
    `;

    const [showVideo, setShowVideo] = useState(false);

    const toggleVideo = useCallback(() => {
        setShowVideo(prevShowVideo => !prevShowVideo);
    }, [setShowVideo]);

    const onEnded = () => {
        setShowVideo(false);
    };

    useEffect(() => {
        const onKeyDown = (e: any) => {
            if (e.keyCode === 27) {
                setShowVideo(false);
            }
        }

        window.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, []);

    return (
        <Wrapper css={wrapperStyle} useMinHeight={false} className={className}>
            <VideoPlaceholderWithFocusPoint
                showVideo={showVideo}
                image={image}
                onClick={toggleVideo}
                useFocusPoint={useFocusPoint}
            />

            {showVideo && (
                <ContentSection css={videoSectionStyle}>
                    <VideoPlayer
                        onOverlayClick={toggleVideo}
                        videoPath={videoPath}
                        onEnded={onEnded}
                        autoPlay
                        overlay
                    />
                </ContentSection>
            )}
        </Wrapper>
    );
};

export default memo(VideoWithPlaceholder);
