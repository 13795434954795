import React from "react";
import { css, useTheme } from "@emotion/react";
import Loading from "core/Components/Utils/Loading";

type Props = {
    children: React.ReactNode,
    isSubmitting: boolean,
};

const LoginContainer: React.FC<Props> = ({children, isSubmitting}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: row;
        width: 100%;
        
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }
    `;

    return (
        <>
            {isSubmitting && <Loading onTop fitToScreen overlay overlayColour={"white"} delay={500} /> }
            <div css={style}>
                {children}
            </div>
        </>
    );
}

export default LoginContainer;