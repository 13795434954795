import React from "react";
import { css, SerializedStyles } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    paginationCustomStyle?: SerializedStyles,
    useDefaultPagination?: boolean,
    isBottomPagination?: boolean,
    sliderRef?: any,
};

const CarouselContainer: React.FC<Props> = ({
    children,
    className,
    paginationCustomStyle,
    useDefaultPagination = false,
    isBottomPagination = false,
    sliderRef,
}) => {
    const style = css`
        .slide {
            display: flex;
            align-items: center;
        }

        .slider-control-bottomcenter {
            ${isBottomPagination ? (
                css`
                    position: absolute;
                    bottom: 0;
                `
            ) : (
                !useDefaultPagination && css`
                    display: none;
                `
            )};
            
            ${paginationCustomStyle}
        }
    `;

    return (
        <div css={style} ref={sliderRef} className={className}>
            {children}
        </div>
    );
};

export default CarouselContainer;
