import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    isVisible: boolean,
    message: string,
    isLoginDialog?: boolean,
}

const ErrorSection: React.FC<Props> = ({isVisible, message, isLoginDialog}) => {
    const theme = useTheme();

    const loginErrorSection = (isVisible: boolean) => css`
        ${isVisible ? "display: flex" : "display: none"};
        width: 100%;
        background-color: ${theme.colours.bridesmaid};
        border-radius: 45px;
        color: ${theme.colours.red[999]};
        margin-top: 6px;
        padding: 11px 24px;
        
        ${isLoginDialog && css`
            padding: 9px 18px;
        `}
        
        ${theme.breakpoints.up("xxl")} {
            font-size: 15px;
        }

        ${theme.breakpoints.down("xl")} {
            font-size: 14px;
        }

        ${theme.breakpoints.between("md", "xl")} {
            border-radius: 11px;
        }

        ${theme.breakpoints.down("sm")} {
            border-radius: 6px;
        }
    `;

    return (
        <div css={loginErrorSection(isVisible)}>
            {message}
        </div>
    )
}

export default ErrorSection;