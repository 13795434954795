import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const DropdownContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const dropdownLayoutStyle = css`
        justify-content: end;
        width: fit-content;
        white-space: nowrap;
        height: fit-content;

        ${theme.breakpoints.up("5xl")} {
            > div:not(:last-child) {
                margin-right: 8px;
            }
        }

        ${theme.breakpoints.between("lg", "4xl")} {
            > div:not(:last-child) {
                margin-right: 5px;
            }
        }

        ${theme.breakpoints.only("md")} {
            padding-top: 21px;
            justify-content: start;
            width: 100%;

            > div:not(:last-child) {
                margin-right: 14px;
            }
        }

        ${theme.breakpoints.only("sm")} {
            padding-top: 24px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            place-content: space-between;
            gap: 14px 16px;
            width: 100%;

            > div:not(:last-child) {
                margin-right: 9px;
            }
        }

        ${theme.breakpoints.down("xs")} {
            padding-top: 17px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            place-content: space-between;
            gap: 14px 16px;

            > div {
                width: 100%;
            }
        }
    `;

    return (
        <ContentSection css={dropdownLayoutStyle}>
            {children}
        </ContentSection>
    );
};

export default DropdownContainer;
