import React, { useEffect } from "react";
import { css, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";
import useStripePlans, { StripePlan } from "core/hooks/useStripePlans";

type Props = {
    children: React.ReactNode,
    setState: any,
};

const PricingCardsContainer: React.FC<Props> = ({ children, setState }) => {
    const theme = useTheme();
    const plans = useStripePlans();

    useEffect(() => {
        if (plans) {
            const monthlyPlan = plans.filter((plan: StripePlan) => plan.Interval === "month")[0];
            const tiers = monthlyPlan.Tiers;

            setState({
                personalCost: tiers[0].UnitAmount / 100,
                familyCost: tiers[1].UnitAmount / 100,
            });
        }
    }, [plans, setState]);

    const pricingCardListStyle = css`
        width: unset;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 22px;
        margin-bottom: 77px;

        ${theme.breakpoints.up("5xl")} {
            gap: 36px;
            margin-bottom: 73px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 27px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 38px;
        }

        ${theme.breakpoints.only("md")} {
            gap: 17px;
            margin-bottom: 63px;
        }

        ${theme.breakpoints.down("sm")} {
            grid-template-columns: 1fr;
            gap: 58px;
            margin-bottom: 37px;
        }
    `;

    return (
        <ContentSection css={pricingCardListStyle}>
            {children}
        </ContentSection>
    );
}

export default PricingCardsContainer;
