import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const PriceContainer: React.FC<Props> = ({
    children,
    className,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        width: 100%;
        height: 91px;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: space-evenly;
        color: white;

        ${theme.breakpoints.up("5xl")} {
            height: 138px;
        }

        ${theme.breakpoints.only("md")} {
            height: 67px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 77px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default PriceContainer;
