import React, { useMemo } from "react";
import { css } from "@emotion/react";

import Arrow from "core/Components/Icons/Arrow";
import { DROPDOWN_ARROW_COLOUR, DROPDOWN_TRANSITION_TIME } from "core/constants";

type Props = {
    showMenu: boolean,
    optionBackgroundColour: string,
};

const DropdownButtonArrow: React.FC<Props> = ({ showMenu, optionBackgroundColour }) => {
    const arrowStyle = css`
        width: 20px;
        height: 20px;
        transition: all ${DROPDOWN_TRANSITION_TIME} ease-in-out;
        
        ${showMenu && css`
            transform: rotate(180deg);
        `};
    `;

    const arrowColour = useMemo(() => showMenu ? optionBackgroundColour : DROPDOWN_ARROW_COLOUR, [showMenu, optionBackgroundColour]);

    return (
        <Arrow css={arrowStyle} colour={arrowColour} />
    );
}

export default DropdownButtonArrow;
