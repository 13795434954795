import React from "react";
import { css } from "@emotion/react";
import { isEmpty } from "lodash";

import useSetState from "core/hooks/useSetState";
import VideoButton from "core/Components/Buttons/VideoButton";
import SectionContainer from "core/Components/SectionContainer";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoPlayer from "core/Components/Video/VideoPlayer";

import Wrapper from "elements/Components/ContentWithImage/components/Wrapper";
import Header from "elements/Components/ContentWithImage/components/Header";
import Subheader from "elements/Components/ContentWithImage/components/Subheader";
import ShadowedContainer from "elements/Components/ContentWithImage/components/ShadowedContainer";
import TextContentContainer from "elements/Components/ContentWithImage/components/TextContentContainer";
import Description from "elements/Components/ContentWithImage/components/Description";
import ButtonsContainer from "elements/Components/ContentWithImage/components/ButtonsContainer";
import Button from "elements/Components/ContentWithImage/components/Button";
import ImageContainer from "elements/Components/ContentWithImage/components/ImageContainer";
import Image from "elements/Components/ContentWithImage/components/Image";
import ContentSection from "core/Components/ContentSection";

type Props = {
    data: ContentWithImage
};

type DefaultState = {
    videoPath: string,
}

const INITIAL_STATE = {
    videoPath: "",
}

const ContentWithImage: React.FC<Props> = ({ data }) => {
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const colourTheme = JSON.parse(data.theme);
    const { fromColour, toColour, } = colourTheme;
    const layout = JSON.parse(data.layout);
    const { useShadow } = layout;
    const videoPath = data.video ? data.video.AbsoluteURL : data.videoPath

    const onClickVideoHandler = () => {
        setState({ videoPath });
    };

    const onClearVideoHandler = () => {
        setState({ videoPath: "" });
    };

    const textStyle = css`
        justify-content: flex-start;
    `;

    // image is required (Header, subheader, and text are optional)
    return isEmpty(data.image?.id) ? null : (
        <SectionContainer
            {...fromColour && toColour && !useShadow && { gradientColours: { fromColour, toColour } }}
            overflowHidden
        >
            <Wrapper data={data}>
                <ShadowedContainer data={data}>
                    <TextContentContainer data={data}>
                        {data.header && (
                            <Header data={data} />
                        )}

                        {data.subheader && (
                            <Subheader data={data} css={textStyle} />
                        )}

                        {data.text && (
                            <Description data={data} css={textStyle} />
                        )}

                        <ButtonsContainer>
                            {data.buttonText && (
                                <Button data={data} />
                            )}
                            {data.videoDisplay === 'textButton' && (
                                <VideoButton
                                    data={{
                                        ...data,
                                        videoPath: videoPath
                                    }}
                                    onClickVideoHandler={onClickVideoHandler}
                                />
                            )}
                        </ButtonsContainer>
                    </TextContentContainer>

                    <ImageContainer data={data}>
                        <Image
                            data={data}
                            {...data.videoDisplay === 'image' && videoPath && {
                                autoplay: data.autoplay,
                                onVideoClick: onClickVideoHandler,
                                showVideo: !!state.videoPath || data.autoplay,
                            }}
                        />
                    </ImageContainer>

                    {state.videoPath && data.videoDisplay === 'textButton' && (
                        <VideoViewContainer clearPlayVideo={onClearVideoHandler}>
                            <VideoViewLayer>
                                {data.video ? (
                                    <ContentSection backgroundColour={"black"}>
                                        <video
                                            src={state.videoPath}
                                            width={"100%"}
                                            height={"100%"}
                                            autoPlay
                                            controls={true}
                                        />
                                    </ContentSection>
                                ) : (
                                    <VideoPlayer
                                        videoPath={state.videoPath}
                                        className={data.trackingButtonName}
                                        autoPlay
                                    />
                                )}
                            </VideoViewLayer>
                        </VideoViewContainer>
                    )}
                </ShadowedContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default ContentWithImage;
