import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const TopBorderedHalfContainer: React.FC<Props> = ({
    children,
}) => {
    const theme: Theme = useTheme();

    const halfContainerWithVerticalLineStyle = css`
        position: relative;
        display: grid;
        grid-template-columns: 0.75fr 1.25fr;
        width: 100%;
        height: 50%;

        :before {
            position: absolute;
            content: '';
            border: 1px solid ${theme.colours.doveGray2};
            width: calc(100% - 2px);
            top: 0;
        }
    `;

    return (
        <div css={halfContainerWithVerticalLineStyle}>
            {children}
        </div>
    )
}

export default TopBorderedHalfContainer;
