import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const Text: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        margin: 10px 0 20px 0;
        font-size: 18px;
        font-weight: ${theme.fonts.weights.light};
        color: ${theme.colours.scorpion};

        ${theme.breakpoints.up("xl")} {
            font-size: 21px;
        }

        ${theme.breakpoints.down("lg")} {
            font-size: 18px;
        }

        ${theme.breakpoints.down("xs") } {
            font-size: 16px;
            line-height: 25px;
        }
    `;

    return (
        <p css={style}>{children}</p>
    );
};

export default Text;
