import React from "react";
import { useNavigate } from "react-router-dom";
import { css, useTheme } from "@emotion/react";

import BurgerNavSmallHeaderMenuChevron from "core/Components/HamburgerNavigations/small/BurgerNavSmallHeaderMenuChevron";
import BurgerNavSmallHeaderMenuTitle from "core/Components/HamburgerNavigations/small/BurgerNavSmallHeaderMenuTitle";

type Props = {
    headerMenu: StandardPage,
    setSubMenu: Function,
    hasSubMenu: boolean,
    tabbedMenu: boolean,
    currentURLSegment?: string,
    closeMenuHandler: Function,
};

const BurgerNavSmallHeaderMenuBox: React.FC<Props> = ({
    headerMenu,
    setSubMenu,
    hasSubMenu,
    tabbedMenu,
    currentURLSegment = "",
    closeMenuHandler,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const borderBottomColour = !hasSubMenu || !tabbedMenu ? theme.colours.viking : "transparent";

    const onClickMenuHandler = () => {
        if (headerMenu.isClickable && !hasSubMenu) {
            navigate(headerMenu.linkUrl);
            closeMenuHandler();
        } else {
            setSubMenu({ headerMenu: headerMenu, keepHeader: false });
        }
    };

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 65px;
        cursor: pointer;
        border-bottom: 2px solid ${borderBottomColour};
        
        ${currentURLSegment === headerMenu.uRLSegment && css`
            opacity: 0.7;
        `}
        
        ${theme.breakpoints.only("md")} {
            height: 84px;
        }

        ${theme.breakpoints.only("sm")} {
            height: 75px;
        }

        ${theme.breakpoints.down("xs")} {
            height: 80px;
        }

        ${headerMenu.isClickable && css`
            &:hover {
                > div {
                    color: ${theme.colours.gold};
                }
            }
        `}
    `;

    return (
        <div css={style} onClick={onClickMenuHandler}>
            <BurgerNavSmallHeaderMenuTitle headerMenu={headerMenu} closeMenuHandler={closeMenuHandler}>
                {headerMenu.title}
            </BurgerNavSmallHeaderMenuTitle>

            {hasSubMenu && (
                <BurgerNavSmallHeaderMenuChevron tabbedMenu={tabbedMenu} />
            )}
        </div>
    );
};

export default BurgerNavSmallHeaderMenuBox;
