const sizes = {
    '1': { "5xl": 70, "4xl": 70, "3xl": 70, "xxl": 70, "xl": 65, "lg": 60, "md": 60, "sm": 45, "xs": 45, "xxs": 45 },
    '2': { "5xl": 42, "4xl": 42, "3xl": 42, "xxl": 42, "xl": 42, "lg": 42, "md": 42, "sm": 34, "xs": 34, "xxs": 34 },
    '3': { "5xl": 65, "4xl": 45, "3xl": 45, "xxl": 45, "xl": 30, "lg": 30, "md": 30, "sm": 30, "xs": 30, "xxs": 30 },
    '4': { "5xl": 32, "4xl": 27, "3xl": 27, "xxl": 27, "xl": 25, "lg": 21, "md": 21, "sm": 21, "xs": 21, "xxs": 21 },
    '5': { "5xl": 30, "4xl": 30, "3xl": 30, "xxl": 30, "xl": 30, "lg": 30, "md": 25, "sm": 19, "xs": 19, "xxs": 19 },
    '6': { "5xl": 28, "4xl": 24, "3xl": 24, "xxl": 24, "xl": 22, "lg": 19, "md": 19, "sm": 15, "xs": 16, "xxs": 16 },
    '7': { "5xl": 24, "4xl": 20, "3xl": 20, "xxl": 16, "xl": 16, "lg": 16, "md": 18, "sm": 16, "xs": 16, "xxs": 16 },
    '8': { "5xl": 23, "4xl": 15, "3xl": 15, "xxl": 15, "xl": 12, "lg": 12, "md": 14, "sm": 14, "xs": 14, "xxs": 14 },
    '9': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 18, "md": 18, "sm": 14, "xs": 14, "xxs": 14 },
    '10': { "5xl": 24, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 18, "md": 14, "sm": 14, "xs": 14, "xxs": 14 },
    '11': { "5xl": 30, "4xl": 30, "3xl": 30, "xxl": 30, "xl": 30, "lg": 30, "md": 30, "sm": 18, "xs": 20, "xxs": 20 },
    '12': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 18, "md": 17, "sm": 14, "xs": 14, "xxs": 14 },
    '13': { "5xl": 24, "4xl": 24, "3xl": 24, "xxl": 24, "xl": 24, "lg": 20, "md": 20, "sm": 20, "xs": 20, "xxs": 20 },
    '14': { "5xl": 24, "4xl": 24, "3xl": 24, "xxl": 24, "xl": 24, "lg": 24, "md": 18, "sm": 16, "xs": 16, "xxs": 16 },
    '15': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 18, "md": 18, "sm": 18, "xs": 14, "xxs": 14 },
    '18': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 16, "md": 16, "sm": 16, "xs": 16, "xxs": 16 },
    '19': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 16, "xl": 15, "lg": 15, "md": 15, "sm": 14, "xs": 14, "xxs": 14 },
    '20': { "5xl": 30, "4xl": 22, "3xl": 22, "xxl": 22, "xl": 22, "lg": 22, "md": 22, "sm": 18, "xs": 18, "xxs": 18 },
    '21': { "5xl": 18, "4xl": 18, "3xl": 18, "xxl": 18, "xl": 18, "lg": 14, "md": 14, "sm": 14, "xs": 14, "xxs": 14 },
    '22': { "5xl": 16, "4xl": 16, "3xl": 16, "xxl": 16, "xl": 16, "lg": 14, "md": 14, "sm": 14, "xs": 14, "xxs": 14 },
};

const heights = {
    '1': { "5xl": 80, "4xl": 80, "3xl": 80, "xxl": 80, "xl": 75, "lg": 70, "md": 70, "sm": 50, "xs": 50, "xxs": 50 },
    '2': { "5xl": 50, "4xl": 50, "3xl": 50, "xxl": 50, "xl": 50, "lg": 50, "md": 50, "sm": 40, "xs": 40, "xxs": 40 },
    '3': { "5xl": 72, "4xl": 50, "3xl": 50, "xxl": 50, "xl": 34, "lg": 34, "md": 34, "sm": 34, "xs": 34, "xxs": 34 },
    '4': { "5xl": 40, "4xl": 35, "3xl": 35, "xxl": 35, "xl": 33, "lg": 25, "md": 25, "sm": 25, "xs": 25, "xxs": 25 },
    '5': { "5xl": 40, "4xl": 40, "3xl": 40, "xxl": 40, "xl": 40, "lg": 40, "md": 35, "sm": 26, "xs": 26, "xxs": 26 },
    '6': { "5xl": 36, "4xl": 30, "3xl": 30, "xxl": 30, "xl": 27, "lg": 23, "md": 23, "sm": 19, "xs": 20, "xxs": 20 },
    '7': { "5xl": 36, "4xl": 28, "3xl": 28, "xxl": 23, "xl": 23, "lg": 23, "md": 24, "sm": 23, "xs": 20, "xxs": 20 },
    '8': { "5xl": 30, "4xl": 22, "3xl": 22, "xxl": 22, "xl": 16, "lg": 16, "md": 20, "sm": 20, "xs": 20, "xxs": 20 },
    '9': { "5xl": 25, "4xl": 25, "3xl": 25, "xxl": 25, "xl": 25, "lg": 25, "md": 25, "sm": 20, "xs": 20, "xxs": 20 },
    '10': { "5xl": 36, "4xl": 24, "3xl": 24, "xxl": 24, "xl": 24, "lg": 24, "md": 19, "sm": 19, "xs": 19, "xxs": 19 },
    '11': { "5xl": 40, "4xl": 40, "3xl": 40, "xxl": 40, "xl": 40, "lg": 37, "md": 37, "sm": 25, "xs": 26, "xxs": 26 },
    '12': { "5xl": 24, "4xl": 24, "3xl": 24, "xxl": 24, "xl": 24, "lg": 24, "md": 23, "sm": 18, "xs": 18, "xxs": 18 },
    '13': { "5xl": 28, "4xl": 28, "3xl": 28, "xxl": 28, "xl": 28, "lg": 24, "md": 24, "sm": 24, "xs": 24, "xxs": 24 },
    '14': { "5xl": 25, "4xl": 25, "3xl": 25, "xxl": 25, "xl": 25, "lg": 25, "md": 25, "sm": 18, "xs": 18, "xxs": 18 },
    '15': { "5xl": 30, "4xl": 30, "3xl": 30, "xxl": 30, "xl": 30, "lg": 30, "md": 25, "sm": 20, "xs": 20, "xxs": 20 },
    '16': { "5xl": 25, "4xl": 25, "3xl": 25, "xxl": 25, "xl": 25, "lg": 25, "md": 25, "sm": 25, "xs": 25, "xxs": 20 },
    '17': { "5xl": 32, "4xl": 32, "3xl": 32, "xxl": 32, "xl": 32, "lg": 26, "md": 26, "sm": 26, "xs": 26, "xxs": 26 },
    '18': { "5xl": 26, "4xl": 26, "3xl": 26, "xxl": 25, "xl": 24, "lg": 20, "md": 20, "sm": 20, "xs": 20, "xxs": 20 },
    '19': { "5xl": 24, "4xl": 24, "3xl": 24, "xxl": 28, "xl": 28, "lg": 28, "md": 28, "sm": 18, "xs": 18, "xxs": 18 },
    '20': { "5xl": 40, "4xl": 29, "3xl": 29, "xxl": 29, "xl": 29, "lg": 29, "md": 29, "sm": 22, "xs": 22, "xxs": 22 },
    '21': { "5xl": 22, "4xl": 22, "3xl": 22, "xxl": 22, "xl": 22, "lg": 17, "md": 17, "sm": 17, "xs": 17, "xxs": 17 },
    '22': { "5xl": 19, "4xl": 19, "3xl": 19, "xxl": 19, "xl": 19, "lg": 18, "md": 18, "sm": 18, "xs": 18, "xxs": 18 },
};

const v = {
    sizes,
    heights,
};

export default v;