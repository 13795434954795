import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    data: ContentWithList,
    children: React.ReactNode,
};

const Wrapper: React.FC<Props> = ({ data, children }) => {
    const theme: Theme = useTheme();
    const isImageFirst = data.isImageFirst;

    const wrapperStyle = css`
        padding: 58px 101px 68px;

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
        
        ${theme.breakpoints.up("5xl")} {
            padding: 73px 202px 72px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 61px 181px 72px;
        }
        
        ${theme.breakpoints.only("xl")} {
            padding: 35px 80px 35px;
        }
        
        ${theme.breakpoints.only("lg")} {
            padding: 30px 59px 30px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 49px 45px 42px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 45px 45px 43px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 59px 20px 57px;
        }
    `;

    return (
        <WrapperFromCore css={wrapperStyle}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
