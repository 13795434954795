import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Arrow from "core/Components/Icons/Arrow";
import { DROPDOWN_TRANSITION_TIME } from "core/constants";

type Props = {
    size: number,
    showOptions?: boolean,
};

const DropdownChevron: React.FC<Props> = ({ size, showOptions = false }) => {
    const theme: Theme = useTheme();

    const style = css`
        width: ${size}px;
        height: ${size}px;
        cursor: pointer;
        transition: all ${DROPDOWN_TRANSITION_TIME} ease-in-out;

        ${showOptions && css`
            transform: rotate(180deg);
        `};
    `;

    const arrowColour = showOptions ? theme.colours.curiousBlue : "black";

    return (
        <Arrow css={style} colour={arrowColour} />
    );
};

export default DropdownChevron;