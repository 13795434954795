import React from "react";
import Marquee from "react-fast-marquee";
import { isEmpty } from "lodash";

import useSetState from "core/hooks/useSetState";
import { sort } from "core/includes/sort";
import SlideContainer from "core/Components/Carousel/SlideContainer";
import SlideWrapper from "core/Components/Carousel/SlideWrapper";
import SectionContainer from "core/Components/SectionContainer";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import Wrapper from "core/Components/Wrapper";

import ImageText from "elements/Components/SliderWithVideo/components/ImageText";
import VideoPlayerContainer from "elements/Components/SliderWithVideo/components/VideoPlayerContainer";
import Placeholder from "elements/Components/SliderWithVideo/components/Placeholder";
import CarouselContainer from "elements/Components/SliderWithVideo/components/CarouselContainer";
import SlideImageAndTextContainer from "elements/Components/SliderWithVideo/components/SlideImageAndTextContainer";
import SlideImage from "elements/Components/SliderWithVideo/components/SlideImage";

type Props = {
    data: SliderWithVideo
};

type DefaultState = {
    onPlay: boolean,
    selectedVideo: SlideWithVideo,
};

const SliderWithVideo: React.FC<Props> = ({ data }) => {
    const videoList = sort(data.videoList);
    const [state, setState] = useSetState<DefaultState>({
        onPlay: false,
        selectedVideo: videoList[0],
    });

    const onClickVideoHandler = (video: SlideWithVideo) => {
        setState({
            onPlay: false,
            selectedVideo: video,
        })
    };

    const onClickVideoPlay = () => {
        setState({ onPlay: true });
    }

    return isEmpty(videoList) ? null : (
        <SectionContainer data={data}>
            <Wrapper flexDirection={"column"}>
                {(state.selectedVideo && state.onPlay) && (
                    <VideoPlayerContainer>
                        <VideoPlayer videoPath={state.selectedVideo.videoPath} autoPlay mute />
                    </VideoPlayerContainer>
                )}

                {(state.selectedVideo && !state.onPlay) && (
                    <Placeholder
                        video={state.selectedVideo}
                        {...state.selectedVideo.videoPath && { onClickVideoPlay }}
                    />
                )}

                <CarouselContainer>
                    <Marquee
                        gradient={false}
                        speed={40}
                        pauseOnHover={true}
                    >
                        {videoList.map((video: SlideWithVideo, index: number) => (
                            <SlideContainer key={index}>
                                <SlideWrapper>
                                    <SlideImageAndTextContainer
                                        video={video}
                                        onClickVideoHandler={onClickVideoHandler}
                                    >
                                        {video.image?.URL && (
                                            <>
                                                <SlideImage video={video} />
                                                <ImageText>
                                                    {video.header}
                                                </ImageText>
                                            </>
                                        )}
                                    </SlideImageAndTextContainer>
                                </SlideWrapper>
                            </SlideContainer>
                        ))}
                    </Marquee>
                </CarouselContainer>
            </Wrapper>
        </SectionContainer>
    );
};

export default SliderWithVideo;
