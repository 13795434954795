import React from "react";
import { css, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    children: React.ReactNode,
    autoNumbering: boolean,
    number: number,
};

const SubHeader: React.FC<Props> = ({ children, autoNumbering, number }) => {
    const theme = useTheme();
    const currentNumber = autoNumbering ? `${number + 1}. ` : "";

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
    `;

    return (
        <div css={style}>
            <Title Tag={"h2"} fontSizeType={"5"}>{currentNumber}{children}</Title>
        </div>
    );
};

export default SubHeader;