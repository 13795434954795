import React from "react";
import { css } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";
import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    Tag?: 'h1' | 'h2' | 'h5',
    colour?: string,
    marginBetweenLines?: number,
    isHTML?: boolean,
};

const Header: React.FC<Props> = ({
    children,
    Tag = "h2",
    colour = "",
    marginBetweenLines,
    isHTML = false,
}) => {
    const [fontSize, lineHeight] = useFontSize(Tag);

    const style = css`
        white-space: break-spaces;
        ${isHTML && css`
            p {
                font-size: ${fontSize}px;
                line-height: ${lineHeight}px;
            }
        `}

        ${colour && css`
            color: ${colour};
        `}

        ${marginBetweenLines && css`
            margin-bottom: ${marginBetweenLines}px;
        `};
    `;

    return (
        <ContentCombine
            Tag={Tag}
            css={style}
            {...isHTML && {
                defaultTextAlign: "center"
            }}
        >
            {children}
        </ContentCombine>
    );
};

export default Header;
