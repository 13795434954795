import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    height?: number,
    gap: number,
    featureBoxWidth: number,
    otherBoxWidth: number,
    extraPaddingTop: number,
    className?: string,
};

const FeatureColourBoxWrapper: React.FC<Props> = ({
    children,
    height,
    gap,
    featureBoxWidth,
    otherBoxWidth,
    extraPaddingTop,
    className = ''
}) => {
    const theme = useTheme();

    const style = css`
        position: absolute;
        top: ${90 + extraPaddingTop}px;
        display: grid;
        grid-template-columns: ${featureBoxWidth}px ${otherBoxWidth}px ${otherBoxWidth}px;
        gap: ${gap}px;
        place-items: center;
        padding: 5px 0;
        z-index: ${theme.zIndex.zIndexOne};
        
        ${height && css`
            height: ${height}px;
        `}

        ${theme.breakpoints.up('md')} {
            top: ${40 + extraPaddingTop}px;
        }
        
        ${theme.breakpoints.down('sm')} {
            grid-template-columns: ${featureBoxWidth}px ${otherBoxWidth}px;
        }
    `;

    return (
        <div css={style} className={className}>{children}</div>
    );
}

export default FeatureColourBoxWrapper;
