import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const TextContentContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const textContentSectionStyle = css`
        justify-content: flex-start;
        flex-direction: column;
        padding: 45px 0;

        ${theme.breakpoints.up("5xl")} {
            padding: 75px 0;
        }

        ${theme.breakpoints.only( "xl")} {
            padding: 43px 0;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 50px 0;
        }

        ${theme.breakpoints.only("md")} {
            padding: 25px 0;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 39px 0;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 24px 0;
        }
    `;

    return (
        <ContentSection css={textContentSectionStyle}>
            {children}
        </ContentSection>
    );
}

export default TextContentContainer;
