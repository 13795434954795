import React from "react";
import { css, useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";

import FooterLink from "core/Components/Footer/FooterLink";

type Props = {
    footerBottomMenu: [any] | undefined,
};

const FooterBottomSection: React.FC<Props> = ({
    footerBottomMenu,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: end;
        margin-top: 16px;
        width: 100%;
        
        ${theme.breakpoints.down("md")} {
            justify-content: start;
            flex-direction: column;
        }
    `;

    return (
        <div css={style}>
            {footerBottomMenu?.map((menu: StandardPage) => (
                <FooterLink key={'footer' + menu.id} isBottomMenu>
                    <NavLink to={'/' + menu.uRLSegment}>
                        {menu.titleForFooter ?? menu.title}
                    </NavLink>
                </FooterLink>
            ))}
        </div>
    );
};

export default FooterBottomSection;