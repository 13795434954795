import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";
import SocialShare from "core/Components/Socials/SocialShare";

type Props = {
    emailSubject?: string,
    className?: string,
}

const SocialsVerticalList: React.FC<Props> = ({ emailSubject, className }) => {
    const theme = useTheme();

    const containerStyle = css`
        background-color: white;
        width: 85px;
        padding: 26px 0 48px;
        box-shadow: ${theme.borderAndShadow.boxShadow7};
        border-radius: 52px;
        
        ${theme.breakpoints.up('5xl')} {
            width: 101px;
            padding: 41px 0;
        }

        ${theme.breakpoints.only('lg')} {
            width: 71px;
            padding: 40px 0 48px;
        }
    `;

    const socialsTitleStyle = css`
        line-height: 14px;
        color: ${theme.colours.curiousBlue2};
    `;

    const socialsStyle = css`
        padding-top: 10px;
        margin-left: 28px;
        
        ${theme.breakpoints.up('5xl')} {
            gap: 40px;
            padding-top: 50px;
            padding-bottom: 40px;
        }

        ${theme.breakpoints.only('lg')} {
            padding-top: 22px;
            margin-left: 22px;
        }
    `;

    return (
        <div css={containerStyle} className={className}>
            <ContentCombine fontSizeType={'9'} css={socialsTitleStyle}>
                Share
            </ContentCombine>
            <SocialShare
                css={socialsStyle}
                emailSubject={emailSubject}
            />
        </div>
    );
}

export default SocialsVerticalList;
