import React, { forwardRef, useRef, useState, useCallback } from "react";
import { css, useTheme } from "@emotion/react";

import { NOOP } from "core/constants";
import useFocusedImage from "core/hooks/useFocusedImage";
import passForwardRef from "core/includes/forwardRef";
import { isUp } from "theme/breakpoints";
import useBreakpoint from "core/hooks/useBreakpoint";
import ConditionalWrapper from "core/Components/ConditionalWrapper";

type Props = {
    image: string | Image,
    borderRadius?: string|number,
    backgroundColour?: string,
    onClickHandler?: ({ link, src, backgroundColour, img }: any) => void,
    className?: string,
    useFocusPoint?: boolean,
    useDivForFocusPoint?: boolean,
    divAlignItemForFocusPoint?: string,
    customAltText?: string,
    opacity?: number,
    gradientColor?: string,
};

const Image = forwardRef<HTMLInputElement, Props>(({
    image,
    borderRadius = 0,
    backgroundColour = "",
    onClickHandler = NOOP,
    className = "",
    useFocusPoint = false,
    useDivForFocusPoint = false,
    divAlignItemForFocusPoint = "",
    customAltText = '',
    opacity = 0,
    gradientColor = '255, 255, 255'
}, ref) => {
    const theme = useTheme();
    const { breakpoint } = useBreakpoint();
    const imgURL = typeof image === 'object' ? image?.URL : image;
    const altText = typeof image === 'object' ? image?.AltText || "" : "";
    const focus = useFocusPoint && typeof image === 'object' && image?.FocusPoint ? image.FocusPoint : [0, 0];
    const imgRef = useRef<any>();

    const [ratio, setRatio] = useState(1);

    const focusedImageRefCallback = useFocusedImage(focus?.[0], focus?.[1] && -focus[1]);

    const onImageClick = useCallback(() => {
        if (onClickHandler) {
            onClickHandler({ imgURL, backgroundColour, img: image });
        }
    }, [onClickHandler, imgURL, backgroundColour, image]);

    const useOpacity = !!opacity && isUp(breakpoint, 'lg');
    opacity = opacity / 100;

    const style = css`
        display: flex;
        
        ${useFocusPoint ? css`
            position: absolute !important;
            opacity: 0;
            // Should not set width and height for image when using FocusPoint.
            width: unset !important;
            height: unset !important;
        ` : css`
            position: relative !important;
            width: 100%;
            height: 100%;
        `};
        
        ${borderRadius > 0 && css`
            border-radius: ${borderRadius}px;
        `};
        
        ${onClickHandler !== NOOP && css`
            cursor: pointer;
        `};
        
        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};
    `;

    const divWrapperStyle = css`
        width: 100%;
        height: 100%;

        ${useDivForFocusPoint && !useFocusPoint && css`
            position: absolute;

            ${divAlignItemForFocusPoint && css`
                display: flex;
                align-items: ${divAlignItemForFocusPoint};
            `}
        `};

        ${useOpacity && css`
            ::after {
                display: flex;
                position: absolute;
                content: '';
                flex: 1;
                width: 100%;
                height: 100%;

                ${theme.breakpoints.up('lg')} {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: linear-gradient(
                        to right,
                        rgba(${gradientColor}, 1),
                        rgba(${gradientColor}, ${opacity}),
                        rgba(${gradientColor}, ${opacity / 1.1}),
                        rgba(${gradientColor}, 0),
                        transparent
                    );
                }
            }
        `}
    `;

    const imageRender = (
        <img
            className={className}
            css={style}
            {...useFocusPoint && {
                ref: (theRef: any) => {
                    if (!theRef) return;
                    imgRef.current = theRef;
                    const newRatio = imgRef.current.naturalWidth / imgRef.current.naturalHeight || 1;
                    if (newRatio !== ratio) {
                        setRatio(imgRef.current.naturalWidth / imgRef.current.naturalHeight || 1);
                    }
                    focusedImageRefCallback(theRef);
                    passForwardRef(theRef, ref);
                    // fadein image so it isn't janky (the official scientific term)
                    setTimeout(() => {
                        if (theRef.style.opacity <= 0 && !theRef.getAttribute('data-fade-in')) {
                            theRef.setAttribute('data-fade-in', "init");
                            theRef.style.transform = 'translateZ(0)';
                            // fade in
                            let iterations = 0;
                            let interval: any = null;
                            interval = setInterval(
                                () => {
                                    if (theRef.style.opacity < 1) {
                                        theRef.style.opacity = ++iterations * 0.1;
                                    } else {
                                        clearInterval(interval);
                                        theRef.setAttribute('data-fade-in', "finished");
                                    }
                                },
                                5
                            );
                        }
                    }, Math.floor(Math.random() * 10));
                }
            }}
            src={imgURL}
            alt={altText}
            onClick={onImageClick}
            loading="lazy"
        />
    );

    return (
        <ConditionalWrapper
            condition={(useDivForFocusPoint && !useFocusPoint) || useOpacity}
            wrap={(children: React.ReactNode) => (
                <div css={divWrapperStyle}>
                    {children}
                </div>
            )}
        >
            {imageRender}
        </ConditionalWrapper>
    );
});

export default Image;
