import React from "react";
import { useTheme, css } from "@emotion/react";

import DropDown, { FormDropdownOption } from "core/Components/Form/DropDown";

type Props = {
    hasError: boolean,
    setSelected: Function,
    selected: FormDropdownOption,
    options: FormDropdownOption[],
};

const FormDropDown: React.FC<Props> = ({
    hasError,
    setSelected,
    options,
    selected,
}) => {
    const theme = useTheme();

    const dropDownContainerStyle = css`
        width: 53%;
        height: 50px;
        margin-left: 3px;
        padding: 5px 0 5px 2px;
    `;

    const optionStyles = ({ selected }: { selected: boolean }) => css`
        padding-left: ${selected ? '4px' : '16px'};
        font-family: ${theme.fonts.frutiger};
        color: ${theme.colours.grey[920]};
        font-size: 14px;

        p {
            font-weight: ${theme.fonts.weights.light};
        }

        ${theme.breakpoints.up("5xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 12px;
        }
    `;

    const dropdownPlaceholderStyles = css`
        padding-left: 4px;

        font-size: 14px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 12px;
        }
    `;

    return (
        <div css={dropDownContainerStyle}>
            <DropDown
                placeholder="Select an option"
                selected={selected}
                setSelected={setSelected}
                options={options}
                searchable={false}
                hasError={hasError}
                extraOptionStyles={optionStyles({ selected: false })}
                extraSelectedStyles={optionStyles({ selected: true })}
                extraPlaceholderStyles={dropdownPlaceholderStyles}
            />
        </div>
    );
};

export default FormDropDown;
