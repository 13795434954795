import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isLoginDialog?: boolean,
};

const LoginLeftContainer: React.FC<Props> = ({children, isLoginDialog}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        
        ${!isLoginDialog && css`
            width: 50%;
            
            ${theme.breakpoints.down("md")} {
                width: 100%;
            }
    
            ${theme.breakpoints.up("5xl")} {
                padding: 138px 151px 178px 298px;
            }
    
            ${theme.breakpoints.between("3xl", "4xl")} {
                padding: 73px 103px 129px 166px;
            }
    
            ${theme.breakpoints.only("xxl")} {
                padding: 73px 57px 67px 122px;
            }
    
            ${theme.breakpoints.only("xl")} {
                padding: 30px 81px 37px 131px;
            }
    
            ${theme.breakpoints.only("lg")} {
                padding: 45px 39px 85px 45px;
            }
    
            ${theme.breakpoints.only("md")} {
                padding: 26px 189px;
            }
    
            ${theme.breakpoints.only("sm")} {
                padding: 22px 73px;
            }
    
            ${theme.breakpoints.down("xs")} {
                padding: 19px 27px;
            }
        `}

        ${isLoginDialog && css`
            width: 100%;
        `}
        
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default LoginLeftContainer;