import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";

type Props = {
    data: ContentWithList,
    contentListItem: ContentListItem,
    onClick?: () => void,
};

const Icon: React.FC<Props> = ({ data, contentListItem, onClick }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { chevronButtonColour, chevronColour } = colourTheme;
    const linkURL = contentListItem.innerURL || contentListItem.outerURL;

    const roundChevronButtonStyle = css`
        width: 33px;
        height: 33px;

        ${theme.breakpoints.down("sm")} {
            width: 27px;
            height: 27px;
        }
    `;

    return (
        <RoundChevronButton
            type={'right'}
            {...onClick ? { onClick: onClick } : { linkURL: linkURL }}
            roundColour={chevronButtonColour}
            chevronColour={chevronColour}
            css={roundChevronButtonStyle}
        />
    );
}

export default Icon;
