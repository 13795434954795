import React from "react";
import { css, useTheme } from "@emotion/react";
import { BsFacebook, BsInstagram, BsYoutube, BsTwitterX, BsLinkedin } from "react-icons/bs";
import classNames from "classnames";

import FooterSocialItem from "core/Components/Footer/FooterSocialItem";

const FooterSocialBox = () => {
    const theme = useTheme();
    const iconSize = 28;

    const style = css`
        position: relative;
        display: flex;
        justify-content: space-between;
        
        .social__item-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${iconSize}px;
            height: ${iconSize}px;
            border-radius: 50%;
            background-color: ${theme.colours.grey[800]};
            
            a {
                position: relative;
                width: ${iconSize - 10}px;
                height: ${iconSize - 10}px;
                z-index: 1;
            }
            
            &:not(:last-of-type) {
                margin-right: 9px;
            }
            
        }

        .social__item-wrapper.facebook {
            &:hover {
                background: ${theme.colours.azureRadiance2};
            }
        }

        .social__item-wrapper.instagram {
            &:hover {
                background: radial-gradient(circle farthest-corner at 35% 90%, ${theme.colours.koromiko}, transparent 50%), 
                radial-gradient(circle farthest-corner at 0 140%, ${theme.colours.koromiko}, transparent 50%), 
                radial-gradient(ellipse farthest-corner at 0 -25%, ${theme.colours.indigo}, transparent 50%), 
                radial-gradient(ellipse farthest-corner at 20% -50%, ${theme.colours.indigo}, transparent 50%), 
                radial-gradient(ellipse farthest-corner at 100% 0, ${theme.colours.fuchsiaBlue}, transparent 50%), 
                radial-gradient(ellipse farthest-corner at 60% -20%, ${theme.colours.fuchsiaBlue}, transparent 50%), 
                radial-gradient(ellipse farthest-corner at 100% 100%, ${theme.colours.cerise}, transparent), 
                linear-gradient(
                    ${theme.colours.blueMarguerite},
                    ${theme.colours.mediumRedViolet} 30%,
                    ${theme.colours.ceriseRed} 50%,
                    ${theme.colours.crusta} 70%,
                    ${theme.colours.koromiko2} 100%
                );
            }
        }

        .social__item-wrapper.youtube {
            &:hover {
                background: ${theme.colours.crimson2};
            }
        }

        .social__item-wrapper.twitter {
            &:hover {
                background: ${theme.colours.twitterBlue};
            }
        }

        .social__item-wrapper.linkedin {
            svg {
                fill: ${theme.colours.grey[800]};
            }
            
            a::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 80%;
                height: 80%;
                background-color: white;
            }
            
            &:hover {
                background: ${theme.colours.linkedinBlue};
                
                svg {
                    fill: ${theme.colours.linkedinBlue};
                }
            }
        }
    `;

    const socialIconStyle = css`
        position: relative;
        width: ${iconSize - 10}px;
        height: ${iconSize - 10}px;
        fill: ${theme.colours.white};
        z-index: 2;
    `;

    return (
        <div css={style}>
            <FooterSocialItem
                href={"https://www.facebook.com/writerstoolbox"}
                className={classNames("social__item-wrapper", "facebook")}
            >
                <BsFacebook css={socialIconStyle} />
            </FooterSocialItem>
            <FooterSocialItem
                href={"https://www.instagram.com/writerstoolbox"}
                className={classNames("social__item-wrapper", "instagram")}
            >
                <BsInstagram css={socialIconStyle} />
            </FooterSocialItem>
            <FooterSocialItem
                href={"https://www.youtube.com/c/WritersToolbox"}
                className={classNames("social__item-wrapper", "youtube")}
            >
                <BsYoutube css={socialIconStyle} />
            </FooterSocialItem>
            <FooterSocialItem
                href={"https://twitter.com/_writerstoolbox"}
                className={classNames("social__item-wrapper", "twitter")}
            >
                <BsTwitterX css={socialIconStyle} />
            </FooterSocialItem>
            <FooterSocialItem
                href={"https://www.linkedin.com/company/writerstoolbox/mycompany"}
                className={classNames("social__item-wrapper", "linkedin")}
            >
                <BsLinkedin css={socialIconStyle} />
            </FooterSocialItem>
        </div>
    );
};

export default FooterSocialBox;