import React, { memo } from "react";
import { css, useTheme } from "@emotion/react";

import Header from "elements/Components/ContactUs/components/Header";
import HubspotFormText from "elements/Components/ContactUs/components/HubspotFormText";
import Text from "elements/Components/ContactUs/components/Text";
import HubspotForm from "core/Components/HubspotForm";

type Props = {
    data: ContactUs,
};

const HubspotFormContainer: React.FC<Props> = ({ data }) => {
    const theme = useTheme();

    const style = css`
        ${theme.breakpoints.up("xl")} {
            width: 615px;
            padding: 41px 93px 0 40px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 484px;
            padding: 30px;
        }

        ${theme.breakpoints.between("sm", "md")} {
            width: 496px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 100%;
            padding: 19px;
        }
    `;

    const contentStyle = css`
        margin-left: 40px;

        @media (max-width: 1038px) {
            margin-left: 0;
        }
    `;

    return (
        <div css={style}>
            <HubspotFormText>
                <Header>{data.header}</Header>
                <Text>{data.text}</Text>
            </HubspotFormText>

            <div css={contentStyle}>
                <HubspotForm hubspotFormId={data.hubspotFormId} shouldLoadScript />
            </div>
        </div>
    );
};

export default memo(HubspotFormContainer);
