import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    showText?: boolean,
}

const BannerContentContainer: React.FC<Props> = ({
    children,
    showText = true,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        z-index: ${theme.zIndex.zIndexTwo};
        
        ${showText ? css`
            position: relative;
            flex-direction: column;
            
            ${theme.breakpoints.up("5xl")} {
                padding: 74px 204px;
            }
    
            ${theme.breakpoints.between("xxl", "4xl")} {
                padding: 65px 80px;
            }
    
            ${theme.breakpoints.only("xl")} {
                padding: 60px 80px;
            }
    
            ${theme.breakpoints.only("lg")} {
                padding: 45px 45px;
            }
    
            ${theme.breakpoints.between("sm", "md")} {
                padding: 55px 38px;
            }
    
            ${theme.breakpoints.down("xs")} {
                padding: 35px 20px;
            }
        ` : css`
            position: absolute;
            bottom: 61px;
            width: 100%;

            ${theme.breakpoints.up("5xl")} {
                bottom: 202px;
            }

            ${theme.breakpoints.between("3xl", "4xl")} {
                bottom: 97.5px;
            }

            ${theme.breakpoints.only("xl")} {
                bottom: 81px;
            }

            ${theme.breakpoints.only("lg")} {
                bottom: 73px;
            }

            ${theme.breakpoints.only("md")} {
                bottom: 90px;
            }

            ${theme.breakpoints.only("sm")} {
                bottom: 79.78px;
            }

            ${theme.breakpoints.down("xs")} {
                bottom: 57.78px;
            }
        `}
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default BannerContentContainer;
