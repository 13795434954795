import { useEffect } from "react";
import document from 'core/includes/document';

const script = (url: string, onload: any) => {
    const script = document.createElement("script");

    script.src = url;
    script.onload = onload;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);

    return () => {
        document.head.removeChild(script);
    };
};

export const useScript = (url: string, onload: any, reload?: any) => {
    useEffect(() => {
        if (reload) {
            return script(url, onload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
};

export const useDefaultScript = (url: string, onload: any) => {
    useEffect(() => {
        return script(url, onload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};