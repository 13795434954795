import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children?: React.ReactNode
};

const ImageSectionContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const leftSectionStyle = css`
        position: relative;
        height: 100%;
        flex-direction: column;

        ${theme.breakpoints.down("md")} {
            justify-content: start;
        }
    `;

    return (
        <ContentSection css={leftSectionStyle}>
            {children}
        </ContentSection>
    );
}

export default ImageSectionContainer;
