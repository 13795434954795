import React from 'react';
import { css } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
};

const SlideContainer: React.FC<Props> = ({
    children,
}) => {
    const Tag = "p"
    const [fontSize, lineHeight] = useFontSize(Tag, '12');

    const style = css`
        text-align: center;
        margin: 40px 0 53px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
    `;

    return (
        <Tag css={style}>
            {children}
        </Tag>
    );
};

export default SlideContainer;