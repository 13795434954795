import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    options: Option[],
    selectedValue: Option[],
    isLargeLabel?: boolean,
    defaultName?: string,
};

const DropdownButtonPlaceholder: React.FC<Props> = ({
    options,
    selectedValue,
    isLargeLabel = false,
    defaultName,
}) => {
    const theme = useTheme();

    const placeholder = useMemo(() => {
        if (selectedValue.length === 1) {
            return selectedValue[0].label;
        } else if (selectedValue.length === options.length) {
            return selectedValue.find((item) => item.value === "")?.label;
        } else {
            if (selectedValue.length === 0 && defaultName) {
                return defaultName;
            } else {
                return `${selectedValue.length} items selected`;
            }
        }
    }, [selectedValue, options.length, defaultName]);

    const dropdownSelectedValue = css`
        margin-right: 10px;
        font-weight: ${theme.fonts.weights.bold};
        white-space: nowrap;

        ${isLargeLabel && css`
            font-size: 26px;
        `}

        ${!isLargeLabel && css`
            ${theme.breakpoints.up("5xl")} {
                font-size: 20px;
                line-height: 34px;
            }

            ${theme.breakpoints.between("3xl", "4xl")} {
                font-size: 17px;
                line-height: 30px;
            }

            ${theme.breakpoints.between("xl", "xxl")} {
                font-size: 15px;
                line-height: 30px;
            }

            ${theme.breakpoints.only("lg")} {
                font-size: 13px;
                line-height: 20px;
            }

            ${theme.breakpoints.only("md")} {
                font-size: 15px;
                line-height: 20px;
            }

            ${theme.breakpoints.down("sm")} {
                font-size: 13px;
                line-height: 15px;
            }
        `}
    `;

    return (
        <div css={dropdownSelectedValue}>{placeholder}</div>
    );
}

export default DropdownButtonPlaceholder;
