import React, { useMemo } from "react";
import { css, useTheme, SerializedStyles } from "@emotion/react";

import { DROPDOWN_TRANSITION_TIME } from "core/constants";
import useBreakpoint from "core/hooks/useBreakpoint";
import Collapse from "core/Components/Collapse";
import useDropdownOptionHeight from "core/Components/Buttons/DropdownButton/hooks/useDropdownOptionHeight";
import { isUp } from "theme/breakpoints";

type Props = {
    children: React.ReactNode,
    customOptionContainerStyle?: SerializedStyles,
    optionBackgroundColour: string,
    zIndex: number,
    useInnerScroll?: boolean,
    optionCount: number,
    showMenu: boolean,
};

const DropdownButtonOptionsContainer: React.FC<Props> = ({
    children,
    customOptionContainerStyle,
    optionBackgroundColour,
    zIndex,
    useInnerScroll = true,
    optionCount,
    showMenu,
}) => {
    const theme = useTheme();
    const optionHeight = useDropdownOptionHeight();
    const showInnerScroll = optionCount > 0; // Show & hide the inner scroll bar.
    const { breakpoint } = useBreakpoint();
    const scrollBarSpace = useMemo(() => {
        switch (true) {
            case !useInnerScroll || !showInnerScroll:
                return 0;
            case isUp(breakpoint, 'lg'):
                return 15;
            default:
                return 12;
        }
    }, [breakpoint, useInnerScroll, showInnerScroll]);

    const dropdownMenu = css`
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(4px);
        width: 100%;
        border-radius: 8px;
        background-color: ${optionBackgroundColour};
        color: ${theme.colours.white};
        z-index: ${zIndex};
        
        .filter-dropdown {
            margin-bottom: 20px;
            width: calc(100% - ${scrollBarSpace}px);

            ${useInnerScroll && css`
                opacity: 1;
                overflow: hidden;

                ${showInnerScroll && css`
                    overflow-y: auto;
                `}

                ${!showMenu && css`
                    transition: ${optionCount / 10}s;
                    opacity: 0;
                `}
                
                ${theme.breakpoints.up('5xl')} {
                    margin-top: calc(${optionHeight}px + 15px);
                    max-height: 190px;
                }

                ${theme.breakpoints.between('xl', '4xl')} {
                    margin-top: calc(${optionHeight}px + 15px);
                    max-height: 172px;
                }

                ${theme.breakpoints.down('lg')} {
                    margin-top: calc(${optionHeight}px + 10px);
                    max-height: 140px;
                }
            `}
        }

        .filter-dropdown::-webkit-scrollbar {
            width: 3px;
            background-color: ${optionBackgroundColour};
        }

        .filter-dropdown::-webkit-scrollbar-thumb {
            background-color: white;
        }

        .filter-dropdown::-webkit-scrollbar-track {
            border-left: 1px solid ${optionBackgroundColour};
            border-right: 1px solid ${optionBackgroundColour};
            background-color: white;
        }

        ${customOptionContainerStyle}
    `;

    return (
        <Collapse isOpen={showMenu} css={dropdownMenu} transitionTime={DROPDOWN_TRANSITION_TIME}>
            <div className={'filter-dropdown'}>
                {children}
            </div>
        </Collapse>
    );
}

export default DropdownButtonOptionsContainer;
