import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    backgroundColour: string,
    children: React.ReactNode,
};

const PricingCardContainer: React.FC<Props> = ({
    backgroundColour,
    children,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 281px;
        min-height: 526px;
        border-radius: 12px;
        padding: 0 12px;
        overflow: hidden;
        box-shadow: ${theme.borderAndShadow.boxShadow3};
        background-color: ${backgroundColour};

        ${theme.breakpoints.up("5xl")} {
            width: 432px;
            min-height: 807px;
            border-radius: 33px;
            padding: 0 20px;
        }

        ${theme.breakpoints.only("md")} {
            width: 215px;
            min-height: 452px;
            border-radius: 12px;
            padding: 0 8px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 251px;
            min-height: 454px;
            border-radius: 12px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default PricingCardContainer;
