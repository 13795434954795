import BannerWithFullImage from "elements/Components/BannerWithFullImage/BannerWithFullImage";
import BannerWithImage from "elements/Components/BannerWithImage/BannerWithImage";
import CollapseList from "elements/Components/CollapseList/CollapseList";
import ContactUs from "elements/Components/ContactUs/ContactUs";
import ContentWithImageHorizontalCards from "elements/Components/ContentWithImageHorizontalCards/ContentWithImageHorizontalCards";
import ContentWithImage from "elements/Components/ContentWithImage/ContentWithImage";
import ContentWithList from "elements/Components/ContentWithList/ContentWithList";
import ContentWithImageVerticalCards from "elements/Components/ContentWithImageVerticalCards/ContentWithImageVerticalCards";
import DescriptionWithSimpleText from "elements/Components/DescriptionWithText/DescriptionWithSimpleText";
import DescriptionWithSimpleTextAndImage from "elements/Components/DescriptionWithText/DescriptionWithSimpleTextAndImage";
import DescriptionWithRichText from "elements/Components/DescriptionWithRichText/DescriptionWithRichText";
import DescriptionWithRichTextAndImage from "elements/Components/DescriptionWithRichTextAndImage/DescriptionWithRichTextAndImage";
import FullImage from "elements/Components/FullImage/FullImage";
import SingleVideo from "elements/Components/SingleVideo/SingleVideo";
import SliderWithFeedback from "elements/Components/SliderWithFeedback/SliderWithFeedback";
import SliderWithImage from "elements/Components/SliderWithImage/SliderWithImage";
import SliderWithVideo from "elements/Components/SliderWithVideo/SliderWithVideo";
import SliderWithVideoPopup from "elements/Components/SliderWithVideoPopup/SliderWithVideoPopup";
import SubscriptionPlan from "elements/Components/SubscriptionPlan/SubscriptionPlan";
import Timeline from "elements/Components/Timeline/Timeline";
import TopBanner from "elements/Components/TopBanner/TopBanner";
import ContentWithRoundImage from "elements/Components/ContentWithRoundImage/ContentWithRoundImage";
import SubscribeForUpdates from "elements/Components/SubscribeForUpdates/SubscribeForUpdates";
import SliderWithQuote from "elements/Components/SliderWithQuote/SliderWithQuote";
import HubspotForm from "elements/Components/HubspotForm/HubspotForm";
import DescriptionWithRichTextSingleColumn
    from "elements/Components/DescriptionWithRichTextSingleColumn/DescriptionWithRichTextSingleColumn";
import SubscriptionList from "elements/Components/SubscriptionList/SubscriptionList";

type Component = {
    [key: string]: any,
};

/**
 * Add your components here.
 */
const components: Component = {
    BannerWithFullImage,
    BannerWithImage,
    CollapseList,
    ContactUs,
    ContentWithImageHorizontalCards,
    ContentWithImage,
    ContentWithList,
    ContentWithImageVerticalCards,
    ContentWithRoundImage,
    DescriptionWithRichText,
    DescriptionWithRichTextAndImage,
    DescriptionWithSimpleText,
    DescriptionWithSimpleTextAndImage,
    DescriptionWithRichTextSingleColumn,
    FullImage,
    SingleVideo,
    SliderWithFeedback,
    SliderWithImage,
    SliderWithQuote,
    SliderWithVideo,
    SliderWithVideoPopup,
    SubscribeForUpdates,
    SubscriptionPlan,
    Timeline,
    TopBanner,
    HubspotForm,
    SubscriptionList,
};

export default components;
