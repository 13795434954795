import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    video: SlideWithVideo
};

const SlideImage: React.FC<Props> = ({ video }) => {
    const theme: Theme = useTheme();

    const videoImageStyle = css`
        height: 160px;

        ${theme.breakpoints.up("5xl")} {
            height: 243px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            height: 184px;
        }

        ${theme.breakpoints.only("xl")} {
            height: 184px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            height: 111px;
        }

        ${theme.breakpoints.only("sm")} {
            height: 73px;
        }

        ${theme.breakpoints.down("xs")} {
            height: 124px;
        }
    `;

    return (
        <Image
            image={video.image}
            borderRadius={0}
            css={videoImageStyle}
        />
    );
};

export default SlideImage;
