import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
};

const Wrapper: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const imageCardSectionStyle = css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 26px;
        padding: 80px 0;

        ${theme.breakpoints.up("3xl")} {
            gap: 62px;
            padding: 100px 0;
        }

        ${theme.breakpoints.only("xl")} {
            gap: 23px;
            padding: 60px 0;
        }

        ${theme.breakpoints.only("lg")} {
            gap: 16px;
            padding: 50px 0;
        }

        ${theme.breakpoints.down("md")} {
            grid-template-columns: 1fr;
            padding: 40px 0;
        }

        ${theme.breakpoints.only("md")} {
            gap: 91px;
        }

        ${theme.breakpoints.only("sm")} {
            gap: 46px;
        }

        ${theme.breakpoints.down("xs")} {
            gap: 42px;
        }
    `;

    return (
        <WrapperFromCore css={imageCardSectionStyle}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
