import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const HeaderContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        background-color: ${theme.colours.alabaster2};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default HeaderContainer;