import React from "react";
import { css, useTheme } from "@emotion/react";
import { BreakpointSize, isDown } from "theme/breakpoints";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    children: React.ReactNode,
    className?: string,
    chevronDirection: "left" | "right",
    leftAndRight?: number,
    size?: number,
    imageHeight?: number,
    topOffset?: number,
    breakpointForBottomChevrons?: BreakpointSize,
};

const ChevronContainer: React.FC<Props> = ({
    children,
    className,
    chevronDirection,
    leftAndRight = 72,
    size = 30,
    imageHeight = 0,
    topOffset = 0,
    breakpointForBottomChevrons = "md",
}) => {
    const { breakpoint } = useBreakpoint();
    const theme = useTheme();

    const style = css`
        position: absolute;

        // / 2: Middle of the image
        ${chevronDirection === "left"
            ? css`left: -${leftAndRight}px; top: ${imageHeight / 2 - size / 2 + topOffset}px`
            : css`right: -${leftAndRight}px; top: ${imageHeight / 2 - size / 2 + topOffset}px`
        };

        // 27: margin-top of ImageText
        ${isDown(breakpoint, breakpointForBottomChevrons) && css`
            ${theme.breakpoints.only("md")} {
                ${chevronDirection === "left"
                    ? css`left: -${leftAndRight}px; top: ${imageHeight + 27 + topOffset}px`
                    : css`right: -${leftAndRight}px; top: ${imageHeight + 27 + topOffset}px`
                };
            }

            // 28: margin-top of ImageText
            ${theme.breakpoints.only("sm")} {
                ${chevronDirection === "left"
                    ? css`left: -${leftAndRight}px; top: ${imageHeight + 28 + topOffset}px`
                    : css`right: -${leftAndRight}px; top: ${imageHeight + 28 + topOffset}px`
                };
            }

            // 28: margin-top of ImageText, 7: Set the detail position
            ${theme.breakpoints.down("xs")} {
                ${chevronDirection === "left"
                    ? css`left: 0; top: ${imageHeight + 28 + topOffset}px`
                    : css`right: 0; top: ${imageHeight + 28 + topOffset}px`
                };
            }`
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default ChevronContainer;
