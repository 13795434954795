import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import VideoViewLayer from "core/Components/Video/VideoViewLayer";

type Props = {
    children: React.ReactNode
};

const VideoPlayerContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const videoPlayerContainerStyle = css`
        width: 1440px;
        height: 810px;
        z-index: ${theme.zIndex.zIndexLow};
        
        ${theme.breakpoints.up("5xl")} {
            width: 2200px;
            height: 1237px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 1599px;
            height: 899px;
        }

        ${theme.breakpoints.only("xl")} {
            width: 1280px;
            height: 719px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 1024px;
            height: 576px;
        }

        ${theme.breakpoints.only("md")} {
            width: 768px;
            height: 432px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 568px;
            height: 319px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 360px;
            height: 203px;
        }
    `;

    return (
        <VideoViewLayer css={videoPlayerContainerStyle}>
            {children}
        </VideoViewLayer>
    );
};

export default VideoPlayerContainer;
