import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ImageCarouselContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        .active {
            .paging-dot {
                fill: ${theme.colours.curiousBlue2};
            }
        }

        button {
            opacity: 1 !important;

            .paging-dot {
                margin: 0 7px 14px;
                width: 7px;
                height: 7px;

                circle { cx: 3; cy: 3; r: 3; }

                ${theme.breakpoints.up("5xl")} {
                    width: 13px;
                    height: 13px;
                    margin: 0 23px 126px;
                }

                ${theme.breakpoints.between("3xl", "4xl")} {
                    width: 13px;
                    height: 13px;
                    margin: 0 23px 32px;
                }

                ${theme.breakpoints.only("xl")} {
                    margin: 0 7px 22px;
                }

                ${theme.breakpoints.only("lg")} {
                    margin: 0 6px 24px;
                }

                ${theme.breakpoints.only("md")} {
                    margin: 0 6px 39px;
                }

                ${theme.breakpoints.only("sm")} {
                    margin: 0 6px 25px;
                }

                ${theme.breakpoints.down("xs")} {
                    margin: 0 6px 12px;
                }
            }
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ImageCarouselContainer;
