import React from "react";
import { useTheme, css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import RoundButton from "core/Components/Buttons/RoundButton";

const ErrorPageButton: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const style = css`
        width: 157px;
        height: 42px;

        ${theme.breakpoints.up("5xl")} {
            width: 183px;
            height: 49px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            width: 147px;
            height: 42px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 125px;
            height: 34px;
        }
    `;

    const textStyle = css`
        color: ${theme.colours.white};
        font-size: 20px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 22px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
        }
    `;

    return (
        <RoundButton css={style} buttonTextCustomStyle={textStyle} onButtonClick={() => navigate(-1)}>
            Go back
        </RoundButton>
    );
};

export default ErrorPageButton;
