import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    headerMenu: StandardPage,
    closeMenuHandler: Function,
};

const BurgerNavSmallHeaderMenuTitle: React.FC<Props> = ({
    children,
    headerMenu,
    closeMenuHandler,
}) => {
    const theme = useTheme();

    const style = css`
        color: ${theme.colours.white};

        ${theme.breakpoints.only("md")} {
            font-size: 19px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 18px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 16px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default BurgerNavSmallHeaderMenuTitle;