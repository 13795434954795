import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
};

const HeaderText: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        padding-top: 42px;

        ${theme.breakpoints.down("sm")} {
            padding-top: 17px;
        }
    `;

    const titleStyle = css`
        color: ${theme.colours.tundora2};
        opacity: 0.84;
    `;

    return (
        <div css={style}>
            <ContentCombine Tag={"p"} fontSizeType={"9"} css={titleStyle}>{children}</ContentCombine>
        </div>
    );
};

export default HeaderText;