import React from "react";
import { useTheme, css } from "@emotion/react";

type Props = {
    children: string,
};

const ErrorPageNumber: React.FC<Props> = ({ children = "404" }) => {
    const theme = useTheme();

    const errorNumberStyle = css`
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: italic;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
        opacity: 0.05;
        width: 550px;
        height: 363px;
        font-size: 300px;
        line-height: 400px;
        
        ${theme.breakpoints.up("5xl")} {
            width: 648px;
            height: 424px;
            font-size: 350px;
            line-height: 464px;
        }
        
        ${theme.breakpoints.between("md", "lg")} {
            width: 467px;
            height: 303px;
            font-size: 250px;
            line-height: 400px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 285px;
            height: 182px;
            font-size: 150px;
            line-height: 400px;
        }
    `;

    return (
        <div css={errorNumberStyle}>
            {children}
        </div>
    );
};

export default ErrorPageNumber;
