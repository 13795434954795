import React, { useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import Gallery from "core/Components/Item/Gallery";
import GroupHeading from "core/Components/Item/GroupHeading";
import ItemChevron from "core/Components/Item/ItemChevron";
import ItemSection from "core/Components/Item/ItemSection";
import ItemWrapper from "core/Components/Item/ItemWrapper";
import LeftContainer from "core/Components/Item/LeftContainer";
import MainHeading from "core/Components/Item/MainHeading";
import RightContainer from "core/Components/Item/RightContainer";
import RightTextContainer from "core/Components/Item/RightTextContainer";
import TitleAndText from "core/Components/Item/TitleAndText";
import Quote from "core/Components/Item/Quote";
import Writer from "core/Components/Item/Writer";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import SectionContainer from "core/Components/SectionContainer";

import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { useAppState } from "core/contexts/AppContext";

type DefaultState = {
    videoPath: string,
    showVideo: boolean,
}

const INITIAL_STATE = {
    videoPath: '',
    showVideo: false,
};

type Props = {
    data: SuccessStoryPage,
};

const SuccessStoryPage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [, setAppState] = useAppState();
    const { minWidth } = useBreakpoint();

    useEffect(() => {
        setAppState({
            currentPageName: data.title
        });
    }, [data.title, setAppState]);

    const [videoState, setVideoState] = useSetState<DefaultState>(INITIAL_STATE);

    const colourTheme = data?.theme ? JSON.parse(data?.theme) : null;
    const onShowVideoClick = (url: string) => {
        if (url) {
            setVideoState({
                videoPath: url,
                showVideo: true,
            });
        }
    };
    const onCloseVideoClick = () => setVideoState({ showVideo: false });

    const themeColour = useMemo(() => {
        return colourTheme?.colour ?? (
            data?.schoolName
                ? theme.colours.successStories.find(
                    (colourSet: { target: string, colour: string }) => data?.schoolName.toLowerCase().includes(colourSet.target)
                )?.colour
                : "white"
        );
    }, [colourTheme?.colour, data?.schoolName, theme.colours.successStories]);

    const [
        chevronSize,
        chevronColour,
        chevronBackground,
        chevronBackgroundHover,
        marginBetweenLines
    ] = useMemo(() => {
        const white = theme.colours.white;
        const grey = theme.colours.grey[500];
        const hoverColour = theme.colours.azureRadiance;

        switch (true) {
            case minWidth >= theme.breakpoints.sizes['5xl']:
                return [41, white, grey, hoverColour, 27];
            case minWidth >= theme.breakpoints.sizes['3xl']:
                return [32, white, grey, hoverColour, 23];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [32, white, grey, hoverColour, 13];
            case minWidth >= theme.breakpoints.sizes['sm']:
                return [24, white, grey, hoverColour, 13];
            default:
                return [24, white, grey, hoverColour, 8];
        }
    }, [
        minWidth,
        theme.colours.white,
        theme.colours.grey,
        theme.breakpoints.sizes,
        theme.colours.azureRadiance
    ]);

    return (
        <SectionContainer>
            <ItemChevron chevronSize={chevronSize}>
                <RoundChevronButton
                    type={"left"}
                    onClick={() => navigate(-1)}
                    roundColour={chevronBackground}
                    roundHoverColour={chevronBackgroundHover}
                    chevronColour={chevronColour}
                    size={chevronSize}
                />
            </ItemChevron>
            <ItemWrapper>
                <ItemSection fontColour={"black"}>
                    <LeftContainer>
                        {data?.imagesAndVideos && (
                            <Gallery imagesAndVideos={data.imagesAndVideos} onVideoClick={onShowVideoClick} />
                        )}
                    </LeftContainer>
                </ItemSection>
                <ItemSection fontColour={theme.colours.white} backgroundColour={themeColour}>
                    <RightContainer>
                        <GroupHeading text={data?.groupName} textColour={data?.textColour} />
                        <MainHeading text={data?.title} textColour={data?.textColour} />
                        <RightTextContainer>
                            <TitleAndText
                                textHeader={data?.descriptionHeader}
                                text={data?.description}
                                alignment={"center"}
                                titleColour={data?.textColour}
                                anchorColour={theme.colours.supernova}
                            />
                        </RightTextContainer>
                        <Quote text={data?.quote} textColour={data?.textColour} />
                        <Writer colour={data?.textColour} marginBetweenLines={marginBetweenLines}>{data?.writer}</Writer>
                    </RightContainer>
                </ItemSection>
            </ItemWrapper>

            {videoState.showVideo && (
                <VideoViewContainer
                    clearPlayVideo={onCloseVideoClick}
                    useBackground
                    leftSectionColour={theme.colours.white}
                    rightSectionColour={themeColour}
                >
                    <VideoViewLayer>
                        <VideoPlayer videoPath={videoState.videoPath} autoPlay />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}
        </SectionContainer>
    );
};

export default SuccessStoryPage;
