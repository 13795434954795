import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";

import TextClamp from "react-string-clamp";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    text: string,
    lineHeight: number,
    className?: string,
};

const TextClampAutoLines: React.FC<Props> = ({ text, lineHeight, className = '' }) => {
    const shortDescRef: any = useRef(null);
    const { breakpoint } = useBreakpoint();
    const [, setUpdate] = useState({});

    useEffect(() => {
        setUpdate({});
    }, [breakpoint]);

    const numberOfLinesShortDesc = Math.floor(
        shortDescRef?.current?.clientHeight / lineHeight
    ) || 1;

    const style = css`
        line-height: ${lineHeight}px;
        display: flex;
    `;

    return (
        <div ref={shortDescRef} css={style} className={className}>
            <TextClamp
                text={text ?? ''}
                lines={numberOfLinesShortDesc}
            />
        </div>
    );
}

export default TextClampAutoLines;
