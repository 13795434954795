import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import LinkTo from "core/Components/LinkTo";

type Props = {
    children: React.ReactNode,
    currentURLSegment?: string,
    link: string,
    isLast: boolean,
};

const BreadcrumbItem: React.FC<Props> = ({
    children,
    currentURLSegment = "",
    link,
    isLast
}) => {
    const theme: Theme = useTheme();

    const style = css`
        ${(link.indexOf(currentURLSegment) > -1 || currentURLSegment === 'home') ? css`
            color: ${theme.colours.curiousBlue};
        ` : css`
            color: ${theme.colours.gray2}
        `}
    `;
    
    const menuDivideStyle = css`
        padding: 0 10px;
        color: ${theme.colours.gray2}
    `;

    return (
        <>
            <span css={style}>
                <LinkTo to={link}>{children}</LinkTo>
            </span>

            {!isLast && (
                <span css={menuDivideStyle}>/</span>
            )}
        </>
    );
};

export default BreadcrumbItem;