import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const InputNumberUpDown: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;

        ${theme.breakpoints.up('5xl')} {
            svg {
                width: 24px;
                height: 24px;
            }
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            svg {
                width: 16px;
                height: 16px;
            }
        }

        ${theme.breakpoints.down('md')} {
            padding-left: 2px;
            
            svg {
                width: 12px;
                height: 12px;
            }
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    )
};

export default InputNumberUpDown;