import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    headerColour?: string,
};

const Header: React.FC<Props> = ({
    children,
    headerColour,
}) => {
    const theme = useTheme();

    const style = css`
        white-space: break-spaces;
        font-weight: ${theme.fonts.weights.bold};
        font-size: 45px;
        line-height: 64px;
        margin-bottom: 42px;
        
        ${headerColour && css`
            color: ${headerColour};
        `};
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 80px;
            line-height: 98px;
            margin-bottom: 52px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 26px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 24px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 96px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 44px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default Header;
