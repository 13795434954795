import colours from 'theme/colours';

export const NOOP = () => {};

export const getNumberWithCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const FOOTER_COUNTRY_DROPDOWN_HEIGHT = "38px";

export const DROPDOWN_TRANSITION_TIME = "0.5s";

export const DROPDOWN_OPTION_BACKGROUND_COLOUR = colours.pictonBlue2;

export const DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR = colours.malibu2;

export const DROPDOWN_ARROW_COLOUR = colours.silverChalice2;
