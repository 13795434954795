import React from "react";
import { css, useTheme } from "@emotion/react";
import { HiChevronDown } from "react-icons/hi";

type Props = {
    tabbedMenu: boolean,
};

const BurgerNavSmallHeaderMenuChevron: React.FC<Props> = ({
    tabbedMenu,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.colours.white};
        
        ${tabbedMenu && css`
            transform: rotate(180deg);
        `}

        &:hover {
            opacity: 0.7;
        }
    `;

    return (
        <div css={style}>
            <HiChevronDown fontSize={"30px"} cursor={"pointer"} />
        </div>
    );
};

export default BurgerNavSmallHeaderMenuChevron;