import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    className?: string,
    colour?: string,
};

const DescriptionText: React.FC<Props> = ({
    children,
    className,
    colour,
}) => {
    const theme = useTheme();

    const style = css`
        text-align: center;
        white-space: break-spaces;
        margin: 0 auto 52px;
        
        ${colour && css`
            color: ${colour};
        `};

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 55px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 31px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 42px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 32px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 29px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 17px;
        }
    `;

    return (
        <ContentCombine Tag={'p'} fontSizeType={"5"} css={style} className={className}>{children}</ContentCombine>
    );
};

export default DescriptionText;
