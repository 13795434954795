import React from 'react';
import components from 'elements/config/components';

type Props = {
    data: StandardPage,
};

const StandardPage: React.FC<Props> = ({ data }) => (
    <>
        {data?.elements && data.elements.map((ele: any, index: number) => {
            let Component = components.hasOwnProperty(ele.__typename) && components[ele.__typename]
            return <Component data={ele} key={`${ele.id}-${index}`} />
        })}
    </>
);

export default StandardPage;