import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode
};

const ModalText: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const contentTextStyle = css`
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: center;
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 27px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 14px;
            line-height: 18px;
        }
    `;

    return (
        <ContentCombine css={contentTextStyle}>{children}</ContentCombine>
    );
};

export default ModalText;
