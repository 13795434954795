import React from 'react';
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    milestoneYear?: string,
    colour: string,
    circleSize: number,
    isOddOrder?: boolean,
    isLargeTitle: boolean,
};

const TimelineCircle: React.FC<Props> = ({
    milestoneYear,
    colour,
    circleSize,
    isOddOrder,
    isLargeTitle,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        position: absolute;
        width: ${circleSize}px;
        height: ${circleSize}px;
        top: calc(50% - ${circleSize / 2}px);
        left: calc(37.5% - ${circleSize / 2}px);
        border-radius: 50%;
        background: ${colour};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        white-space: break-spaces;
        font-weight: ${theme.fonts.weights.semiBold};

        ${theme.breakpoints.up("5xl")} {
            ${isLargeTitle 
                ? css`font-size: 30px;`
                : css`font-size: 23px;`
            }
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            ${isLargeTitle
                ? css`font-size: 21px;`
                : css`font-size: 18px;line-height: 23px;`
            }
        }

        ${theme.breakpoints.between("lg", "xl")} {
            ${isLargeTitle
                ? css`font-size: 21px;`
                : css`font-size: 14px;`
            }
        }

        ${theme.breakpoints.only("md")} {
            top: ${circleSize / -2}px;
            
            ${isOddOrder ? css`
                left: unset;
                right: ${circleSize / -2}px;
            ` : css`
                left: ${circleSize / -2}px;
            `}
        }

        ${theme.breakpoints.down("sm")} {
            top: ${circleSize / -2}px;

            ${isOddOrder ? css`
                left: unset;
                right: ${circleSize / -2}px;
            ` : css`
                left: ${circleSize / -2}px;
            `}
        }
    `;

    return (
        <p css={style} className={'timeline-circle-state-hover'}>
            {milestoneYear ? milestoneYear : ''}
        </p>
    );
};

export default TimelineCircle;
