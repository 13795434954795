import { improveDecimals } from "core/includes/formatters";

export const formatNumber = (number: number) => {
    // Make sure there are 2 decimal places if at least 1 is present.
    // Add a comma for values over 1000.
    return String(improveDecimals(number)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const n = {
    formatNumber
}

export default n;