import React, { FunctionComponent } from 'react';

interface IConditionalWrapProps {
    condition: boolean;
    wrap: Function;
    children: React.ReactNode;
}

const ConditionalWrapper: FunctionComponent<IConditionalWrapProps> = ({
    condition,
    wrap,
    children,
}) => (condition ? wrap(children) : <>{children}</>);

export default ConditionalWrapper;