import React from "react";

type Props = {
    children: React.ReactNode,
};

const FeatureArea: React.FC<Props> = ({ children }) => (
    <div>{children}</div>
);

export default FeatureArea;
