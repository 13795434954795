import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import SearchableListMenu, { Option } from "core/Components/Form/SearchableListMenu";
import { OnFieldChange } from "core/hooks/useForm";
import useSetState from "core/hooks/useSetState";
import Countries from "core/includes/countries";
import { ReactComponent as CrossIcon } from "images/icons/cross-no-fill.svg";
import { countryToPhoneCode } from "theme/utils";
import Input from "./Input";
import DropdownChevron from "./DropdownChevron";

type GenericOption = {
    [valueOrLabel: string]: any,
};

type LabelFunction = (option: GenericOption) => string;

type Props = {
    clearable?: boolean,
    disableSearch?: boolean,
    hasError?: boolean,
    labelField?: string | LabelFunction,
    name: string,
    options: GenericOption[],
    pinnedOptionValues: string[],
    onChange: OnFieldChange,
    greyOutOptionFunc?: (option: Option) => boolean,
    placeholder?: string,
    value: string,
    valueField?: string,
    containsSearchBox?: boolean,
    onOptionSelectedFunc?: (option: string) => void,
    variant?: SearchableListVariant,
    externalInputRef?: any,
    phoneCode?: string,
    className?: string,
    passedInputRef?: React.RefObject<HTMLInputElement>,
    fieldReadOnly?: boolean,
    fullHeightForOptions?: boolean,
};

type State = {
    active: boolean,
    search: string,
};

const DEFAULT_STATE: State = {
    active: false,
    search: '',
};

const SearchableList: React.FC<Props> = ({
    clearable = false,
    disableSearch = false,
    hasError = false,
    labelField = 'ID',
    name,
    options,
    pinnedOptionValues,
    onChange,
    greyOutOptionFunc,
    placeholder,
    value,
    valueField = 'Title',
    containsSearchBox = false,
    onOptionSelectedFunc,
    variant,
    externalInputRef,
    phoneCode,
    className,
    fieldReadOnly = false,
    passedInputRef,
    fullHeightForOptions = false,
}) => {
    const theme: Theme = useTheme();
    const [state, setState] = useSetState<State>(DEFAULT_STATE);
    const dropdownRef = useRef<HTMLInputElement>(null);
    let inputRef = useRef<HTMLInputElement>(null);
    if (passedInputRef) inputRef = passedInputRef;

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState({
                    active: false,
                });
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setState, dropdownRef]);

    // generate label, values
    const labelledOptions: Option[] = useMemo(() => {
        // get labels
        return options?.map((option: GenericOption) => {
            let label = typeof labelField === "function" ? labelField(option) : option[labelField];

            if (variant === 'phone') label += ` ${countryToPhoneCode(option[valueField])}`;

            return {
                label,
                value: option[valueField],
            }
        });
    }, [labelField, options, valueField, variant]);

    const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
        setState({
            search: e.currentTarget.value,
        });
    };

    // determine the label of the current value
    const valueLabel = useMemo(() => {
        if (!value) {
            return '';
        }
        const selectedOption = labelledOptions.find((option) => option.value === value);
        return selectedOption ? selectedOption.label : value;
    }, [labelledOptions, value]);

    /**
     * Clear the value.
     */
    const onClear = useCallback(() => {
        onChange(name, '');
        setState({ search: "" });
    }, [onChange, name, setState]);

    /**
     * Handle show/hide of dropdown menu
     */
    const toggleDropdown = useCallback(() => {
        const isActive = !state.active;

        setState((prevState: State) => ({
            active: isActive,
        }));
    }, [setState, state.active]);

    const openDropdown = useCallback(() => {
        setState({
            active: true,
        });
    }, [setState]);

    const closeDropdown = useCallback(() => {
        setState({
            active: false,
        });
    }, [setState]);

    /**
     * Handle option being selected (change in value)
     */
    const onOptionSelected = useCallback((option: Option) => {
        onChange(name, option.value);
        externalInputRef?.current?.focus?.()

        if (onOptionSelectedFunc) {
            onOptionSelectedFunc(option.value);
        }

        closeDropdown();
        if (inputRef.current !== null)
            inputRef.current.blur();
    }, [name, onChange, closeDropdown, externalInputRef, onOptionSelectedFunc]);

    /**
     * Convert a country code to its corresponding flag.
     */
    function countryToFlag(code: string) {
        if (typeof String.fromCodePoint !== 'undefined') {
            const phoneCodeRegEx = /^\+[0-9]+$/;
            const isPhoneCode = phoneCodeRegEx.test(code);
            const country = isPhoneCode ? Countries.find(country => country.phone === code) : Countries.find(country => country.code === code);
            if (country && country.phone !== 'none') {
                const code = country?.code;
                return (
                    code
                        ?.toUpperCase()
                        ?.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
                );
            } else return <span />
        }
    }

    const containerStyle = (variant?: SearchableListVariant) => css`
        position: relative;
        width: 100%;
        z-index: 4;
        
        input {
            border-bottom: 0;
            box-shadow: ${theme.borderAndShadow.boxShadow};
        }
    
        ${variant === 'phone' && css`
            position: absolute;
            top: 0;
            width: 70px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
        `}
    `;

    const clearableStyle = css`
        display: block;
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        z-index: 2;
        left: calc(100% - 56px);
        font-size: 12px;
        color: #AAAAAA;
        top: calc(50% - 12px);
        cursor: pointer;
        translateY(-50%);
    
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    `;

    const placeholderStyle = (variant?: SearchableListVariant) => css`
        font-size: ${theme.forms.label.fontSize};
        cursor: pointer;
        
        input {
            padding-right: ${clearable ? 52 : 38}px;
            text-overflow: ellipsis;
        }
    
        ${variant === 'phone' && css`
            min-height: 38px;
            padding-left: 9px;
            padding-top: 8px;
            font-size: 32px;
        `};
    `;

    const phoneCodeStyle = css`
        position: absolute;
        left: 70px;
        height: 100%;
        padding-top: 18px;
        font-size: 16px;
        font-family: ${theme.fonts.frutiger};
    `;

    const crossIconStyle = css`
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
        height: 10px;
        z-index: ${theme.zIndex.zIndexMedium};
        fill: black;
        opacity: 0.54;
    `;

    const dropdownChevronStyle = (variant?: SearchableListVariant) => css`
        right: 15px;
        cursor: pointer;
        background-color: ${theme.colours.curiousBlue2};
    
        ${variant === 'phone' && css`
            right: 8px;
        `};
    `;

    const mainContainerStyle = css`
        position: relative;
    `;

    return (
        <div ref={dropdownRef} css={mainContainerStyle} className={className}>
            <div css={containerStyle(variant)}>
                {variant !== 'phone' && (
                    <Input
                        name={name}
                        css={placeholderStyle(variant)}
                        label={placeholder}
                        onFocus={openDropdown}
                        onChange={onChange}
                        onFilterChange={onFilterChange}
                        value={valueLabel}
                        hasError={hasError}
                        hasIconOnError={false}
                        variant={variant}
                        autoComplete="nope"
                        innerRef={inputRef}
                        readOnly={disableSearch || fieldReadOnly}
                    />
                )}
                {variant === 'phone' && (
                    <div
                        onClick={toggleDropdown}
                        css={placeholderStyle(variant)}
                    >
                        {!!phoneCode && countryToFlag(phoneCode)}
                    </div>
                )}
                {!fieldReadOnly && (
                    <>
                        {!!value && clearable && (
                            <span css={clearableStyle} title="Clear" aria-label="Clear" onClick={onClear}><CrossIcon css={crossIconStyle}/></span>
                        )}
                        <DropdownChevron dropped={state.active} css={dropdownChevronStyle(variant)} hasError={hasError} onClick={toggleDropdown}/>
                    </>
                )}
                {variant === 'phone' && (
                    <div
                        css={phoneCodeStyle}
                        onClick={toggleDropdown}
                    >
                        {countryToPhoneCode(phoneCode)}
                    </div>
                )}
            </div>
            {(state.active && !fieldReadOnly) && (
                <SearchableListMenu
                    options={labelledOptions}
                    pinnedOptionValues={pinnedOptionValues}
                    onOptionSelected={onOptionSelected}
                    onClose={closeDropdown}
                    value={value}
                    greyOutOptionFunc={greyOutOptionFunc}
                    searchString={state.search}
                    containsSearchBox={containsSearchBox}
                    onFilterChange={onFilterChange}
                    variant={variant}
                    fullHeightForOptions={fullHeightForOptions}
                />
            )}
        </div>
    );
};

export default memo(SearchableList);

