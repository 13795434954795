import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    data: ContentWithImage,
    children: React.ReactNode,
};

const ImageContainer: React.FC<Props> = ({ data, children }) => {
    const theme: Theme = useTheme();

    const layout = JSON.parse(data.layout);
    const { useShadow, isBiggerImage, isHalfFullImage } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';

    const imageContentSectionStyle = css`
        min-width: 50%;
        flex-direction: column;
        border-radius: 12px;
        
        ${!data.useFocusPoint && css`
            width: unset;
            height: unset;
        `}
        
        ${imageSize === 'large' && css`
            ${theme.breakpoints.up("xxl")} {
                max-width: 615px;
                min-width: 615px;
                height: 600px;
            }

            ${theme.breakpoints.up("3xl")} {
                height: 615px;
            }
            
            ${theme.breakpoints.only("xl")} {
                min-width: 547px;
                max-width: 547px;
                height: 547px;
            }
          
            ${theme.breakpoints.down("lg")} {
                min-width: 467px;
                max-width: 467px;
                height: 456px;
            }
        `}

        ${theme.breakpoints.only("md")} {
            ${imageSize === 'large' ? css`
                min-width: 476px;
                max-width: 476px;
            ` : css`
                min-height: 471px;
            `}
        }

        ${theme.breakpoints.only("sm")} {
            ${imageSize === 'large' ? css`
                min-width: 338px;
                max-width: 338px;
                height: 338px;
            ` : css`
                min-height: 401px;
            `}
        }

        ${theme.breakpoints.down("xs")} {
            ${imageSize === 'large' ? css`
                min-width: 360px;
                max-width: 360px;
                height: 338px;
            ` : css`
                min-height: 320px;
            `}
        }

        ${useShadow && css`
            border-radius: 0;

            ${theme.breakpoints.down("md")} {
                border-radius: 0 0 12px 12px;
            }
        `};

        ${imageSize === 'halfFull' && css`
            overflow: unset !important;
        `};
    `;

    return (
        <ContentSection css={imageContentSectionStyle} className={"radius-box"}>
            {children}
        </ContentSection>
    );
}

export default ImageContainer;
