import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    text: string
};

const GuidanceText: React.FC<Props> = ({ text }) => {
    const theme: Theme = useTheme();

    const textStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.appleBlossom};
        font-size: 18px;
        line-height: 24px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 24px;
            line-height: 36px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 18px;
        }
    `;

    return (
        <ContentCombine css={textStyle}>{text}</ContentCombine>
    );
};

export default GuidanceText;
