import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const HubspotFormText: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        margin-left: 40px;

        @media (max-width: 1038px) {
            margin-left: 0;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default HubspotFormText;
