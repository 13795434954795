import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";

import BurgerNavMenuIconLines from "core/Components/HamburgerNavigations/BurgerNavMenuIconLines";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    showBurgerMenu?: boolean,
    toggleBurgerMenu: Function,
};

const BurgerNavMenuIcon: React.FC<Props> = ({ showBurgerMenu, toggleBurgerMenu }) => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();

    const onClickHandler = () => {
        toggleBurgerMenu({ forcedClose: false });
    };

    const iconSize = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["md"]:
                return 28;
            default:
                return 24;
        }
    }, [minWidth, theme.breakpoints.sizes]);


    const style = css`
        margin-left: ${theme.sizes.menu.sideMargin}px;
        width: ${iconSize}px;
        height: ${iconSize}px;
        cursor: pointer;
        z-index: ${theme.zIndex.zIndexHamburgerMenu};

        ${theme.mixins.bloop};
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            <BurgerNavMenuIconLines showBurgerMenu={showBurgerMenu} iconSize={iconSize} />
        </div>
    );
};

export default BurgerNavMenuIcon;
