import React, { ElementType } from "react";
import { css } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    containerRef?: any,
    className?: string,
    Tag?: ElementType,
    fontSizeType?: string,
};

const Title: React.FC<Props> = ({
    children,
    className,
    containerRef,
    Tag = 'div',
    fontSizeType = '',
}) => {
    const [fontSize, lineHeight] = useFontSize(Tag, fontSizeType);

    const styles = css`
        ${fontSize && css`
            font-size: ${fontSize}px;
        `}
        
        ${lineHeight && css`
            line-height: ${lineHeight}px;
        `}
    `;

    return (
        <Tag css={styles} className={className} ref={containerRef}>{children}</Tag>
    )
};

export default Title;
