import { useMemo } from "react";
import { useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";

const useDropdownOptionHeight = () => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();

    const dropdownOptionHeight = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return 47;
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return 43;
            default:
                return 35;
        }
    }, [minWidth, theme.breakpoints.sizes]);

    return dropdownOptionHeight;
};

export default useDropdownOptionHeight;
