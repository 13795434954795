import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import classNames from "classnames";

type Props = {
    text: string,
    className?: string,
    textColour?: string,
}

const TermsAndConditionsText: React.FC<Props> = ({
    text,
    className = '',
    textColour = 'white',
}) => {

    const theme: Theme = useTheme();
    const styles = css`
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        color: ${textColour};

        ${theme.breakpoints.up('4xl')} {
            margin-top: 129px;
        }

        ${theme.breakpoints.only('3xl')} {
            margin-top: 119px;
        }

        ${theme.breakpoints.only('xxl')} {
            width: 100%;
            margin-top: 73px;
        }

        ${theme.breakpoints.down('xl')} {
            width: 90%;
            margin-top: 80px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-top: 65px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 68px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 47px;
        }

        ${theme.breakpoints.only('xs')} {
            margin-top: 45px;
        }
        
        p {
            font-size: 12px;
            line-height: 18px;
        }
        
    `;

    return (
        <div css={styles} className={classNames(className, 'typography')} dangerouslySetInnerHTML={{ __html: text }} />
    )
}

export default TermsAndConditionsText;