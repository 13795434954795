import React from "react";

import Image from "core/Components/Image";

import CardContainer from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/CardContainer";
import TextContainer from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/TextContainer";
import ImageContainer from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/ImageContainer";
import Header from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/Header";
import Description from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/Description";
import Button from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/components/Button";

type Props = {
    imageHorizontalCard: ImageHorizontalCard,
    index: number,
};

const ImageHorizontalCard: React.FC<Props> = ({ imageHorizontalCard, index }) => (
    <CardContainer imageHorizontalCard={imageHorizontalCard} index={index}>
        <TextContainer>
            <Header imageHorizontalCard={imageHorizontalCard} />
            <Description imageHorizontalCard={imageHorizontalCard} />
            <Button imageHorizontalCard={imageHorizontalCard} />
        </TextContainer>

        <ImageContainer>
            <Image image={imageHorizontalCard.image} useFocusPoint={imageHorizontalCard.useFocusPoint} />
        </ImageContainer>
    </CardContainer>
);

export default ImageHorizontalCard;
