import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const UnderlinedPriceContainer: React.FC<Props> = ({
    children,
}) => {
    const theme: Theme = useTheme();

    const priceContainerStyle = css`
        width: 100%;
        height: 155px;
        position: relative;

        ${theme.breakpoints.up("5xl")} {
            height: 240px;
        }

        ${theme.breakpoints.only("md")} {
            height: 135px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 110px;
        }

        :before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border-bottom: 0.5px solid ${theme.colours.white};
        }
    `;

    return (
        <ContentSection css={priceContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default UnderlinedPriceContainer;
