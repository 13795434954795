import React from "react";
import { css, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
};

const Wrapper: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const wrapperStyle = css`
        flex-direction: column;
        align-self: center;
        padding: 76px 276px 56px;
        position: relative;
        
        ${theme.breakpoints.up("5xl")} {
            padding: 55px 416px 40px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 76px 355px 56px;
        }
        
        ${theme.breakpoints.only("xl")} {
            padding: 30px 198px 30px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 43px 70px 41px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 141px 44px 70px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 61px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 50px 15px;
        }
    `;

    return (
            <WrapperFromCore css={wrapperStyle}>
                {children}
            </WrapperFromCore>
    );
}

export default Wrapper;
