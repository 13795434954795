import React from "react";
import { css } from "@emotion/react";
import { HiChevronRight } from "react-icons/hi";

const BurgerNavHeaderMenuChevron = () => {
    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    return (
        <div css={style}>
            <HiChevronRight fontSize={"40px"} cursor={"pointer"} />
        </div>
    );
};

export default BurgerNavHeaderMenuChevron;