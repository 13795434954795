import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    cardCount: number,
};

const CardListContainer: React.FC<Props> = ({
    children,
    cardCount,
}) => {
    const theme: Theme = useTheme();

    const gridColumnCount = cardCount > 2 ? 3 : cardCount;
    let gridColumns = "";
    for (let i = 0; i < gridColumnCount; i++) {
        gridColumns += "1fr ";
    }

    const contentStyle = css`
        height: auto;
        margin: 0 auto;
        flex-direction: column;
        display: grid;
        grid-template-columns: ${gridColumns};
        gap: 37px;
        row-gap: 27px;

        ${theme.breakpoints.only('xxl')} {
            gap: 57px;
        }

        ${theme.breakpoints.only('xl')} {
            gap: 34px;
            row-gap: 25px;
        }
        
        ${theme.breakpoints.only('lg')} {
            gap: 15px;
            row-gap: 13px;
        }

        ${theme.breakpoints.down('md')} {
            grid-template-columns: 1fr;
            gap: 0;
            row-gap: ${cardCount < 3 ? 36 : 18}px;
        }
    `;

    return (
        <div css={contentStyle}>
            {children}
        </div>
    );
};

export default CardListContainer;
