import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
    hasTextData: boolean,
};

const Wrapper: React.FC<Props> = ({ children, hasTextData }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        flex-direction: column;
        align-self: center;
        padding: 85px 40px 105px;
        position: relative;

        ${theme.breakpoints.up("5xl")} {
            padding: 86px 48px 221.57px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 70px 40px 90px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 49px 30px 80px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 42px 45px;
        }

        ${theme.breakpoints.down("sm")} {
            padding: 31px 20px;
        }
        
        ${!hasTextData && css`
            padding: 0 40px 105px;
            
            ${theme.breakpoints.up("5xl")} {
                padding: 0 48px 221.57px;
            }
    
            ${theme.breakpoints.only("xl")} {
                padding: 0 40px 90px;
            }
    
            ${theme.breakpoints.only("lg")} {
                padding: 0 30px 80px;
            }
    
            ${theme.breakpoints.only("md")} {
                padding: 0 10px 45px;
            }
    
            ${theme.breakpoints.down("sm")} {
                padding: 0 7px 20px;
            }
        `};
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMinHeight={false}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
