import React from 'react';
import dayjs from "dayjs";

import ContentCombine from "core/Components/ContentCombine";
import { getTwelveHourTime } from "core/includes/dates";

type Props = {
    className?: string,
    startDate?: string,
    endDate?: string,
    startTime: string | null,
    endTime: string | null,
    fontSizeType?: string,
    textColour?: string,
    useText?: boolean,
};

const TimePeriod: React.FC<Props> = ({
    className = '',
    startDate = '',
    endDate = '',
    startTime,
    endTime,
    fontSizeType = '',
    textColour = '',
    useText = false
}) => (
    <ContentCombine fontSizeType={fontSizeType} justifyContent={'start'} textColour={textColour} className={className}>
        {(startTime && endTime) && (
            getTwelveHourTime(startTime, true) +
            '-' +
            getTwelveHourTime(endTime, true) +
            (useText ? ((!dayjs(startDate).diff(dayjs(endDate)) ? '' : ' both days')) : '')
        )}
    </ContentCombine>
);

export default TimePeriod;