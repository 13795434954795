import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
    index: number,
    containerRef: any,
    circleSize: number,
};

const VerticalLineAndTextContainer: React.FC<Props> = ({
    children,
    index,
    containerRef,
    circleSize,
}) => {
    const theme: Theme = useTheme();

    const isOddOrder = (index + 1) % 2;

    const style = css`
        position: relative;
        width: 100%;
        display: flex;
        min-height: 117px;
        max-height: 260px;

        ${!isOddOrder && css`
            min-height: unset;
        `};
        
        ${!index && css`
            min-height: 230px;

            ${theme.breakpoints.up("5xl")} {
                min-height: 367px;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                min-height: 170px;
            }
        `};

        :before {
            position: absolute;
            content: '';
            border: 1px solid ${theme.colours.doveGray2};
            height: calc(100% - 2px);
            top: 0;
        }

        :after {
            position: absolute;
            content: '';
            top: ${isOddOrder ? 0 : 'calc(100% - 1px)'};
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: ${theme.colours.doveGray2};
            left: -5px;
        }

        // Moves vertical line for even order items under the circle.
        // Half circle height 54px might be changed according to design later.
        transform: translateY(${isOddOrder ? '0' : `${circleSize / 2 + 'px'}` });
    `;

    return (
        <div css={style} ref={containerRef}>
            {children}
        </div>
    );
}

export default VerticalLineAndTextContainer;
