import borderAndShadow from "./borderAndShadow";
import breakpoints from "./breakpoints";
import colours from './colours';
import currency from "./currency";
import fonts, { fontImports } from "./fonts";
import forms from "./forms";
import keyframes from "./keyframes";
import mixins from "./mixins";
import popover from "./popover";
import sizes from "./sizes";
import transform from "./transform";
import transitions from './transitions';
import zIndex from "./zIndex";
import fontSizes from "theme/fontSizes";

const theme = {
    borderAndShadow,
    breakpoints,
    colours,
    currency,
    fonts,
    fontImports,
    forms,
    keyframes,
    mixins,
    popover,
    sizes,
    transform,
    transitions,
    zIndex,
    fontSizes,
};

export default theme;