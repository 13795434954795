import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    data: Timeline
};

const Description: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const textStyle = css`
        color: ${theme.colours.tundora};
        font-size: 30px;
        line-height: 37px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 40px;
            line-height: 54px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
            line-height: 20px;
        }
    `;

    return (
        <p css={textStyle}>{data.text}</p>
    );
};

export default Description;
