import React from 'react';
import { NavLink } from "react-router-dom";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    data: StandardPage | null,
};

const NavLinkTag: React.FC<Props> = ({
    children,
    data,
}) => {
    const style = css`
        width: fit-content;
    `;

    return (
        data?.isClickable ? (
            <NavLink css={style} className={"nav-link"} to={data.linkUrl}>
                {children}
            </NavLink>
        ) : (
            <div className={"nav-link"}>{children}</div>
        )
    );
};

export default NavLinkTag;