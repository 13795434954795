import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    textColour?: string,
    quantity: string | number,
    onChange: (quantity: React.ChangeEvent<HTMLInputElement>) => void,
};

const Input: React.FC<Props> = ({
    quantity,
    onChange,
    textColour,
}) => {
    const theme = useTheme();

    const formatInput = (e: React.KeyboardEvent<HTMLElement>) => {
        let checkIfNum;
        if (e.key !== undefined && e.key !== "Backspace") {
            checkIfNum = e.key === "e" || e.key === "l" || !e.key.match(/^[0-9\b\del]+$/gm);
        }

        return checkIfNum && e.preventDefault();
    };

    const style = css`
        color: ${textColour ?? theme.colours.black};
        text-align: center;
        border: 0;
        background: unset;
        width: 65px;
        outline: none;
        font-size: 22px;

        ${theme.breakpoints.up("5xl")} {
            width: 77px;
            font-size: 30px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 72px;
            font-size: 25px;
        }

        ${theme.breakpoints.only("xl")} {
            width: 60px;
            font-size: 19px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 55px;
            font-size: 22px;
        }

        ${theme.breakpoints.down("md")} {
            width: 48px;
            font-size: 18px;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `;

    return (
        <input onChange={onChange} onKeyDown={formatInput} type={"number"} placeholder={"0  "} value={quantity} css={style} />
    );
}

export default Input;
