import React from "react";
import { NavLink } from "react-router-dom";
import { css, useTheme } from "@emotion/react";

import RoundTextButton from "core/Components/Buttons/RoundTextButton";
import { LOGIN_URL } from "core/config/config";

type Props = {
    closeMenuHandler: Function,
    menuButton?: Button
    hideCustomButton?: boolean,
    buttonFontSize?: number,
};

const BurgerNavLinkButtons: React.FC<Props> = ({
    closeMenuHandler,
    menuButton = undefined,
    hideCustomButton = false,
    buttonFontSize = 18,
}) => {
    const theme = useTheme();
    const buttonWidth = 167, buttonHeight = 48;
    const linkURL = menuButton?.innerURL || menuButton?.outerURL;

    const closeHandler = () => {
        closeMenuHandler();
    };

    const style = css`
        display: flex;
        flex-direction: column;

        a:last-child {
            margin-top: 12px;
        }
    `;

    return (
        <div css={style}>
            {(!hideCustomButton && menuButton?.buttonText && linkURL) && (
                <NavLink to={linkURL} className={menuButton.trackingButtonName}>
                    <RoundTextButton
                        minWidth={buttonWidth}
                        height={buttonHeight}
                        fontSize={buttonFontSize}
                        textColour={"black"}
                        backgroundColour={theme.colours.gold}
                        backgroundHoverColour={theme.colours.mySin}
                        onClick={closeHandler}
                    >
                        {menuButton.buttonText}
                    </RoundTextButton>
                </NavLink>
            )}
            <a href={LOGIN_URL} key={"/login"} className={menuButton?.trackingLoginButtonName}>
                <RoundTextButton
                    onClick={closeHandler}
                    width={buttonWidth}
                    height={buttonHeight}
                    fontSize={buttonFontSize}
                    textColour={"black"}
                    textHoverColour={theme.colours.curiousBlue}
                    backgroundColour={"white"}
                    backgroundHoverColour={"white"}
                    noButtonBorderHover
                >
                    Log in
                </RoundTextButton>
            </a>
        </div>
    );
};

export default BurgerNavLinkButtons;