import React from "react";
import { css, SerializedStyles, Theme, useTheme } from "@emotion/react";
import RoundTextButtonWrapper from "core/Components/Buttons/RoundTextButtonWrapper";
import Loading from "core/Components/Utils/Loading";

type Props = {
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void,
    sidePadding?: number,
    width?: number,
    minWidth?: number,
    height?: number,
    fontSize?: number,
    isTextBold?: boolean,
    textColour?: string,
    textHoverColour?: string,
    buttonLineColour?: string,
    backgroundColour?: string,
    backgroundHoverColour?: string,
    useBoxShadow?: boolean,
    loading?: boolean,
    isDisabled?: boolean,
    buttonBorder?: number,
    noButtonBorderHover?: boolean,
    className?: string,
    buttonTextCustomStyle?: SerializedStyles,
}

const RoundTextButton: React.FC<Props> = ({
    children,
    onClick,
    sidePadding,
    width,
    minWidth = 0,
    height = 32,
    fontSize,
    isTextBold = true,
    textColour,
    textHoverColour,
    buttonLineColour,
    backgroundColour,
    backgroundHoverColour,
    noButtonBorderHover= false,
    useBoxShadow = true,
    loading = false,
    isDisabled = false,
    buttonBorder = 0,
    className,
    buttonTextCustomStyle,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        align-items: center;
        height: ${height}px;
        border: 0;
        background-color: transparent;
        z-index: ${theme.zIndex.zIndexOne};
        cursor: pointer;
        padding: 1px 6px;
        
        ${isDisabled && css`
            pointer-events: none;
            user-select: none;
            opacity: 0.8;
        `};
    `;

    return (
        <div css={style} onClick={onClick} className={className}>
            <RoundTextButtonWrapper
                sidePadding={sidePadding}
                width={width}
                minWidth={minWidth}
                fontSize={fontSize}
                isTextBold={isTextBold}
                textColour={textColour}
                textHoverColour={textHoverColour}
                buttonLineColour={buttonLineColour}
                backgroundColour={backgroundColour}
                backgroundHoverColour={backgroundHoverColour}
                useBoxShadow={useBoxShadow}
                buttonBorder={buttonBorder}
                noButtonBorderHover={noButtonBorderHover}
                css={buttonTextCustomStyle}
            >
                {loading ? <Loading small ringIndicator /> : children}
            </RoundTextButtonWrapper>
        </div>
    );
};

export default RoundTextButton;
