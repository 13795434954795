import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";
import { NOOP } from "core/constants";

type Props = {
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void,
    linkURL?: string,
};

const ListItemContainer: React.FC<Props> = ({ children, onClick = NOOP, linkURL = '' }) => {
    const theme: Theme = useTheme();

    const listItemContainerStyle = css`
        align-items: center;
        margin-bottom: 21px;

        ${theme.breakpoints.between("sm", "md")} {
            height: unset;
            width: 100%;
            margin-bottom: 13px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 11px;
        }

        :hover {
            div, svg {
                opacity: 0.7;
            }
        }
    `;

    return (
        <ContentSection css={listItemContainerStyle} onClick={onClick} linkURL={linkURL}>
            {children}
        </ContentSection>
    )
}

export default ListItemContainer;
