import React, { useCallback, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { useMutation } from "@apollo/react-hooks";
import EmailValidator from "email-validator";

import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "elements/Components/SingleVideo/components/Wrapper";
import ContentCombine from "core/Components/ContentCombine";
import useForm, { FieldList } from "core/hooks/useForm";
import Input from "core/Components/Form/Input";
import LabelledField from "core/Components/Form/LabelledField";
import SearchableList from "core/Components/Form/SearchableList";
import Countries from "core/includes/countries";
import RoundTextButton from "core/Components/Buttons/RoundTextButton";
import { SUBSCRIBE_FOR_UPDATES } from "core/includes/queries";
import errorParser from "core/includes/errorParser";
import useToast from "core/hooks/useToast";
import { ReactComponent as Tick } from 'images/icons/tick.svg';

type Props = {
    data: SubscribeForUpdates
};

const SubscribeForUpdates: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasInitiallySubscribed, setHasInitiallySubscribed,] = useState(false);
    const [,errorToast] = useToast();

    const validator: any = (fields: FieldList, isSubmit: boolean = false) => {
        const errors: any = {};

        if (!fields.firstName) {
            errors.firstName = "Please provide first name";
        }

        if (!fields.lastName) {
            errors.lastName = "Please provide last name";
        }

        if (!fields.school) {
            errors.school = "Please provide school name";
        }

        if (!fields.email) {
            errors.email = "Please provide your email";
        }

        if (fields.email && !EmailValidator.validate(fields.email) && isSubmit) {
            errors.email = "Please provide a valid email address";
        }

        if (!fields.country) {
            errors.country = "Please select a country";
        }

        return errors;
    };

    const [fields, errors, onFieldChange,, validate] = useForm({
        fields: {
            firstName: '',
            lastName: '',
            email: '',
            school: '',
            country: '',
        },
        validator,
    });

    const [subscribe] = useMutation(SUBSCRIBE_FOR_UPDATES);

    const onButtonClick = useCallback(() => {
        if (validate()) {
            setIsSubmitting(true);
            subscribe({
                variables: {
                    hubspotFormId: data.hubspotFormIdentifier,
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    email: fields.email,
                    school: fields.school,
                    country: Countries.find((c: any) => c.code === fields.country)?.label,
                }
            }).then(() => {
                setHasInitiallySubscribed(true);
                localStorage.setItem('hasSubscribedForUpdates', '1');
            }).catch((e: any) => {
                errorToast(errorParser(e));
            }).finally(() => setIsSubmitting(false));
        }
    }, [data, fields, subscribe, validate, errorToast]);

    const hasSubscribed = !!localStorage.getItem('hasSubscribedForUpdates') && !hasInitiallySubscribed;

    if (hasSubscribed) {
        return null;
    }

    const headerStyle = css`
        font-size: 35px;
        line-height: 39px;
        text-align: center;
        color: ${colourTheme.headerColour};
        font-weight: ${theme.fonts.weights.bold};
    `;

    const subheaderStyle = css`
        margin-top: 20px;
        text-align: center;
        color: ${colourTheme.subheaderColour};
        font-weight: ${theme.fonts.weights.light};

        ${theme.breakpoints.only('md')} {
            margin-top: 14px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 28px;
        }
    `;

    const inputStyle = css`
        color: ${colourTheme.inputTextColour};
        
        input {
            height: 32px;
            width: 191px;
            color: ${colourTheme.inputTextColour};
            box-shadow: unset;
            border-bottom: 2px solid ${colourTheme.inputTextColour};
            font-size: 16px;
            font-family: ${theme.fonts.frutiger};

            ${theme.breakpoints.down('lg')} {
                font-size: 14px;
            }

            ::placeholder {
                color: ${colourTheme.inputTextColour};
                opacity: 1; /* Firefox */
            }

            ::-ms-input-placeholder { /* Edge 12 -18 */
                color: ${colourTheme.inputTextColour};
            }
        }

        ${theme.breakpoints.only('lg')} {
            input {
                width: 163px;
            }
        }

        ${theme.breakpoints.only('md')} {
            input {
                width: 239px;
            }
        }

        ${theme.breakpoints.down('sm')} {
            input {
                width: 100%;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
        input[data-autocompleted] {
            background-color: transparent !important;
        }
    `;

    const formStyle = css`
        margin-top: 50px;
        display: flex;
        flex-direction: row;

        ${theme.breakpoints.up('md')} {
            column-gap: 24px;
        }

        ${theme.breakpoints.between('md', 'lg')} {
            margin-top: 40px;
        }

        ${theme.breakpoints.only('md')} {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
            margin-top: 22px;
            display: flex;
            flex-direction: column;
        }
    `;

    const searchableListStyle = css`
        ul {
            max-height: 105px;
        }

        input {
            height: 32px;
            width: 191px;
            border-radius: 8px;
            padding: 8px;
            font-weight: ${theme.fonts.weights.normal};
            background-color: white;

            ::placeholder {
                color: black;
                font-weight: ${theme.fonts.weights.normal};
                opacity: 1; /* Firefox */
            }

            ::-ms-input-placeholder { /* Edge 12 -18 */
                font-weight: ${theme.fonts.weights.normal};
                color: black;
            }
        }

        .flag {
            padding-left: 0;
        }

        .option {
            font-weight: ${theme.fonts.weights.light};
        }

        ${theme.breakpoints.down( 'md')} {
            input {
                width: 100%;
            }
        }
    `;

    const buttonStyle = css`
        margin-top: 36px;
        flex-shrink: 0;

        ${theme.breakpoints.only('lg')} {
            margin-top: 30px;
        }

        &:hover {
            > div {
                color: ${colourTheme.buttonTextHoverColour};
            }
        }
    `;

    const wrapperStyle = ({ hasInitiallySubscribed = false } = {}) => css`
        height: 367px;
        transition: height 300ms ease;

        ${theme.breakpoints.only('lg')} {
            height: 339px;
        }

        ${theme.breakpoints.only('md')} {
            height: 435px;
            padding-top: ${hasInitiallySubscribed ? 40 : 16}px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-bottom: 26px;
        }

        ${theme.breakpoints.only('sm')} {
            height: 570px;
        }

        ${theme.breakpoints.down('xs')} {
            height: 628px;
        }

        ${hasInitiallySubscribed && css`
            ${theme.breakpoints.up('lg')} {
                height: 202px;
                padding-left: 0;
                padding-right: 0;
            }

            ${theme.breakpoints.only('md')} {
                height: 245px;
                padding-left: 170px;
                padding-right: 170px;
            }

            ${theme.breakpoints.only('sm')} {
                height: 231px;
                padding-left: 70px;
                padding-right: 70px;
            }

            ${theme.breakpoints.down('xs')} {
                height: 309px;
            }
        `};
    `;

    const labelledFieldStyle = css`
        .error-label {
            color: ${colourTheme.errorColour};
        }

        ${theme.breakpoints.down('md')} {
            .error-label {
                top: 86%;
            }
        }

        ${theme.breakpoints.only('md')} {
            margin-bottom: 16px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-bottom: 10px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-bottom: 8px;
        }
    `;

    const labelledFieldForDropdownStyle = css`
        margin-bottom: 19px !important;
        grid-column: span 2;

        .error-label {
            color: ${colourTheme.errorColour};
        }

        ${theme.breakpoints.down('md')} {
            .error-label {
                top: 86%;
            }
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 5px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-top: 10px;
        }
    `;

    const thanksStyle = ({ hasInitiallySubscribed = false } = {}) => css`
        flex: 0;
        height: 0;
        opacity: 0;
        transition: all 300ms ease;

        ${hasInitiallySubscribed && css`
            height: unset;
            opacity: 1;
            flex: 1;
        `};
    `;

    const subscribeContentStyle = ({ hasInitiallySubscribed = false }) =>  css`
        opacity: 1;
        transition: all 300ms ease;
        
        ${theme.breakpoints.up('xxl')} {
            height: 199px;
        }
        
        ${theme.breakpoints.only('xl')} {
            height: 189px;
        }

        ${theme.breakpoints.only('lg')} {
            height: 178px;
        }

        ${theme.breakpoints.only('md')} {
            height: 293px;
        }

        ${theme.breakpoints.only('sm')} {
            height: 417px;
        }

        ${theme.breakpoints.down('xs')} {
            height: 474px;
        }

        ${hasInitiallySubscribed && css`
            height: 0 !important; 
            opacity: 0;
        `};
    `;

    return (
        <SectionContainer data={data}>
            <Wrapper css={wrapperStyle({ hasInitiallySubscribed })}>
                {/*{hasInitiallySubscribed ? (*/}
                <div css={thanksStyle({ hasInitiallySubscribed })}>
                    <div css={headerStyle}>
                        Thanks for subscribing. We will be in touch soon.
                    </div>
                </div>
                {/*) : (*/}
                <div css={subscribeContentStyle({ hasInitiallySubscribed })}>
                    {data.header && (
                        <div css={headerStyle}>
                            {data.header}
                        </div>
                    )}
                    {data.subheader && (
                        <ContentCombine fontSizeType={'22'} css={subheaderStyle}>
                            {data.subheader}
                        </ContentCombine>
                    )}
                    <div css={formStyle}>
                        <LabelledField
                            name={'firstName'}
                            error={errors.firstName}
                            css={labelledFieldStyle}
                        >
                            <Input
                                css={inputStyle}
                                hasError={!!errors.firstName}
                                label={'First name'}
                                name={'firstName'}
                                onChange={onFieldChange}
                                value={fields.firstName}
                            />
                        </LabelledField>
                        <LabelledField
                            name={'lastName'}
                            error={errors.lastName}
                            css={labelledFieldStyle}
                        >
                            <Input
                                css={inputStyle}
                                hasError={!!errors.lastName}
                                label={'Last name'}
                                name={'lastName'}
                                onChange={onFieldChange}
                                value={fields.lastName}
                            />
                        </LabelledField>
                        <LabelledField
                            name={'email'}
                            error={errors.email}
                            css={labelledFieldStyle}
                        >
                            <Input
                                css={inputStyle}
                                hasError={!!errors.email}
                                label={'Email address'}
                                name={'email'}
                                onChange={onFieldChange}
                                value={fields.email}
                            />
                        </LabelledField>
                        <LabelledField
                            name={'school'}
                            error={errors.school}
                            css={labelledFieldStyle}
                        >
                            <Input
                                css={inputStyle}
                                hasError={!!errors.school}
                                label={'School'}
                                name={'school'}
                                onChange={onFieldChange}
                                value={fields.school}
                            />
                        </LabelledField>
                        <LabelledField
                            name={'country'}
                            error={errors.country}
                            css={labelledFieldForDropdownStyle}
                        >
                            <SearchableList
                                name={'country'}
                                css={searchableListStyle}
                                options={Countries}
                                pinnedOptionValues={data.pinnedCountryCodes}
                                value={fields.country}
                                onChange={onFieldChange}
                                labelField={'label'}
                                valueField={'code'}
                                placeholder={'Country'}
                                hasError={!!errors.country}
                                variant={'country'}
                                clearable
                            />
                        </LabelledField>
                    </div>
                </div>
                {/*)}*/}
                <RoundTextButton
                    minWidth={174}
                    height={51}
                    fontSize={20}
                    textColour={colourTheme.buttonTextColour}
                    backgroundColour={colourTheme.buttonColour}
                    backgroundHoverColour={colourTheme.buttonHoverColour ?? colourTheme.buttonColour}
                    css={buttonStyle}
                    onClick={onButtonClick}
                    loading={isSubmitting}
                    isDisabled={hasInitiallySubscribed}
                >
                    {hasInitiallySubscribed ? <Tick /> : data.buttonText}
                </RoundTextButton>
            </Wrapper>
        </SectionContainer>
    );
}

export default SubscribeForUpdates;
