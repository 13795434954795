export const hasFeatureFlagBasedOnCountryCode = (countryCode = "", featureFlags = [], featureFlag = "") => {
    if (!featureFlags || !countryCode) {
        return false;
    }

    let hasFeatureFlag = false;
    featureFlags.forEach((featFlag: string) => {
        if (featFlag === featureFlag) {
            hasFeatureFlag = featureFlag.toLowerCase().includes(`${countryCode.toLowerCase()}-`);
            return;
        }
    });

    return hasFeatureFlag;
}

const c = {
    hasFeatureFlagBasedOnCountryCode
};

export default c;