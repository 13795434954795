import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";
import { isEmpty } from "lodash";

type Props = {
    header: string,
    subheader: string,
};

const IntroHeader:React.FC<Props> = ({ header, subheader }) => {
    const theme = useTheme();

    const style = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 80px;
            line-height: 98px;
        }

        ${theme.breakpoints.between("xl", "4xl")} {
            font-size: 70px;
            line-height: 80px;
        }

        ${theme.breakpoints.only("lg")} {
            font-size: 50px;
            line-height: 55px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 45px;
            line-height: 55px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 35px;
            line-height: 42px;
        }
        
        ${!isEmpty(subheader) && css`
            margin-bottom: 16px;
        `}
    `;

    return (
        <ContentCombine defaultTextAlign={"center"} css={style}>{header}</ContentCombine>
    );
};

export default IntroHeader;