import React, { useRef } from "react";

import ContentSection from "core/Components/ContentSection";
import * as storage from "core/includes/localStorage";
import PriceContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/PriceContainer";
import DollarSign from "elements/Components/SubscriptionPlan/components/PricingCard/components/DollarSign";
import PriceNumber from "elements/Components/SubscriptionPlan/components/PricingCard/components/PriceNumber";
import PriceUnitContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/PriceUnitContainer";
import PaymentType from "elements/Components/SubscriptionPlan/components/PricingCard/components/PaymentType";
import SlashSign from "elements/Components/SubscriptionPlan/components/PricingCard/components/SlashSign";
import PricingCardContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/PricingCardContainer";
import UnderlinedHeaderContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/UnderlinedHeaderContainer";
import Header from "elements/Components/SubscriptionPlan/components/PricingCard/components/Header";
import UnderlinedPriceContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/UnderlinedPriceContainer";
import DescriptionAndButtonContainer from "elements/Components/SubscriptionPlan/components/PricingCard/components/DescriptionAndButtonContainer";
import Description from "elements/Components/SubscriptionPlan/components/PricingCard/components/Description";
import Button from "elements/Components/SubscriptionPlan/components/PricingCard/components/Button";

type DefaultState = {
    personalCost: number,
    familyCost: number,
};

type Props = {
    data: PricingCard,
    stripePrices: DefaultState,
};

const PricingCard: React.FC<Props> = ({
    data,
    stripePrices,
}) => {
    const paymentTypeRef = useRef<any>();
    const colourTheme = JSON.parse(data.theme);
    const { backgroundColour, priceColour } = colourTheme;
    let price = data.price;

    if (stripePrices) {
        if (data.header === "Personal") {
            price = Math.round(stripePrices.personalCost) ?? price;
        } else if (data.header === "Family") {
            price = Math.round(stripePrices.familyCost) ?? price;
        }
    }

    const setPlanType = () => {
        const planName = data.header.toLowerCase();
        const storageCurrentPlanTypeID = storage.retrieveValue("currentPlanTypeID");
        if (storageCurrentPlanTypeID !== planName) {
            storage.removeItem("currentNumberOfUsersID");
            storage.removeItem("currentPlanID");
        }
        storage.persistValue("currentPlanTypeID", planName);
    };

    return (
        <PricingCardContainer backgroundColour={backgroundColour}>
            <UnderlinedHeaderContainer>
                <Header data={data} />
            </UnderlinedHeaderContainer>

            <UnderlinedPriceContainer>
                {data.header === "Education" ? (
                    <PriceContainer>
                        <PaymentType paymentType={data.paymentType} hasPrice={false} />
                    </PriceContainer>
                ) : (
                    <PriceContainer>
                        <ContentSection>
                            <DollarSign />
                            <PriceNumber colour={priceColour}>{price}</PriceNumber>
                        </ContentSection>

                        <PriceUnitContainer>
                            <SlashSign paymentTypeRef={paymentTypeRef} />
                            <PaymentType paymentType={data.paymentType} paymentTypeRef={paymentTypeRef} />
                        </PriceUnitContainer>
                    </PriceContainer>
                )}
            </UnderlinedPriceContainer>

            <DescriptionAndButtonContainer>
                <Description data={data} />
                <Button data={data} onClick={setPlanType} target={"_self"} />
            </DescriptionAndButtonContainer>
        </PricingCardContainer>
    );
};

export default PricingCard;
