import { ReactNode, useMemo } from "react";
import moment from "moment";
import { getSiteTitle, SITE_TITLE } from "core/config/config";

export type MetaData = {
    element: string,
    props: {
        content?: string,
        children?: ReactNode,
        href?: string,
        itemprop?: string,
        name?: string,
        property?: string,
        rel?: string,
    }
}

const useMetadata = (page: Page) => {
    return useMemo(() => {
        const metaTitle = page?.metaTitle || page?.title;
        const metaDescription = page?.metaDescription;
        const imageUrl = getPreviewImage(page?.socialImageUrl || '');
        const niceLastEdited: string = moment(page?.lastEdited || '').format() || "";
        const robots = page?.robots || "index, follow";
        const ogType = page?.oGType || "website";
        const ogLocale = page?.oGLocale || "en_US";
        const twitterCard = page?.twitterCard || "summary";
        const absoluteUrl = page?.absoluteUrl || '';
        const hideSocial = page?.hideSocial || false;
        const canonical = page?.canonical || '';

        const d: MetaData[] = [
            {
                element: "title",
                props: {
                    children: getSiteTitle((!page?.title || page?.title === 'Home') ? '' : page.title) || SITE_TITLE,
                }
            },
            {
                element: "meta",
                props: {
                    name: "description",
                    content: metaDescription || "Writer's Toolbox",
                }
            },
            {
                element: "meta",
                props: {
                    name: "robots",
                    content: robots,
                }
            },
            {
                element: "link",
                props: {
                    rel: "canonical",
                    href: canonical,
                },
            },
            {
                element: "meta",
                props: {
                    property: "og:title",
                    content: metaTitle,
                },
            },
            {
                element: "meta",
                props: {
                    property: "og:url",
                    content: absoluteUrl,
                },
            },
            {
                element: "meta",
                props: {
                    property: "article:published_time",
                    content: niceLastEdited,
                },
            },
            {
                element: "meta",
                props: {
                    property: "article:modified_time",
                    content: niceLastEdited,
                },
            },
            ...!hideSocial ? [
                {
                    element: "meta",
                    props: {
                        property: "og:type",
                        content: ogType,
                    },
                },
                {
                    element: "meta",
                    props: {
                        property: "og:description",
                        content: metaDescription,
                    },
                },
                {
                    element: "meta",
                    props: {
                        property: "og:updated_time",
                        content: niceLastEdited,
                    },
                },
                {
                    element: "meta",
                    props: {
                        name: "twitter:card",
                        content: twitterCard,
                    },
                },
                {
                    element: "meta",
                    props: {
                        name: "og:locale:alternate",
                        content: ogLocale,
                    },
                },
                ...imageUrl ? [
                    {
                        element: "meta",
                        props: {
                            property: "og:image",
                            content: imageUrl,
                            itemprop: "image",
                        }
                    }
                ] : []
            ] : []
        ];

        const extraHeadTags = page?.extraHeadTags || [];
        extraHeadTags.forEach((extraHeadTag: HeadTag) => {
            let element = 'meta';
            let props = {};
            if (extraHeadTag.title && extraHeadTag.value) {
                if (extraHeadTag.type === 'link') {
                    element = 'link';
                    props = {
                        rel: extraHeadTag.title,
                        value: extraHeadTag.value
                    }
                } else if (extraHeadTag.type === 'name') {
                    props = {
                        name: extraHeadTag.title,
                        content: extraHeadTag.value
                    }
                } else {
                    props = {
                        property: extraHeadTag.title,
                        content: extraHeadTag.value
                    }
                }
                d.push({
                    element: element,
                    props
                })
            }
        });

        return d.filter((metaData: MetaData) => !!metaData.props.content || !!metaData.props.href || metaData.element === 'title');
    }, [page]);
}

const getPreviewImage = (url: string) => {
    if (!url) return null;

    if (url.indexOf("https") === 0) {
        return url.replace("https", "http");
    }

    return url;
}

export default useMetadata;
