import React from "react";
import { css, SerializedStyles } from "@emotion/react";

import useDropdownOptionHeight from "core/Components/Buttons/DropdownButton/hooks/useDropdownOptionHeight";

type Props = {
    children: React.ReactNode,
    options: Option[],
    option: Option,
    isMultiple?: boolean,
    onChange?: (item: Option) => void,
    onChangeMultiple?: (items: Option[]) => void,
    customOptionStyle?: SerializedStyles,
    multipleValue?: any,
    setMultipleValue?: any,
    index: number,
    optionBackgroundHoveredColour: string,
};

const DropdownButtonOptionContainer: React.FC<Props> = ({
    children,
    options,
    option,
    isMultiple = false,
    onChange,
    onChangeMultiple,
    customOptionStyle,
    multipleValue,
    setMultipleValue,
    index,
    optionBackgroundHoveredColour,
}) => {
    const optionHeight = useDropdownOptionHeight();

    const onItemClick = () => {
        // For multiple options
        if (isMultiple && multipleValue && onChangeMultiple) {
            let selectedValues: MultipleOptions = {};

            // In case currently select all
            if (option.value === "") {
                // In case all is not selected before, select all, and make all options true
                if (!multipleValue[option.value]) {
                    const optionValues: MultipleOptions = {};
                    options.map((option) => {
                        return optionValues[option.value] = true;
                    });
                    setMultipleValue(optionValues);
                    selectedValues = optionValues;
                }
            } else {
                // If all is selected previously, deselect all
                if (multipleValue[""]) {
                    const values = { ...multipleValue, "": false, [option.value]: !multipleValue[option.value] };
                    setMultipleValue(values);
                    selectedValues = values;
                } else {
                    const values = { ...multipleValue, [option.value]: !multipleValue[option.value] };

                    // Select All if all other options are selected
                    let otherValueTrue = true;
                    for (const [key, value] of Object.entries(values)) {
                        if (key !== "" && value === false) {
                            otherValueTrue = false;
                        }
                    }
                    setMultipleValue({ ...values, "": otherValueTrue });
                    selectedValues = { ...values, "": otherValueTrue };
                }
            }

            let changedOption: Option[] = [];
            for (const [key, value] of Object.entries(selectedValues)) {
                if (value) {
                    changedOption.push({
                        "value": key,
                        "label": options.find((item) => item.value === key)?.label ?? ""
                    });
                }
            }
            onChangeMultiple(changedOption);
        }
        // For single option
        else if (onChange) {
            onChange(option);
        }
    };

    const isAllSelected = option.value === "" && multipleValue && multipleValue[option.value];

    const dropdownItem = css`
        display: flex;
        align-items: center;
        padding: 10px 14px;
        height: ${optionHeight}px;

        ${(isMultiple && !isAllSelected) && css`
            cursor: pointer;
        `}
        
        ${!isMultiple && css`
            cursor: pointer;
        `}

        &:hover {
            background-color: ${optionBackgroundHoveredColour};
        }

        ${customOptionStyle}
    `;

    return (
        <div onClick={onItemClick} css={dropdownItem}>
            {children}
        </div>
    );
}

export default DropdownButtonOptionContainer;
