import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const TextContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const textContainerStyle = css`
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px 25px 130px;

        ${theme.breakpoints.up("5xl")} {
            padding: 30px 28px 180px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 26px 28px 140px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 22px 22px 130px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 20px 12px 88px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 20px 17px 120px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 12px 14px;
            width: 100%;
            height: unset;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 10px 13px;
            width: 100%;
            height: unset;
        }
    `;

    return (
        <ContentSection css={textContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default TextContainer;
