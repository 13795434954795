import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    description: string,
    isOutOfStock?: boolean,
    expectedDate?: string,
};

const Description: React.FC<Props> = ({
    isOutOfStock,
    description,
    expectedDate,
}) => {
    const theme = useTheme();

    const outOfStockMsg = useMemo(() => {
        let msg = "Temporarily out of stock.";

        if (expectedDate) {
            const date = new Date(expectedDate).toLocaleString('en', { month: 'short', year: 'numeric' });
            msg += `\nNew stock expected ${date}.`;
        }

        return msg;
    }, [expectedDate]);

    const style = css`
        width: 90%;
        font-size: 16px;
        line-height: 26px;
        margin: 52px auto 0;

        ${theme.breakpoints.only("xl")} {
            margin-top: 61px;
            font-size: 14px;
            line-height: 23px;
        }

        ${theme.breakpoints.down("lg")} {
            margin-top: 47px;
            font-size: 14px;
            line-height: 23px;
        }

        ${theme.breakpoints.down("md")} {
            margin-top: 57px;
        }
        
        ${isOutOfStock && css`
            margin-top: 16px;

            ${theme.breakpoints.up("5xl")} {
                margin-top: 26px;
            }

            ${theme.breakpoints.between("3xl", "4xl")} {
                margin-top: 18px;
            }

            ${theme.breakpoints.only("xl")} {
                font-size: 14px;
                line-height: 23px;
                margin-top: 20px;
            }

            ${theme.breakpoints.down("lg")} {
                font-size: 14px;
                line-height: 23px;
                margin-top: 9px;
            }

            ${theme.breakpoints.down("sm")} {
                margin-top: 10px;
            }
        `};
    `;

    const outOfStockStyle = css`
        justify-content: center;
        text-align: center;
        font-size: 16px;
        height: 52px;
        line-height: 26px;
        margin-top: 7px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.redOrange};

        ${theme.breakpoints.down("xl")} {
            font-size: 14px;
            line-height: 23px;
        }
    `;

    return (
        <>
            {isOutOfStock && (
                <ContentCombine css={outOfStockStyle}>{outOfStockMsg}</ContentCombine>
            )}

            <ContentCombine css={style}>{description}</ContentCombine>
        </>
    );
};

export default Description;
