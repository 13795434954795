import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";
type Props = {
    data: TopBanner,
    buttonHeight: number,
    buttonSidePadding: number,
};

const SecondButton: React.FC<Props> = ({ data, buttonHeight, buttonSidePadding }) => {
    const theme: Theme = useTheme();

    const secondLinkURL = data.secondInnerURL || data.secondOuterURL;
    const secondColourTheme = JSON.parse(data.secondButtonTheme);
    const { buttonTextColour: secondButtonTextColour, borderColour: secondButtonBorderColour, } = secondColourTheme;

    const secondButtonTextCustomStyle = css`
        color: ${secondButtonTextColour};
        font-size: 20px;

        ${theme.breakpoints.up("3xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    if (!data.secondButtonText) return null;

    return (
        <RoundButton
            backgroundColour={theme.colours.transparent}
            borderColour={secondButtonBorderColour}
            textColour={secondButtonTextColour}
            linkURL={secondLinkURL}
            buttonHeight={buttonHeight}
            buttonSidePadding={buttonSidePadding}
            buttonTextCustomStyle={secondButtonTextCustomStyle}
        >
            {data.secondButtonText}
        </RoundButton>
    );
}

export default SecondButton;
