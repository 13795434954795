import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: DescriptionWithRichTextSingleColumn
};

const Text: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const textStyle = css`
        align-self: flex-start;
        font-weight: ${theme.fonts.weights.light};
        color: black;
        
        ${theme.breakpoints.between('xl', 'xxl')} {
            font-size: 16px;
            line-height: 22px;
        }

        ${theme.breakpoints.down('lg')} {
            font-size: 14px;
            line-height: 18px;
        }

        ${theme.breakpoints.only('md')} {
            line-height: 20px;
        }

        ${theme.breakpoints.only('sm')} {
            line-height: 22px;
        }

        ${theme.breakpoints.down('xs')} {
            line-height: 20px;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"9"} css={textStyle}>{data.text}</ContentCombine>
    );
};

export default Text;