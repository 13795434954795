import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    imageHorizontalCard: ImageHorizontalCard,
};

const Description: React.FC<Props> = ({ imageHorizontalCard, }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(imageHorizontalCard.theme);
    const { textColour } = colourTheme;

    const contentTextStyle = css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 8;

        ${textColour && css`
            color: ${textColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            -webkit-line-clamp: 8;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            -webkit-line-clamp: 7;
        }

        ${theme.breakpoints.only("xl")} {
            -webkit-line-clamp: 9;
        }

        ${theme.breakpoints.only("lg")} {
            -webkit-line-clamp: 6;
        }

        ${theme.breakpoints.only("md")} {
            -webkit-line-clamp: 8;
        }

        ${theme.breakpoints.down("sm")} {
            -webkit-line-clamp: 8;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"7"} css={contentTextStyle}>{imageHorizontalCard.text}</ContentCombine>
    );
};

export default Description;
