import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children?: React.ReactNode,
    noMarginTop?: boolean,
};

const FormText: React.FC<Props> = ({ noMarginTop, children }) => {
    const theme: Theme = useTheme();

    const contentTextStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        text-align: center;
        font-size: 20px;
        margin-top: 37px;
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 24px;
            margin-top: 44px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 18px;
            margin-top: 33px;
        }
        
        ${noMarginTop && css`
            margin-top: 0;
        `};
    `;

    return (
        <ContentCombine css={contentTextStyle}>{children}</ContentCombine>
    );
};

export default FormText;
