import React from "react";
import { css, useTheme } from "@emotion/react";
import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
    isImageFirst: boolean,
};

const Wrapper: React.FC<Props> = ({
    children,
    isImageFirst,
}) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        overflow: hidden;
        width: 100%;

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}

        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
        }
      
        ${theme.breakpoints.up("lg")} {
            height: 500px;
        }
    `;

    return (
        <WrapperFromCore css={style} useMaxWidth={false} useMinHeight={false}>{children}</WrapperFromCore>
    );
}

export default Wrapper;
