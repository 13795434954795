import React from "react";
import { css, useTheme, Theme } from "@emotion/react";
import { isEmpty } from "lodash";
import Carousel from "nuka-carousel";

import { sort } from "core/includes/sort";
import SectionContainer from "core/Components/SectionContainer";
import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import CarouselContainer from "core/Components/Carousel/CarouselContainer";
import ChevronContainer from "core/Components/Carousel/ChevronContainer";
import SlideContainer from "core/Components/Carousel/SlideContainer";
import SlideWrapper from "core/Components/Carousel/SlideWrapper";

import SlideFeedback from "elements/Components/SliderWithFeedback/SlideFeedback";
import SlideWriter from "elements/Components/SliderWithFeedback/SlideWriter";
import Wrapper from "core/Components/Wrapper";

type Props = {
    data: SliderWithFeedback
};

const SliderWithFeedback: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const feedbackList = sort(data.feedbackList);
    const dataCount = data.feedbackList.length ?? 0;
    const theme: Theme = useTheme();

    const chevronButton = (type: "left" | "right", slideEvent: any) => (
        <ChevronContainer
            chevronType={type}
            dataCount={dataCount}
            isSide={false}
        >
            <RoundChevronButton
                type={type}
                onClick={slideEvent}
                roundColour={colourTheme.TextColour}
                chevronColour={colourTheme.BackgroundColour}
            />
        </ChevronContainer>
    );

    const carouselContainerStyle = css`
        width: 100%;

        ${theme.breakpoints.up('3xl')} {
            padding: 45px 182px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 45px 80px;
        }
        
        ${theme.breakpoints.only("xl")} {
            padding: 51px 80px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            padding: 51px 45px;
        }
      
        ${theme.breakpoints.down("xs")} {
            padding: 51px 20px;
        }
    `;

    const wrapperStyle = css`
        ${theme.breakpoints.up('3xl')} {
            max-width: 1600px;
        }
    `;

    return isEmpty(feedbackList) ? null : (
        <SectionContainer data={data}>
            <Wrapper css={wrapperStyle} useMinHeight={false}>
                <CarouselContainer
                    useDefaultPagination
                    css={carouselContainerStyle}
                >
                    <Carousel
                        wrapAround
                        slidesToShow={1}
                        dragging={false}
                        autoplay
                        autoplayInterval={4000}
                        speed={1500}
                        renderCenterLeftControls={({ previousSlide }) => (
                            chevronButton("left", previousSlide)
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            chevronButton("right", nextSlide)
                        )}
                        defaultControlsConfig={{
                            pagingDotsStyle: {
                                fill: colourTheme.TextColour
                            }
                        }}
                    >
                        {feedbackList.map((feedback: SlideWithFeedback, index: number) => (
                            <SlideContainer key={index}>
                                <SlideWrapper textColour={colourTheme.TextColour}>
                                    <SlideFeedback>{feedback.feedback}</SlideFeedback>
                                    <SlideWriter>{feedback.writer}</SlideWriter>
                                </SlideWrapper>
                            </SlideContainer>
                        ))}
                    </Carousel>
                </CarouselContainer>
            </Wrapper>
        </SectionContainer>
    );
};

export default SliderWithFeedback;
