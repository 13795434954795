import React from "react";
import { isEmpty } from "lodash";

import SectionContainer from "core/Components/SectionContainer";
import VideoWithPlaceholder from "core/Components/Video/VideoWithPlaceholder";

import Wrapper from "elements/Components/SingleVideo/components/Wrapper";
import Header from "elements/Components/SingleVideo/components/Header";
import Subheader from "elements/Components/SingleVideo/components/Subheader";
import VideoContainer from "elements/Components/SingleVideo/components/VideoContainer";

type Props = {
    data: SingleVideo
};

const SingleVideo: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const { fromColour, toColour } = colourTheme;

    // videoPath is required (Header and subheader are optional)
    return isEmpty(data.videoPath) ? null : (
        <SectionContainer gradientColours={{ fromColour, toColour }}>
            <Wrapper>
                {data.header && (
                    <Header data={data} />
                )}

                {data.subheader && (
                    <Subheader data={data} />
                )}

                <VideoContainer>
                    <VideoWithPlaceholder
                        videoPath={data.videoPath}
                        image={data.videoThumbnail}
                        useFocusPoint={data.useFocusPoint}
                        className={data.trackingVideoName}
                    />
                </VideoContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default SingleVideo;
