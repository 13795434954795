import React from "react";
import { css } from "@emotion/react";

type props = {
    children: React.ReactNode,
}

export const ImageWrapper: React.FC<props> = ({
    children,
}) => {
    const style = css`
        width: 100%;
        display: flex;
        flex-direction: column;
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ImageWrapper;
