import React, { useMemo } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import moment from "moment/moment";

import Image from "core/Components/Image";
import ContentCombine from "core/Components/ContentCombine";
import RoundButton from "core/Components/Buttons/RoundButton";
import useBreakpoint from "core/hooks/useBreakpoint";
import { isBetween, isDown, isUp } from "theme/breakpoints";


type Props = {
    post: BlogPost,
    className?: string,
    useCurve?: boolean,
}

const Banner: React.FC<Props> = ({ post, className, useCurve = false }) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpoint();
    const publishedDate = post.publishedDate ? moment(post.publishedDate).format('MMM DD \'YY') : "";
    const { isMobileBreakpoint } = useBreakpoint();

    const [buttonWidth, buttonHeight, buttonFontSize] = useMemo(() => {
        switch(true) {
            case (isUp(breakpoint, '5xl')):
                return [202, 50, 20];
            case (isBetween(breakpoint, 'xl', '4xl')):
                return [157, 38, 16];
            case (isBetween(breakpoint, 'md', 'lg')):
                return [129, 32, 13];
            case (isDown(breakpoint, 'sm')):
                return [113, 28, 12]
            default:
                return [202, 50, 20];
        }

    }, [breakpoint]);

    const containerStyle = css`
        position: relative;
        display: flex;
        margin: 28px 0 150px;
        box-shadow: ${theme.borderAndShadow.boxShadow6};
        border-radius: ${theme.borderAndShadow.largerRadius};
        justify-content: space-between;
        overflow: hidden;
        flex-grow: 1;

        ${theme.breakpoints.down('5xl')} {
            width: 95%;
            margin-bottom: 130px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-bottom: 115px;
        }

        ${theme.breakpoints.down('xxl')} {
            width: 100%;
            margin-bottom: 100px;
        }

        ${theme.breakpoints.down('md')} {
            flex-direction: column;
            height: 715px;
            margin-bottom: 130px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 55px;
        }

        ${theme.breakpoints.only('sm')} {
            height: 542px;
        }

        ${theme.breakpoints.down('xs')} {
            height: 570px;
        }
    `;

    const leftSectionContainerStyle = css`
        width: 47%;
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        padding-left: 48px;

        ${theme.breakpoints.between('3xl', '4xl')} {
            width: 45%;
            padding-left: 32px;
            padding-top: 20px;
        }
        
        ${theme.breakpoints.between('xl', 'xxl')} {
            padding-top: 18px;
        }

        ${theme.breakpoints.only('xxl')} {
            padding-left: 22px;
        }

        ${theme.breakpoints.only('xl')} {
            padding-left: 15px;
        }

        ${theme.breakpoints.only('lg')} {
            padding-left: 25px;
            padding-top: 14px;
        }

        ${theme.breakpoints.down('md')} {
            flex-direction: column;
            width: 100%;
            height: 50%;
            padding: 15px 35px 40px;
            margin: 0;
        }

        ${theme.breakpoints.only('md')} {
            padding-top: 12px;
            padding-bottom: 25px;
        }

        ${theme.breakpoints.down('sm')} {
            height: 54%;
            padding: 11px 15px 15px 15px;
        }

        ${theme.breakpoints.down('xs')} {
            height: 56%;
            padding-top: 5px;
            padding-bottom: 10px;
        }
    `;

    const rightSectionContainerStyle = css`
        width: 53%;
        
        ${theme.breakpoints.between('3xl', '4xl')} {
            width: 55%;
        }

        ${theme.breakpoints.down('md')} {
            width: 100%;
            height: 100%;
        }
    `;

    const authorContainerStyle = css`
        min-height: 24px;
        display: flex;
        font-size: 18px;
        color: ${theme.colours.gray2};
        font-style: italic;
        align-items: center;
    `;

    const authorImageStyle = css`
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 15px;
    `;

    const titleStyle = css`
        color: ${theme.colours.curiousBlue2};
        justify-content: left;
        margin-bottom: 10px;
        font-weight: bold;
        
        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-bottom: 18px;
        }
        
        ${theme.breakpoints.only('xxl')} {
            margin-bottom: 15px;
        }

        ${theme.breakpoints.only('xl')} {
            padding-right: 16%;
        }

        ${theme.breakpoints.only('md')} {
            padding-right: 0;
        }
    `;

    const descriptionContainerStyle = css`
        margin-top: 92px;
        padding-left: 5px;
        margin-bottom: 74px;

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-top: 72px;
            margin-bottom: 57px;
        }

        ${theme.breakpoints.only('xxl')} {
            margin-top: 65px;
            margin-bottom: 52px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-top: 56px;
            margin-bottom: 67px;
        }

        ${theme.breakpoints.only('lg')} {
           margin-top: 72px;
            margin-bottom: 26px;
        }
        
        ${theme.breakpoints.only('md')} {
            margin: 52px 0 25px;
        }

        ${theme.breakpoints.down('md')} {
            padding: 0;
        }

        ${theme.breakpoints.only('sm')} {
            margin: 16px 0;
        }

        ${theme.breakpoints.down('xs')} {
            margin: 4px 0 10px;
        }
    `;

    const descriptionStyle = css`
        position: relative;
        padding-right: 21%;
        color: ${theme.colours.boulder2};
        justify-content: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ${theme.breakpoints.only('xl')} {
            padding-right: 10%;
        }

        ${theme.breakpoints.only('lg')} {
            padding-right: 15%;
        }

        ${theme.breakpoints.only('sm')} {
            padding-right: 0;
        }

        ${theme.breakpoints.down('xs')} {
            padding-right: 10%;
        }
    `;

    const roundButtonStyle = css`
        margin-bottom: 136px;
        
        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-bottom: 106px;
        }

        ${theme.breakpoints.only('xxl')} {
            margin-bottom: 104px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-bottom: 78px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-bottom: 85px;
        }

        ${theme.breakpoints.down('md')} {
            margin-bottom: 0;
        }
    `;

    const svgLeftStyle = css`
        position: absolute;
        top: -1%;
        left: -2px;
        fill: white;
        z-index: 3;
    `;

    const svgTopStyle = css`
        position: absolute;
        left: -1%;
        top: -2px;
        fill: white;
        z-index: 3;
    `;

    return (
        <div css={containerStyle} className={className}>
            <div css={leftSectionContainerStyle}>
                <div css={authorContainerStyle}>
                    <>
                        {post.author?.webpWithFallbackPortrait && (
                            <Image image={post.author.webpWithFallbackPortrait} css={authorImageStyle} />
                        )}
                        <ContentCombine
                            Tag={'p'}
                            fontSizeType={"9"}
                        >
                            {post.author?.name && (
                                <span>Posted by {post.author.name}, </span>
                            )}
                            {publishedDate}
                        </ContentCombine>
                    </>
                </div>
                <div css={descriptionContainerStyle}>
                    <ContentCombine
                        Tag={'h1'}
                        fontSizeType={'3'}
                        css={titleStyle}
                        className={"header"}
                    >
                        {post.title}
                    </ContentCombine>
                    <ContentCombine
                        Tag={'p'}
                        fontSizeType={'9'}
                        css={descriptionStyle}
                    >
                        {post.shortDescription}
                    </ContentCombine>
                </div>
                <RoundButton
                    textColour={'white'}
                    backgroundColour={theme.colours.curiousBlue2}
                    textFontSize={buttonFontSize}
                    buttonWidth={buttonWidth}
                    buttonHeight={buttonHeight}
                    css={roundButtonStyle}
                    className={"button"}
                >
                    Read more
                </RoundButton>
            </div>
            <div css={rightSectionContainerStyle}>
                <Image
                    image={post.banner}
                    useFocusPoint={post.useFocusPointListPageBanner}
                />
                {/*SVG to simulate special border radius for banner*/}
                {useCurve && (
                    isMobileBreakpoint ? (
                        <svg
                            css={svgTopStyle}
                            viewBox={"0 0 100 18"}
                            width={"102%"}
                        >
                            <path d={`M 0,0 L 0,18 Q 50,-16 100,18 L 100,0 L 0, 0`} />
                        </svg>
                    ) : (
                        <svg
                            css={svgLeftStyle}
                            viewBox={"0 0 23 100"}
                            height={"102%"}
                        >
                            <path d={`M 0,0 L 23,0 Q -22,50 23,100 L 0,100 L 0, 0`} />
                        </svg>
                    )
                )}
            </div>
        </div>
    );
}

export default Banner;