import React from 'react';
import { css, useTheme } from '@emotion/react';
import Stars from "core/Components/Item/Stars";

type Props = {
    stars?: number,
    textColour?: string,
};

const Review: React.FC<Props> = ({ stars, textColour = 'white' }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        font-size: 20px;
        line-height: 26px;
        margin-top: 51.41px;
        justify-content: center;
        color: ${textColour}
        
        ${theme.breakpoints.up('5xl')} {
            margin-top: 41.41px;
            font-size: 24px;
            line-height: 36px;
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            font-size: 20px;
            line-height: 24px;
            margin-top: 51.41px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            font-size: 20px;
            line-height: 24px;
            margin-top: 19.52px;
        }

        ${theme.breakpoints.only('md')} {
            font-size: 20px;
            line-height: 24px;
            margin-top: 8.41px;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 14px;
            line-height: 17px;
            margin-top: 18px;
        }
    `;

    return (
        <div css={style}>
            <span>Write a review</span>
            <Stars stars={stars} />
        </div>
    );
};


export default Review;