import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: PricingCard,
};

const Header: React.FC<Props> = ({
    data,
}) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { headerColour } = colourTheme;

    const headerTitleStyle = css`
        font-size: 18px;
        line-height: 37px;
        font-weight: ${theme.fonts.weights.bold};
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        
        ${headerColour && css`
            color: ${headerColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 24px;
            line-height: 36px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 13px;
            line-height: 27px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 29px;
        }
    `;

    return (
        <ContentCombine css={headerTitleStyle}>{data.header}</ContentCombine>
    );
};

export default Header;
