import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const TextSectionContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const textSectionStyle = css`
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 1043px;
        
        ${theme.breakpoints.up("5xl")} {
            width: 1298px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 838px;
        }

        ${theme.breakpoints.only("md")} {
            width: 605px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 466px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 318px;
        }
    `;

    return (
        <ContentSection css={textSectionStyle}>
            {children}
        </ContentSection>
    );
}

export default TextSectionContainer;
