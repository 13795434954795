import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    type?: string,
    colour?: string,
};

const ItemLocation: React.FC<Props> = ({ children, type, colour }) => {
    const theme = useTheme();
    const defaultColour = theme.colours.grey[920];

    const style = css`
        padding-top: 7px;
        font-weight: ${theme.fonts.weights.bold};
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${type === "event" ? defaultColour : colour ?? defaultColour};

        ${theme.breakpoints.up("5xl")} {
            font-size: 18px;
            line-height: 22px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 16px;
            line-height: 19px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 15px;
            line-height: 25px;
        }

        ${theme.breakpoints.only("lg")} {
            font-size: 11px;
            line-height: 19px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 12px;
            line-height: 21px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 20px;
            -webkit-line-clamp: 2;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemLocation;