import React from "react";

type Props = {
    children: React.ReactNode,
};

const FeatureContentContainer: React.FC<Props> = ({ children }) => {
    return (
        <div>{children}</div>
    );
}

export default FeatureContentContainer;
