import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const Wrapper: React.FC<Props> = ({ children, className = '' }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        width: 100%;
        height: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        padding: 40px 279px;

        ${theme.breakpoints.up("3xl")} {
            padding: 38px 359px;
            max-width: 1600px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 41px 273px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 40px 93px;
        }

        ${theme.breakpoints.between("sm", "md")} {
            padding: 40px 45px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 40px 19px;
        }
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMinHeight={false} className={className}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
