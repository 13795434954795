import { useMemo } from "react";
import { useTheme } from "@emotion/react";
import useBreakpoint from "core/hooks/useBreakpoint";

const useListBreakpoint = () => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();

    const [bannerHeight, itemBoxWidth] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return [888, 475];
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [619, 400];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [560, 397];
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return [489, 356];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [490, 297];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [600, 297];
            default:
                return [385, 300];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    return { bannerHeight: bannerHeight, itemBoxWidth: itemBoxWidth };
};

export default useListBreakpoint;