import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    sidePadding?: number,
    width?: number,
    minWidth?: number,
    fontSize?: number,
    isTextBold?: boolean,
    textColour?: string,
    textHoverColour?: string,
    buttonLineColour?: string,
    backgroundColour?: string,
    backgroundHoverColour?: string,
    useBoxShadow?: boolean,
    buttonBorder?: number,
    noButtonBorderHover?: boolean,
    className?: string,
}

const RoundTextButtonWrapper: React.FC<Props> = ({
    children,
    sidePadding,
    width,
    minWidth = 0,
    fontSize,
    isTextBold = true,
    textColour,
    textHoverColour,
    buttonLineColour,
    backgroundColour,
    backgroundHoverColour,
    useBoxShadow = true,
    buttonBorder = 0,
    noButtonBorderHover = false,
    className,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 ${sidePadding ?? 20}px;
        border-radius: 26px;
        font-size: ${fontSize ? fontSize + "px" : theme.fonts.baseSize};
        color: ${textColour ? textColour : theme.colours.grey[920]};
        border: ${buttonBorder}px solid ${textColour};
        background-color: ${backgroundColour ? backgroundColour : "translate"};

        &:hover {
            background-color: ${backgroundHoverColour ? backgroundHoverColour : theme.colours.malibu2};
        }

        ${!!width && css`
            justify-content: center;
            width: ${width}px;
        `}

        ${minWidth > 0 && css`
            justify-content: center;
            min-width: ${minWidth}px;
        `}
        
        ${buttonLineColour && css`
            border: solid 1px ${buttonLineColour};
        `}

        ${useBoxShadow && css`
            box-shadow: ${theme.borderAndShadow.boxShadow};
        `}

        ${isTextBold && css`
            font-weight: ${theme.fonts.weights.bold};
        `}

        ${noButtonBorderHover && css`
            &:hover {
                border: 1px solid transparent;
            }
        `};

        ${textHoverColour && css`
            &:hover {
                color: ${textHoverColour};
            }
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default RoundTextButtonWrapper;
