import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: PricingCard,
};

const Description: React.FC<Props> = ({
    data,
}) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { textColour } = colourTheme;

    const descriptionStyle = css`
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        font-weight: ${theme.fonts.weights.bold};
        position: absolute;
        top: 0;
        padding: 20px 11px;
        align-items: flex-start;
        
        ${textColour && css`
            color: ${textColour};
        `}

        ${theme.breakpoints.up("5xl")} {
            font-size: 27px;
            line-height: 32px;
            padding: 37px 27px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 13px;
            line-height: 16px;
            padding: 16px 18px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 15px;
            line-height: 18px;
            padding: 14px 16px;
        }
    `;

    return (
        <ContentCombine css={descriptionStyle}>{data.text}</ContentCombine>
    );
};

export default Description;
