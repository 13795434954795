import React from "react";
import { css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const ImageContainer: React.FC<Props> = ({ children }) => {
    const imageContainerStyle = css`
        display: flex;
        align-items: flex-end;
    `;

    return (
        <ContentSection css={imageContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default ImageContainer;
