import React, { ElementType } from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    text: string,
    textColour?: string,
    Tag?: ElementType,
};

const GroupHeading: React.FC<Props> = ({ text, textColour = 'white', Tag = 'p' }) => {
    const theme = useTheme();

    const style = css`
        margin: 85px auto 0;
        font-size: 25px;
        line-height: 40px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${textColour};
        
        ${theme.breakpoints.up('5xl')} {
            margin: 123px auto 0;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin: 94px auto 0;
        }

        ${theme.breakpoints.only('xl')} {
            margin: 128px auto 0;
        }

        ${theme.breakpoints.only('lg')} {
            margin: 79px auto 0;
        }

        ${theme.breakpoints.only('md')} {
            margin: 41px auto 0;
        }

        ${theme.breakpoints.only('sm')} {
            width: 80%;
            font-size: 18px;
            line-height: 25px;
            margin: 40px auto 0;
        }

        ${theme.breakpoints.down('xs')} {
            width: 80%;
            font-size: 18px;
            line-height: 25px;
            margin: 35px auto 0;
        }
    `;

    return (
        <Tag css={style}>
            { text }
        </Tag>
    );
};

export default GroupHeading;
