import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const LoginRightContainer: React.FC<Props> = ({children}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        width: 50%;

        ${theme.breakpoints.down("md")} {
            width: 100%;
        }

        ${theme.breakpoints.down("sm")} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default LoginRightContainer;