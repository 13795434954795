import React, { useEffect, useMemo, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import Gallery from "core/Components/Item/Gallery";
import GroupHeading from "core/Components/Item/GroupHeading";
import ItemButtonGroup from "core/Components/Item/ItemButtonGroup";
import ItemChevron from "core/Components/Item/ItemChevron";
import ItemSection from "core/Components/Item/ItemSection";
import ItemWrapper from "core/Components/Item/ItemWrapper";
import LeftContainer from "core/Components/Item/LeftContainer";
import LeftTextContainer from "core/Components/Item/LeftTextContainer";
import MainHeading from "core/Components/Item/MainHeading";
import RightContainer from "core/Components/Item/RightContainer";
import RightTextContainer from "core/Components/Item/RightTextContainer";
import SimpleText from "core/Components/Item/SimpleText";
import TitleAndText from "core/Components/Item/TitleAndText";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import SectionContainer from "core/Components/SectionContainer";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import FormModal from "core/Components/FormModal";
import HubspotForm from "core/Components/HubspotForm";
import useBreakpoint from "core/hooks/useBreakpoint";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    data: CareerPage,
};

const CareerPage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [, setAppState] = useAppState();
    const { minWidth } = useBreakpoint();

    useEffect(() => {
        setAppState({
            currentPageName: data.title
        });
    }, [data.title, setAppState]);

    const colourTheme = data?.theme ? JSON.parse(data?.theme) : null;

    const [showingPopup, setShowingPopup] = useState(false);
    const [videoPath, setVideoPath] = useState('');

    const onShowPopupClick = () => setShowingPopup(true);
    const onClosePopupClick = () => setShowingPopup(false);
    const onShowVideoClick = (url: string) => setVideoPath(url);
    const onCloseVideoClick = () => setVideoPath('');

    const [chevronSize, chevronColour, chevronBackground, chevronBackgroundHover] = useMemo(() => {
        const white = theme.colours.white;
        const grey = theme.colours.grey[500];
        const hoverColour = theme.colours.azureRadiance;

        switch (true) {
            case minWidth >= theme.breakpoints.sizes['5xl']:
                return [41, white, grey, hoverColour];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [32, white, grey, hoverColour];
            default:
                return [24, white, grey, hoverColour];
        }
    }, [
        minWidth,
        theme.colours.white,
        theme.colours.grey,
        theme.breakpoints.sizes,
        theme.colours.azureRadiance
    ]);

    const formModalStyle = css`
        top: 70px;
    `;

    return (
        <SectionContainer>
            <ItemChevron chevronSize={chevronSize}>
                <RoundChevronButton
                    type={"left"}
                    onClick={() => navigate(-1)}
                    roundColour={chevronBackground}
                    roundHoverColour={chevronBackgroundHover}
                    chevronColour={chevronColour}
                    size={chevronSize}
                />
            </ItemChevron>
            <ItemWrapper>
                <ItemSection fontColour={"black"}>
                    <LeftContainer>
                        {!!data?.imagesAndVideos?.length && (
                            <Gallery imagesAndVideos={data.imagesAndVideos} onVideoClick={onShowVideoClick} />
                        )}

                        <LeftTextContainer>
                            {(data.specificationHeader && data.specification) && (
                                <TitleAndText
                                    title={data.specificationHeader}
                                    titleColour={"black"}
                                    text={data.specification}
                                    textFontSizeType={"10"}
                                />
                            )}

                            {(data.informationHeader && data.information) && (
                                <TitleAndText
                                    title={data.informationHeader}
                                    titleColour={"black"}
                                    text={data.information}
                                    textFontSizeType={"10"}
                                />
                            )}
                        </LeftTextContainer>
                    </LeftContainer>
                </ItemSection>
                <ItemSection fontColour={theme.colours.white} backgroundColour={colourTheme?.colour}>
                    <RightContainer>
                        <GroupHeading text={data.cityCode.name + ', ' + data.cityCode.countryCode.name} textColour={data.textColour} />
                        <MainHeading text={data.title} textColour={data.textColour} />
                        <ItemButtonGroup
                            registerButtonTitle={"Apply now"}
                            onRegisterClick={onShowPopupClick}
                            buttonSidePadding={35}
                            isShowingRegister
                        />
                        <SimpleText text={data.department} textColour={data.textColour} useMarginTop />
                        <SimpleText text={data.employmentType} textColour={data.textColour} />
                        <SimpleText text={data.salary} textColour={data.textColour} />
                        <RightTextContainer>
                            <TitleAndText
                                text={data.description}
                                titleColour={data.textColour}
                                textFontSizeType={"10"}
                            />
                        </RightTextContainer>
                    </RightContainer>
                </ItemSection>
            </ItemWrapper>

            {(data.hubspotFormId) && (
                <FormModal
                    css={formModalStyle}
                    isVisible={showingPopup}
                    onClose={onClosePopupClick}
                    isFixed
                >
                    <MainHeading text={data.title} textColour={theme.colours.curiousBlue} Tag={"h2"} />
                    <HubspotForm hubspotFormId={data.hubspotFormId} shouldLoadScript={showingPopup} />
                </FormModal>
            )}

            {videoPath && (
                <VideoViewContainer
                    clearPlayVideo={onCloseVideoClick}
                    leftSectionColour={theme.colours.white}
                    rightSectionColour={colourTheme?.colour}
                    useBackground
                >
                    <VideoViewLayer>
                        <VideoPlayer videoPath={videoPath} autoPlay />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}
        </SectionContainer>
    );
};

export default CareerPage;
