import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
}

const BannerText: React.FC<Props> = ({
    children
}) => {
    const theme = useTheme();

    const style = css`
        justify-content: start;
        width: 70%;
        color: ${theme.colours.white};
        filter: drop-shadow(${theme.borderAndShadow.dropShadow2});
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up("5xl")} {
            font-size: 52px;
            line-height: 63px;
            margin-bottom: 33px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 52px;
            line-height: 63px;
            margin-bottom: 32px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 26px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 25px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 24px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 12px;
        }

        ${theme.breakpoints.down("md")} {
            width: 100%;
        }
    `;

    return (
        <ContentCombine css={style}>{children}</ContentCombine>
    );
};

export default BannerText;