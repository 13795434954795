import useBreakpointLib from 'use-breakpoint';
import breakpoints, { isDown } from 'theme/breakpoints';

const OVER_5_XL = breakpoints.sizes["5xl"] + 1;

const useBreakpoint = () => {
    const {
        breakpoint,
        maxWidth,
        minWidth
    } = useBreakpointLib(breakpoints.sizes, "xxs");

    const isMobileBreakpoint = isDown(breakpoint, "md");

    return {
        breakpoint,
        maxWidth: maxWidth ?? OVER_5_XL,
        minWidth,
        isMobileBreakpoint,
    }
};

export default useBreakpoint;
