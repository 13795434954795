import React, { ReactNode } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import FeatureContentContainer from "elements/Components/SubscriptionPlan/components/Features/FeatureContentContainer";
import FeatureContentWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureContentWrapper";
import FeatureContent from "elements/Components/SubscriptionPlan/components/Features/FeatureContent";
import { ReactComponent as Tick } from 'images/icons/v.svg';

type Props = {
    options: SubscriptionOption[],
    tierCategories: [TierCategory],
    featureBoxWidth: number,
    otherBoxWidth: number,
    dotMargin: number,
    featureInnerBoxWidth: number,
    gap: number,
    otherInnerBoxWidth: number,
}
const SubscriptionOptions: React.FC<Props> = ({
    options,
    tierCategories,
    featureBoxWidth,
    otherBoxWidth,
    dotMargin,
    featureInnerBoxWidth,
    gap,
    otherInnerBoxWidth,
}) => {
    const theme: Theme = useTheme();

    const getOptionTier = (option: SubscriptionOption, tierCategory: TierCategory) => {
        const tier = option.tiers.find((tier: SubscriptionTier) => tier.category.id === tierCategory.id);
        let isTypography = false;
        let content: ReactNode = <>-</>;

        if (tier) {
            if (!!tier.description) {
                content = <div css={contentStyle} dangerouslySetInnerHTML={{ __html: tier.description }} />;
                isTypography = true;
            } else if (tier.isIncluded) {
                content = <Tick css={tickStyle(tierCategory.backgroundColour)} />;
            }
        }

        return (
            <FeatureContent
                key={`oc-${tierCategory.name}`}
                width={otherInnerBoxWidth}
                colour={tierCategory.backgroundColour}
                {...isTypography && {
                    justifyContent: 'left',
                    className: 'typography',
                }}
            >
                {content}
            </FeatureContent>
        )
    }

    const tickStyle = (colour: string) => css`
        width: 38px;
        height: 38px;

        path:last-of-type {
            fill: ${colour}
        }
    `;

    const contentStyle = css`
        width: 100%;
    `;

    const optionTitleStyle = css`
        font-weight: ${theme.fonts.weights.bold};
    `;

    return (
        <>
            {options.map((option: SubscriptionOption, index: number) => (
                <FeatureContentContainer key={`o-${option.id}`}>
                    <FeatureContentWrapper
                        gap={gap}
                        featureBoxWidth={featureBoxWidth}
                        otherBoxWidth={otherBoxWidth}
                        dotMargin={dotMargin}
                        isLast={options.length - 1 === index}
                        useBeforeTag
                    >
                        <FeatureContent width={featureInnerBoxWidth} justifyContent={"start"}>
                            <div>
                                <p css={optionTitleStyle}>{option.title}</p>
                                <div dangerouslySetInnerHTML={{__html: option.description}} />
                            </div>
                        </FeatureContent>
                        {tierCategories.map((tierCategory: TierCategory) => (
                            getOptionTier(option, tierCategory)
                        ))}
                    </FeatureContentWrapper>
                </FeatureContentContainer>
            ))}
        </>
    )
}

export default SubscriptionOptions;