import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    marginSide: string,
};

const NavSide: React.FC<Props> = ({
    children,
    className = "",
    marginSide = 0,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        width: fit-content;
        align-items: center;
        flex-grow: 1;
        
        ${marginSide === 'right' && css`
            justify-content: end;
        `}
        
        ${theme.breakpoints.up("5xl")} {
            margin: 0 ${marginSide === 'right' ? 200 : 0}px 0 ${marginSide === 'left' ? 200 : 0}px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin: 0 ${marginSide === 'right' ? 80 : 0}px 0 ${marginSide === 'left' ? 80 : 0}px;
        }

        ${theme.breakpoints.only("xl")} {
            margin: 0 ${marginSide === 'right' ? 38 : 0}px 0 ${marginSide === 'left' ? 38 : 0}px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default NavSide;
