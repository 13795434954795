import React from "react";

import CardChevronButton from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/Button/CardChevronButton";
import ListItemHeader from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/ListItem/components/ListItemHeader";
import ListItemContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/ListItem/components/ListItemContainer";

type Props = {
    contentListItem: ContentListItem,
    card: ImageVerticalCard,
    setHubspotFormId: (id: string) => void,
    setHubspotFormTitle: (title: string) => void,
    onShowPopupClick: () => void,
};

const ListItem: React.FC<Props> = ({
    contentListItem,
    card,
    setHubspotFormId,
    setHubspotFormTitle,
    onShowPopupClick,
}) => {
    const linkURL = contentListItem.innerURL || contentListItem.outerURL;
    const hubspotFormId = contentListItem.hubspotFormId;

    const setHubspot = () => {
        setHubspotFormId(hubspotFormId);
        setHubspotFormTitle(contentListItem.header);
        onShowPopupClick();
    };

    return (
        <ListItemContainer
            key={contentListItem.id}
            {...hubspotFormId ? { onClick: setHubspot } : { linkURL }}
        >
            <ListItemHeader card={card}>
                {contentListItem.header}
            </ListItemHeader>

            <CardChevronButton />
        </ListItemContainer>
    );
}

export default ListItem;
