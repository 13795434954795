import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    textColour?: string,
    className?: string,
};

const SlideWrapper: React.FC<Props> = ({ children, textColour, className }) => {
    const theme: Theme = useTheme();

    const style = css`
        padding: 0 7px;
        
        ${theme.breakpoints.up("5xl")} {
            padding: 0 10px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 0 8px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            padding: 0 5px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 0 3px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 0 5px;
        }
        
        ${textColour && css`
            color: ${textColour};
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default SlideWrapper;
