import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import TextClamp from "react-string-clamp";

import Ribbon from "core/Components/Ribbon";
import Title from "core/Components/Title";
import ItemImage from "core/Components/ItemWithRibbon/ItemImage";
import ItemBox from "core/Components/ItemWithRibbon/ItemBox";
import ItemContent from "core/Components/ItemWithRibbon/ItemContent";
import ItemLocation from "core/Components/ItemWithRibbon/ItemLocation";
import ItemText from "core/Components/ItemWithRibbon/ItemText";
import ItemTitle from "core/Components/ItemWithRibbon/ItemTitle";
import LinkTo from "core/Components/LinkTo";
import { sort } from "core/includes/sort";
import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import { isBetween, isDown, isUp } from "theme/breakpoints";
import useBreakpoint from "core/hooks/useBreakpoint";
import DatePeriod from "core/Components/Item/DatePeriod";
import { getTwelveHourTime } from "core/includes/dates";
import ConditionalWrapper from "core/Components/ConditionalWrapper";
import TextClampAutoLines from "core/Components/TextClampAutoLines";

type Props = {
    type?: string,
    data: any,
    schoolType?: [SchoolTypeItem],
    showRibbon?: boolean,
    ribbonText?: string,
    subheader?: string,
    headerColour?: string,
    subheaderColour?: string,
    textBoxColour?: string,
    commonImage?: Image | undefined,
    commonFocusPoint?: boolean,
    titleColourOnImage?: string,
    showTitleOnImage?: boolean,
};

const ItemBoxContainer: React.FC<Props> = ({
    type = "",
    data,
    schoolType = undefined,
    showRibbon = false,
    ribbonText = undefined,
    subheader = undefined,
    subheaderColour = undefined,
    textBoxColour = undefined,
    commonImage = undefined,
    commonFocusPoint = true,
    titleColourOnImage = "",
    showTitleOnImage = false,
}) => {
    const theme = useTheme();
    const { breakpoint } = useBreakpoint();

    // Event list only
    const startTime = data.eventStartTime;
    const endTime = data.eventEndTime;

    const chevronSize = useMemo(() => {
        if (isUp(breakpoint, '5xl')) {
            return 44;
        } else if (isBetween(breakpoint, 'xxl', '4xl')) {
            return 36;
        } else if (breakpoint === 'xl') {
            return 33;
        }
        return 28;
    }, [breakpoint]);

    // Success Story only
    const schoolIds = data.schoolType ? JSON.parse(data.schoolType) : null;
    const schoolName = schoolIds
        ? schoolType?.find((schoolTypeItem: SchoolTypeItem) => (schoolTypeItem.id === schoolIds[0]))?.type
        : undefined;
    const ribbonTitle = ribbonText ?? (type === "event" ? data.cityCode?.name : schoolName);
    const imageList = sort(data.imagesAndVideos);
    const image = commonImage ?? imageList?.[0]?.image;
    const useFocusPoint = commonImage ? (
        commonFocusPoint
    ) : (
        type === "event" ? data.imagesAndVideos?.[0]?.useFocusPoint : data.useFocusPoint
    );

    const themeType = data.theme ? JSON.parse(data.theme) : null;

    const [shortDescriptionFontSize, shortDescriptionLineHeight] = useMemo(() => {
        switch(true) {
            case isDown(breakpoint, 'xl'):
                return [14, 18];
            default:
                return [15, 19];
        }
    }, [breakpoint]);

    const shortDescriptionStyle = css`
        width: calc(100% - ${chevronSize}px);
        display: flex;
        font-size: ${shortDescriptionFontSize}px;
        line-height: ${shortDescriptionLineHeight}px;
        color: black;
        overflow: hidden;
        
        ${theme.breakpoints.down('lg')} {
            font-size: 12px;
            line-height: 15px;
        }

        ${type === 'career' && css`
            ${theme.breakpoints.up('5xl')} {
                margin-bottom: 20px;
            }
        `};

        ${type === 'success-story' && css`
            ${theme.breakpoints.up('5xl')} {
                margin-bottom: 8px;
            }
        `};

        ${type === 'event' && css`
            min-height: 57px;
        `};
    `;

    const lineStyle = css`
        height: 1px;
        background: ${theme.colours.mercury};
        
        ${theme.breakpoints.up('5xl')} {
            margin: 14px 0;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin: 7px 0;
        }

        ${theme.breakpoints.only('xxl')} {
            margin: 4px 0;
        }

        ${theme.breakpoints.only('xl')} {
            margin: 10px 0;
        }

        ${theme.breakpoints.down('lg')} {
            margin: 7px 0;
        }
    `;

    const timeStyle = css`
        font-size: 14px;
        color: black;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up('xl')} {
            height: 21px;
        }

        ${theme.breakpoints.down('lg')} {
            height: 15px;
            font-size: 12px;
            line-height: 15px;
        }
    `;

    const priceLabelStyle = css`
        color: ${theme.colours.tundora2};
        font-size: 18px;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.down('lg')} {
            font-size: 12px;
            line-height: 15px;
        }
    `;

    const priceAdditionLabelStyle = css`
        margin-left: 5px;
        max-width: 95px;
        max-height: 27px;
        color: ${theme.colours.tundora2};
        font-size: 11px;
        line-height: 13px;
        overflow: hidden;
        white-space: pre-wrap;

        ${theme.breakpoints.down('lg')} {
            max-width: 70px;
            max-height: 20px;
            font-size: 8px;
            line-height: 10px;
        }
    `;

    const bottomContainerStyle = css`
        display: flex;
        width: 100%;
        justify-content: ${type === 'event' ? 'space-between': 'end'};
        flex: 0;
        align-items: flex-end;

        ${type === 'career' && css`
            align-items: flex-end;
            flex: 1;
        `};
    `;

    const leftSideContainerStyle = css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
    `;

    const priceContainerStyle = css`
        display: flex;
        align-items: center;
        border: 2px solid ${themeType?.colour};
        border-radius: ${theme.borderAndShadow.larger2Radius};
        padding: 6px 10px;
        margin-right: 10px;
        height: ${chevronSize}px;

        ${theme.breakpoints.between('xxl', '4xl')} {
            padding: 3px 10px;
        }

        ${theme.breakpoints.down('xl')} {
            padding: 1px 10px;
        }
    `;

    const descAndChevronStyle = css`
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow: hidden;
    `;

    const eventTitleAndDescStyle = css`
        ${type === 'event' && css`
            height: 110px;
            overflow: hidden;
            
            ${theme.breakpoints.only('xl')} {
                height: 107px;
            }

            ${theme.breakpoints.down('lg')} {
                height: 90px;
            }
        `};
    `;

    return (
        <LinkTo to={data.linkUrl}>
            <ItemBox hoveredColour={themeType?.hoveredColour}>
                {(showRibbon || data.showRibbon) && !!ribbonTitle && (
                    <Ribbon text={ribbonTitle} background={themeType?.colour} textColour={data.textColour} />
                )}
                <ItemImage
                    image={image}
                    useFocusPoint={useFocusPoint}
                    title={data.title}
                    titleColourOnImage={titleColourOnImage}
                    showTitleOnImage={showTitleOnImage}
                />
                <ItemContent>
                    <ItemText type={type} backgroundColour={textBoxColour ?? themeType?.colour}>
                        <div css={eventTitleAndDescStyle}>
                            <ItemTitle
                                type={type}
                                colour={themeType?.colour}
                            >
                                <Title>
                                    <TextClamp
                                        text={type === 'career' ? data.department ?? '' : data.title ?? ''}
                                        lines={type === 'event' ? 1 : 2}
                                    />
                                </Title>
                            </ItemTitle>
                            {type !== 'career' && (
                                <ItemLocation type={type} colour={subheaderColour ?? 'black'}>
                                    {subheader ?? data.location}
                                </ItemLocation>
                            )}
                            {type === 'event' && (
                                <TextClamp
                                    css={shortDescriptionStyle}
                                    text={data.shortDescription ?? ''}
                                    lines={(!!subheader || !!data.location) ? 3 : 4}
                                />
                            )}
                        </div>
                        <div css={lineStyle} />
                        <ConditionalWrapper
                            condition={type === 'career' || type === 'success-story'}
                            wrap={(children: any) => (
                                <div css={descAndChevronStyle}>{children}</div>
                            )}
                        >
                            {(type === 'career' || type === 'success-story') && (
                                <TextClampAutoLines
                                    text={data.shortDescription}
                                    lineHeight={shortDescriptionLineHeight}
                                    css={shortDescriptionStyle}
                                />
                            )}
                            <div css={bottomContainerStyle}>
                                {type === 'event' && (
                                    <div>
                                        <p css={timeStyle}>
                                            <DatePeriod
                                                start={data.eventDate}
                                                end={data.eventEndDate}
                                                isAbbreviationMonths={
                                                    new Date(data.eventDate).getMonth() !==
                                                    new Date(data.eventEndDate).getMonth()
                                                }
                                            />
                                        </p>
                                        <p css={timeStyle}>
                                            {(startTime && endTime) && (
                                                getTwelveHourTime(startTime, true) + ' - ' + getTwelveHourTime(endTime, true)
                                            )}
                                        </p>
                                    </div>
                                )}
                                <div css={leftSideContainerStyle}>
                                    {type === 'event' && (
                                        <div css={priceContainerStyle} className={'price-container'}>
                                            {(data.price.amount || data.price.label) ? (
                                                <>
                                                    <div css={priceLabelStyle}>
                                                        {data.price.label ?? `$${data.price.amount}`}
                                                    </div>
                                                    {data.price.subLabel && (
                                                        <div css={priceAdditionLabelStyle}>
                                                            {data.price.subLabel}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <span css={priceLabelStyle}>FREE</span>
                                            )}
                                        </div>
                                    )}
                                    <RoundChevronButton
                                        type={'right'}
                                        roundColour={themeType?.colour}
                                        size={chevronSize}
                                    />
                                </div>
                            </div>
                        </ConditionalWrapper>
                    </ItemText>
                </ItemContent>
            </ItemBox>
        </LinkTo>
    );
};

export default ItemBoxContainer;