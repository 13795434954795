import React from "react";

import SectionContainer from "core/Components/SectionContainer";

import Wrapper from "elements/Components/DescriptionWithRichText/components/Wrapper";
import Header from "elements/Components/DescriptionWithRichText/components/Header";
import Subheader from "elements/Components/DescriptionWithRichText/components/Subheader";
import TextContainer from "elements/Components/DescriptionWithRichText/components/TextContainer";
import Text from "elements/Components/DescriptionWithRichText/components/Text";

type Props = {
    data: DescriptionWithRichText
};

const DescriptionWithRichText: React.FC<Props> = ({ data }) => {
    return (
        <SectionContainer data={data}>
            <Wrapper>
                {data.header && (
                    <Header data={data} />
                )}

                {data.subheader && (
                    <Subheader data={data} />
                )}

                <TextContainer>
                    <Text data={data} />
                </TextContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default DescriptionWithRichText;
