import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    type?: string,
};

const HeaderContainer:React.FC<Props> = ({ children, type }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        ${(type === "event" || type === "career") && css`
            ${theme.breakpoints.down("md")} {
                flex-direction: column;
                
                ${type === "career" && css`
                    padding: 0 22px;
                    align-items: flex-start;
                `} 
            }
        `}

        ${(type === "success-story") && css`
            ${theme.breakpoints.down("sm")} {
                flex-direction: column;
            }
        `}
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default HeaderContainer;