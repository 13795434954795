import React from 'react';
import { css, useTheme } from "@emotion/react";
import useElementDimensions from "core/hooks/useElementDimensions";

type Props = {
    paymentTypeRef?: any,
};

const SlashSign: React.FC<Props> = ({
    paymentTypeRef
}) => {
    const theme = useTheme();

    const unitAmountTextHeight = useElementDimensions(paymentTypeRef.current).height;
    const polygonTopLeft = unitAmountTextHeight < 30 ? 80 : 85;
    const polygonBottomRight = unitAmountTextHeight < 30 ? 20 : 15;

    const slashSignStyle = css`
        width: ${unitAmountTextHeight / 3}px;
        height: ${unitAmountTextHeight}px;
        clip-path: polygon(${polygonTopLeft}% 0, 100% 0%, ${polygonBottomRight}% 100%, 0% 100%);
        background: ${theme.colours.white};
    `;

    return (
        <div css={slashSignStyle} />
    );
}

export default SlashSign;
