import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const Header: React.FC<Props> = ({ children, className = '' }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        color: ${theme.colours.curiousBlue};
        font-weight: ${theme.fonts.weights.bold};
    `;

    return (
        <ContentCombine Tag={"h2"} css={style} className={className}>{children}</ContentCombine>
    );
};

export default Header;