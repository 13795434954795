import React from 'react';
import moment from "moment";

type Props = {
    start: string,
    end: string,
    isAbbreviationMonths?: boolean,
};

const DatePeriod: React.FC<Props> = ({ start, end, isAbbreviationMonths = true }) => (
    <>{getDate(start, end, isAbbreviationMonths)}</>
);

const getDate = (start: string, end: string, isAbbreviationMonths: boolean) => {
    const dateFormat = 'DD ' + (isAbbreviationMonths ? 'MMM' : 'MMMM');

    if (start && !end) {
        return moment(start).format(dateFormat);
    }

    if (!start && end) {
        return moment(end).format(dateFormat);
    }

    if (start && end) {
        const sd = moment(start);
        const ed = moment(end);
        const sm = sd.format('MM'); // Start Month
        const em = ed.format('MM'); // End Month

        if (sd.diff(ed) < 0) {
            return (sm === em ? sd.format('DD') : sd.format(dateFormat)) + " - " + ed.format(dateFormat);
        }

        return sd.format(dateFormat);
    }

    return null;
};

export default DatePeriod;