import { useEffect } from "react";

type Props = {
    url: string,
}

const RedirectPage = ({ url = '' }: Props) => {
    useEffect(() => {
        if (url !== '') {
            window.location.replace(url);
        }
    }, [url]);

    return null;
}

export default RedirectPage;
