import React, { useMemo } from 'react';
import { isEqual } from "lodash";
import { Theme, useTheme, css, Global } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";
import Image from "core/Components/Image";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    item: SlideWithImage
};

const Card: React.FC<Props> = ({ item  }) => {
    const theme: Theme = useTheme();
    const { minWidth } = useBreakpoint();
    const hasText = (item.expandedCardSubHeader || item.expandedCardHeader || item.expandedCardText);

    const image = useMemo(() => {
        if (minWidth >= theme.breakpoints.sizes["lg"]) {
            return item.expandedImageLandscape || item.expandedImagePortrait;
        }

        return item.expandedImagePortrait || item.expandedImageLandscape;
    }, [minWidth, theme.breakpoints.sizes, item]);

    const useFocusPoint = useMemo(() => (
        (isEqual(image, item.expandedImageLandscape) && item.useFocusPointExpandedImageLandscape) ||
        (isEqual(image, item.expandedImagePortrait) && item.useFocusPointExpandedImagePortrait)
    ), [image, item]);

    const textContainerStyle = css`
        ${theme.breakpoints.down('3xl')} {
            margin-bottom: 92px;
        }

        ${theme.breakpoints.down('xxl')} {
            margin-bottom: 100px;
        }
    
        ${theme.breakpoints.only('md')} {
            margin-bottom: 106px;
        }
        
        ${theme.breakpoints.down('sm')} {
            margin-bottom: 55px;
        }

        ${theme.breakpoints.up('lg')} {
            padding: 0 8%;
        }
        
        ${theme.breakpoints.up('3xl')} {
            padding: 0 13%;
        }

        ${theme.breakpoints.up('5xl')} {
            padding: 0 8%;
            margin-bottom: 100px;
        }
    `;

    const imageContainerStyle = css`
        position: relative;
        width: 100vw;
        height: 630px;
        overflow: hidden;
        margin-bottom: 55px;

        ${theme.breakpoints.up('5xl')} {
            height: 963px;
        }

        ${theme.breakpoints.only('3xl')} {
            height: 716px;
        }
        
        ${theme.breakpoints.only('xl')} {
            height: 573px;
        }

        ${theme.breakpoints.only('lg')} {
            height: 458px;
            margin-bottom: 35px;
        }

        ${theme.breakpoints.down('md')} {
            min-width: 360px;
            margin-bottom: 30px;
        }
    `;

    const headerStyle = css`
        color: ${theme.colours.blue[250]};
        margin-bottom: 30px;
        text-align: center;
    `;

    const subHeaderStyle = css`
        margin-bottom: 30px;
        text-align: center;
    `;

    const textStyle = css`
        font-weight: ${theme.fonts.weights.light};
        ${theme.breakpoints.up('md') } {
            display: block;
            column-gap: 2%;
            column-count: 2;
        }
    `;

    const imageStyle = css`
        ${useFocusPoint && css`
            ${theme.breakpoints.down("md")} {
                > img {
                    display: flex;
                    min-height: unset !important;
                    max-height: unset !important;
                    height: unset !important;
                    width: 100% !important;
                    inset: unset !important;
                }
            }
        `}
    `;

    const overflowBodyStyle = css`
        body {
            overflow-x: hidden;
        }
    `;

    return (
        <>
            {hasText && (
                <div css={textContainerStyle}>
                    <ContentCombine Tag={'h2'} css={headerStyle}>{item.expandedCardHeader}</ContentCombine>
                    <ContentCombine Tag={'h4'} css={subHeaderStyle}>{item.expandedCardSubHeader}</ContentCombine>
                    <ContentCombine Tag={'h6'} fontSizeType={'6'} css={textStyle}>{item.expandedCardText}</ContentCombine>
                </div>
            )}
            {image && (
                <div css={imageContainerStyle}>
                    <Global styles={overflowBodyStyle} />
                    <Image
                        image={image}
                        useFocusPoint={useFocusPoint}
                        css={imageStyle}
                    />
                </div>
            )}
        </>
    )
};

export default Card;