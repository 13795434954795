import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const LeftContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        width: 560px;
        margin: 53.2px 79.1px 0 auto;
        
        ${theme.breakpoints.up('5xl')} {
            width: 653px;
            margin: 94px 170px 100px auto;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            width: 565px;
            margin: 53px 72px 70px auto;
        }

        ${theme.breakpoints.only('xxl')} {
            width: 565px;
            margin: 53px 72px 70px auto;
        }

        ${theme.breakpoints.only('xl')} {
            width: 499px; 
            margin: 90px 60px 100px auto;
        }

        ${theme.breakpoints.only('lg')} {
            width: 399.29px;
            margin: 94.49px 56.71px 50px auto;
        }
        
        ${theme.breakpoints.only('md')} {
            width: 478px;
            margin: 84px auto 60px;
        }
        
        ${theme.breakpoints.only('sm')} {
            width: 318.24px;
            margin: 56.79px auto 83px;
        }
        
        ${theme.breakpoints.down('xs')} {
            width: 318.24px;
            margin: 41.79px auto 59px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default LeftContainer;