import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const VideoViewLayer: React.FC<Props> = ({ children, className }) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        z-index: ${theme.zIndex.zIndexHighest + 1};
        width: 1081px;
        height: 608px;
        
        ${theme.breakpoints.up("5xl")} {
            width: 1708px;
            height: 960px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 854px;
            height: 482px;
        }

        ${theme.breakpoints.only("md")} {
            width: 697px;
            height: 394px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 478px;
            height: 269px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 318px;
            height: 179px;
            margin: 0 20px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default VideoViewLayer;
