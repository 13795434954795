import React, { useEffect, useRef } from "react";
import { css, useTheme } from "@emotion/react";
import document from 'core/includes/document';

import useDropdownOptionHeight from "core/Components/Buttons/DropdownButton/hooks/useDropdownOptionHeight";

type Props = {
    dropdownRef: any,
    options: Option[],
    showMenu: boolean,
};

const DropdownButtonScroll: React.FC<Props> = ({
    dropdownRef,
    options,
    showMenu,
}) => {
    const optionHeight = useDropdownOptionHeight();
    const scrollRef = useRef<any>();
    const theme = useTheme();
    const menuHeight = theme.sizes.menu.heightMain;
    const dropdownHeight = (options.length + 1) * optionHeight;
    const dropdownY = dropdownRef.current?.getBoundingClientRect().y;
    const lastOptionY = dropdownY + dropdownHeight;
    const deviceHeight = document.documentElement.clientHeight;

    useEffect(() => {
        if (showMenu && scrollRef.current && lastOptionY > deviceHeight) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [showMenu, lastOptionY, deviceHeight]);

    const style = css`
        position: absolute;
        top: -${menuHeight + 10}px;
    `;

    return (
        <div css={style} ref={scrollRef} />
    );
}

export default DropdownButtonScroll;
