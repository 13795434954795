import React, { useRef } from "react";

import MainImage from "elements/Components/Timeline/components/TimelineItem/components/MainImage";
import Avatar from "elements/Components/Timeline/components/TimelineItem/components/Avatar";
import Description from "elements/Components/Timeline/components/TimelineItem/components/Description";
import TimelineCircle from "elements/Components/Timeline/components/TimelineItem/components/TimelineCircle";
import MobileViewContainer from "elements/Components/Timeline/components/TimelineItem/components/MobileViewContainer";
import MobileViewDescriptionAndYearContainer from "elements/Components/Timeline/components/TimelineItem/components/MobileViewDescriptionAndYearContainer";
import MobileViewMainImageContainer from "elements/Components/Timeline/components/TimelineItem/components/MobileViewMainImageContainer";
import MobileViewYear from "elements/Components/Timeline/components/TimelineItem/components/MobileViewYear";
import MobileViewDescriptionContainer from "elements/Components/Timeline/components/TimelineItem/components/MobileViewDescriptionContainer";

type Props = {
    timelineItem: TimelineItem,
    index: number,
    circleSize: number,
    roundImageSize: number,
    squareImageWidth: number,
    squareImageHeight: number,
    sizeGap: number,
    isLastItem: boolean,
    avatarWidth: number,
    avatarHeight: number,
    isLargeTitle: boolean,
};

const MobileViewTimelineItem: React.FC<Props> = ({
    timelineItem,
    index,
    circleSize,
    roundImageSize,
    squareImageWidth,
    squareImageHeight,
    sizeGap,
    isLastItem,
    avatarWidth,
    avatarHeight,
    isLargeTitle,
}) => {
    const { theme, description, mainImage, avatarImage, milestoneYear, title } = timelineItem;
    const colourTheme = JSON.parse(theme);
    const { colour } = colourTheme;
    const descContainerRef = useRef<any>();
    const isOddOrder = !!((index + 1) % 2);

    return (
        <>
            <MobileViewContainer index={index}>
                <MobileViewMainImageContainer>
                    <MainImage
                        index={index}
                        src={mainImage?.URL}
                        roundImageSize={roundImageSize}
                        squareImageWidth={squareImageWidth}
                        squareImageHeight={squareImageHeight}
                        sizeGap={sizeGap}
                    />
                    {avatarImage?.URL && (
                        <Avatar
                            index={index}
                            src={avatarImage?.URL}
                            sizeGap={sizeGap}
                            roundImageSize={roundImageSize}
                            squareImageWidth={squareImageWidth}
                            avatarWidth={avatarWidth}
                            avatarHeight={avatarHeight}
                        />
                    )}
                </MobileViewMainImageContainer>
                <MobileViewDescriptionAndYearContainer
                    index={index}
                    isLastItem={isLastItem}
                    roundImageSize={roundImageSize}
                    squareImageHeight={squareImageHeight}
                >
                    <MobileViewYear
                        milestoneYear={milestoneYear}
                        colour={colour}
                        isOddOrder={isOddOrder}
                        isLargeTitle={isLargeTitle}
                        title={title}
                    />
                    <MobileViewDescriptionContainer isOddOrder={isOddOrder} containerRef={descContainerRef}>
                        <Description index={index}>
                            {description}
                        </Description>
                        <TimelineCircle colour={colour} circleSize={circleSize} isOddOrder={isOddOrder} isLargeTitle={isLargeTitle} />
                    </MobileViewDescriptionContainer>
                </MobileViewDescriptionAndYearContainer>
            </MobileViewContainer>
        </>
    );
}

export default MobileViewTimelineItem;
