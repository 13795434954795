import React from "react";
import { css } from "@emotion/react";

import NavLinkTag from "core/Components/Navigations/NavLinkTag";
import NavSubWrapper from "core/Components/Navigations/NavSubWrapper";
import NavTextBox from "core/Components/Navigations/NavTextBox";

type Props = {
    headerMenuAll?: [StandardPage],
    selectedSegments?: string[],
    getSubMenu: Function,
    mouseOverHandler: Function,
    mouseClickHandler: Function,
    showBreadcrumb: boolean,
    isClick: boolean,
};

const NavLinkContainer: React.FC<Props> = ({
    headerMenuAll = undefined,
    selectedSegments = undefined,
    getSubMenu,
    mouseOverHandler,
    mouseClickHandler,
    showBreadcrumb,
    isClick,
}) => {
    const style = css`
        position: relative;
        display: flex;
        
        > li {
            position: relative;
            
            > .subMenu {
                display:none;
            }
        }

        ${!isClick && css`
            > li:hover {
                > .subMenu {
                    display: block;

                    ${showBreadcrumb && css`
                        z-index: 1;
                        border-top: 1px solid rgba(0, 0, 0, 0.16);
                    `}
                }
            }
        `}
    `;

    return (
        <ul css={style}>
            {headerMenuAll && headerMenuAll.map((headerMenu: StandardPage) => (
                headerMenu.showInMenus ? (
                    <li key={"header" + headerMenu.id}>
                        <NavLinkTag data={headerMenu}>
                            <NavTextBox
                                data={headerMenu}
                                selectedSegments={selectedSegments}
                                mouseOverHandler={mouseOverHandler}
                                mouseClickHandler={mouseClickHandler}
                            >
                                {headerMenu.title}
                            </NavTextBox>
                        </NavLinkTag>
                        <NavSubWrapper
                            headerMenu={headerMenu}
                            getSubMenu={getSubMenu}
                            selectedSegments={selectedSegments}
                            mouseClickHandler={mouseClickHandler}
                        />
                    </li>
                ) : null
            ))}
        </ul>
    );
};

export default NavLinkContainer;