import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: BannerWithFullImage
};

const Header: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { headerColour } = colourTheme;

    const headerStyle = css`
        white-space: break-spaces;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};
        width: 581px;

        ${theme.breakpoints.up("5xl")} {
            width: 1034px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 437px;
        }

        ${theme.breakpoints.only("xl")} {
            width: 437px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 428px;
        }

        ${theme.breakpoints.only("md")} {
            width: 428px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 326px;
        }
    `;


    return (
        <Title Tag={"h1"} fontSizeType={"2"} css={headerStyle}>
            {data.header}
        </Title>
    );
}

export default Header;
