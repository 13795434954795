import React, { useState } from 'react';
import { css, SerializedStyles, useTheme } from "@emotion/react";

import { FormDropdownOption } from "core/Components/Form/DropDown";
import searchIconUrl from "images/icons/search.svg";

type Props = {
    extraOptionContainerStyles?: SerializedStyles,
    extraOptionStyles?: SerializedStyles,
    selected?: FormDropdownOption | null | undefined,
    setSelected?: Function,
    setDropped: Function,
    options?: Array<FormDropdownOption>,
    searchable: boolean
};

const DropDownList: React.FC<Props> = ({
    extraOptionContainerStyles,
    extraOptionStyles,
    options = [],
    searchable,
    selected,
    setDropped,
    setSelected = () => {},
}) => {
    const theme = useTheme();
    const [searchString, setSearchString] = useState("");
    const filteredOptions = options.filter((option) => {
        return option.value.toLowerCase().includes(searchString.toLowerCase())
    });

    const onItemClick = (option: FormDropdownOption) => {
        setSelected(option);
        setDropped(false);
    };

    const containerStyle = css`
        display: flex;
        border-radius: 3px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: ${theme.zIndex.zIndexTwo};

        ${extraOptionContainerStyles};
    `;

    const contentContainerStyle = css`
        background-color: white;
        position: relative;
        width: 100%;
        margin: auto;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
        border-radius: 3px;
    `;

    const searchContainerStyle = css`
        display: flex;
        position: relative;
        padding: 0 5px;
    `;

    const searchIconStyle = css`
        margin-right: 10px;
        background-image: url(${searchIconUrl});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 12%;
    `;

    const itemContainerStyle = css`
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    `;

    const itemStyle = (selected: boolean, isLast: boolean) => css`
        display: flex;
        padding: 5px;
        height: 40px;
        box-sizing: border-box;
        background-color: ${selected && theme.colours.hawkesBlue};
        border-bottom: ${selected && '2px solid' + theme.colours.cornflower};

        &:hover {
            border-radius: ${isLast && '0 0 3px 3px'};
            background-color: ${!selected && theme.colours.solitude};
            cursor: ${selected ? 'default' : 'pointer'}
        }

        p {
            font-size: 100%;
            font-weight: 300;
            font-family: ${theme.fonts.frutiger};
            margin: auto 0;
        }

        ${extraOptionStyles};
    `;

    const inputStyle = css`
        width: 100%;
        border: none;
        padding: 0;
        flex-grow: 1;
        height: 35px;
        font-size: 100%;
        font-weight: 300;
        font-family: ${theme.fonts.frutiger};

        &:focus {
            outline: none;
        }
    `;

    return (
        <div css={containerStyle}>
            <div css={contentContainerStyle}>
                {searchable && (
                    <div css={searchContainerStyle}>
                        <input
                            css={inputStyle}
                            onChange={event => {
                                setSearchString(event.target.value)
                            }}
                            value={searchString}
                        />
                        <div css={searchIconStyle} />
                    </div>
                )}
                <div css={itemContainerStyle}>
                    {filteredOptions.map((option, index) => (
                        <div
                            css={itemStyle(!!selected && option.id === selected.id, index === options.length - 1)}
                            key={index}
                            onClick={() => onItemClick(option)}
                        >
                            <p>{option.value}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default DropDownList;
