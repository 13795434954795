import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
    card: ImageVerticalCard,
    isImageFirst: boolean,
    isCardWithList: boolean,
};

const CardSection: React.FC<Props> = ({ children, card, isImageFirst, isCardWithList }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const {
        fromColour,
        toColour,
    } = colourTheme;
    const isLargeCard = card.image || isCardWithList;

    const contentSectionStyle = css`
        position: relative;
        width: 385px;
        height: ${isLargeCard ? 524 : 267}px;
        overflow: hidden;
        justify-content: flex-start;
        flex-direction: column;
        background: transparent linear-gradient(360deg, ${(fromColour)} 0%, ${(toColour)} 100%) 0 0 no-repeat padding-box;
        border-radius: 12px;

        ${!card.image && css`
            -webkit-mask-image: unset !important;
            overflow: visible;
        `}

        ${theme.breakpoints.only("xl")} {
            width: 351px;
            height: ${isLargeCard ? 483 : 267}px;
        }
        
        ${theme.breakpoints.only("lg")} {
            width: 301px;
            height: ${isLargeCard ? 414 : 227}px;
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: row;
            
            ${isImageFirst && css`
                flex-direction: row-reverse;
            `}
        }
        
        ${theme.breakpoints.only('md')} {
            width: ${!!card.image ? 675 : 542}px;
            height: 291px;
        }

        ${theme.breakpoints.only("sm")} {
            width: ${!!card.image ? 478 : 403}px;
            height: 230px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 320px;
            height: ${isLargeCard ? 439 : 220}px;
            flex-direction: column;
        }
    `;

    return (
        <ContentSection css={contentSectionStyle} className={"radius-box"}>
            {children}
        </ContentSection>
    );
}

export default CardSection;
