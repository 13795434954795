import React from "react";
import { useTheme, css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ErrorPageContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const containerStyle = css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 237px;
        width: 799px;
        height: 443px;

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 356px;
            width: 928px;
            height: 547px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 120px;
        }
        
        ${theme.breakpoints.only("lg")} {
            margin-bottom: 204px;
            width: 536px;
            height: 384px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 332px;
            width: 494px;
            height: 384px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 303px;
            width: 414px;
            height: 271px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 205px;
            width: 233px;
            height: 290px;
        }
    `;

    return (
        <div css={containerStyle}>
            {children}
        </div>
    );
};

export default ErrorPageContainer;
