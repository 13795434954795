import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    onClick?: (e: React.MouseEvent) => void,
    card: ImageVerticalCard,
    className?: string,
};

const CardVideoButton: React.FC<Props> = ({
    onClick,
    card,
    className = '',
}) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const { buttonColour, buttonTextColour } = colourTheme;

    const buttonTextCustomStyle = css`
        color: ${buttonTextColour};
        font-size: 19px;

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
        }
    `;

    return (
        <RoundButton
            backgroundColour={buttonColour}
            textColour={buttonTextColour}
            isTransparentButton={false}
            onButtonClick={onClick}
            buttonTextCustomStyle={buttonTextCustomStyle}
            className={className}
        >
            {card.buttonText}
        </RoundButton>
    );
}

export default CardVideoButton;
