import React from "react";
import { css, useTheme } from "@emotion/react";

import IntroHeader from "pages/Components/ListPage/IntroHeader";
import IntroSubheader from "pages/Components/ListPage/IntroSubheader";

type Props = {
    data: any
};

const IntroText:React.FC<Props> = ({ data }) => {
    const theme = useTheme();

    const style = css`
        ${theme.breakpoints.up("5xl")} {
            padding: 90px 150px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 40px 150px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 40px 150px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 30px 100px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 31px 47px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 50px 45px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 45px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 35px 30px;
        }
    `;

    return (
        (data.header || data.subheader) ? (
            <div css={style}>
                <IntroHeader header={data.header} subheader={data.subheader} />
                <IntroSubheader subheader={data.subheader} />
            </div>
        ) : null
    );
};

export default IntroText;