import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    text: string,
};

const ProductText: React.FC<Props> = ({
    text,
}) => {
    const theme = useTheme();

    const style = css`
        text-align: center;
        justify-content: center;
        width: 70%;
        height: 60px;
        font-size: 22px;
        line-height: 30px;
        margin: 5px 0 26px;

        ${theme.breakpoints.up("5xl")} {
            height: 72px;
            font-size: 30px;
            line-height: 36px;
            margin: 12px 0 37px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            height: 68px;
            font-size: 25px;
            line-height: 34px;
            margin: 6px 0 32px;
        }

        ${theme.breakpoints.only("xl")} {
            height: 52px;
            font-size: 19px;
            line-height: 26px;
        }

        ${theme.breakpoints.only("lg")} {
            height: 60px;
            font-size: 22px;
            line-height: 30px;
        }

        ${theme.breakpoints.down("md")} {
            height: 38px;
            font-size: 14px;
            line-height: 19px;
            margin: 3px 0 20px;
        }
    `;

    return (
        <ContentCombine css={style}>{text}</ContentCombine>
    );
};

export default ProductText;
