import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    fontColour?: string,
};

const Text: React.FC<Props> = ({ children, fontColour = '' }) => {
    const style = css`
        ${fontColour && css`
            color: ${fontColour};
        `}
    `;

    return (
        <p css={style}>{children}</p>
    );
};

export default Text;
