import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8'

/**
 * Used for encrypting (namely the config on initial load).
 */
export const SECRET = 'kuZHRN3wOJBpnf2czvGU';

export const encrypt = (message: string|null|undefined) => message ? aes.encrypt(message, SECRET).toString() : '';

export const decrypt = (encrypted: string|null|undefined) => encrypted ? aes.decrypt(encrypted, SECRET).toString(utf8): '';