import React, { useRef, useState, useEffect } from 'react';
import { css, useTheme } from "@emotion/react";
import Tippy from "@tippy.js/react";
import defaultTippyProps from "core/includes/tippy";

type Props = {
    content: string,
    children: React.ReactNode,
}

const TruncateTextWithTippy: React.FC<Props> = ({ content = '', children }) => {
    const theme = useTheme();
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef<any>();
    useEffect(() => {
        setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }, []);

    const tippyStyle = css`
        position: relative;
        padding: 8px;
        background-color: white;
        border-radius: 6px;
        box-shadow: ${theme.borderAndShadow.boxShadow};

        ::after {
            display: block;
            content: '';
            position: absolute;
            top: calc(100% - 3px);
            left: 50%;
            background-color: white;
            height: 17px;
            width: 17px;
            transform: rotate(45deg) translateX(-50%);
            pointer-events: none;
            box-shadow: 2px 2px 2px 0 rgba(178, 178, 178, .4);
        }
    `;

    const textStyle = css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 2;
    `;

    return (
        <Tippy
            content={content}
            enabled={isOverflowed}
            css={tippyStyle}
            {...defaultTippyProps}
        >
            <div
                ref={textElementRef}
                css={textStyle}
            >
                {children}
            </div>
        </Tippy>
    );
};

export default TruncateTextWithTippy;