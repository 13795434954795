import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    gap?: number,
    featureBoxWidth: number,
    otherBoxWidth: number,
    useBeforeTag?: boolean,
    dotMargin?: number,
    isLast?: boolean,
};

const FeatureContentWrapper: React.FC<Props> = ({
    children,
    gap,
    featureBoxWidth,
    otherBoxWidth,
    useBeforeTag = false,
    dotMargin = 0,
    isLast = false,
}) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        display: grid;
        grid-template-columns: ${featureBoxWidth}px ${otherBoxWidth}px ${otherBoxWidth}px;
        place-items: center;
        padding: 5px 0;

        ${gap && css`
            gap: ${gap}px;
        `}

        ${useBeforeTag && !isLast && css`
            :before {
                position: absolute;
                content: '';
                width: calc(100% - ${dotMargin * 2}px);
                height: 100%;
                border-bottom: 1px dashed ${theme.colours.alto2};
                pointer-events: none;
            }
        `}


        ${theme.breakpoints.down('sm')} {
            grid-template-columns: ${featureBoxWidth}px ${otherBoxWidth}px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default FeatureContentWrapper;
