import React from "react";
import Carousel from "nuka-carousel";
import { Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    topBannerHeight: number,
    bannerCount: number,
};

const ImageCarousel: React.FC<Props> = ({ children, topBannerHeight, bannerCount = 1 }) => {
    const theme: Theme = useTheme();

    const style = {
        width: '100vw',
        height: topBannerHeight,
    };

    return (
        bannerCount > 1 ? (
            <Carousel
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                autoplay={false}
                adaptiveHeightAnimation={false}
                style={style}
                defaultControlsConfig={{
                    pagingDotsStyle: {
                        fill: theme.colours.white,
                    }
                }}
            >
                {children}
            </Carousel>
        ) : (
            <div>{children}</div>
        )
    );
}

export default ImageCarousel;
