import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { css, useTheme } from "@emotion/react";
import Carousel from "nuka-carousel";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import SlideContainer from "core/Components/Carousel/SlideContainer";
import SlideWrapper from "core/Components/Carousel/SlideWrapper";
import DoubleQuotes from "core/Components/Icons/DoubleQuotes";
import Subheader from "core/Components/Elements/Subheader";
import TextBox from "core/Components/Elements/TextBox";
import SectionContainer from "core/Components/SectionContainer";

import useBreakpoint from "core/hooks/useBreakpoint";
import { sort } from "core/includes/sort";
import { isUp } from "theme/breakpoints";

import CarouselContainer from "elements/Components/SliderWithImage/components/CarouselContainer";
import ChevronContainer from "elements/Components/SliderWithImage/components/ChevronContainer";
import DoubleQuotesWrapper from "elements/Components/SliderWithImage/components/DoubleQuotesWrapper";
import Header from "elements/Components/FullImage/components/Header";
import Wrapper from "elements/Components/SliderWithImage/components/Wrapper";
import ButtonWrapper from "elements/Components/BannerWithImage/components/ButtonWrapper";
import QuoteCarouselWrapper from "elements/Components/SliderWithQuote/QuoteCarouselWrapper";

type Props = {
    data: SliderWithQuotes,
};

const SliderWithQuote: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const { breakpoint, minWidth } = useBreakpoint();
    let quoteList = sort(data.quoteList);
    const dataCount = quoteList.length ?? 0;
    const quoteRefsRef: any = useRef([]);
    const [slideIndex, setSlideIndex] = useState(0);
    const [autoplayReverse, setAutoplayReverse] = useState(false);
    const [imageHeightFromTextSize, setImageHeightFromTextSize] = useState(0);
    const [quoteRefs, setQuoteRefs] = useState([]);

    const [
        imageWidth,
        imageHeight,
        slideCount,
        chevronSize,
        chevronLeftAndRight,
        quotesHeight,
        carouselCellSpacing,
        bottomPadding
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [343, 275, 3, 40, 52, 46, 32, 65];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [343, 257, 3, 34, 72, 46, 39, 44];
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return [312, 233, 3, 34, 40, 36, 34, 52];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [259, 193, 3, 34, 72, 30, 28, 51];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [556, 221, 1, 28, 45, 35, 33, 77];
            case minWidth >= theme.breakpoints.sizes["sm"]:
                return [447, 207, 1, 24, 0, 40, 28, 80];
            default:
                return [320, 234, 1, 28, 0, 40, 14, 80];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    useEffect(() => {
        if (!isEqual(quoteRefsRef, quoteRefs)) {
            setQuoteRefs(quoteRefsRef.current);
        }
    }, [quoteRefs]);

    useEffect(() => {
        if (!quoteRefs || isEmpty(quoteRefs) || quoteRefs.some(ref => (!ref || false))) {
            return;
        }

        const largestEl = quoteRefs.reduce<any>((a: any, b: any) => (
            a?.children?.[0]?.clientHeight > b.children?.[0]?.clientHeight ? a : b
        ), []);

        const bottomPadding = 30;

        const childrenCombinedHeight = largestEl?.children?.[0]?.clientHeight + largestEl?.children?.[1]?.clientHeight;

        setImageHeightFromTextSize(childrenCombinedHeight + bottomPadding);
    }, [quoteRefs, bottomPadding, minWidth]);

    const removeChevrons = (dataCount <= 3 && isUp(breakpoint, "lg")) || dataCount === 1;

    const chevronContainerStyle = (type: "left" | "right", isDisabled: boolean = false) => css`
        ${isDisabled && css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
        `};
        
        ${theme.breakpoints.only('lg')} {
            ${type === 'left' && css`
                padding-left: 25px;
            `}

            ${type === 'right' && css`
                padding-right: 25px;
            `}
        }
    `;

    const firstButtonLinkURL = data.innerURL || data.outerURL;
    const secondButtonLinkURL = data.secondInnerURL || data.secondOuterURL;

    const imageHeightForCarousel = data.disableGrowToFit ? imageHeight : imageHeightFromTextSize;

    const sliderColours: any = {
        0: theme.colours.pinkSalmon,
        1: theme.colours.pictonBlue3,
        2: theme.colours.sushi,
    }

    const wrapperStyle = css`
        flex-direction: column;
        justify-content: center;
        margin: 0 !important;

        ${theme.breakpoints.up('3xl')} {
            padding: 60px 252px 59px 254px;
            max-width: 1600px;
        }

        ${theme.breakpoints.only('xxl')} {
            padding: 59px 168px 59px 165px;
        }

        ${theme.breakpoints.only('xl')} {
            padding: 59px 137px 59px 138px;
        }

        ${theme.breakpoints.only('lg')} {
            padding: 40px 97px 41px 41px;
        }

        ${theme.breakpoints.only('md')} {
            padding: 32px 0 40px;
        }

        ${theme.breakpoints.down('sm')} {
            padding: 40px 20px;
        }
    `;

    const carouselContainerStyle = css`
        margin-top: -26px;

        .slide {
            ${removeChevrons && css` justify-content: center; `};
        }

        .slider-control-bottomcenter {
            ${theme.breakpoints.only('md')} {
                bottom: -50px;
            }

            ${theme.breakpoints.only('sm')} {
                bottom: -64px;
            }

            ${theme.breakpoints.down('xs')} {
                bottom: -55px;
            }
        }
    `;

    const quoteStyle = (index: number) => {
        const colour = sliderColours[index];

        return css`
            margin-bottom: 0 !important;
            transform: translateY(${quotesHeight / 2}px);

            svg {
                height: ${quotesHeight}px !important;
            }

            g > path:nth-of-type(2) {
                fill: white;
                stroke: ${colour};
            }

            ${theme.breakpoints.up('xxl')} {
                svg {
                    width: 75px !important;
                }

                g > path:nth-of-type(2) {
                    stroke-width: 2;
                }
            }

            ${theme.breakpoints.between('md', 'xl')} {
                g > path:nth-of-type(2) {
                    stroke-width: 2.5;
                }
            }

            ${theme.breakpoints.only('xl')} {
                svg {
                    width: 58px !important;
                }
            }

            ${theme.breakpoints.only('lg')} {
                svg {
                    width: 47px !important;
                }
            }

            ${theme.breakpoints.only('md')} {
                svg {
                    width: 56px !important;
                }
            }

            ${theme.breakpoints.down('sm')} {
                svg {
                    width: 63px !important;
                }

                g > path:nth-of-type(2) {
                    stroke-width: 2;
                }
            }
        `;
    }

    const sliderStyle = (index: number) => {
        const colour = sliderColours[index];

        return css`
            width: ${imageWidth}px;
            height: ${imageHeightForCarousel}px;
            text-align: center;
            border-radius: 12px;
            background-color: ${colour};
        `;
    }

    const slideContainerStyle = css`
        position: relative;
        flex-direction: column;
    `;

    const slideWrapperStyle = css`
        height: 100%;
        width: 100%;
    `;

    const quoteAndTextContainerStyle = css`
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 2%;
        justify-content: space-between;
        color: white;
        font-size: 18px;
        line-height: 24px;

        ${theme.breakpoints.only('xl')} {
            font-size: 16px;
            line-height: 23px;
        }

        ${theme.breakpoints.only('lg')} {
            font-size: 15px;
            line-height: 20px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 16px;
            line-height: 23px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 14px;
            line-height: 19px;
        }
    `;

    const quoteTextStyle = css`
        ${theme.breakpoints.up('3xl')} {
            padding-top: 21%;
            font-size: 20px;
            line-height: 28px;
        }

        ${theme.breakpoints.between('xl', 'xxl')} {
            padding-top: 18%;
        }

        ${theme.breakpoints.only('lg')} {
            padding-top: 17%;
        }

        ${theme.breakpoints.only('md')} {
            padding-top: 12%;
        }

        ${theme.breakpoints.only('sm')} {
            padding-top: 14%;
        }

        ${theme.breakpoints.down('xs')} {
            padding-top: 20%;
        }
    `;

    const quoteAuthorStyle = css`
        width: 100%;
        padding: 0 10px;
        font-weight: ${theme.fonts.weights.light};
        color: white;
        
        ${theme.breakpoints.up('xxl')} {
            min-height: 48px;
            margin-bottom: 5%;
        }
        
        ${theme.breakpoints.only('xl')} {
            min-height: 46px;
            margin-bottom: 4%;
        }

        ${theme.breakpoints.only('lg')} {
            min-height: 40px;
            margin-bottom: 3%;
        }

        ${theme.breakpoints.only('md')} {
            min-height: 48px;
            margin-bottom: 2%;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 3%;
        }
        
        ${theme.breakpoints.only('sm')} {
            min-height: 46px;
        }

        ${theme.breakpoints.down('xs')} {
            min-height: 38px;
        }
    `;

    const buttonContainerStyle = css`
        margin-top: 65px;
        display: flex;
        flex-direction: row;
        gap: 26px;
        
        ${theme.breakpoints.only('xxl')} {
            margin-top: 44px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            margin-top: 51px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 37px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 45px;
        }

        ${theme.breakpoints.down('xs')} {
            width: 100%;
            margin-top: 42px;
        }
    `;

    const textBoxStyle = css`
        text-align: center;
      
        ${theme.breakpoints.up('3xl')} {
            padding: 0 0 36px 0;
        }

        ${theme.breakpoints.only('xxl')} {
            padding: 0 0 38px 0;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            padding: 0 0 32px 0;
        }

        ${theme.breakpoints.only('md')} {
            padding: 0 45px 50px 46px;
        }

        ${theme.breakpoints.only('sm')} {
            padding: 0 70px 19px;
        }

        ${theme.breakpoints.only('xs')} {
            padding: 0 0 20px 0;
        }
    `;

    const buttonWrapperStyle = css`
        ${theme.breakpoints.down('xs')} {
            flex-grow: 1;
        }
    `;

    if (quoteList.some((quote: Quote) => !quote.text)) {
        throw new Error('Quote must have text');
    }

    const lastSlideIndex = isUp(breakpoint, "lg") ? dataCount - 3 : dataCount -1;
    const disableLeftChevron = data.disableCarousel && slideIndex === 0;
    const disableRightChevron = data.disableCarousel && slideIndex === lastSlideIndex;

    return isEmpty(quoteList) ? null : (
        <SectionContainer data={data}>
            <Wrapper css={wrapperStyle}>
                {data.header && (
                    <TextBox css={textBoxStyle}>
                        <Header>{data.header}</Header>
                        {data.subheader && (
                            <Subheader fontSizeType={'15'} lineHeightType={'16'}>{data.subheader}</Subheader>
                        )}
                    </TextBox>
                )}
                <CarouselContainer css={carouselContainerStyle}>
                    <QuoteCarouselWrapper
                        imageWidth={imageWidth}
                        carouselCellSpacing={carouselCellSpacing}
                        numberOfItems={dataCount}
                    >
                        <Carousel
                            slidesToShow={dataCount < slideCount ? dataCount : slideCount}
                            dragging={false}
                            autoplayInterval={6000}
                            speed={1500}
                            renderCenterLeftControls={({ previousSlide }) => (
                                <ChevronContainer
                                    chevronDirection={"left"}
                                    imageHeight={imageHeightForCarousel}
                                    topOffset={quotesHeight}
                                    size={chevronSize}
                                    leftAndRight={chevronLeftAndRight}
                                    breakpointForBottomChevrons={"sm"}
                                    css={chevronContainerStyle("left", disableLeftChevron)}
                                >
                                    <RoundChevronButton
                                        type={"left"}
                                        onClick={previousSlide}
                                        size={chevronSize}
                                    />
                                </ChevronContainer>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <ChevronContainer
                                    chevronDirection={"right"}
                                    imageHeight={imageHeightForCarousel}
                                    topOffset={quotesHeight}
                                    size={chevronSize}
                                    leftAndRight={chevronLeftAndRight}
                                    breakpointForBottomChevrons={"sm"}
                                    css={chevronContainerStyle("right", disableRightChevron)}
                                >
                                    <RoundChevronButton
                                        type={"right"}
                                        onClick={nextSlide}
                                        size={chevronSize}
                                    />
                                </ChevronContainer>
                            )}
                            cellSpacing={carouselCellSpacing}
                            defaultControlsConfig={{
                                pagingDotsStyle: {
                                    fill: "black",
                                }
                            }}
                            afterSlide={(slideIndex: number) => {
                                setSlideIndex(slideIndex);
                                setAutoplayReverse(prevAutoplayReverse => {
                                    if (prevAutoplayReverse) {
                                        return slideIndex !== 0;
                                    } else {
                                        return slideIndex === lastSlideIndex;
                                    }
                                });
                            }}
                            withoutControls={removeChevrons}
                            autoplay={!removeChevrons}
                            autoplayReverse={data.disableCarousel && autoplayReverse}
                            wrapAround={!data.disableCarousel}
                        >
                            {quoteList.map((item: SlideWithQuote, index: number) => (
                                <div css={slideContainerStyle} key={item.id}>
                                    <DoubleQuotesWrapper css={quoteStyle(index % 3)}>
                                        <DoubleQuotes />
                                    </DoubleQuotesWrapper>
                                    <SlideContainer
                                        key={index}
                                        alignItem={"start"}
                                        css={sliderStyle(index % 3)}
                                    >
                                        <SlideWrapper css={slideWrapperStyle}>
                                            <div
                                                css={quoteAndTextContainerStyle}
                                                ref={ref => quoteRefsRef.current[index] = ref}
                                            >
                                                <p css={quoteTextStyle}>
                                                    "&#8288;{item.text}&#8288;"
                                                </p>
                                                {item.author && (
                                                    <h3 css={quoteAuthorStyle}>
                                                        {item.author}
                                                    </h3>
                                                )}
                                            </div>
                                        </SlideWrapper>
                                    </SlideContainer>
                                </div>
                            ))}
                        </Carousel>
                    </QuoteCarouselWrapper>
                </CarouselContainer>
                <div css={buttonContainerStyle}>
                    {!isEmpty(data.buttonText) && (
                        <ButtonWrapper
                            linkURL={firstButtonLinkURL}
                            buttonType={"text"}
                            buttonTextColour={"white"}
                            buttonColour={theme.colours.mySin}
                            css={buttonWrapperStyle}
                            className={data.trackingButtonName}
                        >
                            {data.buttonText}
                        </ButtonWrapper>
                    )}

                    {!isEmpty(data.secondButtonText) && (
                        <ButtonWrapper
                            linkURL={secondButtonLinkURL}
                            buttonType={"text"}
                            buttonTextColour={theme.colours.curiousBlue2}
                            buttonColour={theme.colours.curiousBlue2}
                            css={buttonWrapperStyle}
                            className={data.trackingSecondButtonName}
                            isSecondButton
                        >
                            {data.secondButtonText}
                        </ButtonWrapper>
                    )}
                </div>
            </Wrapper>
        </SectionContainer>
    );
};

export default SliderWithQuote;
