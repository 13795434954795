import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
    card: ImageVerticalCard,
    isRowDirectionListCard?: boolean,
};

const CardTextContainer: React.FC<Props> = ({ card, children, isRowDirectionListCard }) => {
    const theme: Theme = useTheme();

    const textContainerStyle = css`
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 14px 20px 22px;
        
        ${!!card.image && css`
            height: unset;
            min-height: 50%;
            max-height: 50%;
        `};

        ${theme.breakpoints.between("lg", "xl")} {
            padding: 13px 18px 20px;
        }

        ${theme.breakpoints.between("sm", "md")} {
            padding: 17px 15px;
            min-height: 100%;
            max-height: 100%;

            ${isRowDirectionListCard && css`
                width: 100%;
            `};
        }

        ${theme.breakpoints.only("md")} {
            min-width: 50%;
        }

        ${theme.breakpoints.only("sm")} {
            min-width: 55%;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 13px 14px;
        }
        
        ${!card.image && css`
            ${theme.breakpoints.only("md")} {
                padding: 29px 17px;
            }

            ${theme.breakpoints.only("sm")} {
                padding: 17px 12px;
            }

            ${theme.breakpoints.only("xs")} {
                padding: 13px 14px;
            }
        `};
    `;

    return (
        <ContentSection css={textContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default CardTextContainer;
