import React from "react";
import { css } from "@emotion/react";
import theme from "theme/index";

type Props = {
    className?: string,
    colour?: string,
    marginBetweenLines?: number,
};

const DoubleQuotes: React.FC<Props> = ({
    className = "",
    colour = theme.colours.gray2,
    marginBetweenLines = 0,
}) => {
    const doubleQuotesColour = colour ? colour : theme.colours.gray2;

    const style = css`
        display: flex;
        justify-content: center;
        
        ${marginBetweenLines > 0 && css`
            margin-bottom: ${marginBetweenLines}px;
        `};

        > svg {
            width: 45px;
            height: 28px;

            ${theme.breakpoints.up("5xl")} {
                width: 50px;
                height: 42px;
            }
        }
    `;

    return (
        <div css={style} className={className}>
            <svg height="30" viewBox="0 0 50 30" width="50" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="a">
                    <path d="m0 0h50v30h-50z"/>
                </clipPath>
                <g clipPath="url(#a)">
                    <path d="m0 0h50v30h-50z" fill="transparent"/>
                    <path d="m200.645 44.92a10.781 10.781 0 0 0 -7.32-10.126c-.366-3.538 3.416-6.344 6.344-7.564-10.248 0-20.13 6.588-20.13 17.69 0 5.124 5.612 10.492 10.614 10.492a10.571 10.571 0 0 0 10.492-10.492zm-24.034 0a10.732 10.732 0 0 0 -7.442-10.126c-.366-3.538 3.538-6.344 6.466-7.564-10.248 0-20.13 6.588-20.13 17.69 0 5.124 5.49 10.492 10.614 10.492a10.571 10.571 0 0 0 10.492-10.492z" fill={doubleQuotesColour} transform="translate(-154.075 -25.847)"/>
                </g>
            </svg>
        </div>
    );
};

export default DoubleQuotes;
