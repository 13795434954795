import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import { useNavigate } from "react-router";

import BannerContainer from "core/Components/BannerForAdvertisement/BannerContainer";
import BannerContentContainer from "core/Components/BannerForAdvertisement/BannerContentContainer";
import BannerText from "core/Components/BannerForAdvertisement/BannerText";
import BannerTitle from "core/Components/BannerForAdvertisement/BannerTitle";
import RoundButton from "core/Components/Buttons/RoundButton";
import Image from "core/Components/Image";
import useBreakpoint from "core/hooks/useBreakpoint";

import { isUp } from "theme/breakpoints";

type Props = {
    type?: string,
    data?: BannerForAdvertisementType,
    bannerTitle?: string,
    bannerText?: string,
    mainBannerImage?: Image|null,
    useMainBannerFocusPoint?: boolean,
    bannerHeight: number,
    showText?: boolean,
    showButton?: boolean,
    customButtonText?: string,
    useDivForFocusPoint?: boolean,
    customAltText?: string,
};

const BannerForAdvertisement: React.FC<Props> = ({
    type,
    data = null,
    bannerTitle = undefined,
    bannerText = undefined,
    mainBannerImage = null,
    useMainBannerFocusPoint = true,
    bannerHeight,
    showText = true,
    showButton = true,
    customButtonText = null,
    useDivForFocusPoint = true,
    customAltText   = 'true',
}) => {
    const theme = useTheme();
    const navigate: any = useNavigate();
    const { breakpoint } = useBreakpoint();
    const colourTheme = data?.theme ? JSON.parse(data?.theme) : null;

    const [buttonHeight, buttonSidePadding] = useMemo(() => {
        switch (true) {
            case showText:
                return [42, 22];
            case isUp(breakpoint, "3xl"):
                return [54, 31];
            default:
                return [42, 22];
        }
    }, [breakpoint, showText]);

    const navigateToDetailPage = () => {
        if (!data) return;

        navigate(data.linkUrl);
    };

    const buttonStyle = css`
        filter: drop-shadow(${theme.borderAndShadow.dropShadow2});
    `;

    const buttonTextStyle = css`
        ${showText ? css`
            ${theme.breakpoints.up("5xl")} {
                font-size: 25px;
            }
    
            ${theme.breakpoints.between("xxl", "4xl")} {
                font-size: 18px;
            }
    
            ${theme.breakpoints.between("md", "xl")} {
                font-size: 14px;
            }
    
            ${theme.breakpoints.only("sm")} {
                font-size: 15px;
            }
    
            ${theme.breakpoints.down("xs")} {
                font-size: 12px;
            }
        ` : css`
            font-size: 20px;

            ${theme.breakpoints.up("3xl")} {
                font-size: 25px;
            }

            ${theme.breakpoints.down("sm")} {
                font-size: 16px;
            }
        `}
    `;

    const altText = customAltText || data?.altText || '';

    return (
        <BannerContainer minHeight={bannerHeight}>
            {(bannerTitle || bannerText || data?.location || data?.bannerHeader || showButton) && (
                <BannerContentContainer showText={showText}>
                    {(showText && (bannerTitle || data?.location)) && (
                        <BannerTitle>{bannerTitle ?? data?.location}</BannerTitle>
                    )}

                    {(showText && (bannerText || data?.bannerHeader)) && (
                        <BannerText>{bannerText ?? data?.bannerHeader}</BannerText>
                    )}

                    {showButton && (
                        <RoundButton
                            css={buttonStyle}
                            backgroundColour={colourTheme?.colour}
                            textColour={data?.textColour}
                            isTransparentButton={false}
                            onButtonClick={navigateToDetailPage}
                            buttonHeight={buttonHeight}
                            buttonSidePadding={buttonSidePadding}
                            buttonTextCustomStyle={buttonTextStyle}
                        >
                            {customButtonText ?? (type === "event" ? "Book Now" : "Watch Now")}
                        </RoundButton>
                    )}
                </BannerContentContainer>
            )}

            {mainBannerImage && (
                <Image
                    image={mainBannerImage}
                    useFocusPoint={useMainBannerFocusPoint}
                    useDivForFocusPoint={useDivForFocusPoint}
                    customAltText={altText}
                />
            )}
        </BannerContainer>
    );
};

export default BannerForAdvertisement;
