import React from 'react';
import { css, useTheme } from '@emotion/react';

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    title?: string,
    textHeader?: string,
    text?: string,
    titleColour?: string,
    alignment?: "left" | "center" | "right",
    textFontSizeType?: string,
    anchorColour?: string,
};

const TitleAndText: React.FC<Props> = ({
    title,
    textHeader,
    text,
    titleColour = 'white',
    alignment = "left",
    textFontSizeType = "",
    anchorColour = "",
}) => {
    const theme = useTheme();

    const style = css`
        text-align: ${alignment};
        margin-bottom: 53px;
        color: ${titleColour};

        ${theme.breakpoints.up('5xl')} {
            margin-bottom: 62px;
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            margin-bottom: 60px;
        }
        
        ${theme.breakpoints.only('md')} {
            margin-bottom: 50px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-bottom: 40px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-bottom: 36px;
        }
        
        ${!textFontSizeType && css`
            div {
                font-size: 16px;
                line-height: 25px;

                ${theme.breakpoints.up('5xl')} {
                    font-size: 19px;
                    line-height: 30px;
                }

                ${theme.breakpoints.between('xxl', '4xl')} {
                    font-size: 16px;
                    line-height: 28px;
                }

                ${theme.breakpoints.between('md', 'xl')} {
                    font-size: 15px;
                    line-height: 28px;
                }

                ${theme.breakpoints.down('sm')} {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        `}
    `;

    const titleStyle = css`
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 22px;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up('5xl')} {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 16px;
        }
        
        ${theme.breakpoints.down('4xl')} {
            font-size: 25px;
            line-height: 30px;
        }
        
        ${theme.breakpoints.between('lg', '4xl')} {
            margin-bottom: 21px;
        }

        ${theme.breakpoints.only('md')} {
            margin-bottom: 31px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 15px;
        }
    `;

    const textHeaderStyle = css`
        font-size: 25px;
        line-height: 40px;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up('5xl')} {
            margin-bottom: 25px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-bottom: 39.39px;
        }
        
        ${theme.breakpoints.between('xl', 'xxl')} {
            margin-bottom: 20px;
        }
        
        ${theme.breakpoints.only('lg')} {
            margin-bottom: 17px;
        }

        ${theme.breakpoints.only('md')} {
            margin-bottom: 15px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 13px;
        }
    `;

    return (
        <div css={style}>
            {title && (
                <p css={titleStyle}>{title}</p>
            )}

            {textHeader && (
                <p css={textHeaderStyle}>{textHeader}</p>
            )}

            {text && (
                <ContentCombine
                    fontSizeType={textFontSizeType}
                    anchorColour={anchorColour}
                >
                    {text}
                </ContentCombine>
            )}
        </div>
    );
};


export default TitleAndText;