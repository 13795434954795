import { Bin, Item, Packer } from "@owens3364/3d-bin-packing";

/**
 * Get the shipping price.
 * This function doesn't allow to pack over one box (Only one box packaging is allowed).
 * It should change the function and check process by size & weight if it needs to allow over one box packaging.
 *
 * @param items Selected products
 * @param parcelBoxes Standard parcel box info (by CMS > CountryCode > ShippingRate)
 *                    parcel boxes can be undefined before loading
 */
export const getShippingRate = (items: any[], parcelBoxes?: ParcelBox[]) => {
    let totalWeight = 0;

    if (parcelBoxes?.length && items.length) {
        for (const parcelBox of parcelBoxes) {
            const box = new Bin('parcel-box', parcelBox.maxWidth, parcelBox.maxHeight, parcelBox.maxDepth);
            const packer = new Packer();
            packer.addBin(box);

            for (const item of items) {
                for (let i = 0; i < item.quantity; i++) {
                    packer.addItem(new Item(`item-${item.id}-${i}`, item.width, item.height, item.depth));
                    totalWeight += item.weight;
                }
            }

            packer.pack();

            if (!packer.unfitItems.length) {
                return getShippingAmount(parcelBoxes, parcelBox, totalWeight);
            }
        }

        // Has not the fitted box
        return getShippingAmount(parcelBoxes, parcelBoxes[parcelBoxes.length - 1], totalWeight);
    }

    // [Error] It means admin didn't set any standard parcel boxes on CMS.
    return -1;
};

/**
 * Get the price of the fitted box. Moreover, if the weight is over despite finding the fitted box, it finds the
 * closest weight's price for the over weight.
 *
 * @param parcelBoxes Standard parcel box info (by CMS > CountryCode > ShippingRate)
 * @param currentBox The fitted box of selected items
 * @param totalWeight The total weight of selected items
 */
const getShippingAmount = (parcelBoxes: ParcelBox[], currentBox: ParcelBox, totalWeight: number) => {
    let parcelBoxObject;
    let shippingPrice = 0;

    const sameSizeBoxes = parcelBoxes
        .filter((parcelBox: ParcelBox) => (
            parcelBox.maxDepth === currentBox.maxDepth &&
            parcelBox.maxWidth === currentBox.maxWidth &&
            parcelBox.maxHeight === currentBox.maxHeight
        ));

    const allowedSizeBox = sameSizeBoxes.filter(parcelBox => totalWeight > parcelBox.maxWeight);

    if (!allowedSizeBox.length) {
        parcelBoxObject = sameSizeBoxes.reduce((max, current) => (current.maxWeight > max.maxWeight ? current : max));
        shippingPrice += parcelBoxObject.price;

    // Over weight
    } else {
        const allowedBox = sameSizeBoxes.reduce(
            (closest, current) => {
                return Math.abs(current.maxWeight - totalWeight) < Math.abs(closest.maxWeight - totalWeight) ? current : closest
            }
        );

        // Add the shipping price of the packed box size
        shippingPrice += allowedBox.price;

        // Remaining weight after packing
        let overWeight = totalWeight - allowedBox.maxWeight;

        // Over weight: find the price of the closest weight.
        while (overWeight > 0) {
            const cloneOverWeight = overWeight;

            const extraBox = sameSizeBoxes.reduce(
                (closest, current) => {
                    const closestDiff = Math.abs(cloneOverWeight - closest.maxWeight);
                    const currentDiff = Math.abs(cloneOverWeight - current.maxWeight);

                    if (currentDiff < closestDiff) {
                        return current;
                    }

                    // If the weight gap is the same, the price of the light total size has a priority.
                    if (currentDiff === closestDiff) {
                        const currentSizeSum = current.maxDepth + current.maxWidth + current.maxHeight;
                        const closestSizeSum = closest.maxDepth + closest.maxWidth + closest.maxHeight;

                        if (currentSizeSum < closestSizeSum) {
                            return current;
                        }
                    }
                    return closest;
                }
            );

            if (extraBox) {
                shippingPrice += extraBox.price;
                overWeight -= extraBox.maxWeight;
            } else {
                break;
            }
        }
    }

    return shippingPrice;
}