import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    margin?: string,
};

const Button: React.FC<Props> = ({
    children,
    margin,
}) => {
    const style = css`
        display: flex;
        justify-content: center;
        
        ${margin && css`
            margin: ${margin}px;
        `};
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default Button;
