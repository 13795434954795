import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const ButtonGroupContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        margin-top: 51.3px;
        
        ${theme.breakpoints.up('5xl')} {
            margin-top: 40.97px;
        };

        ${theme.breakpoints.between('xxl', '4xl')} {
            margin-top: 51.3px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            margin-top: 53px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 24.15px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 18px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 14.89px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default ButtonGroupContainer;