import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    index: number,
    isLastItem: boolean,
    roundImageSize: number,
    squareImageHeight: number,
};

const MobileViewDescriptionAndYearContainer: React.FC<Props> = ({
    children,
    index,
    isLastItem,
    roundImageSize,
    squareImageHeight,
}) => {
    const theme: Theme = useTheme();
    const isOddOrder = !!((index + 1) % 2);
    const isRoundMainImage = index % 10 < 5 ? (index + 1) % 2 : index % 2;

    const mobileViewDescriptionAndYearContainerStyle = css`
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: ${isRoundMainImage ? `${roundImageSize}px` : `${squareImageHeight}px`};

        // Centre Line for Mobile View
        :before {
            position: absolute;
            content: '';
            border-left: 2px solid ${theme.colours.doveGray2};
            height: 100%;
            width: 50%;

            ${isOddOrder && css`
                border-left: 0;
                border-right: 2px solid ${theme.colours.doveGray2};
            `};

            ${index === 0 && css`
                top: ${roundImageSize / 2 - 2}px;
                
                ${theme.breakpoints.down("xs")} {
                    top: ${roundImageSize / 2 + 20}px;
                }
            `};

            ${isLastItem && css`
                height: ${(roundImageSize / 2) - 2}px;
            `};
        }
    `;

    return (
        <div css={mobileViewDescriptionAndYearContainerStyle}>
            {children}
        </div>
    )
}

export default MobileViewDescriptionAndYearContainer;
