import React from "react";
import { useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const PageHeaderAndDropdownsContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }
    `;

    return (
        <ContentSection justifyContent={"space-between"} height={"unset"} css={style}>
            {children}
        </ContentSection>
    );
};

export default PageHeaderAndDropdownsContainer;
