import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: SingleVideo
};

const Subheader: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const { textColour } = colourTheme;
    const theme: Theme = useTheme();

    const subheaderStyle = css`
        text-align: center;
        margin-top: 0;
        margin-bottom: 23px;
        
        ${textColour && css`
            color: ${textColour};
        `}

        ${theme.breakpoints.between("lg", "xl")} {
            margin-bottom: 36px;
        }

        ${theme.breakpoints.down("md")} {
            margin-bottom: 30px;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"14"} lineHeightType={"15"} css={subheaderStyle}>{data.subheader}</ContentCombine>
    );
};

export default Subheader;
