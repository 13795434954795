import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    textBoxWidth?: number, // Width is the percentage
    flexDirection?: string,
    isShortModuleHeight?: boolean,
};

const TextBoxContainer: React.FC<Props> = ({
    children,
    className,
    textBoxWidth,
    flexDirection,
    isShortModuleHeight = false,
}) => {
    const theme = useTheme();

    const style = css`
        z-index: ${theme.zIndex.zIndexTwo};
        
        // Column(Half) size box style in module: Description (Simple Text & Image)
        ${flexDirection === 'column' ? css`
            width: 100%;
            
            ${theme.breakpoints.up("5xl")} {
                padding: 56px 0;
                width: ${textBoxWidth}%;
            }

            ${theme.breakpoints.between("3xl", "4xl")} {
                padding: 46px 0;
                width: ${textBoxWidth}%;
            }

            ${theme.breakpoints.only("xxl")} {
                padding: 35px 0;
                width: ${textBoxWidth}%;
            }

            ${theme.breakpoints.only("xl")} {
                padding: 33px 0;
                width: ${textBoxWidth}%;
            }

            ${theme.breakpoints.only("lg")} {
                padding: 31px 0;
                width: ${textBoxWidth}%;
            }
        // Whole size box style in module: Description (Simple Text)
        // Design added one more different option
        ` : css`
            padding: 45px 0;
            ${theme.breakpoints.up("3xl")} {
                ${!isShortModuleHeight && css`
                    padding: 151px 0;
                `}
                padding-left: 182px;
                padding-right: 182px;
            }
            
            ${theme.breakpoints.only("xxl")} {
                ${!isShortModuleHeight && css`
                    padding: 133px 0 132px;
                `}
                padding-left: 80px;
                padding-right: 80px;
                
            }

            ${theme.breakpoints.only("xl")} {
                ${!isShortModuleHeight && css`
                    padding: 106px 0;
                `}
                padding-left: 80px;
                padding-right: 80px;
            }

            ${theme.breakpoints.only("lg")} {
                ${!isShortModuleHeight && css`
                    padding: 76px 0 76px;
                `}
                padding-left: 45px;
                padding-right: 45px;
            }
        `};

        ${theme.breakpoints.only("md")} {
            ${!isShortModuleHeight && css`
                padding: 46px 0;
            `}
            padding-left: 45px;
            padding-right: 45px;
        }

        ${theme.breakpoints.only("sm")} {
            ${!isShortModuleHeight && css`
                padding: 146px 0;
            `}
            padding-left: 50px;
            padding-right: 50px;
        }

        ${theme.breakpoints.down("xs")} {
            ${!isShortModuleHeight && css`
                padding: 75px 0;
            `}
            padding-left: 20px;
            padding-right: 20px;
        }
    `;

    return (
        <div css={style} className={className}>{children}</div>
    );
};

export default TextBoxContainer;
