import React from 'react';
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    index: number,
    timelineItem: TimelineItem,
    roundImageSize: number,
    squareImageHeight: number,
};

const TimelineItemContainer: React.FC<Props> = ({
    children,
    index,
    timelineItem,
    roundImageSize,
    squareImageHeight,
}) => {
    const { theme: coloursTheme, isAvatarRotate } = timelineItem;
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(coloursTheme);
    const { colour, hoveredColour } = colourTheme;
    const isOddOrder = (index + 1) % 2;
    const isRoundMainImage = index % 10 < 5 ? (index + 1) % 2 : index % 2;

    const style = css`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 468px;
        justify-content: center;

        .timeline-number-state-hover,
        .timeline-main-image-state-hover,
        .timeline-avatar-state-hover {
            transition: all 0.3s ease-in-out;
        }

        ${theme.breakpoints.only("md")} {
            width: 100%;
            min-height: ${isRoundMainImage ? `${roundImageSize}px` : `${squareImageHeight}px`};
            height: 100%;
        }

        ${theme.breakpoints.down("sm")} {
            width: 100%;
            min-height: ${isRoundMainImage ? `${roundImageSize}px` : `${squareImageHeight}px`};
            height: 100%;
        }
        
        &:hover {
            .timeline-number-state-hover {
                color: ${hoveredColour};
            }

            .timeline-main-image-state-hover {
                transform: scale(1.01) ${!isOddOrder ? 'translateY(-10px)' : 'translateY(10px)'};

                ${theme.breakpoints.only("md")} {
                    transform: scale(1.1);
                }

                ${theme.breakpoints.down("sm")} {
                    transform: scale(1.1);
                }
            }

            .timeline-circle-state-hover {
                transition: all 0.3s ease-in-out;
                background: transparent radial-gradient(
                    closest-side at 50% 50%,
                    ${colour} 0%,
                    ${hoveredColour} 100%
                ) 0% 0% no-repeat padding-box;
            }

            .timeline-avatar-state-hover {
                ${isAvatarRotate ?
                        css`
                            transform: scale(1.14) rotate(8deg) ${!isOddOrder ? 'translateY(-20px)' : 'translateY(20px)'};
                            right: ${index ? 'calc(0px - 130px / 3)' : 'calc(50% - 130px / 6)'};
                        ` :
                        css`
                            transform: scale(1.14) ${!isOddOrder ? 'translateY(-12px)' : 'translateY(12px)'};
                        `
                };

                ${theme.breakpoints.only("md")} {
                    transform: scale(1.1);
                }

                ${theme.breakpoints.down("sm")} {
                    transform: scale(1.1);
                }
            }
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default TimelineItemContainer;
