import React from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    headerColour: string,
};

const Title: React.FC<Props> = ({
    children,
    headerColour,
}) => {
    const theme = useTheme();
    const Tag = "h1";
    const fontSizeType = "2"
    const [fontSize, lineHeight] = useFontSize(Tag, fontSizeType);

    const style = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${headerColour};
        margin-bottom: 33px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 19px;
            line-height: 46px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 25px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 20px;
            line-height: 36px;
        }
    `;

    return (
        <Tag css={style}>{children}</Tag>
    );
}

export default Title;
