import React, { ReactNode } from "react";
import { css } from "@emotion/react";

type Props = {
    children: ReactNode,
}

const BurgerNavSmallCartWrapper: React.FC<Props> = ({ children }) => {
    const style = css`
        display: flex;
        justify-content: end;
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default BurgerNavSmallCartWrapper;