import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const VideoContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const videoSectionStyle = css`
        position: relative;
        background: black;
        overflow: hidden;
        width: 882px;
        height: 496px;
        border-radius: 22px;

        ${theme.breakpoints.only("xl")} {
            width: 734px;
            height: 413px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 848px;
            height: 477px;
        }

        ${theme.breakpoints.only("md")} {
            width: 678px;
            height: 381px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 478px;
            height: 269px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 322px;
            height: 181px;
        }
    `;

    return (
        <ContentSection css={videoSectionStyle} className={"radius-box"}>
            {children}
        </ContentSection>
    );
}

export default VideoContainer;
