import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import MainHeading from "core/Components/Item/MainHeading";
import Form from "elements/Components/HubspotForm/components/Form";
import Wrapper from "core/Components/Wrapper";
import SectionContainer from "core/Components/SectionContainer";

type Props = {
    data: HubspotForm
}

const HubspotForm: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        width: 100%;
        padding: 61px 182px 72px;

        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
            min-height: 812px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 58px 80px 68px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 35px 88px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 30px 45px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 49px 45px 42px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 45px 50px 43px 49px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 59px 20px 57px 19px;
        }
    `;

    return (
        <SectionContainer>
            <Wrapper css={wrapperStyle}>
                <MainHeading text={data?.header} textColour={theme.colours.curiousBlue} />
                <Form hubspotFormId={data?.hubspotFormId} />
            </Wrapper>
        </SectionContainer>
    );
}

export default HubspotForm;