import React, { useEffect, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/react";

import { DROPDOWN_OPTION_BACKGROUND_COLOUR, DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR } from "core/constants";

import ColouredContainer from "core/Components/Buttons/DropdownButton/components/ColouredContainer";
import DropdownButtonContainer from "core/Components/Buttons/DropdownButton/components/DropdownButtonContainer";
import DropdownButtonPlaceholder from "core/Components/Buttons/DropdownButton/components/DropdownButtonPlaceholder";
import DropdownButtonPlaceholderContainer from "core/Components/Buttons/DropdownButton/components/DropdownButtonPlaceholderContainer";
import DropdownButtonOptionsContainer from "core/Components/Buttons/DropdownButton/components/DropdownButtonOptionsContainer";
import DropdownButtonOptionContainer from "core/Components/Buttons/DropdownButton/components/DropdownButtonOptionContainer";
import DropdownButtonOptionLabel from "core/Components/Buttons/DropdownButton/components/DropdownButtonOptionLabel";
import DropdownButtonOptionCheckBox from "core/Components/Buttons/DropdownButton/components/DropdownButtonOptionCheckBox";
import DropdownButtonArrow from "core/Components/Buttons/DropdownButton/components/DropdownButtonArrow";
import DropdownButtonScroll from "core/Components/Buttons/DropdownButton/components/DropdownButtonScroll";

type Props = {
    className?: string,
    options: Option[],
    isMultiple?: boolean,
    onChange?: (item: Option) => void,
    onChangeMultiple?: (items: Option[]) => void,
    selectedValue: Option[],
    customOptionStyle?: SerializedStyles,
    customOptionContainerStyle?: SerializedStyles,
    isLargeLabel?: boolean,
    defaultName?: string,
    backgroundColour?: string,
    optionBackgroundColour?: string,
    optionBackgroundHoveredColour?: string,
    useInnerScroll?: boolean,
    zIndex?: number,
};

const DropdownButton: React.FC<Props> = ({
    className,
    options,
    isMultiple = false,
    onChange,
    onChangeMultiple,
    selectedValue,
    customOptionStyle,
    customOptionContainerStyle,
    isLargeLabel = false,
    defaultName,
    backgroundColour,
    optionBackgroundColour = DROPDOWN_OPTION_BACKGROUND_COLOUR,
    optionBackgroundHoveredColour = DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR,
    useInnerScroll = true,
    zIndex = 100,
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [multipleValue, setMultipleValue] = useState<MultipleOptions>();
    const inputRef = useRef<any>();
    const dropdownRef = useRef<any>();

    useEffect(() => {
        const handler = (e: any) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener("click", handler);

        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (isMultiple) {
            const optionValues: MultipleOptions = {};
            const selectedAll = !!selectedValue.find((item) => item.value === "");

            if (selectedAll) {
                options.map((option) => {
                    return optionValues[option.value] = true;
                });
            } else {
                options.map((option) => {
                    const selected = selectedValue.find((item) => item.value === option.value);
                    return optionValues[option.value] = !!selected;
                });
            }

            setMultipleValue(optionValues);
        }
    }, [isMultiple, options, setMultipleValue, selectedValue]);

    useEffect(() => {
        if (multipleValue) {
            let selected = false;
            for (const [, value] of Object.entries(multipleValue)) {
                if (value) selected = true;
            }

            // In case nothing selected, select all
            if (!selected) {
                const optionValues: MultipleOptions = {};
                options.map((option) => {
                    return optionValues[option.value] = true;
                });
                setMultipleValue(optionValues);
            }
        }
    }, [multipleValue, options]);

    const handleInputClick = () => {
        setShowMenu(!showMenu);
    };

    return (
        <ColouredContainer>
            <DropdownButtonScroll dropdownRef={dropdownRef} options={options} showMenu={showMenu} />
            <DropdownButtonContainer className={className} dropdownRef={dropdownRef}>
                <DropdownButtonPlaceholderContainer
                    optionBackgroundColour={optionBackgroundColour}
                    backgroundColour={backgroundColour}
                    handleInputClick={handleInputClick}
                    inputRef={inputRef}
                    showMenu={showMenu}
                    zIndex={zIndex}
                >
                    <DropdownButtonPlaceholder
                        selectedValue={selectedValue}
                        options={options}
                        isLargeLabel={isLargeLabel}
                        defaultName={defaultName}
                    />
                    <DropdownButtonArrow showMenu={showMenu} optionBackgroundColour={optionBackgroundColour} />
                </DropdownButtonPlaceholderContainer>

                <DropdownButtonOptionsContainer
                    customOptionContainerStyle={customOptionContainerStyle}
                    optionBackgroundColour={optionBackgroundColour}
                    zIndex={zIndex - 1}
                    useInnerScroll={useInnerScroll}
                    optionCount={options.length}
                    showMenu={showMenu}
                >
                    {options.map((option: Option, index: number) => (
                        <DropdownButtonOptionContainer
                            key={`${option.value}-${index}`}
                            index={index}
                            options={options}
                            option={option}
                            multipleValue={multipleValue}
                            setMultipleValue={setMultipleValue}
                            isMultiple={isMultiple}
                            onChangeMultiple={onChangeMultiple}
                            onChange={onChange}
                            customOptionStyle={customOptionStyle}
                            optionBackgroundHoveredColour={optionBackgroundHoveredColour}
                        >
                            <DropdownButtonOptionLabel option={option}/>

                            {isMultiple && multipleValue && (
                                <DropdownButtonOptionCheckBox
                                    option={option}
                                    multipleValue={multipleValue}
                                />
                            )}
                        </DropdownButtonOptionContainer>
                    ))}
                </DropdownButtonOptionsContainer>
            </DropdownButtonContainer>
        </ColouredContainer>
    );
}

export default DropdownButton;
