import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    flexDirection?: string,
    justifyContent?: string,
    alignItems?: string,
    useMaxWidth?: boolean,
    useMinHeight?: boolean,
    className?: string,
};

const Wrapper: React.FC<Props> = ({
    children,
    flexDirection,
    justifyContent = 'center',
    alignItems = 'center',
    useMaxWidth = true,
    useMinHeight = true,
    className,
}) => {
    const theme = useTheme();

    // menuHeight should be re-considered according to how menu development goes later.
    const menuHeight = theme.sizes.menu.heightMain;
    const defaultDecreaseHeight = 30;
    const totalDecreaseHeight = menuHeight + defaultDecreaseHeight;

    const style = css`
        display: flex;
        justify-content: ${justifyContent};
        align-items: ${alignItems};
        width: 100%;
        
        ${flexDirection && css`
            flex-direction: ${flexDirection};
        `};
        
        ${useMaxWidth && css`
            ${theme.breakpoints.up("5xl")} {
                max-width: ${theme.breakpoints.sizes['5xl']}px;
            }

            ${theme.breakpoints.between("3xl", "4xl")} {
                max-width: ${theme.breakpoints.sizes["3xl"]}px;
            }
            
            ${theme.breakpoints.only("xxl")} {
                max-width: ${theme.breakpoints.sizes.xxl}px;
            }
    
            ${theme.breakpoints.only("xl")} {
                max-width: ${theme.breakpoints.sizes.xl}px;
            }

            ${theme.breakpoints.only("lg")} {
                max-width: ${theme.breakpoints.sizes.lg}px;
            }
    
            ${theme.breakpoints.only("md")} {
                max-width: ${theme.breakpoints.sizes.md}px;
            }

            ${theme.breakpoints.only("sm")} {
                max-width: ${theme.breakpoints.sizes.sm}px;
            }

            ${theme.breakpoints.down("xs")} {
                max-width: ${theme.breakpoints.sizes.xs}px;
            }
        `};

        ${useMinHeight && css`
            ${theme.breakpoints.up("5xl")} {
                min-height: calc(1240px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.between("xxl", "4xl")} {
                min-height: calc(900px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.only("xl")} {
                min-height: calc(720px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.only("lg")} {
                min-height: calc(768px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.only("md")} {
                min-height: calc(1024px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.only("sm")} {
                min-height: calc(840px - ${totalDecreaseHeight}px);
            }

            ${theme.breakpoints.down("xs")} {
                min-height: calc(720px - ${totalDecreaseHeight}px);
            }
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default Wrapper;
