import React from "react";
import {css, useTheme} from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    fontSizeType?: string,
    lineHeightType?: string,
    colour?: string,
    isBold?: boolean,
    isJustifyContentCenter?: boolean,
};

const ContentTextWrapper: React.FC<Props> = ({
    children,
    colour,
    isBold = false,
    isJustifyContentCenter = false,
    fontSizeType = '6',
    lineHeightType = '',
}) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        text-align: center;

        ${isJustifyContentCenter && css`
            justify-content: center;
        `}

        ${isBold && css`
            font-weight: ${theme.fonts.weights.bold};
        `}
        
        ${colour && css`
            color: ${colour};
        `}
    `;

    return (
        <ContentCombine
            Tag={"p"}
            fontSizeType={fontSizeType}
            lineHeightType={lineHeightType}
            css={style}
        >
            {children}
        </ContentCombine>
    );
};

export default ContentTextWrapper;
