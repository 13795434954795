import React from "react";
import { css, useTheme, } from "@emotion/react";

type Props = {
    option: Option,
};

const DropdownButtonOptionLabel: React.FC<Props> = ({ option, }) => {
    const theme = useTheme();

    const optionLabelStyle = css`
        font-size: 15px;
        line-height: 17px;
        white-space: break-spaces;

        ${theme.breakpoints.down("lg")} {
            font-size: 12px;
            line-height: 15px;
        }
    `;

    return (
        <span css={optionLabelStyle}>
            {option.label}
        </span>
    );
}

export default DropdownButtonOptionLabel;
