import React, { ElementType } from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    text: string,
    textColour?: string,
    Tag?: ElementType,
};

const MainHeading: React.FC<Props> = ({ text, textColour = 'white', Tag = 'h1' }) => {
    const theme = useTheme();

    const style = css`
        font-size: 40px;
        line-height: 45px;
        font-weight: ${theme.fonts.weights.bold};
        margin-top: 39.36px;
        color: ${textColour};
        
        ${theme.breakpoints.up('5xl')} {
            font-size: 50px;
            line-height: 55px;
            margin-top: 19px;
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            font-size: 40px;
            line-height: 45px;
            margin-top: 39.36px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            font-size: 40px;
            line-height: 45px;
            margin-top: 34px;
        }

        ${theme.breakpoints.only('md')} {
            font-size: 40px;
            line-height: 40px;
            margin-top: 16.07px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 25px;
            line-height: 30px;
            margin-top: 23px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 25px;
            line-height: 30px;
            margin-top: 16px;
        }
    `;

    return (
        <Tag css={style}>
            {text}
        </Tag>
    );
};

export default MainHeading;