import React from 'react';
import { css } from "@emotion/react";

type Props = {
    index: number,
    children: React.ReactNode,
};

const MobileViewContainer: React.FC<Props> = ({
    index,
    children,
}) => {
    const isOddOrder = (index + 1) % 2;

    const mobileViewContainerStyle = css`
        display: flex;
        height: 100%;

        ${isOddOrder && css`
            flex-direction: row-reverse;
        `};
    `;

    return (
        <div css={mobileViewContainerStyle}>
            {children}
        </div>
    )
}

export default MobileViewContainer;
