import React from 'react';
import { useNavigate } from "react-router-dom";

import FooterBottomSection from "core/Components/Footer/FooterBottomSection";
import FooterContainer from "core/Components/Footer/FooterContainer";
import FooterCopyright from "core/Components/Footer/FooterCopyright";
import FooterDivideLine from "core/Components/Footer/FooterDivideLine";
import FooterMainSection from "core/Components/Footer/FooterMainSection";
import FooterRow from "core/Components/Footer/FooterRow";
import FooterTopLeftSection from "core/Components/Footer/FooterTopLeftSection";
import FooterTopRightSection from "core/Components/Footer/FooterTopRightSection";

import SectionContainer from "core/Components/SectionContainer";
import useBreakpoint from "core/hooks/useBreakpoint";

import { isUp, isDown } from "theme/breakpoints";

type Props = {
    footerMenu?: { menu: [any], side: [any], bottom: [any] },
};

const Footer: React.FC<Props> = ({ footerMenu }) => {
    const navigate = useNavigate();
    const { breakpoint } = useBreakpoint();

    // Logo link to home
    const onMoveToHome = () => {
        navigate('/');
    };

    return (
        <SectionContainer overflowHidden>
            <FooterContainer>
                <FooterDivideLine position={"top"} />

                {isDown(breakpoint, "md") && (
                    <FooterRow>
                        <FooterTopLeftSection footerTopMenu={footerMenu?.side} onMoveToHome={onMoveToHome} />
                    </FooterRow>
                )}

                {/* Header menu section */}
                <FooterRow removePaddingForVerticalMenu>
                    <FooterMainSection footerMenu={footerMenu?.menu} />
                </FooterRow>

                {isDown(breakpoint, "md") && (
                    <FooterRow>
                        <FooterBottomSection footerBottomMenu={footerMenu?.bottom} />
                    </FooterRow>
                )}

                {/* Logo, links and social icons */}
                <FooterRow>
                    {isUp(breakpoint, "lg") && (
                        <FooterTopLeftSection footerTopMenu={footerMenu?.side} onMoveToHome={onMoveToHome} />
                    )}
                    <FooterTopRightSection />
                </FooterRow>

                <FooterDivideLine position={"bottom"} />

                {/* Bottom links */}
                {isUp(breakpoint, "lg") && (
                    <FooterRow>
                        <FooterBottomSection footerBottomMenu={footerMenu?.bottom} />
                    </FooterRow>
                )}

                {/* Copyright */}
                <FooterRow>
                    <FooterCopyright />
                </FooterRow>
            </FooterContainer>
        </SectionContainer>
    );
};

export default Footer;
