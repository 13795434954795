import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    milestoneYear: string,
    colour: string,
    isOddOrder: boolean,
    isLargeTitle: boolean,
    title?: string,
};

const MobileViewYear: React.FC<Props> = ({
    milestoneYear,
    colour,
    isOddOrder,
    isLargeTitle,
    title = "",
}) => {
    const theme: Theme = useTheme();

    const style = css`
        height: 50%;
        color: ${colour};
        padding: 24px 24px 0;
        display: flex;
        align-items: flex-end;
        font-weight: ${theme.fonts.weights.bold};
        
        ${isOddOrder && css`
            justify-content: flex-end;
        `};

        ${isLargeTitle
            ? css`font-size: 45px; line-height: 50px;`
            : css`font-size: 30px; line-height: 35px; padding: 24px 24px 5px;`
        }
    `;

    return (
        <p css={style} className={"timeline-number-state-hover"}>
            {title ? title : milestoneYear}
        </p>
    );
}

export default MobileViewYear;
