const slide = {
    buttonBottom: 70,
    chevronSize: {
        sm: 20,
        md: 30,
        lg: 40,
    },
    defaultSliderHeight: 400,
};

const menu = {
    heightMain: 58,
    heightMainUnderLine: 8,
    heightSub: 315,
    paddingLeftOftSub: 430,
    paddingRightOfSub: 300,
    burgerHeightMain: 55,
    breadcrumbHeight: 27,
    breadcrumbBurgerHeight: 35,
    breadcrumbBurgerSmallHeight: 26,
    sideMargin: 44,
}

const v = {
    slide,
    menu,
};

export default v;
