import React from "react";
import { css, useTheme } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    image: Image,
    useFocusPoint: boolean,
};

const ImageContainer: React.FC<Props> = ({
    image,
    useFocusPoint,
}) => {
    const theme = useTheme();

    const style = css`
        width: 580px;
        height: 508px;
        object-fit: cover;
        box-sizing: content-box;
        margin-bottom: 37px;

        ${theme.breakpoints.up("xl")} {
            width: 580px;
            height: 508px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 482px;
            height: 422px;
        }

        ${theme.breakpoints.only("md")} {
            width: 100%;
        }

        ${theme.breakpoints.down("sm")} {
            width: 100%;
            height: 60vw;
        }
    `;

    return (
        <div css={style}>
            <Image
                image={image}
                useFocusPoint={useFocusPoint}
            />
        </div>
    );
}

export default ImageContainer;
