import React from "react";
import { css, useTheme } from "@emotion/react";

import FooterSocialBox from "core/Components/Footer/FooterSocialBox";

const FooterTopRightSection: React.FC = () => {
    const theme = useTheme();

    const style = css`
        display: flex;
        align-self: end;


        ${theme.breakpoints.only("md")} {
            margin-top: 23px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-top: 21px;
            align-self: start;
        }
        
        ${theme.breakpoints.down("md")} {
            width: 100%;
            justify-content: end;
        }
    `;

    return (
        <div css={style}>
            <FooterSocialBox />
        </div>
    );
};

export default FooterTopRightSection;
