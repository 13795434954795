import React, { forwardRef } from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    type?: string,
    colour?: string,
    className?: string,
};

const ItemTitle = forwardRef<HTMLDivElement, Props>(({
    children,
    type,
    colour,
    className,
}, forwardRef) => {
    const theme = useTheme();
    const defaultColour = theme.colours.dustyGray;

    const style = css`
        flex-shrink: 0;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: ${theme.fonts.weights.bold};
        color: ${colour ?? defaultColour};

        ${theme.breakpoints.up("5xl")} {
            font-size: 23px;
            line-height: 28px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 20px;
            line-height: 24px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 17px;
            line-height: 20px;
        }

        ${theme.breakpoints.down('lg')} {
            font-size: 14px;
            line-height: 16px;
        }
    `;

    return (
        <div css={style} className={'title'} ref={forwardRef}>
            {children}
        </div>
    );
});

export default ItemTitle;