import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    index: number,
    colour: string,
    circleSize: number,
    title?: string,
    useNumbering: boolean,
};

const Number: React.FC<Props> = ({
    index,
    colour,
    circleSize,
    title = "",
    useNumbering = false,
}) => {
    const theme: Theme = useTheme();

    const isOddOrder = (index + 1) % 2;
    const number = (index + 1).toString().padStart(2, '0');

    const style = css`
        position: absolute;
        right: 10px;
        top: 5px;
        height: 100%;
        font-size: 45px;
        line-height: 50px;
        color: ${colour};
        font-weight: ${theme.fonts.weights.bold};

        transform: translateY(${isOddOrder ? '0' : `${circleSize / 2 + 'px'}`});

        ${theme.breakpoints.up("5xl")} {
            font-size: 65px;
            line-height: 72px;
        }
        
        ${theme.breakpoints.between("lg", "xl")} {
            top: 8px;
            font-size: 30px;
            line-height: 34px;
        }
    `;

    return (
        <p css={style} className={'timeline-number-state-hover'}>
            {useNumbering ? number : title}
        </p>
    );
}

export default Number;
