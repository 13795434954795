import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    dataCount?: number,
    chevronType: "left" | "right",
    isSide?: boolean,
    slideImageHeight?: number,
    className?: string,
};

const ChevronContainer: React.FC<Props> = ({
    children,
    dataCount = 0,
    chevronType,
    isSide = true,
    slideImageHeight = 0,
    className,
}) => {
    const style = css`
        position: absolute;
        
        ${isSide && css`
            ${chevronType === "left"
                ? css`left: -72px; top: ${slideImageHeight / 2}px`
                : css`right: -72px; top: ${slideImageHeight / 2}px`
            };
        `};

        ${!isSide && css`
            bottom: 3px;

            ${chevronType === "left"
                ? css`left: calc(50% - (18px * ${dataCount} / 2) - 50px);`
                : css`right: calc(50% - (18px * ${dataCount} / 2) - 50px);`
            };
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default ChevronContainer;
