import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    data: any,
    onClickVideoHandler?: () => void,
    customFontWeight?: string|number,
    borderColour?: string,
    textColour?: string,
};

const VideoButton: React.FC<Props> = ({
    data,
    onClickVideoHandler,
    customFontWeight,
    borderColour,
    textColour,
}) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { buttonColour, buttonTextColour } = colourTheme;
    const isLeftButton = !!data.buttonText;

    const buttonStyle = css`
        display: flex;
        align-items: center;
        height: 42px;
        background-color: ${buttonColour || theme.colours.cerulean};
        border-radius: 26px;
        box-shadow: ${theme.borderAndShadow.boxShadow5};
        cursor: pointer;
        border: 0;
        transition: filter 0.3s ease-in-out;
        
        ${theme.breakpoints.up("5xl")} {
            height: 54px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 38px;
        }
        
        ${isLeftButton && css`
            background-color: unset;
            border: 2px solid ${buttonColour};
        `};
        
        ${borderColour && css`
            border: 2px solid ${borderColour};
        `};
        
        &:hover {
            filter: brightness(90%);
        }
    `;

    const textStyle = css`
        font-size: 20px;
        font-weight: ${customFontWeight ? customFontWeight : theme.fonts.weights.bold};
        margin-inline: 20px;
        color: ${isLeftButton ? buttonColour : buttonTextColour};
        
        ${textColour && css`
            color: ${textColour};
        `};

        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 15px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 14px;
        }
    `;

    if (!data.videoPath || !data.videoButtonText) return null;

    return (
        <button onClick={onClickVideoHandler} css={buttonStyle}>
            <span css={textStyle}>
                {data.videoButtonText}
            </span>
        </button>
    );
}

export default VideoButton;
