import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ButtonContainer: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        justify-content: center;
        align-self: flex-start;
        width: fit-content;
        height: fit-content;
        gap: 13px;
        
        ${theme.breakpoints.up("5xl")} {
            gap: 17px;
        }
      
        ${theme.breakpoints.down("sm")} {
            gap: 18px;
            flex-direction: column;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default ButtonContainer;
