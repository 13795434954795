import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: Timeline
};

const Header: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const headerStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
        margin-bottom: 28px;

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 31px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 28px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 24px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 10px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 21px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 10px;
        }
    `;

    return (
        <Title Tag={"h2"} css={headerStyle}>
            {data.header}
        </Title>
    );
}

export default Header;
