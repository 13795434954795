import React, { memo } from "react";
import { css, SerializedStyles, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    customCss?: SerializedStyles,
    customButtonCss?: SerializedStyles,
    justifyContent?: string,
    buttonRef?: any,
};

const FormActions: React.FC<Props> = ({
    children,
    customCss,
    customButtonCss,
    justifyContent,
    buttonRef,
}) => {
    const theme:Theme = useTheme();

    const styles = (customCss?: SerializedStyles, customButtonCss?: SerializedStyles) => css`
        margin-top: ${theme.forms.spacing.margin};
        display: flex;
        justify-content: right;
    
        button {
            margin-left: ${theme.forms.spacing.margin};
            &:first-of-type {
                margin-left: 0;
            }
    
            ${!!customButtonCss && customButtonCss}
        }
        
        ${justifyContent && css`
            justify-content: ${justifyContent};
        `};
    
        ${!!customCss && customCss}
    `;

    return (
        <div css={styles(customCss, customButtonCss)} ref={buttonRef}>{children}</div>
    );
};

export default memo(FormActions);
