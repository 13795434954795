import React from 'react';
import { Theme, ThemeProvider } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const GalleryThemeProvider: React.FC<Props> = ({ children }) => {
    const newTheme = (parentTheme: Theme) => ({
        ...parentTheme,
        borderAndShadow: {
            ...parentTheme.borderAndShadow,
            smallRadius: '6',
            largeRadius: '12',
        }
    });

    return (
        <ThemeProvider theme={newTheme}>
            { children }
        </ThemeProvider>
    );
};

export default GalleryThemeProvider;