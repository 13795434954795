import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children?: React.ReactNode,
    index: number,
};

const ProductContainer: React.FC<Props> = ({ index, children }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        min-height: 442px;
        padding: 20px 0;
        
        ${theme.breakpoints.up("5xl")} {
            min-height: 608px;
            padding: 30px 0;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            min-height: 500px;
        }

        ${theme.breakpoints.only("xl")} {
            min-height: 390px;
        }

        ${theme.breakpoints.only("lg")} {
            min-height: 440px;
        }

        ${theme.breakpoints.only("md")} {
            min-height: 326px;
            padding: 15px 0;
        }

        ${theme.breakpoints.only("sm")} {
            min-height: 416px;
            padding: 10px 0;
        }

        ${theme.breakpoints.down("xs")} {
            min-height: 347px;
            padding: 10px 0;
        }

        // 4 columns Layouts
        ${theme.breakpoints.up("xl")} {
            border-right: 1px solid ${theme.colours.silver3};
            
            ${index % 4 === 3 && css`
                border-right: 0;
            `};

            ${index > 3 && css`
                border-top: 1px solid ${theme.colours.silver3};
            `};
        }

        // 3 columns Layouts
        ${theme.breakpoints.between("md","lg")} {
            border-right: 1px solid ${theme.colours.silver3};

            ${index % 3 === 2 && css`
                border-right: 0;
            `};

            ${index > 2 && css`
                border-top: 1px solid ${theme.colours.silver3};
            `};
        }

        // 1 column Layouts
        ${theme.breakpoints.down("sm")} {
            ${index > 0 && css`
                border-top: 1px solid ${theme.colours.silver3};
            `};
        }
    `;

    return (
        <ContentSection css={style}>
            {children}
        </ContentSection>
    );
}

export default ProductContainer;
