import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    dropdownRef: any,
};

const DropdownButtonContainer: React.FC<Props> = ({
    className,
    children,
    dropdownRef
}) => {
    const theme = useTheme();

    const dropdownContainer = css`
        position: relative;
        border-radius: 8px;
        min-width: 177px;

        ${theme.breakpoints.up("5xl")} {
            min-width: 245px;
        }

        ${theme.breakpoints.only("xl")} {
            min-width: 157px;
        }

        ${theme.breakpoints.only("lg")} {
            min-width: 136px;
        }

        ${theme.breakpoints.only("md")} {
            min-width: 126px;
        }

        ${theme.breakpoints.only("sm")} {
            min-width: 204px;
        }

        ${theme.breakpoints.down("xs")} {
            min-width: 154px;
        }
    `;

    return (
        <div css={dropdownContainer} className={className} ref={dropdownRef}>
            {children}
        </div>
    );
}

export default DropdownButtonContainer;
