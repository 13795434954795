import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode
};

const OrderInfoText: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        margin: 13px 0;

        font-size: 14px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 12px;
        }
    `;

    return (
        <ContentCombine css={style}>{children}</ContentCombine>
    );
};

export default OrderInfoText;
