import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    price: number | string,
    subLabel?: string,
    textColour?: string,
    className?: string,
};

const Price: React.FC<Props> = ({ price, subLabel = null, textColour = 'white', className = '' }) => {
    const theme = useTheme();
    const productPrice = typeof price === "string" ? price : `$${price?.toFixed(2) || 0.00}`;

    const style = css`
        margin-top: 22px;
        font-weight: ${theme.fonts.weights.light};
        font-size: 50px;
        line-height: 69px;
        color: ${textColour};
        
        ${theme.breakpoints.up('xxl')} {
            font-size: 50px;
            line-height: 69px;
            margin-top: 22px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            font-size: 30px;
            line-height: 40px;
            margin-top: 55.49px;
        }

        ${theme.breakpoints.only('md')} {
            font-size: 30px;
            line-height: 40px;
            margin-top: 27.95px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 25px;
            line-height: 30px;
            margin-top: 23px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 25px;
            line-height: 30px;
            margin-top: 19px;
        }
    `;

    return (
        <div css={style} className={className}>
            <div>{productPrice}</div>
            {subLabel && (
                <ContentCombine fontSizeType={'9'}>
                    {subLabel}
                </ContentCombine>
            )}
        </div>
    );
};

export default Price;