import React from 'react';
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const MobileViewMainImageContainer: React.FC<Props> = ({
    children,
}) => {
    const mobileViewMainImageContainerStyle = css`
        width: 50%;
        align-items: center;
        display: flex;
        position: relative;
    `;

    return (
        <div css={mobileViewMainImageContainerStyle}>
            {children}
        </div>
    )
}

export default MobileViewMainImageContainer;
