import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const ContentContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const containerStyle = css`
        width: 100%;

        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
        }
    `;

    return (
        <ContentSection css={containerStyle}>
            {children}
        </ContentSection>
    );
}

export default ContentContainer;
