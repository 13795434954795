import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";

import Cart from "core/Components/Icons/Cart";

type Props = {
    to: string,
    closeBurgerMenu: ({ forcedClose }: any) => void,
}

const CartIcon: React.FC<Props> = ({ to, closeBurgerMenu }) => {
    const theme: Theme = useTheme();

    const onClickHandler = () => {
        closeBurgerMenu({ forcedClose: true });
    };

    const style = css`
        display: flex;
        align-items: center;
        height: 100%;

        ${theme.breakpoints.up("xxl")} {
            margin-right: 20px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-right: 10px;
        }

        ${theme.breakpoints.down("md")} {
            margin-right: ${theme.sizes.menu.sideMargin}px;
        }
    `;

    const cartStyle = css`
        width: 25px;
        height: 25px;
        
        &:hover {
            > svg > path {
                    fill: ${theme.colours.scienceBlue};
                }
            }
        }
    `;

    return (
        <NavLink to={to} onClick={onClickHandler}>
            <div css={style}>
                <Cart css={cartStyle} />
            </div>
        </NavLink>
    );
};

export default CartIcon;