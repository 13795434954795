import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ProductImageContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const style = css`
        width: 197px;
        height: 213px;
        margin-bottom: 30px;

        ${theme.breakpoints.up("5xl")} {
            width: 272px;
            height: 294px;
            margin-bottom: 55px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 223px;
            height: 241px;
            margin-bottom: 34px;
        }
        
        ${theme.breakpoints.only("xl")} {
            width: 174px;
            height: 189px;
            margin-bottom: 27px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 197px;
            height: 213px;
        }

        ${theme.breakpoints.only("md")} {
            width: 143px;
            height: 155px;
            margin-bottom: 22px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 204px;
            height: 204px;
            margin-bottom: 37px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 180px;
            height: 180px;
            margin-bottom: 36px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ProductImageContainer;

