import React from "react";
import { css, useTheme } from "@emotion/react";
import FooterVerticalHeaderMenuTitle from "core/Components/Footer/vertical/FooterVerticalHeaderMenuTitle";
import FooterVerticalHeaderMenuChevron from "core/Components/Footer/vertical/FooterVerticalHeaderMenuChevron";

type Props = {
    headerMenu: StandardPage,
    hasSubMenu: boolean,
    selectedMenu: StandardPage | null,
    setSelectedMenuHandler: Function,
};

const FooterVerticalHeaderMenu: React.FC<Props> = ({
    headerMenu,
    hasSubMenu,
    selectedMenu,
    setSelectedMenuHandler
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        align-self: center;
        padding: 20px 0 23px 0;
        width: 100%;
        border-bottom: 1px solid ${theme.colours.alto3};

        ${theme.breakpoints.between("sm", "md")} {
            padding-left: 45px;
            padding-right: 45px;
        };

        ${theme.breakpoints.down("xs")} {
            padding-left: 20px;
            padding-right: 20px;
        };
    `;

    return (
        <div css={style}>
            <FooterVerticalHeaderMenuTitle data={headerMenu} />

            {hasSubMenu && (
                <FooterVerticalHeaderMenuChevron
                    headerMenu={headerMenu}
                    selectedMenu={selectedMenu}
                    setSelectedMenuHandler={setSelectedMenuHandler}
                />
            )}
        </div>
    );
};

export default FooterVerticalHeaderMenu;