import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    card: ImageVerticalCard,
    children: React.ReactNode,
};

const ListItemHeader: React.FC<Props> = ({ card, children, }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const {
        textColour
    } = colourTheme;

    const listItemHeaderStyle = css`
        align-items: start;
        width: 90%;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 24px;
        line-height: 30px;
        color: ${textColour};

        ${theme.breakpoints.only("xl")} {
            font-size: 22px;
            line-height: 27px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 19px;
            line-height: 23px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 15px;
            line-height: 19px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 16px;
            line-height: 20px;
        }
    `;

    return (
        <ContentCombine css={listItemHeaderStyle}>{children}</ContentCombine>
    );
};

export default ListItemHeader;
