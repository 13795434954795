import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    index: number,
};

const Description: React.FC<Props> = ({
    children,
    index,
}) => {
    const theme: Theme = useTheme();
    const isOddOrder = (index + 1) % 2;

    const descriptionStyle = css`
        padding: 8px 8px 0 8px;
        overflow: hidden;
        color: ${theme.colours.doveGray2};
        font-size: 15px;
        line-height: 22px;
        position: relative;

        ${isOddOrder && css`
            ${theme.breakpoints.down("md")} {
                border-right: 2px solid ${theme.colours.doveGray2};
                right: -2px;
            }
        `};

        ${!isOddOrder && css`
            align-self: flex-start;
        `};

        ${theme.breakpoints.up("5xl")} {
            width: 260px;
            font-size: 23px;
            line-height: 30px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            width: 158px;
            font-size: 15px;
            line-height: 22px;
        }
        
        ${theme.breakpoints.between("lg", "xl")} {
            width: 106px;
            font-size: 12px;
            line-height: 16px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 15px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 13px;
        }
    `;

    return (
        <p css={descriptionStyle}>
            {children}
        </p>
    );
}

export default Description;
