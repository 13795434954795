import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    chevronSize?: number,
};

const ItemChevron: React.FC<Props> = ({
    children,
    chevronSize = 30,
}) => {
    const theme = useTheme();

    const style = css`
        position: fixed;
        z-index: ${theme.zIndex.zIndexHigh};
        top: calc(${theme.sizes.menu.heightMain}px / 2 - ${chevronSize}px / 2);
        left: calc(${theme.sizes.menu.heightMain}px / 2 - ${chevronSize}px / 2);

        ${theme.breakpoints.down("xl")} {
            left: 9px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            top: calc(${theme.sizes.menu.burgerHeightMain}px / 2 - ${chevronSize}px / 2);
        }

        ${theme.breakpoints.down("xs")} {
            top: calc(${theme.sizes.menu.burgerHeightMain}px / 2 - ${chevronSize}px / 2 + ${theme.sizes.menu.breadcrumbBurgerSmallHeight}px);
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemChevron;