import React from 'react';
import { css, useTheme } from "@emotion/react";
import LinkTo from "core/Components/LinkTo";
import { NOOP } from "core/constants";
import transitions from "theme/transitions";
import mixins from "theme/mixins";

type Props = {
    children: React.ReactNode,
    outerURL?: string,
    innerURL?: string,
    padding?: number;
    width?: number,
    height?: number,
    useImageShadow?: boolean,
    borderRadius?: number,
    backgroundColour?: string,
    onClick?: () => void,
};

const ImageContainer: React.FC<Props> = ({
    children,
    outerURL,
    innerURL,
    padding,
    width,
    height,
    useImageShadow = false,
    borderRadius = 0,
    backgroundColour,
    onClick = NOOP,
}) => {
    const theme = useTheme();
    const hasURL = !!(outerURL || innerURL);
    const linkURL = outerURL || innerURL;
    const isInteractive = hasURL || onClick !== NOOP;

    const style = css`
        margin-right: auto;
        margin-left: auto;
        
        ${padding && css`
            padding: ${padding}px;
        `}
        
        ${width 
            ? css`width: ${width}px;`
            : css`width: 100%;`
        };
        
        ${height
            ? css`height: ${height}px;`
            : css`height: 100%;`
        };
        
        ${useImageShadow && css`
            margin-top: 5px;
            box-shadow: ${theme.borderAndShadow.dropShadow};
        `};

        ${hasURL && css`
            cursor: pointer;
        `};

        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};

        ${borderRadius && css`
            border-radius: ${borderRadius}px;
        `};
        
        ${isInteractive && css`
            img {
                ${mixins.transformedBloop('rotate(5deg)', transitions.transitionTime, true)}
            }
        `}
    `;

    return (
        <div css={style}>
            <LinkTo to={linkURL} onClick={onClick}>
                {children}
            </LinkTo>
        </div>
    );
};

export default ImageContainer;
