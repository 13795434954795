import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const NavMenuText: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        height: ${theme.sizes.menu.heightMain - theme.sizes.menu.heightMainUnderLine}px;
        padding: ${theme.sizes.menu.heightMainUnderLine}px 20px 0;
        font-size: 14px;
        align-items: center;

        ${theme.breakpoints.up('5xl')} {
            padding: ${theme.sizes.menu.heightMainUnderLine}px 30px 0;
            font-size: 16px;
        }

        ${theme.breakpoints.down('xl')} {
            padding: ${theme.sizes.menu.heightMainUnderLine}px 13px 0;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default NavMenuText;