import React from 'react';
import HubspotForm from "core/Components/HubspotForm";

type Props = {
    hubspotFormId: string
}

const Form: React.FC<Props> = ({ hubspotFormId }) => {
    return (
        <HubspotForm hubspotFormId={hubspotFormId} shouldLoadScript />
    );
}

export default Form;