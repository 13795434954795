import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    data: TopBanner,
    buttonHeight: number,
    buttonSidePadding: number,
};

const FirstButton: React.FC<Props> = ({ data, buttonHeight, buttonSidePadding }) => {
    const theme: Theme = useTheme();

    const linkURL = data.innerURL || data.outerURL;
    const colourTheme = JSON.parse(data.buttonTheme);
    const { buttonTextColour, buttonColour, } = colourTheme;

    const buttonTextCustomStyle = css`
        font-size: 20px;

        ${theme.breakpoints.up("3xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    if (!data.buttonText) return null;

    return (
        <RoundButton
            backgroundColour={buttonColour}
            textColour={buttonTextColour}
            linkURL={linkURL}
            buttonHeight={buttonHeight}
            buttonSidePadding={buttonSidePadding}
            buttonTextCustomStyle={buttonTextCustomStyle}
        >
            {data.buttonText}
        </RoundButton>
    );
}

export default FirstButton;
