import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    card: ImageVerticalCard,
    children: React.ReactNode,
    className?: string,
};

const CardRoundButton: React.FC<Props> = ({ card, children, className = '' }) => {
    const linkURL = card.outerURL || card.innerURL;
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const {
        buttonColour,
        buttonTextColour,
    } = colourTheme;

    const buttonTextCustomStyle = css`
        color: ${buttonTextColour};
        font-size: 19px;

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
        }
    `;

    return (
        <RoundButton
            backgroundColour={buttonColour}
            textColour={buttonTextColour}
            linkURL={linkURL}
            buttonTextCustomStyle={buttonTextCustomStyle}
            className={className}
        >
            {children}
        </RoundButton>
    );
}

export default CardRoundButton;
