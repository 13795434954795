import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    hoveredColour?: string,
};

const ItemBox: React.FC<Props> = ({ children , hoveredColour }) => {
    const theme = useTheme();

    const shadowStyle = css`
        border-radius: 12px;
        box-shadow: ${theme.borderAndShadow.boxShadow3};
    `;

    const style = css`
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 12px;
        
        ${hoveredColour && css`
            transition: all ${theme.transitions.transitionFaster};
            :hover {
                .ribbon {
                    background: ${hoveredColour};
                    box-shadow: 0 0 0 999px ${hoveredColour};
                }

                .price-container {
                    border-color: ${hoveredColour};
                }

                .title {
                    color: ${hoveredColour};
                }

                .circle-button {
                    background: ${hoveredColour};
                }
            }
        `}
    `;

    return (
        <div css={shadowStyle}>
            <div css={style} className={"radius-box"} >
                {children}
            </div>
        </div>
    );
};

export default ItemBox;
