import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: ContentWithImage,
    className?: string,
};

const Subheader: React.FC<Props> = ({ data, className }) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { subheaderColour } = colourTheme;
    const layout = JSON.parse(data.layout);
    const { useShadow, isBiggerImage, isHalfFullImage } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';

    const subheaderStyle = css`
        margin-bottom: 46px;

        ${subheaderColour && css`
            color: ${subheaderColour};
        `};

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 50px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 22px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 22px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 25px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 26px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 20px;
        }

        ${(useShadow || imageSize === "small") && css`
            font-weight: ${theme.fonts.weights.bold};

            ${theme.breakpoints.up("5xl")} {
                margin-bottom: 40px;
            }

            ${theme.breakpoints.between("lg", "4xl")} {
                margin-bottom: 30px;
            };

            ${theme.breakpoints.only("md")} {
                margin-bottom: 20px;
            }

            ${theme.breakpoints.down("sm")} {
                margin-bottom: 10px;
            }
        `};
    `;

    return (
        <ContentCombine
            Tag={"p"}
            fontSizeType={"5"}
            css={subheaderStyle}
            className={className}
        >
            {data.subheader}
        </ContentCombine>
    );
};

export default Subheader;
