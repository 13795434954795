import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode
};

const Wrapper: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        flex-direction: column;
        align-items: start;
        padding: 39px 132px;

        ${theme.breakpoints.up("5xl")} {
            padding: 40px 202px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 34px 151px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 45px 80px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 46px 45px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 23px 46px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 56px 44px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 21px 20px;
        }
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMinHeight={false}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
