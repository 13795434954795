import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    subheader: string,
};

const IntroSubheader:React.FC<Props> = ({ subheader }) => {
    const theme = useTheme();

    const style = css`
        ${theme.breakpoints.up("xl")} {
            font-size: 40px;
            line-height: 50px;
        }

        ${theme.breakpoints.only("lg")} {
            font-size: 30px;
            line-height: 40px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 25px;
            line-height: 30px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 20px;
            line-height: 26px;
        }
    `;

    return (
        <ContentCombine defaultTextAlign={"center"} css={style}>{subheader}</ContentCombine>
    );
};

export default IntroSubheader;