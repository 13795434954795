import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    justifyContent: string,
};

const ContentContainer: React.FC<Props> = ({
    children,
    justifyContent,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: ${justifyContent};
        height: 100%;
      
        ${theme.breakpoints.up("xxl")} {
            width: 609px;
        }
      
        ${theme.breakpoints.only("xl")} {
            width: 533px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 432px;
        }
    `;


    const containerStyle = css`
        display: flex;
        z-index: ${theme.zIndex.zIndexOne};

        ${theme.breakpoints.up("lg")} {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
            padding-left: 183px;
        }

        ${theme.breakpoints.only("xxl")} {
            max-width: 1440px;
            padding-left: 81px;
        }
      
        ${theme.breakpoints.only("xl")} {
            max-width: 1280px;
            padding-left: 81px;
        }
      
        ${theme.breakpoints.only("lg")} {
            max-width: 1024px;
            padding-left: 45px;
        }
        
        ${theme.breakpoints.only("md")} {
            display: unset;
            padding: 50px 45px 65px;
            max-width: 768px;
        }

        ${theme.breakpoints.only("sm")} {
            display: unset;
            padding: 26px 45px 46px;
            max-width: 568px;
        }

        ${theme.breakpoints.down("xs")} {
            display: unset;
            padding: 26px 20px 62px;
            max-width: 350px;
        }

        ${theme.breakpoints.down("md")} {
            height: unset;
        }
    `;

    return (
        <div css={containerStyle}>
            <div css={style}>{children} </div>
        </div>
    );
}

export default ContentContainer;
