import { useCallback } from 'react';
import { toast } from "react-toastify";
import errorParser from "core/includes/errorParser";
import { defaultToastOptions, errorToastOptions } from "core/Components/Utils/ToastMessage";

const useToast = () => {
    const success = useCallback((message: any) => {
        toast.success(message, defaultToastOptions);
    }, []);

    const error = useCallback((error: any) => {
        const message = errorParser(error);
        toast.error(message, errorToastOptions);
    }, []);

    const info = useCallback((message: any) => {
        toast.info(message, defaultToastOptions);
    }, []);

    return [success, error, info];
};

export default useToast;
