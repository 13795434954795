import React from "react";
import { css, Theme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isRightSide?: boolean,
};

const HalfListContainer: React.FC<Props> = ({ children, isRightSide, }) => {
    const halfContentListContainer = (theme: Theme) => css`
        flex: 1;

        ${!isRightSide && css`
            ${theme.breakpoints.between("sm", "md")} {
                margin-right: 15px;
            }
        `};
    `;

    return (
        <div css={halfContentListContainer}>
            {children}
        </div>
    );
}

export default HalfListContainer;
