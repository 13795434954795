import React from "react";
import { css, useTheme } from "@emotion/react";

import SocialShare from "core/Components/Socials/SocialShare";

type Props = {
    title: string,
    className?: string,
};

const SocialsHorizontalList: React.FC<Props> = ({ title, className }) => {
    const theme = useTheme();

    const socialShareMobileContainerStyle = css`
        padding-bottom: 95px;

        ${theme.breakpoints.only('md')} {
            padding-bottom: 102px;
        }

        ${theme.breakpoints.only('sm')} {
            padding-bottom: 146px;
        }

        ${theme.breakpoints.down('xs')} {
            padding-bottom: 114px;
        }
    `;

    const socialShareMobileStyle = css`
        .icon {
            height: 46px;
            width: 46px;
        }
        
        column-gap: 62px;

        ${theme.breakpoints.only('md')} {
            column-gap: 53px;

            .icon {
                height: 38px;
                width: 38px;
            }
        }
        
        ${theme.breakpoints.down('sm')} {
            column-gap: 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .icon {
                height: 32px;
                width: 32px;
            }
        }
    `;

    const shareStyle = css`
        text-align: center;
        font-size: 25px;
        padding-bottom: 80px;

        ${theme.breakpoints.down('md')} {
            padding-bottom: 36px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 20px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 19px;
        }
    `;

    return (
        <div css={socialShareMobileContainerStyle} className={className}>
            <div css={shareStyle}>
                Share
            </div>
            <SocialShare
                css={socialShareMobileStyle}
                emailSubject={title}
                isVertical={false}
            />
        </div>
    );
}

export default SocialsHorizontalList;
