import React, { useMemo, memo, useRef } from "react";
import { css, useTheme } from "@emotion/react";
import { isEmpty, isEqual } from "lodash";

import Subheader from "core/Components/Elements/Subheader";
import TextBox from "core/Components/Elements/TextBox";
import Image from "core/Components/Image";
import ImageWrapper from "core/Components/ImageWrapper";
import LinkTo from "core/Components/LinkTo";
import SectionContainer from "core/Components/SectionContainer";
import useBreakpoint from "core/hooks/useBreakpoint";

import Header from "elements/Components/FullImage/components/Header";
import ImageContainer from "elements/Components/FullImage/components/ImageContainer";

import { isUp } from "theme/breakpoints";

type Props = {
    data: FullImage,
};

const FullImage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const linkURL = data.innerURL || data.outerURL;
    const { breakpoint } = useBreakpoint();
    const imageRef: any = useRef();

    const image = useMemo(() => {
        if (isUp(breakpoint, "lg")) {
            return data.landscape || data.portrait || null;
        }

        return data.portrait || data.landscape || null;
    }, [breakpoint, data]);

    const useAFocusPoint = (isEqual(image, data.landscape) && data.useFocusPoint) ||
        (isEqual(image, data.portrait) && data.useFocusPointPortrait);

    const imageStyle = css`
        width: 100%;
        height: 100%;

        ${!useAFocusPoint && css`
            ${theme.breakpoints.down("md")} {
                > img {
                    display: flex;
                    min-height: unset !important;
                    max-height: unset !important;
                    height: unset !important;
                    width: 100% !important;
                    inset: unset !important;
                }
            }
        `}
    `;

    const altText = data.altText || '';

    // image is required
    return isEmpty(image?.id) ? null : (
        <SectionContainer data={data}>
            <ImageWrapper>
                {data.header && (
                    <TextBox textPosition={data.textPosition}>
                        {data.header && (
                            <Header>{data.header}</Header>
                        )}

                        {data.subheader && (
                            <Subheader>{data.subheader}</Subheader>
                        )}
                    </TextBox>
                )}
                <LinkTo to={linkURL} css={imageStyle}>
                    <ImageContainer
                        heightType={data.moduleHeightType}
                        isPortrait={!!data.portrait}
                        useFocusPoint={useAFocusPoint}
                        imageHeight={imageRef.current?.height ?? null}
                    >
                        <Image
                            ref={imageRef}
                            image={image}
                            css={imageStyle}
                            useFocusPoint={useAFocusPoint}
                            customAltText={altText}
                        />
                    </ImageContainer>
                </LinkTo>
            </ImageWrapper>
        </SectionContainer>
    );
}

export default memo(FullImage);
