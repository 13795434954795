import React, { useEffect, useState } from "react";
import { css, useTheme } from "@emotion/react";
import moment from "moment";

import ContentCombine from "core/Components/ContentCombine";
import Header from "elements/Components/FullImage/components/Header";
import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";
import Image from "core/Components/Image";
import RelatedPosts from "pages/Components/BlogPost/RelatedPosts";
import SocialsVerticalList from "core/Components/Socials/SocialsVerticalList";
import SocialsHorizontalList from "core/Components/Socials/SocialsHorizontalList";
import { useAppState } from "core/contexts/AppContext";
import ImageContainer from "elements/Components/BannerWithImage/components/ImageContainer";
import components from "elements/config/components";
import RoundTextButton from "core/Components/Buttons/RoundTextButton";
import document from "core/includes/document";
import { HUBSPOT_PORTAL_ID } from "core/config/config";

type Props = {
    data: BlogPost
};

const BlogPost: React.FC<Props> = ({ data }) => {
    const [isFormSet, setIsFormSet] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const theme = useTheme();
    const [, setAppState] = useAppState();
    const publishedDate = moment(data.publishedDate).format('DD MMMM, YYYY');

    useEffect(() => {
        setAppState({
            currentPageName: data.title
        });
    }, [data.title, setAppState]);

    useEffect(
        () => {
            if (!isFormSet && data.buttonHubspotFormId) {
                const script = document.createElement("script");
                script.src = "https://js.hsforms.net/forms/embed/v2.js";
                document.body.appendChild(script);

                script.addEventListener('load', () => {
                    if ((window as any).hbspt) {
                        (window as any).hbspt.forms.create({
                            region: "na1",
                            portalId: HUBSPOT_PORTAL_ID,
                            formId: data.buttonHubspotFormId,
                            target: "#hubspotForm",
                        });
                    }
                });

                setIsFormSet(true);
            }
        },
        [isFormSet, data.buttonHubspotFormId, setIsFormSet]
    );
    const onHubspotForm = () => {
        setIsFormVisible(true);
    }

    const containerStyle = css`
        display: flex;
        flex-direction: column;
    `;

    const headingContainer = css`
        width: 100%;
        padding-top: 43px;
        display: flex;
        flex-direction: column;

        ${theme.breakpoints.down('xl')} {
            padding-left: 110px;
            padding-right: 110px;
        }

        ${theme.breakpoints.only('md')} {
            padding-left: 40px;
            padding-right: 40px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-top: 30px;
            padding-left: 35px;
            padding-right: 35px;
        }
    `;

    const authorStyle = css`
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 37px;

        ${theme.breakpoints.down('md')} {
            justify-content: center;
            padding-top: 53px;
            padding-bottom: 44px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-top: 24px;
            padding-bottom: 27px;
        }
    `;

    const authorNameContainerStyle = css`
        color: ${theme.colours.scorpion3};
        font-weight: ${theme.fonts.weights.light};
        font-size: 30px;
        line-height: 34px;

        ${theme.breakpoints.down('sm')} {
            font-size: 18px;
            line-height: 20px;
        }
    `;

    const authorNameStyle = css`
        font-weight: ${theme.fonts.weights.bold};
    `;

    const authorThumbnailStyle = css`
        width: 129px;
        height: 129px;
        margin-right: 30px;
        border-radius: 50%;

        ${theme.breakpoints.down('sm')} {
            width: 84px;
            height: 84px;
            margin-right: 20px;
        }
    `;

    const publishedDateStyle = css`
        color: ${theme.colours.gray3};
        font-weight: ${theme.fonts.weights.light};

        font-size: 25px;
        line-height: 34px;

        ${theme.breakpoints.down('sm')} {
            font-size: 16px;
            line-height: 22px;
        }
    `;

    const contentContainerStyle = css`
        position: relative;
        width: 100%;
        color: ${theme.colours.shipGray};
        padding-bottom: 130px;

        ${theme.breakpoints.up('5xl')} {
            min-height: 674px;
        }

        ${theme.breakpoints.between('lg', '4xl')} {
            min-height: 412px;
        }

        ${theme.breakpoints.down('xl')} {
            padding-bottom: 72px;
            padding-right: 110px;
            padding-left: 110px;
        }

        ${theme.breakpoints.down('md')} {
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 120px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-left: 20px;
            padding-right: 20px;
        }

        // The iframe is only used when videos are included in the blog.

        iframe {
            width: 792px;
            height: 528px;

            ${theme.breakpoints.only('xxl')} {
                width: 908px;
                height: 605px;
            }

            ${theme.breakpoints.only('xl')} {
                width: 792px;
                height: 528px;
            }

            ${theme.breakpoints.only('lg')} {
                width: 746px;
                height: 497px;
            }

            ${theme.breakpoints.only('md')} {
                width: 678px;
                height: 452px;
            }

            ${theme.breakpoints.only('sm')} {
                width: 478px;
                height: 319px;
            }

            ${theme.breakpoints.down('xs')} {
                width: 320px;
                height: 213px;
            }
        }
    `;

    const contentStyle = css`
        h3 {
            margin-bottom: 10px;
            font-size: 25px;
            line-height: 30px;
            font-weight: ${theme.fonts.weights.bold};

            ${theme.breakpoints.between('lg', 'xl')} {
                margin-bottom: 5px;
            }

            ${theme.breakpoints.down('md')} {
                font-size: 20px;
                line-height: 24px;
            }
        }

        h2 {
            margin-bottom: 25px;
            margin-top: 60px;
            font-size: 30px;
            line-height: 28px;
            font-weight: ${theme.fonts.weights.bold};

            ${theme.breakpoints.down('md')} {
                font-size: 25px;
                margin-top: 50px;
            }

            ${theme.breakpoints.down('xs')} {
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 40px;
            }
        }

        #list-left {
            margin-left: 18px;

            ${theme.breakpoints.down('sm')} {
                margin-left: 20px;
                width: calc(100% - 20px);
            }

            li {
                margin-right: 0;

                ${theme.breakpoints.up('md')} {
                    padding-left: 12px;
                }
            }
        }

        #list-center {
            counter-reset: list;
            margin: 10px auto;
            width: 645px;

            ${theme.breakpoints.only('md')} {
                width: 573px;
            }

            ${theme.breakpoints.down('sm')} {
                margin-left: 25px;
                width: calc(100% - 25px);
            }

            li {
                text-align: left;
            }
        }

        li {
            line-height: 28px;
            font-size: 18px;
            font-weight: ${theme.fonts.weights.light};

            ${theme.breakpoints.down('md')} {
                font-size: 16px;
                line-height: 25px;
            }

            ${theme.breakpoints.down('xs')} {
                font-size: 15px;
                line-height: 22px;
            }
        }

        ol {
            counter-reset: list;

            > li {
                padding-left: 0 !important; // Since our number is a custom ::before, padding is not applied correctly
                position: relative;
                list-style: none;
            }
        }

        ol > li:before {
            counter-increment: list;
            content: counter(list) ") ";
            margin-left: -20px;
            padding-right: 3px;
        }

        ${theme.breakpoints.up('md')} {
            ol {
                &#list-left {
                    margin-left: 30px;

                    > li:before {
                        margin-left: -30px;
                        padding-right: 10px;
                    }
                }
            }
        }

        ${theme.breakpoints.down('sm')} {
            ol {
                &#list-center {
                    > li:before {
                        padding-left: 2px !important;
                    }
                }
            }
        }

        hr {
            margin-bottom: 30px;

            ${theme.breakpoints.up('md')} {
                width: 102%;
                transform: translateX(-1.5%);
            }

            ${theme.breakpoints.up('lg')} {
                width: 107%;
                transform: translateX(-3.5%);
            }

            ${theme.breakpoints.up('xxl')} {
                width: 110%;
                transform: translateX(-4.5%);
            }

            ${theme.breakpoints.down('sm')} {
                width: 100%;
            }
        }

        p, span {
            font-size: 18px;
            line-height: 28px;
            font-weight: ${theme.fonts.weights.light};

            ${theme.breakpoints.down('md')} {
                font-size: 16px;
                line-height: 27px;
            }

            ${theme.breakpoints.down('xs')} {
                font-size: 16px;
                line-height: 25px;
            }
        }

        #image_container, .caption_image {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            margin-right: 0 !important;
            margin-top: 30px !important;
            margin-bottom: 55px !important;

            ${theme.breakpoints.only('md')} {
                margin-top: 25px !important;
                margin-bottom: 50px !important;
            }

            ${theme.breakpoints.down('sm')} {
                margin-top: 20px !important;
                margin-bottom: 40px !important;
            }


            img {
                flex-grow: 1;
                border: none;
            }
        }

        .caption {
            color: ${theme.colours.mineShaft2} !important;
            text-align: center;
            font-style: normal !important;
            font-weight: ${theme.fonts.weights.normal};
            margin-top: 22px !important;
            margin-bottom: 0 !important;
            font-size: 18px;
            line-height: 18px;

            ${theme.breakpoints.down('md')} {
                font-size: 16px;
                line-height: 25px;
            }

            ${theme.breakpoints.down('xs')} {
                font-size: 12px;
                line-height: 18px;
            }
        }

        blockquote {
            font-family: ${theme.fonts.frutiger};
            margin: 20px 0 0;
            font-size: 28px;
            line-height: 36px;
            float: none;
            width: 100%;
            display: flex;
            justify-content: center;
            text-indent: 0;
            border-image: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0) 38%,
                    ${theme.colours.utils.rgba(theme.colours.curiousBlue)} 38%,
                    ${theme.colours.utils.rgba(theme.colours.curiousBlue)} 65%,
                    rgba(0, 0, 0, 0) 65%
            ) 100 / 1 / 0 stretch;
            border-width: 1px;
            border-style: solid;
            font-style: normal;

            > * {
                color: ${theme.colours.curiousBlue};
                font-size: 28px;
                line-height: 36px;
                padding: 25px 0;
                width: 526px;
                text-align: center;

                ${theme.breakpoints.down('md')} {
                    width: 470px;
                }

                ${theme.breakpoints.down('xs')} {
                    width: 300px;
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }

        span {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
        }
    `;

    const titleStyle = css`
        justify-content: flex-start;
        font-size: 42px;
        line-height: 65px;

        ${theme.breakpoints.down('md')} {
            justify-content: center;
            font-size: 37px;
            line-height: 57px;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 25px;
            line-height: 30px;
        }
    `;

    const subheaderStyle = css`
        margin-bottom: 22px;
        justify-content: flex-start;
        color: ${theme.colours.scorpion2};
    `;

    const socialShareDesktopStyle = css`
        position: absolute;
        top: 55px;
        right: 42px;

        ${theme.breakpoints.down('xl')} {
            display: none;
        }
    `;

    const imageContainerStyle = css`
        width: 100%;
        padding-bottom: 27px;
        display: flex;
        flex-direction: column;

        ${theme.breakpoints.down('xl')} {
            padding-left: 110px;
            padding-right: 110px;
        }

        ${theme.breakpoints.only('lg')} {
            padding-bottom: 34px;
        }

        ${theme.breakpoints.down('md')} {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 34px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-bottom: 24px;
        }
    `;

    const bannerImageStyle = css`
        height: 311px !important;

        ${theme.breakpoints.down('sm')} {
            height: 180px !important;
        }
    `;

    const wrapperStyle = css`
        ${theme.breakpoints.only('xxl')} {
            padding: 0 23%;
        }


        ${theme.breakpoints.only('3xl')} {
            padding: 0 25%;
        }

        ${theme.breakpoints.up('4xl')} {
            padding: 0 30%;
        }
    `;

    const buttonContainerStyle = css`
        display: flex;
        justify-content: center;
        
        padding-top: 60px;
        
        ${theme.breakpoints.down('md')} {
            padding-top: 50px;
        }
    `;

    const nolinkStyle = css`
        text-decoration: none;
    `;

    const buttonTextCustomStyle = css`
        border-radius: 4px;
        font-size: 18px;
        padding: 20px 30px;
    `;

    const buttonStyle = css`
        padding: 0;
    `;

    const style = css`
        width: 100%;
        padding-top: 60px;

        ${theme.breakpoints.down('md')} {
            padding-top: 50px;
        }

        ${!isFormVisible && css`
            display: none;
        `}
    `;

    const getButton = () => (
        <RoundTextButton
            backgroundColour={data.buttonBackgroundColour}
            textColour={data.buttonTextColour}
            buttonTextCustomStyle={buttonTextCustomStyle}
            css={buttonStyle}
            backgroundHoverColour={data.buttonHoverBackgroundColour}
            {...data.buttonHubspotFormId && {
                onClick: onHubspotForm
            }}
        >
            {data.buttonLabel}
        </RoundTextButton>
    );

    return (
        <SectionContainer css={containerStyle}>
            <Wrapper useMaxWidth={false} flexDirection={"column"} css={wrapperStyle}>
                <SocialsVerticalList
                    css={socialShareDesktopStyle}
                    emailSubject={data.title}
                />
                {data.title && (
                    <>
                        <div css={headingContainer}>
                            <Header css={titleStyle}>
                                {data.title}
                            </Header>
                            <div css={authorStyle}>
                                {data.author?.webpWithFallbackPortrait && (
                                    <Image
                                        css={authorThumbnailStyle}
                                        image={data.author?.webpWithFallbackPortrait}
                                    />
                                )}
                                <div>
                                    {data.author?.name && (
                                        <div css={authorNameContainerStyle}>
                                            <span>By&nbsp;</span>
                                            <span css={authorNameStyle}>{data.author.name}</span>
                                        </div>
                                    )}
                                    <div css={publishedDateStyle}>Published {publishedDate}</div>
                                </div>
                            </div>
                        </div>
                        <div css={imageContainerStyle}>
                            {!data.hideBanner && (
                                <ImageContainer
                                    image={data.banner}
                                    useFocusPoint={!!data.banner.FocusPoint}
                                    opacity={0}
                                    gradientColor={"transparent"}
                                    css={bannerImageStyle}
                                />
                            )}
                        </div>
                    </>
                )}
                <div css={contentContainerStyle}>
                    {data?.subheader && (
                        <ContentCombine
                            css={subheaderStyle}
                            Tag={'h5'}
                        >
                            {data.subheader}
                        </ContentCombine>
                    )}
                    <ContentCombine fontSizeType={'9'} css={contentStyle}>
                        {data.content}
                    </ContentCombine>
                    <div id="hubspotForm" css={style} />
                    {data.showButton && !isFormVisible && (
                        <div css={buttonContainerStyle}>
                            {data.buttonHubspotFormId ? (
                                getButton()
                            ) : (
                                <a
                                    href={data.buttonExternalUrl}
                                    {...data.isNewTab && { target: '_blank' }}
                                    css={nolinkStyle}
                                >
                                    {getButton()}
                                </a>
                            )}
                        </div>
                    )}
                </div>
                <SocialsHorizontalList
                    title={data.title}
                />
                <RelatedPosts posts={data.relatedPosts} />
            </Wrapper>
            {data?.elements && data.elements.map((ele: any, index: number) => {
                let Component = components.hasOwnProperty(ele.__typename) && components[ele.__typename]
                return <Component data={ele} key={`${ele.id}-${index}`} />
            })}
        </SectionContainer>
    );
}

export default BlogPost;
