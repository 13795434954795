import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    type?: string,
    backgroundColour?: string,
};

const ItemText: React.FC<Props> = ({ children, type, backgroundColour }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        ${(type === "success-story" || type === "career") && css`
            height: 100%;

            ${theme.breakpoints.up("md")} {
                justify-content: space-between;
            }

            ${theme.breakpoints.up('5xl')} {
                height: 216px;
            }

            ${theme.breakpoints.between('xxl', '4xl')} {
                height: 179px;
            }

            ${theme.breakpoints.only('xl')} {
                height: 160px;
            }

            ${theme.breakpoints.down('lg')} {
                height: 139px;
            }
            
            ${backgroundColour && css`
                width: 100%;
                min-width: 200px;
                background-color: ${backgroundColour};
            `};
        `}

        ${((type === "event")) && css`
            ${theme.breakpoints.down("sm")} {
                justify-content: center;
            }
        `}
        
        ${theme.breakpoints.up("xxl")} {
            padding: 12px 10px 19px 13px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            padding: 7px 7px 13px 12px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 7px 7px 12px 10px;
        }

        ${theme.breakpoints.down("sm")} {
            padding: 7px 11px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemText;