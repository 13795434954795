import jwt_decode from "jwt-decode";
import moment from "moment";
import * as storage from "core/includes/localStorage";
import { API_BASE_URL, HOME_USER_URL } from "core/config/config";
import {
    AUTH_BEARER_PREFIX,
    AUTH_HEADER,
    AUTH_REFRESH_LEEWAY,
    AUTH_STORAGE_KEY
} from "core/includes/constants";
import {
    AUTH_LOGIN_URL,
    AUTH_PASSWORD_RECOVERY,
    EMAIL_VERIFICATION_URL,
    MANAGE_URL, SELECT_PLAN_URL, SOCIAL_CONFIRMATION_URL
} from "core/includes/pricingAuthRedirects";
import LoginError from "pages/includes/LoginError";
import window from "core/includes/window";

/**
 * Save the token to storage.
 *
 * @param token
 */
export const setToken: any = (token: any) => {
    const decoded: any = jwt_decode(token);
    const expiresIn = decoded.exp - decoded.iat;
    const expiresAt = moment().add(expiresIn, "s");

    const tokenData = {
        token,
        expiresAt,
    };

    storage.persist(AUTH_STORAGE_KEY, tokenData);

    return tokenData;
};

/**
 * Clear the token from storage.
 */
export const clearToken: any = () => storage.removeItem(AUTH_STORAGE_KEY);

/**
 * Get tht token from storage.
 *
 * @returns {null|any|{}}
 */
export const getToken: any = () => {
    return storage.retrieve(AUTH_STORAGE_KEY);
};

/**
 * Add the bearer token to headers.
 *
 * @param tokenData
 * @param headers
 * @returns {*}
 */
export const addTokenHeader: any = (tokenData: any, headers: any) => {
    if (tokenData && tokenData.token) {
        headers[AUTH_HEADER] = `${AUTH_BEARER_PREFIX}${tokenData.token}`;
    }

    return headers;
};

/**
 * Should the token be refreshed. Checks if current time is between the after the refresh time. Expiry should
 * also be checked with hasExpired.
 *
 * @param tokenData
 * @returns {boolean|boolean}
 */
export const shouldTokenRefresh: any = (tokenData: any) => {
    // can't refresh if we don't have a token
    if (!tokenData || !tokenData.expiresAt) return false;

    const expires = moment(tokenData.expiresAt);
    const refreshTime = expires.clone().subtract(AUTH_REFRESH_LEEWAY, "s");
    const now = moment();

    return now.isSameOrAfter(refreshTime);
};

/**
 * Has the token has expired.
 *
 * @param tokenData
 * @returns {boolean}
 */
export const hasTokenExpired: any = (tokenData: any) => {
    if (!tokenData || !tokenData.expiresAt) return true;

    const expires = moment(tokenData.expiresAt);
    const now = moment();

    return now.isAfter(expires);
};

/**
 * Extract the token from the headers and store it.
 *
 * @param headers
 * @returns {null|*}
 */
export const updateTokenFromHeaders: any = (headers: any) => {
    const header = headers.get(AUTH_HEADER);

    if (header && header.indexOf(AUTH_BEARER_PREFIX) === 0) {
        const token = header.replace(AUTH_BEARER_PREFIX, "");
        const existingTokenData = getToken();

        // only update if it has changed
        if (!existingTokenData || token !== existingTokenData.token) {
            return setToken(token);
        }
    }

    return null;
};

/**
 * Refresh the token. It sends original token and receives an updated token in the headers.
 *
 * @param tokenData
 * @returns {Promise<null>}
 */
export const refreshToken: any = async (tokenData: any) => {
    const headers = addTokenHeader(tokenData, {});

    const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
        method: "POST",
        headers,
        body: JSON.stringify({withoutSession: true}),
        credentials: "omit"
    });

    const data = await response.json();

    if (data.status) {
        return updateTokenFromHeaders(response.headers);
    }

    throw new Error("Unable to refresh token");
};

/**
 * Login with username/password.
 *
 * @param {string} email
 * @param {string} password
 *
 * @returns {Promise<void>}
 */
export const login: any = async (email: any, password: any) => {
    const response = await fetch(`${HOME_USER_URL}${AUTH_LOGIN_URL}`, {
        credentials: "omit",
        method: "POST",
        body: JSON.stringify({email, password, withoutSession: true, domain: "wtbox.com"}),
        headers: {
            "Content-Type": "application/json"
        }
    });

    await response.json().then((data) => {
        // handle a redirect for logging into wrong site
        if (data.redirect) {
            window.location = data.redirect;

        // successful login, store token
        } else if (data.status) {
            setToken(data.token);

        // error with a message
        } else if (data.message) {
            throw new LoginError(data.message);

        // specific html error message
        } else if (data.htmlMessage) {
            throw new LoginError(data.htmlMessage, "html", data.link);
        }
    });
};

/**
 * Login with social profile.
 *
 * @param {string} email
 * @param {string} token
 * @param {string} type
 *
 * @returns {Promise<void>}
 */
export const socialLogin: any = async (email: string, token: string, type: string) => {
    const body = {email, token, type, withoutSession: true, domain: "wtbox.com"};

    const response = await fetch(`${HOME_USER_URL}${AUTH_LOGIN_URL}`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json"
        }
    });

    const data = await response.json();

    // handle a redirect for logging into wrong site
    if (data.redirect) {
        window.location = data.redirect;
    } else if (data.status) {
        setToken(data.token);
    } else if (data.message) {
        throw new LoginError(data.message);
    } else if (data.htmlMessage) {
        throw new LoginError(data.htmlMessage, "html", data.link);
    }
};

export type SocialLoginType = "google" | "facebook";

export const isSocialLoginUser = (user: any): boolean => {
    return user?.LastLoginType === "google" || user?.LastLoginType === "facebook";
};

/**
 * Extracts a param from the token.
 *
 * @param token
 * @param param
 */
export const getTokenParam: any = (tokenData: string, param: string) => {
    const decoded: any = jwt_decode(tokenData);

    if (decoded && decoded.hasOwnProperty(param)) {
        return decoded[param];
    }

    return null;
};

/**
 * Need to confirm the social
 *
 * @param {any} user
 *
 * @returns {boolean}
 */
export const needSocialConfirmation: any = (user: any) => {
    return !!(isSocialLoginUser(user) && !user?.IsSocialConfirmed);
};

/**
 * Need to verify the email
 *
 * @param {any} user
 *
 * @returns {boolean}
 */
export const needEmailVerification: any = (user: any) => {
    return !!(user?.LastLoginType === "password" && !user?.IsEmailVerified);
};

/**
 * Send social signup page on Home user site
 */
export const sendHomeUserMain = () => {
    window.location = `${HOME_USER_URL}/`;
};

/**
 * Send password recovery page on Home user site
 */
export const sendPasswordRecovery = () => {
    window.location = `${HOME_USER_URL}${AUTH_PASSWORD_RECOVERY}`;
};

/**
 * Send manage page on Home user site
 */
export const sendManage = () => {
    window.location = `${HOME_USER_URL}${MANAGE_URL}`;
};

/**
 * Send email verification page on Home user site
 */
export const sendEmailVerification = () => {
    window.location = `${HOME_USER_URL}${EMAIL_VERIFICATION_URL}`;
};

/**
 * Send select plan page on Home user site
 */
export const sendSelectPlan = () => {
    window.location = `${HOME_USER_URL}${SELECT_PLAN_URL}`;
};

/**
 * Send social signup page on Home user site
 */
export const sendSocialConfirmation = () => {
    window.location = `${HOME_USER_URL}${SOCIAL_CONFIRMATION_URL}`;
};