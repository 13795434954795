import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    divRef: React.RefObject<HTMLDivElement>,
};

const NavWrapper: React.FC<Props> = ({ children, divRef }) => {
    const theme = useTheme();

    const style = css`
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        width: ${theme.breakpoints.sizes['xxl']}px;
        height: ${theme.sizes.menu.heightMain}px;
        background-color: ${theme.colours.white};

        ${theme.breakpoints.up('5xl')} {
            width: ${theme.breakpoints.sizes['5xl']}px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            width: ${theme.breakpoints.sizes['3xl']}px;
        }

        ${theme.breakpoints.down('xl')} {
            width: ${theme.breakpoints.sizes['xl']}px;
        }
        
        ${theme.breakpoints.down('lg')} {
            height: ${theme.sizes.menu.burgerHeightMain}px;
        }
    `;

    return (
        <div css={style} ref={divRef}>
            {children}
        </div>
    );
};

export default NavWrapper;