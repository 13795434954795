import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
    data: StandardPage,
    className?: string,
};

const FooterTopMenu: React.FC<Props> = ({ data, className = "" }) => (
    <div className={className}>
        <NavLink to={'/' + data.uRLSegment}>
            {data.titleForFooter ?? data.title}
        </NavLink>
    </div>
);

export default FooterTopMenu;