import React from "react";

import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";

import CollapseContainer from "elements/Components/CollapseList/CollapseContainer";
import Header from "elements/Components/CollapseList/Header";
import HeaderContainer from "elements/Components/CollapseList/HeaderContainer";
import HeaderBox from "elements/Components/CollapseList/HeaderBox";
import HeaderText from "elements/Components/CollapseList/HeaderText";
import SubContainer from "elements/Components/CollapseList/SubContainer";

type Props = {
    data: CollapseList
};

const CollapseList: React.FC<Props> = ({ data }) => (
    <SectionContainer>
        <Wrapper useMaxWidth={false} useMinHeight={false}>
            <CollapseContainer>
                <HeaderContainer>
                    <HeaderBox>
                        <Header listType={data.listType}>{data.header}</Header>
                        <HeaderText>{data.text}</HeaderText>
                    </HeaderBox>
                </HeaderContainer>

                <SubContainer data={data} />
            </CollapseContainer>
        </Wrapper>
    </SectionContainer>
);

export default CollapseList;
