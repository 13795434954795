import React, { useCallback, useState } from "react";

import Collapse from "core/Components/Collapse";
import SubChevron from "elements/Components/CollapseList/SubChevron";
import SubColumn from "elements/Components/CollapseList/SubColumn";
import SubRow from "elements/Components/CollapseList/SubRow";
import SubHeader from "elements/Components/CollapseList/SubHeader";
import SubText from "elements/Components/CollapseList/SubText";

type Props = {
    data: CollapseItem,
    autoNumbering: boolean,
    number: number,
};

const SubBox: React.FC<Props> = ({ data, autoNumbering, number }) => {
    const [toggle, setToggle] = useState(false);

    const toggleHandler = useCallback(() => {
        setToggle((prevToggle: boolean) => (!prevToggle));
    }, []);

    return (
        <>
            <SubRow>
                <SubColumn toggleHandler={toggleHandler}>
                    <SubHeader autoNumbering={autoNumbering} number={number}>{data.header}</SubHeader>
                    <SubChevron toggle={toggle} />
                </SubColumn>
            </SubRow>
            <Collapse isOpen={toggle}>
                <SubRow>
                    <SubColumn useCollapse>
                        <SubText>{data.text}</SubText>
                    </SubColumn>
                </SubRow>
            </Collapse>
        </>
    );
};

export default SubBox;
