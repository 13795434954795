import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const City: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        font-weight: ${theme.fonts.weights.bold};
        
        ${theme.breakpoints.down("xs") } {
            font-size: 16px;
            line-height: 25px;
        }
    `;

    return (
        <p css={style}>{children}</p>
    );
};

export default City;
