import React from "react";

import components from "elements/config/components";
import ProductList from "pages/Components/ProductListPage/components/ProductList";

type Props = {
    data: ProductListPage,
};

const ProductListPage: React.FC<Props> = ({ data }) => {
    return (
        <>
            {data?.elements && data.elements.map((ele: any, index: number) => {
                if (ele.__typename !== 'ProductListItem') {
                    let Component = components.hasOwnProperty(ele.__typename) && components[ele.__typename]
                    return <Component data={ele} key={`${ele.id}-${index}`} />
                }
                return <ProductList page={data} key={`${ele.id}-${index}`} />
            })}
        </>
    );
};

export default ProductListPage;