import React from "react";
import { css, useTheme } from "@emotion/react";
import Image from "core/Components/Image";
import { ReactComponent as PlayIcon } from "images/icons/play-triangle-with-circle.svg";
import { NOOP } from "core/constants";

type Props = {
    image: Image,
    onVideoClick?: () => void,
};

const MainImage: React.FC<Props> = ({ image, onVideoClick = NOOP }) => {
    const theme = useTheme();
    const radius = theme.borderAndShadow.largeRadius;

    const style = css`
        width: 560px;
        height: 560px;
        border-radius: ${radius}px;
        
        ${!image && css`
            box-shadow: ${theme.borderAndShadow.boxShadow};
        `}
        
        ${(image && onVideoClick) && css`
            cursor: pointer;
        `}
        
        ${theme.breakpoints.up('5xl')} {
            width: 653px;
            height: 653px;
        }
        
        ${theme.breakpoints.between('xxl', '4xl')} {
            width: 565px;
            height: 565px;
        }

        ${theme.breakpoints.only('xl')} {
            width: 499px;
            height: 499px;
        }

        ${theme.breakpoints.only('lg')} {
            width: 399.29px;
            height: 399.29px;
        }

        ${theme.breakpoints.only('md')} {
            width: 478px;
            height: 450px;
        }

        ${theme.breakpoints.down('sm')} {
            width: 318.24px;
            height: 299.6px;
        }
    `;

    const playIconStyle = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20%;
        z-index: ${theme.zIndex.zIndexOne};
        fill: ${theme.colours.raspberry};
        border-radius: 50%;
        will-change: transform;
    `;

    return (
        image ? (
            <div css={style} onClick={onVideoClick} className={"radius-box"}>
                {onVideoClick !== NOOP && (
                    <PlayIcon css={playIconStyle} />
                )}
                <Image
                    image={image}
                    borderRadius={radius}
                    useFocusPoint={true}
                />
            </div>
        ) : (
            <div css={style} />
        )
    );
};

export default MainImage;
