import React from "react";
import { css, useTheme } from "@emotion/react";
import { MdClose } from "react-icons/md";

type Props = {
    className?: string,
};

const CloseButton: React.FC<Props> = ({ className }) => {
    const theme = useTheme();

    const closeButtonStyle = css`
        position: absolute;
        top: -43px;
        right: -40px;
        color: ${theme.colours.white};
        
        &:hover {
            cursor: pointer;
        }

        > svg {
            width: 40px;
            height: 40px;
            fill: white;
            stroke: white;
        }
    `;

    return (
        <div css={closeButtonStyle} className={className}>
            <MdClose />
        </div>
    );
};

export default CloseButton;
