import React from "react";

import NavLinkTag from "core/Components/Navigations/NavLinkTag";
import BurgerNavSubContainer from "core/Components/HamburgerNavigations/large/BurgerNavSubContainer";
import BurgerNavSubTextBox from "core/Components/HamburgerNavigations/large/BurgerNavSubTextBox";
import BurgerNavHeaderTextBox from "core/Components/HamburgerNavigations/large/BurgerNavHeaderTextBox";

type Props = {
    headerMenu?: StandardPage,
    currentURLSegment?: string,
    selectedSegments?: string[],
    getSubMenu: Function,
    closeMenuHandler: Function,
};

const BurgerNavRight: React.FC<Props> = ({
    headerMenu = undefined,
    currentURLSegment = "",
    selectedSegments = undefined,
    getSubMenu,
    closeMenuHandler,
}) => {
    const submenu = getSubMenu(headerMenu);
    const hasSubMenu = !!submenu?.standardPage && (
        submenu.standardPage.length > 1 || (submenu.standardPage.length === 1 && submenu.standardPage[0].showInMenus)
    );

    return (
        <BurgerNavSubContainer showSubMenu={hasSubMenu}>
            {hasSubMenu && headerMenu && (
                <NavLinkTag data={headerMenu}>
                    <BurgerNavHeaderTextBox
                        headerMenu={headerMenu}
                        currentURLSegment={currentURLSegment}
                        closeMenuHandler={closeMenuHandler}
                    >
                        {headerMenu.title}
                    </BurgerNavHeaderTextBox>
                </NavLinkTag>
            )}

            {submenu?.standardPage?.map((menu: StandardPage) => (
                menu.showInMenus ? (
                    <NavLinkTag key={"burgerSubMenu" + menu.id} data={menu}>
                        <BurgerNavSubTextBox
                            subMenu={menu}
                            selectedSegments={selectedSegments}
                            closeMenuHandler={closeMenuHandler}
                        >
                            {menu.title}
                        </BurgerNavSubTextBox>
                    </NavLinkTag>
                ) : null
            ))}
        </BurgerNavSubContainer>
    );
};

export default BurgerNavRight;