import React, { ReactNode, } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    placeholderWidth?: number,
    showOptions?: boolean,
    chevronSize: number,
};

const DEFAULT_LEFT_PADDING = 32;
const DEFAULT_RIGHT_PADDING = 30;

const DropdownOptions: React.FC<Props> = ({ children, placeholderWidth = 0, showOptions = false, chevronSize }) => {
    const theme: Theme = useTheme();
    const totalPlaceholderWidth = placeholderWidth + chevronSize;

    const style = css`
        position: relative;
        padding: 17px ${DEFAULT_RIGHT_PADDING}px 20px ${DEFAULT_LEFT_PADDING}px;
        border-radius: 0 0 12px 12px;
        background-color: ${theme.colours.concrete2};
        min-width: ${totalPlaceholderWidth + DEFAULT_LEFT_PADDING + DEFAULT_RIGHT_PADDING}px;
        
        > li:not(:last-of-type) {
            margin-bottom: 10px;
        }
        
        ${!showOptions && css` display: none; `}
        
        ${placeholderWidth && css`
            left: calc((100% - ${totalPlaceholderWidth}px) / 2);
        `}

        ${theme.breakpoints.between("sm", "lg")} {
            position: fixed;
            left: calc(100% - ${theme.sizes.menu.sideMargin}px - ${totalPlaceholderWidth}px - ${DEFAULT_LEFT_PADDING}px);
        }
        
        ${theme.breakpoints.down("xs")} {
            position: fixed;
            left: calc(${theme.sizes.menu.sideMargin}px - ${DEFAULT_LEFT_PADDING}px);
            z-index: ${theme.zIndex.zIndexHamburgerMenu + 1};
        }
    `;

    return (
        <ul css={style}>
            {children}
        </ul>
    );
};

export default DropdownOptions;