import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Theme, useTheme, css } from "@emotion/react";
import { isEmpty } from "lodash";

import useForm, { FieldList } from "core/hooks/useForm";
import { useAppState } from "core/contexts/AppContext";
import Form from "core/Components/Form/Form";
import LabelledField from "core/Components/Form/LabelledField";
import Input from "core/Components/Form/Input";
import FormActions from "core/Components/Form/FormActions";
import LoadingButton from "core/Components/Buttons/LoadingButton";
import { login, sendPasswordRecovery, SocialLoginType } from "core/includes/auth";
import useElementDimensions from "core/hooks/useElementDimensions";
import SocialLogin from "pages/Components/auth/components/SocialLogin";
import LoginContainer from "pages/Components/auth/components/LoginContainer";
import LoginLeftContainer from "pages/Components/auth/components/LoginLeftContainer";
import LoginRightContainer from "pages/Components/auth/components/LoginRightContainer";
import ErrorSection from "pages/Components/auth/components/ErrorSection";
import loginImage from "images/login-image.png";

type Props = {
    socialSignUp: (user: any, socialLoginType: SocialLoginType) => void,
    redirectAfterSignIn: (user: any) => void,
    errorMessage?: string,
    onLoginDialogClose?: () => void,
    isLoginDialog?: boolean,
};

const validator = (fields:FieldList) => {
    const errors: any = {};

    if (!fields.email) {
        errors.email = "Please provide a email";
    }

    if (!fields.password) {
        errors.password = "Please provide a password";
    }

    return errors;
};

const SignIn: React.FC<Props> = ({
    socialSignUp,
    redirectAfterSignIn,
    errorMessage,
    onLoginDialogClose,
    isLoginDialog
}) => {
    const theme: Theme = useTheme();
    const [loginError, setLoginError] = useState({ isVisible: false, errorMessage: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [appState, setAppState] = useAppState();
    const navigate: any = useNavigate();
    const loginRef = useRef<any>();
    const buttonDimensions = useElementDimensions(loginRef.current);
    const [fields, errors, onFieldChange,, validate] = useForm({
        fields: { email: "", password: "" },
        validator,
    });

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            setLoginError({ isVisible: true, errorMessage: errorMessage || "" });
        }
    }, [errorMessage]);

    const submitSignInForm = () => {
        setLoginError({ isVisible: false, errorMessage: "" });
        let closeDialog = true;

        if (validate()) {
            setIsSubmitting(true);
            login(fields.email, fields.password).then(() => {
                appState.getUser().then((user: any) => redirectAfterSignIn(user));
            }).then(() => {
                setAppState({ authorized: true });
            }).catch((e: any) => {
                setIsSubmitting(false);
                setLoginError({ isVisible: true, errorMessage: e.message });
                closeDialog = false;
            }).finally(() => {
                if (closeDialog && !!onLoginDialogClose) {
                    onLoginDialogClose();
                }
            });
        }
    }

    /**
     * The error could be one of the following list:
     * User already connected
     * Fetching user
     * SDK not loaded
     * Unknown error
     */
    const handleLoginFailure = () => {
        setLoginError({ isVisible: true, errorMessage: "We couldn’t load social login. Make sure to enable third-party cookies." });
    }

    const pickASubscription = () => {
        navigate("/plans-and-pricing");
    }

    const clickForgotPassword = () => {
        sendPasswordRecovery();
    }

    const formContainerStyle = (isLoginDialog?: boolean) => css`
        ${isLoginDialog && css`
            ${theme.breakpoints.down("xs")} {
                margin-top: 0;
            }
        `};

        ${theme.breakpoints.up("5xl")} {
            margin-top: 18px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-top: 21px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-top: 13px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 16px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 8px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 6px;
        }
    `;

    const inputStyle = css`
        margin-top: 0;
        box-sizing: border-box;
        width: 100%;
    `;

    const signInText = css`
        font-size: 14px;
        font-weight: 100;
        text-align: center;
        
        a {
            text-decoration: none;
            color: ${theme.colours.blue["400"]}
        }
    
        a:hover {
            text-decoration: underline;
        }
    
        ${theme.breakpoints.down("sm")} {
            width: 100%;
            text-align: left;
        }
    `;

    const formActions = css`
        display: flex;
        flex-direction: row;
        justify-content: center;

        ${theme.breakpoints.up("5xl")} {
            margin: 63px 0 68px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin: 41px 0 45px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin: 29px 0 34px;
        }

        ${theme.breakpoints.only("md")} {
            margin: 21px 0 26px;
        }

        ${theme.breakpoints.only("sm")} {
            margin: 26px 0 31px;
        }

        ${theme.breakpoints.down("xs")} {
            margin: 19px 0 24px;
        }
    `;

    const formSubmitButton = css`
        border-radius: 45px;
        font-size: 14px;
        height: 40px;
        width: 400px;

        ${theme.breakpoints.down("xs")} {
            width: 297px;
        }
    `;

    const leftHeader = css`
        color: ${theme.colours.curiousBlue};
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up("xxl")} {
            font-size: 50px;
        }

        ${theme.breakpoints.between("md", "xl")} {
            font-size: 43px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 30px;
        }
    `;

    const signUpStyle = css`
        display: inline-block;
        color: ${theme.colours.curiousBlue};
        cursor: pointer;
        text-decoration: none;
    
        &:hover {
            text-decoration: underline;
        }
    `;

    const lineContainerStyle = css`
        display: flex;

        ${isLoginDialog && css`
            ${theme.breakpoints.up("5xl")} {
                margin-bottom: 35px;
            }
            
            ${theme.breakpoints.between("xxl", "4xl")} {
                margin-bottom: 22px;
            }

            ${theme.breakpoints.between("md", "xl")} {
                margin-bottom: 38px;
            }

            ${theme.breakpoints.only("sm")} {
                margin-bottom: 25px;
            }

            ${theme.breakpoints.down("xs")} {
                margin-bottom: 12px;
            }
        `};

        ${!isLoginDialog && css`
            ${theme.breakpoints.up("5xl")} {
                margin-bottom: 62px;
            }

            ${theme.breakpoints.between("xxl", "4xl")} {
                margin-bottom: 21px;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                margin-bottom: 19px;
            }

            ${theme.breakpoints.only("md")} {
                margin-bottom: 19px;
            }

            ${theme.breakpoints.only("sm")} {
                margin-bottom: 22px;
            }

            ${theme.breakpoints.down("xs")} {
                margin-bottom: 29px;
            }
        `};
    `;

    const customFormActionStyle = css`
        margin-top: 0;
    `;

    const lineTextStyle = css`
        padding: 0 28px;
        margin-top: -12px;
        color: ${theme.colours.silverChalice2};
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 18px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 14px;
        }

        ${theme.breakpoints.down("xl")} {
            font-size: 11px;
            margin-top: -8px;
        }
    `;

    const lineStyle = css`
        height: 1px;
        flex-grow: 1;
        background: ${theme.colours.silverChalice2};
    `;

    const imageStyle = css`
        max-width: 100%;
    `;

    const questionStyle = css`
        ${theme.breakpoints.up("3xl")} {
            margin-top: 67px;
            
            > div {
                margin-bottom: 22px;
            }
        }

        ${theme.breakpoints.between("lg", "xxl")} {
            margin-top: 53px;
        }

        ${theme.breakpoints.between("sm", "xxl")} {
            > div {
                margin-bottom: 17px;
            }
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 32px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 53px;

        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 46px;
            
            > div {
                margin-bottom: 28px;
            }
        }
    `;

    return (
        <LoginContainer isSubmitting={isSubmitting}>
            <LoginLeftContainer isLoginDialog={isLoginDialog}>
                {!isLoginDialog && <div css={leftHeader}>Log in</div>}
                <ErrorSection isVisible={loginError.isVisible} message={loginError.errorMessage} isLoginDialog={isLoginDialog} />
                <div css={formContainerStyle(isLoginDialog)}>
                    <Form onSubmit={submitSignInForm}>
                        <LabelledField name="email" error={errors.email} label="Email / Username" version={isLoginDialog ? "loginDialog" : "normal"}>
                            <Input hasError={!!errors.email} css={inputStyle} label="Enter email address or username" name="email" onChange={onFieldChange} value={fields.email} />
                        </LabelledField>
                        <LabelledField name="password" error={errors.password} label="Password" version={isLoginDialog ? "loginDialog" : "normal"}>
                            <Input hasError={!!errors.password} css={inputStyle} label="Password" type="password" name="password" onChange={onFieldChange}
                                   value={fields.password} />
                        </LabelledField>
                        <div css={formActions}>
                            <FormActions buttonRef={loginRef} customCss={customFormActionStyle}>
                                <LoadingButton
                                    color={"secondary"}
                                    css={formSubmitButton}
                                    type="submit"
                                    forceLoading={isSubmitting}
                                    rounded
                                >
                                    Log in
                                </LoadingButton>
                            </FormActions>
                        </div>
                        <div css={lineContainerStyle}>
                            <div css={lineStyle}/>
                            <span css={lineTextStyle}>Or</span>
                            <div css={lineStyle}/>
                        </div>
                    </Form>
                    <SocialLogin
                        socialSignUp={socialSignUp}
                        handleLoginFailure={handleLoginFailure}
                        buttonWidth={buttonDimensions.width}
                    />
                    {!isLoginDialog &&
                        <div css={questionStyle}>
                            <div css={signInText}>Don't have an account? Check out our <div onClick={pickASubscription} css={signUpStyle}>subscriptions.</div></div>
                            <div css={signInText}>Forgot your password? Please <div onClick={clickForgotPassword} css={signUpStyle}>click here.</div></div>
                        </div>
                    }
                </div>
            </LoginLeftContainer>
            {!isLoginDialog &&
                <LoginRightContainer>
                    <img src={loginImage} css={imageStyle} alt={"Login"} />
                </LoginRightContainer>
            }
        </LoginContainer>
    );
};

export default SignIn;