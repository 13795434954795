import React from 'react';
import { css } from "@emotion/react";
import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const PriceUnitContainer: React.FC<Props> = ({
    children,
}) => {
    const containerStyle = css`
        display: flex;
        align-items: flex-end;
        padding-bottom: 11px;
    `;

    return (
        <ContentSection css={containerStyle}>
            {children}
        </ContentSection>
    );
}

export default PriceUnitContainer;
