import React from 'react';
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const QuarterContainer: React.FC<Props> = ({
    children,
}) => {
    const quarterContainerStyle = css`
        width: 100%;
        height: 100%;
        position: relative;
    `;

    return (
        <div css={quarterContainerStyle}>
            {children}
        </div>
    )
}

export default QuarterContainer;
