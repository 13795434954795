import React, { memo } from "react";
import { css, useTheme } from "@emotion/react";
import { Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "pages/includes/apollo";
import RedirectPage from "pages/RedirectPage";
import { LOGIN_URL } from "core/config/config";

const AuthPage: React.FC = () => {
    const theme = useTheme();

    const authPage = css`
        padding-top: ${theme.sizes.menu.heightMain}px;
        font-family: ${theme.fonts.frutiger};
        display: flex;
        flex-direction: column;
        align-items: center;

        ${theme.breakpoints.down("lg")} {
            padding-top: ${theme.sizes.menu.burgerHeightMain}px;
        }
    `;

    return (
        <ApolloProvider client={client}>
            <div css={authPage}>
                <Routes>
                    <Route path={"/login"} element={<RedirectPage url={LOGIN_URL} />} />
                </Routes>
            </div>
        </ApolloProvider>
    );
};

export default memo(AuthPage);