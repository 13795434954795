import React from "react";
import { isEmpty } from "lodash";

import Image from "core/Components/Image";
import SectionContainer from "core/Components/SectionContainer";

import Wrapper from "elements/Components/DescriptionWithRichTextAndImage/components/Wrapper";
import CircleBackground from "elements/Components/DescriptionWithRichTextAndImage/components/CircleBackground";
import Circle from "elements/Components/DescriptionWithRichTextAndImage/components/Circle";
import ImageContainer from "elements/Components/DescriptionWithRichTextAndImage/components/ImageContainer";
import ContentContainer from "elements/Components/DescriptionWithRichTextAndImage/components/ContentContainer";
import ImageSectionContainer from "elements/Components/DescriptionWithRichTextAndImage/components/ImageSectionContainer";
import TextSectionContainer from "elements/Components/DescriptionWithRichTextAndImage/components/TextSectionContainer";
import Header from "elements/Components/DescriptionWithRichTextAndImage/components/Header";
import Subheader from "elements/Components/DescriptionWithRichTextAndImage/components/Subheader";
import Text from "elements/Components/DescriptionWithRichTextAndImage/components/Text";

type Props = {
    data: DescriptionWithRichTextAndImage
};

const DescriptionWithRichTextAndImage: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const { firstCircleColour, secondCircleColour } = colourTheme;

    return isEmpty(data.header) ? null : (
        <SectionContainer>
            <Wrapper>
                <ContentContainer>
                    <ImageSectionContainer>
                        {data.image && (
                            <>
                                <CircleBackground>
                                    <Circle type1 firstCircleColour={firstCircleColour} />
                                    <Circle type2 secondCircleColour={secondCircleColour} />
                                </CircleBackground>

                                <ImageContainer>
                                    <Image image={data.image} useFocusPoint={data.useFocusPoint} />
                                </ImageContainer>
                            </>
                        )}
                    </ImageSectionContainer>

                    <TextSectionContainer>
                        <Header data={data} />
                        <Subheader data={data} />
                        <Text data={data} />
                    </TextSectionContainer>
                </ContentContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default DescriptionWithRichTextAndImage;
