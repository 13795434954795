// For an explanation on policy details, see:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
// and
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-errorPolicy
import { onError } from "@apollo/client/link/error";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import window from 'core/includes/window';
import { AUTH_API_BASE_URL } from "core/config/config";

const defaultOptions: any = {
    watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
    },
    query: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
    },
};

//Global error handle for when a user is logged out.  Redirects to login page.
const errorHandlerLink: any = onError((errorHandler: any) => {
    const networkError: any = errorHandler.networkError;

    if (networkError && networkError.statusCode === 401) {

        let event = new Event("authError");
        window.dispatchEvent(event);

        // capture response information for network errors
    } else if (networkError && networkError.response) {
        let body = networkError.bodyText || "";

        // limit length, max size of sentry logging request is 100kB
        if (body.length >= 5000) {
            body = body.substring(0, 5000);
        }
    }
});

const createClient: any = () => new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: ApolloLink.from(
        [
            errorHandlerLink,
            new HttpLink({ uri: `${AUTH_API_BASE_URL}/graphql` }),
        ]
    ),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export default createClient();