import React from 'react';
import { createRoot, hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
import App from './App';
import createClient from "core/includes/apollo";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const emotionCache = createCache({key: 'css'});
const client = createClient();
const container = document.getElementById('root') as HTMLElement;

const app = (
    <React.StrictMode>
        <HelmetProvider>
            <CacheProvider value={emotionCache}>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ApolloProvider>
            </CacheProvider>
        </HelmetProvider>
    </React.StrictMode>
);

if (container.hasChildNodes()) {
    // @ts-ignore
    hydrateRoot(container, app);
} else {
    const root = createRoot(container);
    root.render(app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
