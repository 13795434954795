import React from "react";
import { css, useTheme } from "@emotion/react";

import BurgerNavButtonContainer from "core/Components/HamburgerNavigations/BurgerNavButtonContainer";
import BurgerNavSmallMenuBox from "core/Components/HamburgerNavigations/small/BurgerNavSmallMenuBox";

type Props = {
    headerMenuAll?: [StandardPage],
    menuButton?: Button,
    selectedHeaderMenu?: StandardPage,
    currentURLSegment?: string,
    selectedSegments?: string[],
    getSubMenu: Function,
    setSubMenu: Function,
    closeMenuHandler: Function,
};

const BurgerNavSmallContainer: React.FC<Props> = ({
    headerMenuAll = undefined,
    menuButton = undefined,
    selectedHeaderMenu = undefined,
    currentURLSegment = "",
    selectedSegments = undefined,
    getSubMenu,
    setSubMenu,
    closeMenuHandler,
}) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        background-color: ${theme.colours.curiousBlue};
        box-shadow: ${theme.borderAndShadow.boxInsetTopShadow1};
        overflow: auto;
        min-height: 100%;
        
        ${theme.breakpoints.up("lg")} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            {headerMenuAll && headerMenuAll.map((menu: StandardPage) => (
                menu.showInMenus ? (
                    <BurgerNavSmallMenuBox
                        key={"burgerSmallMenuBox" + menu.id}
                        headerMenu={menu}
                        selectedHeaderMenu={selectedHeaderMenu}
                        currentURLSegment={currentURLSegment}
                        selectedSegments={selectedSegments}
                        getSubMenu={getSubMenu}
                        setSubMenu={setSubMenu}
                        closeMenuHandler={closeMenuHandler}
                    />
                ) : null
            ))}

            <BurgerNavButtonContainer menuButton={menuButton} closeMenuHandler={closeMenuHandler} />
        </div>
    );
};

export default BurgerNavSmallContainer;
