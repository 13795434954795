import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";


type Props = {
    data: ContentWithImage,
    children: React.ReactNode,
};


const Wrapper: React.FC<Props> = ({ data, children }) => {
    const theme: Theme = useTheme();

    const layout = JSON.parse(data.layout);
    const { isImageFirst, isBiggerImage, isHalfFullImage } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';

    const wrapperForLargeImageStyle = css`
        padding: 43px 0 42px;

        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
            padding: 10px 182px;
        }

        ${theme.breakpoints.up("xxl")} {
            min-height: 600px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 60px 80px 45px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 87px 79px 86px ${isImageFirst ? 81 : 80}px;
            min-height: 547px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 20px ${isImageFirst ? 44 : 45}px 20px 45px;
            min-height: 550px;
        }

        ${theme.breakpoints.only("md")} {
            min-height: 420px;
            padding: 47px 45px 34px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 46px 45px 46px 44px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 0 20px 78px;
        }

        ${theme.breakpoints.down("sm")} {
            min-height: 340px;
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
        }

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
    `;

    const wrapperForHalfFullImageStyle = css`
        padding: ${isImageFirst ? '0 25px 0 0' : '0 0 0 25px'};
        
        ${theme.breakpoints.up("5xl")} {
            padding: ${isImageFirst ? '0 59px 0 0' : '0 0 0 59px'};
        }

        ${theme.breakpoints.only("xl")} {
            padding: ${isImageFirst ? '0 18px 0 0' : '0 0 0 18px'};
        }

        ${theme.breakpoints.only("lg")} {
            padding: ${isImageFirst ? '0 27px 0 0' : '0 0 0 27px'};
        }

        ${theme.breakpoints.only("md")} {
            min-height: 1024px;
            padding: 31px 44px 0;
        }

        ${theme.breakpoints.only("sm")} {
            min-height: 840px;
            padding: 24px 44px 0;
        }

        ${theme.breakpoints.down("xs")} {
            min-height: 720px;
            padding: 20px 20px 0;
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
    `;

    const wrapperForSmallImageStyle = css`
        padding: 143px 93px;
        
        ${theme.breakpoints.up("5xl")} {
            padding: 171px 202px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 143px 128px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 87px 86px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 131px 56px;
        }

        ${theme.breakpoints.only("md")} {
            min-height: 1024px;
            padding: 60px 62px 100px;
        }

        ${theme.breakpoints.only("sm")} {
            min-height: 840px;
            padding: 55px 44px 90px;
        }

        ${theme.breakpoints.down("xs")} {
            min-height: 720px;
            padding: 50px 20px 60px;
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
    `;

    const wrapperStyle = imageSize === 'large' ? wrapperForLargeImageStyle : imageSize === 'small' ? wrapperForSmallImageStyle : wrapperForHalfFullImageStyle;


    return (
        <WrapperFromCore css={wrapperStyle}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
