import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    isBoldSubheader: boolean,
    subheaderColour: string,
};

const SubHeader: React.FC<Props> = ({
    children,
    isBoldSubheader,
    subheaderColour,
}) => {
    const theme = useTheme();

    const style = css`
        margin-bottom: 40px;
        justify-content: start;

        ${isBoldSubheader && css`
            font-weight: ${theme.fonts.weights.bold};
        `};

        ${subheaderColour && css`
            color: ${subheaderColour};
        `}

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 55px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 50px;
        }
        
        ${theme.breakpoints.only("lg")} {
            margin-bottom: 30px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 40px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 23px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 20px;
        }
    `;

    return (
        <ContentCombine Tag={"h2"} css={style}>{children}</ContentCombine>
    );
};

export default SubHeader;
