import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: ContentWithImage,
    className?: string,
};

const Description: React.FC<Props> = ({ data, className }) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { textColour, anchorColour } = colourTheme;

    const contentTextStyle = css`
        margin-bottom: 40px;

        ${textColour && css`
            color: ${textColour};
        `}

        ${theme.breakpoints.up("3xl")} {
            margin-bottom: 20px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 50px;
        }

        ${theme.breakpoints.down('lg')} {
            font-size: 14px;
            line-height: 20px;
        }
        
        ${theme.breakpoints.only("lg")} {
            margin-bottom: 26px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 24px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 22px;
        }
    `;

    return (
        <ContentCombine
            Tag={"p"}
            fontSizeType={"9"}
            css={contentTextStyle}
            className={className}
            anchorColour={anchorColour}
        >
            {data.text}
        </ContentCombine>
    );
};

export default Description;
