import React from "react";

import CardSection from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardSection";
import CardWithImage from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardWithImage";
import CardWithList from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardWithList";

type Props = {
    card: ImageVerticalCard,
    isImageFirst: boolean,
    setHubspotFormId: (id: string) => void,
    setHubspotFormTitle: (title: string) => void,
    setVideoPath: (videoPath: string) => void,
    onShowPopupClick: () => void,
};

const Card: React.FC<Props> = ({
    card,
    isImageFirst,
    setHubspotFormId,
    setHubspotFormTitle,
    setVideoPath,
    onShowPopupClick,
}) => {
    const isCardWithList = card.layoutType === 'list-card';

    return (
        <CardSection card={card} isImageFirst={isImageFirst} isCardWithList={isCardWithList}>
            {isCardWithList ? (
                <CardWithList
                    card={card}
                    setHubspotFormId={setHubspotFormId}
                    setHubspotFormTitle={setHubspotFormTitle}
                    onShowPopupClick={onShowPopupClick}
                />
            ) : (
                <CardWithImage card={card} setVideoPath={setVideoPath} />
            )}
        </CardSection>
    );
}

export default Card;
