import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const RightTextContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        margin: 67px auto 0;

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-top: 60px;
        }

        ${theme.breakpoints.between('lg', 'xxl')} {
            margin-top: 50px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 62px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 38px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 40px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default RightTextContainer;