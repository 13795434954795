import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: DescriptionWithRichTextAndImage
};

const Header: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { headerColour } = colourTheme;

    const headerStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        margin-bottom: 24px;

        ${headerColour && css`
            color: ${headerColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 35px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 30px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 35px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 12px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 17px;
        }

        ${theme.breakpoints.down("md")} {
            text-align: center;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 20px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 15px;
        }
    `;

    return (
        <Title Tag={'h2'} css={headerStyle}>{data.header}</Title>
    );
}

export default Header;
