// For an explanation on policy details, see:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
// and
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-errorPolicy
import { onError } from "@apollo/client/link/error";
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, DefaultOptions } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import * as authService from "./auth";
import { API_BASE_URL, API_HEADERS, AUTH_API_BASE_URL } from "core/config/config";
import { decrypt } from "core/includes/crypto";
import window from "core/includes/window";

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "none",
    },
    query: {
        fetchPolicy: "cache-first",
        errorPolicy: "none",
    },
};

//Global error handle for when a user is logged out.  Redirects to login page.
const errorHandlerLink: any = onError((errorHandler: any) => {
    const networkError: any = errorHandler.networkError;

    if (networkError && networkError.statusCode === 401) {

        let event = new Event("authError");
        window.dispatchEvent(event);

        // capture response information for network errors
    } else if (networkError && networkError.response) {
        let body = networkError.bodyText || "";

        // limit length, max size of sentry logging request is 100kB
        if (body.length >= 5000) {
            body = body.substring(0, 5000);
        }
    }
});

/**
 * Adds custom headers to be passed to backend api. Uses function so headers can either be passed in config
 * or in createClient call.
 *
 * @param apiHeaders
 */
const headersLink = (
    apiHeaders: RequestHeaders|undefined,
) => setContext((_, prevContext) => {
    const { headers } = prevContext;
    let newHeaders = { ...headers };

    if (!headers?.referer) {
        newHeaders.referer = 'https://client.writerstoolbox.com';
    }

    if (apiHeaders && Object.keys(apiHeaders).length > 0) {
        newHeaders = { ...newHeaders, ...apiHeaders };
    }

    return {
        headers: newHeaders
    }
});

/**
 * Adds bearer token to apollo requests.
 *
 * @type {ApolloLink}
 */
const authMiddleware: any = setContext(async () => {
    let token = authService.getToken();

    if (token && token.token) {
        if (!authService.hasTokenExpired(token)) {
            // refresh token if it needs refreshing
            if (authService.shouldTokenRefresh(token)) {
                try {
                    token = await authService.refreshToken(token);
                } catch (e) {
                    // do something
                }
            }

            // add the authorization to the headers
            return {
                headers: authService.addTokenHeader(token, {}),
                credentials: 'omit'
            };
        }
    }

    return {
        credentials: 'omit'
    };
});

/**
 * Create the apollo client. Pass through whether it is being used for ssr. Optionally pass through headers which
 * should be passed to the api.
 *
 * @param {boolean} ssrMode
 * @param {RequestHeaders|undefined} apiHeaders
 */
const createClient: any = (
    ssrMode: boolean = false,
    apiHeaders: RequestHeaders|undefined = undefined,
) => {
    let cache = new InMemoryCache();

    const initialState: {}|null = window?.__APOLLO_STATE__ ?
        JSON.parse(decrypt(window.__APOLLO_STATE__)) :
        null;

    if (initialState) {
        cache = cache.restore(initialState);
    }

    return new ApolloClient({
        // By default, this client will send queries to the
        //  `/graphql` endpoint on the same host
        // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
        // to a different host
        link: ApolloLink.from(
            [
                authMiddleware,
                errorHandlerLink,
                headersLink(apiHeaders || API_HEADERS),
                ApolloLink.split(
                    (operation) => operation.getContext().clientName === "auth",
                    new HttpLink({ uri: `${AUTH_API_BASE_URL}/graphql` }),
                    new HttpLink({ uri: `${API_BASE_URL}/graphql` }),
                ),
            ]
        ),
        ssrForceFetchDelay: ssrMode ? 0 : 100,
        defaultOptions: defaultOptions,
        cache,
        ssrMode,
    });
};

export default createClient;