import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const HeaderBox: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        margin: 0 auto;
        width: 1240px;
        
        ${theme.breakpoints.up("5xl")} {
            padding: 58px 0;
        }
        
        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 86px 0;
        }
        
        ${theme.breakpoints.only("xxl")} {
            padding: 87px 0;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 76px 0;
            width: 1120px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 59px 0;
            width: 930px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 76px 0;
            width: 676px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 34px 0;
            width: 478px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 53px 0;
            width: 313px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default HeaderBox;