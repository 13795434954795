import React from "react";
import { useTheme, css } from "@emotion/react";

type Props = {
    error: string,
};

const ErrorPageText: React.FC<Props> = ({ error = "404" }) => {
    const theme = useTheme();

    const text = error === "404" ?
        "It seems that the page you’re trying to go to doesn’t exist." :
        "Something went wrong on our server. Try again in a moment";

    const textStyle = css`
        color: ${theme.colours.mineShaft};
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 38px;
        text-align: center;

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 44px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 16px;
            line-height: 33px;
            margin-bottom: 30px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 23px;
        }
    `;

    return (
        <div css={textStyle}>
            {text}
        </div>
    );
};

export default ErrorPageText;
