import React from "react";
import { css, useTheme } from "@emotion/react";
import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    children: React.ReactNode,
    linkURL?: string,
    textColour?: string,
    backgroundColour?: string,
};

const Button: React.FC<Props> = ({
    children,
    linkURL,
    textColour,
    backgroundColour,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
    `;

    const buttonStyle = css`
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 48px;
        }
        
        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-bottom: 59px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 53px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 48px;
        }
        
        ${theme.breakpoints.only("md")} {
            margin-bottom: 29px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 37px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 25px;
        }
    `;

    const buttonTextStyle = css`
        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
            line-height: 26px;
        }
        
        ${theme.breakpoints.between("md", "4xl")} {
            font-size: 20px;
            line-height: 21px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
            line-height: 21px;
        }
    `;

    return (
        <div css={style}>
            <RoundButton
                textColour={textColour}
                backgroundColour={backgroundColour}
                linkURL={linkURL}
                css={buttonStyle}
                buttonTextCustomStyle={buttonTextStyle}
            >
                {children}
            </RoundButton>
        </div>
    );
};

export default Button;
