import { useQuery } from "@apollo/client";
import {useLocation} from "react-router";

import { useAppState } from "core/contexts/AppContext";
import errorParser from "core/includes/errorParser";
import { getURLSegment } from "core/includes/formatters";
import { getCanRenderWebp } from "core/includes/image";
import { retrieveValue } from "core/includes/localStorage";
import pageQuery from "core/includes/queries";

/**
 * Looks at the url and requests the page data from the backend.
 */
const useRouter = (stage: string | null) => {
    const location = useLocation();
    const url = getURLSegment(location.pathname);
    const [appState] = useAppState();
    const canRenderWebp = getCanRenderWebp();

    const { data, loading, error } = useQuery(pageQuery, {
        variables: { urlSegment: url, stage: stage, canRenderWebp },
        context: {
            headers: {
                'Selected-Country': appState.countryCode.code || (retrieveValue("selected-country")?.replace(/"/g, "") ?? "")
            }
        },
    });

    let errorToReturn = null;

    if (error) {
        errorToReturn = errorParser(error);
    }

    return [data, loading, errorToReturn];
};

export default useRouter;