import topLevel from './window';
import minDocument from "min-document";

topLevel['__GLOBAL_DOCUMENT_CACHE@4'] = minDocument;

export default ((): any => {
    if (typeof document !== 'undefined') {
        return document;
    }

    return topLevel['__GLOBAL_DOCUMENT_CACHE@4'];
})();