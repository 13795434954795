import React from "react";
import { css } from "@emotion/react";
import { isEmpty } from "lodash";

import { sort } from "core/includes/sort";
import useSetState from "core/hooks/useSetState";

import SectionContainer from "core/Components/SectionContainer";
import Subheader from "core/Components/Elements/Subheader";
import TextBox from "core/Components/Elements/TextBox";
import FormModal from "core/Components/FormModal";
import MainHeading from "core/Components/Item/MainHeading";
import HubspotForm from "core/Components/HubspotForm";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoPlayer from "core/Components/Video/VideoPlayer";

import Card from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/Card";
import CardListContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/CardListContainer";
import ContentWrapper from "elements/Components/ContentWithImageVerticalCards/compopnents/ContentWrapper";
import Header from "elements/Components/FullImage/components/Header";

type Props = {
    data: ContentWithImageVerticalCards
};

type DefaultState = {
    showingPopup: boolean,
    hubspotFormId: string,
    hubspotFormTitle: string,
    videoPath?: string,
}

const INITIAL_STATE = {
    showingPopup: false,
    hubspotFormId: "",
    hubspotFormTitle: "",
    videoPath: "",
}

const ContentWithImageVerticalCards: React.FC<Props> = ({ data }) => {
    const verticalCardList = sort(data.verticalCardList);
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const onShowPopupClick = () => setState({ showingPopup: true });
    const onClosePopupClick = () => setState({ showingPopup: false });
    const setHubspotFormId = (id: string) => setState({ hubspotFormId: id });
    const setHubspotFormTitle = (title: string) => setState({ hubspotFormTitle: title });
    const setVideoPath = (videoPath: string) => setState({ videoPath });
    const clearVideoPath = () => setState({ videoPath: "" });

    const headerStyle = css`
        text-align: center;
    `;

    return isEmpty(verticalCardList) ? null : (
        <SectionContainer data={data}>
            <ContentWrapper>
                {(data.header || data.subheader) && (
                    <TextBox>
                        {data.header && (
                            <Header css={headerStyle}>{data.header}</Header>
                        )}

                        {data.header && (
                            <Subheader>{data.subheader}</Subheader>
                        )}
                    </TextBox>
                )}
                <CardListContainer cardCount={verticalCardList.length}>
                    {verticalCardList.map((card: ImageVerticalCard, index: number) => (
                        <Card
                            key={`card-${index}`}
                            card={card}
                            isImageFirst={data.isImageFirst}
                            setHubspotFormId={setHubspotFormId}
                            setHubspotFormTitle={setHubspotFormTitle}
                            setVideoPath={setVideoPath}
                            onShowPopupClick={onShowPopupClick}
                        />
                    ))}
                </CardListContainer>
            </ContentWrapper>

            {state.videoPath && (
                <VideoViewContainer clearPlayVideo={clearVideoPath}>
                    <VideoViewLayer>
                        <VideoPlayer videoPath={state.videoPath} autoPlay />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}

            {state.hubspotFormId && (
                <FormModal isVisible={state.showingPopup} onClose={onClosePopupClick}>
                    <MainHeading text={state.hubspotFormTitle} />
                    <HubspotForm hubspotFormId={state.hubspotFormId} />
                </FormModal>
            )}
        </SectionContainer>
    );
}

export default ContentWithImageVerticalCards;
