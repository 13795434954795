import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    textPosition?: string,
    className?: string,
};

const TextBox: React.FC<Props> = ({ children, textPosition = "outside", className = "" }) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        
        ${textPosition === "inside" && css`
            position: absolute;
            z-index: 1;
        `}
        
        ${theme.breakpoints.up("3xl")} {
            padding: 35px 240px 17px;
        }
        
        ${theme.breakpoints.only("xxl")} {
            padding: 25px 186px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 35px 80px 21px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 12px 42px 17px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 12px 22px 7px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 16px 45px 17px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 18px 22px 17px;
        }
    `;

    return (
        <div css={style} className={className}>
           {children}
        </div>
    );
};

export default TextBox;