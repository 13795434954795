import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    colour?: string,
    className?: string,
};

const Wrapper: React.FC<Props> = ({
    children,
    colour,
    className,
}) => {
    const theme = useTheme();

    const style = css`
        margin-bottom: 58px;
        
        ${colour && css`
            color: ${colour};
        `}

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 45px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 34px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 26px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 21px;
        }
        
        ${theme.breakpoints.only("md")} {
            margin-bottom: 17px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 28px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default Wrapper;
