import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    imageWidth: number,
    carouselCellSpacing: number,
    numberOfItems: number,
};

const QuoteCarouselWrapper: React.FC<Props> = ({
    children,
    className,
    imageWidth,
    carouselCellSpacing,
    numberOfItems,
}) => {
    const theme = useTheme();

    const style = css`
        margin: 0 auto;

        ${theme.breakpoints.up("lg")} {
            width: ${(imageWidth * Math.min(numberOfItems, 3)) + (carouselCellSpacing * Math.min(numberOfItems, 3))}px;
        }

        ${theme.breakpoints.down("md")} {
            width: ${imageWidth + carouselCellSpacing}px;
        }
        
        ${theme.breakpoints.between('sm', 'md')} {
            margin-bottom: 40px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 30px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default QuoteCarouselWrapper;
