import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";
import useBreakpoint from "core/hooks/useBreakpoint";
import { isDown } from "theme/breakpoints";

type Props = {
    children: React.ReactNode,
    hasCardImage: boolean,
};

const CardButtonContainer: React.FC<Props> = ({ children, hasCardImage }) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpoint();

    const CardButtonContainerComponent = (!hasCardImage && isDown(breakpoint, 'md')) ?
        'div' : ContentSection;

    const buttonContainerStyle = css`
        position: absolute;
        top: calc(100% / ${hasCardImage ? 2 : 1} - 21px);

        ${theme.breakpoints.between("sm", "md")} {
            top: unset;
            width: fit-content;
            height: fit-content;
            left: 13px;
            bottom: 24px;
        }

        ${theme.breakpoints.only("sm")} {
            left: 11px;
            bottom: 13px;
        }

        ${theme.breakpoints.down("xs")} {
            top: calc(100% / ${hasCardImage ? 2 : 1} - 19px);
        }
    `;

    return (
        <CardButtonContainerComponent css={buttonContainerStyle}>
            {children}
        </CardButtonContainerComponent>
    );
}

export default CardButtonContainer;
