import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Wrapper from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
};

const ContentWrapper: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        flex-direction: column;
        padding: 60px 0;

        ${theme.breakpoints.down("lg")} {
            padding: 40px 0;
        }
    `;

    return (
        <Wrapper css={wrapperStyle} useMinHeight={false}>
            {children}
        </Wrapper>
    );
}

export default ContentWrapper;
