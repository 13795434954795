import React, { memo } from "react";
import { css, SerializedStyles, Theme, useTheme } from "@emotion/react";

type Version = "normal" | "loginDialog";

type Props = {
    children?: React.ReactNode,
    error?: string,
    label?: string,
    name: string,
    version?: Version,
    errorAsterisk?: boolean,
    extraErrorStyles?: SerializedStyles,
    className?: string,
    customLabel?: any,
};

const LabelledField: React.FC<Props> = ({
    className,
    children,
    error,
    label,
    name,
    version = "normal",
    errorAsterisk = false,
    extraErrorStyles,
    customLabel = ""
}) => {
    const theme: Theme = useTheme();

    const styles = {
        container: (version?: Version, name?: string) => css`
            position: relative;
            margin-bottom: ${theme.forms.spacing.marginBottom};
    
            &:last-child {
                margin-bottom: 0;
            }
    
            ${version === "loginDialog" && css`
                margin-bottom: 2px;
    
                ${name === "password" && css` margin-top: 22px; `};
            `}
        `,

        error: (extraErrorStyles?: SerializedStyles) => css`
            position: absolute;
            top: 100%;
            color: ${theme.colours.crimson};
            white-space: nowrap;

            ${theme.breakpoints.up("xl")} {
                font-size: 14px;
            }

            ${theme.breakpoints.only("lg")} {
                font-size: 12px;
            }

            ${theme.breakpoints.only("md")} {
                font-size: 14px;
            }

            ${theme.breakpoints.down("sm")} {
                font-size: 12px;
            }
    
            ${extraErrorStyles};
        `,

        label: () => css`
            ${theme.forms.label};
        `,

        asterisk: (errorAsterisk?: boolean, hasError?: string) => css`
            display: ${errorAsterisk ? "unset" : "none"};
            color: ${hasError ? theme.colours.crimson : "unset"};
        `,
    };

    return (
        <div className={className} css={styles.container(version, name)}>
            {!!label && (
                <label css={styles.label} htmlFor={name}>{label}<span css={styles.asterisk(errorAsterisk, error)}>*</span></label>
            )}
            {!!customLabel && (
                <label css={styles.label} htmlFor={name}><span dangerouslySetInnerHTML={{__html: customLabel}} />
                    <span css={styles.asterisk(errorAsterisk, error)}>*</span>
                </label>
            )}
            {children}
            {error && (
                <label
                    css={styles.error(extraErrorStyles)}
                    htmlFor={name}
                    className={'error-label'}
                >
                    {error}
                </label>
            )}
        </div>
    );
};

export default memo(LabelledField);
