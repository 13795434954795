import React, { useEffect, useMemo, useRef } from "react";
import { useTheme } from "@emotion/react";

import useSetState from "core/hooks/useSetState";
import useBreakpoint from "core/hooks/useBreakpoint";

import FeatureArea from "elements/Components/SubscriptionPlan/components/Features/FeatureArea";
import FeatureBox from "elements/Components/SubscriptionPlan/components/Features/FeatureBox";
import FeatureContent from "elements/Components/SubscriptionPlan/components/Features/FeatureContent";
import FeatureContentContainer from "elements/Components/SubscriptionPlan/components/Features/FeatureContentContainer";
import FeatureContentWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureContentWrapper";
import FeatureColourBox from "elements/Components/SubscriptionPlan/components/Features/FeatureColourBox";
import FeatureColourBoxWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureColourBoxWrapper";
import FeatureTarget from "elements/Components/SubscriptionPlan/components/Features/FeatureTarget";
import FeatureTitle from "elements/Components/SubscriptionPlan/components/Features/FeatureTitle";
import FeatureTitleBox from "elements/Components/SubscriptionPlan/components/Features/FeatureTitleBox";
import FeatureTitleWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureTitleWrapper";
import FeatureWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureWrapper";
import OuterFeatureTitleWrapper from "elements/Components/SubscriptionPlan/components/Features/OuterFeatureTitleWrapper";

const featuresAndAuthorities = [
    { features: "Artificial Intelligence writing feedback", personal: true, education: true },
    { features: "Dr Hunter’s tutorial videos", personal: true, education: true },
    { features: "Structured learning journey and learning modules", personal: true, education: true },
    { features: "Eight unique writing tools", personal: true, education: true },
    { features: "Individual writing performance page", personal: true, education: true },
    { features: "Writing activities and quizzes", personal: true, education: true },
    { features: "Reading comprehension activities", personal: true, education: true },
    { features: "Daily writing tasks", personal: true, education: true },
    { features: "Mind mapping tool", personal: true, education: true },
    { features: "Planning tools", personal: true, education: true },
    { features: "Folder architecture for all work", personal: true, education: true },
    { features: "Instructional videos", personal: true, education: true },
    { features: "Initial writing milestone test", personal: true, education: true },
    { features: "Sentence spinner", personal: true, education: true },
    { features: "Writing notebook function", personal: true, education: true },
    { features: "Create and manage classes", personal: false, education: true },
    { features: "View student writing profiles", personal: false, education: true },
    { features: "Full task creation and marking", personal: false, education: true },
    { features: "Task sharing with other teachers", personal: false, education: true },
    { features: "Standard school leadership reporting tools", personal: false, education: true },
    { features: "Mark student work", personal: false, education: true },
    { features: "Writer’s Scorecard Report Card", personal: false, education: false },
    { features: "Administer own school diagnostic testing", personal: false, education: false },
    { features: "Conduct digital examinations and mock exams", personal: false, education: false },
    { features: "Advanced school leadership reporting tools, including tracking priority", personal: false, education: false },
];

type DefaultState = {
    extraPaddingTop: number,
    height: number,
};

const INITIAL_STATE = {
    extraPaddingTop: 85,
    height: 57,
};

const DEFAULT_TITLES = {
    feature: "Features",
    personal: "Personal / Family",
    education: "Education Standard",
    featureTarget: "License",
    personalTarget: "1 - 6 Accounts",
    educationTarget: "School only",
}

const Features = () => {
    const theme = useTheme();
    const contentRef = useRef<any>();
    const topTitleRef = useRef<any>();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const { maxWidth, minWidth } = useBreakpoint();

    const [
        featurePaddingTop,      // padding-top of the FeatureWrapper
        featureBoxWidth,        // The box width of the Feature
        otherBoxWidth,          // The box width of the Personal & the Education
        featureInnerBoxWidth,   // The text area width of the Feature
        otherInnerBoxWidth,     // The text area width of the Personal & the Education
        titleHeight,            // The title box height for each box
        dotMargin,              // The margin of dot Div
        gap,                    // The gap of Grid
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes['5xl']:
                return [85, 580, 580, 488, 488, 88, 40, 32];
            case minWidth >= theme.breakpoints.sizes['3xl']:
                return [85, 405, 405, 340, 340, 57, 30, 30];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [85, 405, 405, 340, 340, 57, 30, 28];
            case minWidth >= theme.breakpoints.sizes['xl']:
                return [85, 405, 405, 340, 340, 57, 30, 18];
            case minWidth >= theme.breakpoints.sizes['lg']:
                return [85, 395, 244, 327, 194, 50, 25, 20];
            case minWidth >= theme.breakpoints.sizes['md']:
                return [85, 332, 160, 292, 122, 50, 20, 13];
            case minWidth >= theme.breakpoints.sizes['sm']:
                return [85, 247, 99, 215, 80, 50, 15, 20];
            default:
                return [85, 174, 61, 147, 50, 48, 10, 10];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    useEffect(() => {
        if (contentRef.current) {
            setState({ height: contentRef.current.offsetHeight });
        }
    }, [setState, contentRef, minWidth])

    useEffect(() => {
        if (minWidth >= theme.breakpoints.sizes['md']) {
            setState({ extraPaddingTop: 0 });
        } else if (topTitleRef.current) {
            setState({ extraPaddingTop: topTitleRef.current.offsetHeight });
        }
    }, [setState, topTitleRef, minWidth, theme.breakpoints.sizes]);

    return (
        <FeatureWrapper paddingTop={featurePaddingTop}>
            <FeatureArea>
                {maxWidth < theme.breakpoints.sizes['md'] && (
                    <OuterFeatureTitleWrapper featureBoxWidth={featureBoxWidth} topTitleRef={topTitleRef}>
                        <FeatureTitleBox width={featureInnerBoxWidth}>
                            <FeatureTitle
                                width={featureInnerBoxWidth}
                                height={titleHeight}
                                title={DEFAULT_TITLES.personal}
                            />
                        </FeatureTitleBox>
                        <FeatureTarget
                            justifyContent={"end"}
                            textColour={theme.colours.curiousBlue}
                        >
                            {DEFAULT_TITLES.personalTarget}
                        </FeatureTarget>
                        <FeatureTitleBox width={featureInnerBoxWidth}>
                            <FeatureTitle
                                width={featureInnerBoxWidth}
                                height={titleHeight}
                                title={DEFAULT_TITLES.education}
                            />
                        </FeatureTitleBox>
                        <FeatureTarget
                            justifyContent={"end"}
                            textColour={theme.colours.pizazz}
                        >
                            {DEFAULT_TITLES.educationTarget}
                        </FeatureTarget>
                    </OuterFeatureTitleWrapper>
                )}

                <FeatureBox boxRef={contentRef}>
                    <FeatureTitleWrapper gap={gap} featureBoxWidth={featureBoxWidth} otherBoxWidth={otherBoxWidth}>
                        <FeatureTitleBox width={featureBoxWidth}>
                            <FeatureTitle width={featureInnerBoxWidth} height={titleHeight} title={DEFAULT_TITLES.feature} />
                        </FeatureTitleBox>

                        {minWidth >= theme.breakpoints.sizes['md'] && (
                            <>
                                <FeatureTitleBox width={otherBoxWidth}>
                                    <FeatureTitle
                                        width={otherInnerBoxWidth}
                                        height={titleHeight}
                                        title={DEFAULT_TITLES.personal}
                                    />
                                </FeatureTitleBox>
                                <FeatureTitleBox width={otherBoxWidth}>
                                    <FeatureTitle
                                        width={otherInnerBoxWidth}
                                        height={titleHeight}
                                        title={DEFAULT_TITLES.education}
                                    />
                                </FeatureTitleBox>
                            </>
                        )}
                    </FeatureTitleWrapper>

                    {featuresAndAuthorities.map((items, index) => (
                        <FeatureContentContainer key={items.features}>
                            <FeatureContentWrapper
                                gap={gap}
                                featureBoxWidth={featureBoxWidth}
                                otherBoxWidth={otherBoxWidth}
                                useBeforeTag
                                dotMargin={dotMargin}
                                isLast={featuresAndAuthorities.length - 1 === index}
                            >
                                <FeatureContent width={featureInnerBoxWidth} justifyContent={"start"}>
                                    {items.features}
                                </FeatureContent>
                                <FeatureContent width={otherInnerBoxWidth} colour={theme.colours.curiousBlue}>
                                    {items.personal ? 'V' : ''}
                                </FeatureContent>
                                <FeatureContent width={otherInnerBoxWidth} colour={theme.colours.pizazz}>
                                    {items.education ? 'V' : ''}
                                </FeatureContent>
                            </FeatureContentWrapper>
                        </FeatureContentContainer>
                    ))}
                </FeatureBox>

                {minWidth >= theme.breakpoints.sizes['md'] && (
                    <FeatureContentWrapper gap={gap} featureBoxWidth={featureBoxWidth} otherBoxWidth={otherBoxWidth}>
                        <FeatureTarget backgroundColour={theme.colours.gallery}>{DEFAULT_TITLES.featureTarget}</FeatureTarget>
                        <FeatureTarget
                            textColour={theme.colours.curiousBlue}
                            backgroundColour={theme.colours.linkWater}
                        >
                            {DEFAULT_TITLES.personalTarget}
                        </FeatureTarget>
                        <FeatureTarget
                            textColour={theme.colours.pizazz}
                            backgroundColour={theme.colours.karry}
                        >
                            {DEFAULT_TITLES.educationTarget}
                        </FeatureTarget>
                    </FeatureContentWrapper>
                )}
            </FeatureArea>

            <FeatureColourBoxWrapper
                extraPaddingTop={state.extraPaddingTop}
                height={state.height}
                gap={gap}
                featureBoxWidth={featureBoxWidth}
                otherBoxWidth={otherBoxWidth}
            >
                <FeatureColourBox width={featureBoxWidth} backgroundColour={theme.colours.gallery} isFeatureBox />
                <FeatureColourBox width={otherBoxWidth} backgroundColour={theme.colours.linkWater} />
                <FeatureColourBox width={otherBoxWidth} backgroundColour={theme.colours.karry} />
            </FeatureColourBoxWrapper>
        </FeatureWrapper>
    );
};

export default Features;
