import React, { useMemo } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";
import pluralize from "pluralize";

import DatePeriod from "core/Components/Item/DatePeriod";
import TimePeriod from "core/Components/Item/TimePeriod";

import { sort } from "core/includes/sort";

type Props = {
    data: EventPage,
    title: string,
    labelColour: string,
};

const LabelBox: React.FC<Props> = ({ data, title, labelColour }) => {
    const theme: Theme = useTheme();

    const getTitle = useMemo(() => {
        switch (title) {
            case 'Date':
                // data.eventSubSchedules.length: default is 0, pluralize() makes title as a plural when number is 0.
                // but the title should be singular when number is 0.
                const sessionLength = isEmpty(data.eventSubSchedules) ? 1 : data.eventSubSchedules.length;
                return pluralize(title, sessionLength, false);
            case 'Time':
                if (
                    (data.useCommonTime || isEmpty(data.eventSubSchedules)) &&
                    data.eventStartTime && data.eventEndTime
                ) {
                    return title;
                } else {
                    return '';
                }
            default:
                return title;
        }
    }, [
        title,
        data.useCommonTime,
        data.eventSubSchedules,
        data.eventStartTime,
        data.eventEndTime
    ]);

    const getValue = () => {
        switch (title) {
            case 'Location':
                return data.cityCode.name;
            case 'Date':
                const subDates = sort(data.eventSubSchedules);
                if (!!subDates.length) { // Use the multiple schedules
                    const style = css`
                        padding-bottom: 5px;
                        font-weight: ${theme.fonts.weights.normal};
                    `;

                    return subDates.map((subDate: EventSubSchedules, index: number) => (
                        <div key={`sub-schedule-${index}`}>
                            <DatePeriod start={subDate.startDate} end={subDate.endDate} isAbbreviationMonths={false} />
                            {!data.useCommonTime && (
                                <TimePeriod
                                    startTime={subDate.startTime}
                                    endTime={subDate.endTime}
                                    fontSizeType={'9'}
                                    textColour={theme.colours.boulder}
                                    css={style}
                                />
                            )}
                        </div>
                    ));
                }
                return <DatePeriod start={data.eventDate} end={data.eventEndDate} isAbbreviationMonths={false} />;
            default:
                return <TimePeriod
                    startTime={data.eventStartTime}
                    endTime={data.eventEndTime}
                    startDate={data.eventDate}
                    endDate={data.eventEndDate}
                    useText={!data.eventSubSchedules.length}
                />
        }
    };

    const style = css`
        display: flex;
        margin-bottom: 16px;
        font-weight: ${theme.fonts.weights.bold};
    `;

    const labelStyle = css`
        width: 100px;
        
        ${labelColour && css`
            color: ${labelColour};
        `} 
    `;

    return (
        getTitle ? (
            <div css={style}>
                <span css={labelStyle}>{getTitle}:</span>
                <span>{getValue()}</span>
            </div>
        ) : null
    );
};

export default LabelBox;