import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    option: Option,
    multipleValue: any,
};

const DropdownButtonOptionCheckBox: React.FC<Props> = ({
    option,
    multipleValue,
}) => {
    const theme = useTheme();
    const isSelected = multipleValue[option.value];
    const isAllSelected = option.value === "" && multipleValue && multipleValue[option.value];

    const checkboxStyle = css`
        display: flex;
        position: absolute;
        right: 9px;

        input[type="checkbox"] {
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            width: 18px;
            height: 18px;
            border: 1px solid white;
            border-radius: 4px;

            ${!isAllSelected && css`
                cursor: pointer;
            `}

            ${theme.breakpoints.up("5xl")} {
                width: 20px;
                height: 20px;
            }

            ${theme.breakpoints.down("lg")} {
                width: 15px;
                height: 15px;
            }

            ${isSelected && css`
                ::before {
                    content: "✔";
                    font-size: 15px;
                    color: white;
                    position: absolute;
                    left: 7px;
                    bottom: 4px;

                    ${theme.breakpoints.up("5xl")} {
                        font-size: 16px;
                        left: 8px;
                        bottom: 5px;
                    }

                    ${theme.breakpoints.down("lg")} {
                        font-size: 12px;
                        left: 6px;
                        bottom: 3px;
                    }
                }
            `}
        }
    `;

    return (
        <div css={checkboxStyle}>
            <input type="checkbox" />
        </div>
    );
}

export default DropdownButtonOptionCheckBox;
