import React, { useCallback } from "react";
import { MdOutlineStar, MdOutlineStarOutline, MdOutlineStarHalf } from "react-icons/md";
import { css, useTheme } from "@emotion/react";

type Props = {
    stars?: number,
};

const Stars: React.FC<Props> = ({
    stars = 0,
}) => {
    const theme = useTheme();

    const style = css`
        margin-left: 23.5px;
        font-size: 24px;
        
        ${theme.breakpoints.up('5xl')} {
            font-size: 34px;
            margin-right: 33.5px;
        };

        ${theme.breakpoints.between('md', '4xl')} {
            margin-left: 23.5px;
            font-size: 24px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-left: 11.64px;
            font-size: 16px;
        }
    `;

    const quotient = Math.floor(stars);
    const remainder = stars % 1;
    const outlineStars = Math.floor(5 - stars);

    const starRate = useCallback(() => {
        const stars = [];
        let index = 0;

        // full colour star
        for (let i = 1; i <= quotient; i++) {
            stars.push(<span key={index++}><MdOutlineStar /></span>);
        }

        // half colour star
        if (remainder > 0) {
            stars.push(<span key={index++}><MdOutlineStarHalf /></span>);
        }

        // outline only star
        for (let i = 1; i <= outlineStars; i++) {
            stars.push(<span key={index++}><MdOutlineStarOutline /></span>);
        }

        return stars;
    }, [quotient, remainder, outlineStars]);

    return (
        <div css={style}>
            {starRate()}
        </div>
    );
};

export default Stars;