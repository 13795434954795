import React from 'react';
import { css } from "@emotion/react";
import IgnoreErrorBoundary from "core/Components/Errors/IgnoreErrorBoundary";

import CloseButton from "core/Components/Video/CloseButton";

type Props = {
    autoPlay?: boolean,
    onOverlayClick?: () => void,
    overlay?: boolean,
    videoPath: string,
    videos?: string[],
    onEnded?: () => void,
    mute?: boolean,
    className?: string,
};

const VideoPlayer: React.FC<Props> = ({
    autoPlay = false,
    onOverlayClick,
    overlay = false,
    videoPath,
    onEnded,
    mute = false,
    className  = '',
}) => {
    const style = css`
        height: 100%;
        width: 100%;
    `;

    return (
        <>
            <IgnoreErrorBoundary>
                {overlay && (
                    <div onClick={onOverlayClick} className={className} />
                )}

                <div css={style} className='player-wrapper'>
                    <iframe
                        id="player"
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed${videoPath}${autoPlay ? '?autoplay=1' : ''}`}
                        title="YouTube video player"
                        allow="
                            accelerometer;
                            autoplay;
                            clipboard-write;
                            encrypted-media;
                            gyroscope;
                            picture-in-picture;
                            web-share
                        "
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </IgnoreErrorBoundary>
            <CloseButton />
        </>
    );
};

export default VideoPlayer;
