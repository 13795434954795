import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    quantityRef: any,
};

const InputNumberField: React.FC<Props> = ({
    quantityRef,
}) => {
    const theme = useTheme();

    const style = css`
        width: 42px;
        height: 42px;
        font-size: 25px;
        border: 0;
        border-radius: 10px;
        text-align: center;

        ${theme.breakpoints.up('5xl')} {
            height: 54px;
            width: 54px;
            font-size: 31px;
        };

        ${theme.breakpoints.between('lg', '4xl')} {
            width: 42px;
            height: 42px;
            font-size: 25px;
        }

        ${theme.breakpoints.only('md')} {
            width: 30px;
            height: 30px;
            font-size: 20px;
        }

        ${theme.breakpoints.down('sm')} {
            width: 38px;
            height: 38px;
            font-size: 16px;
        }
    `;

    return (
        <input type={'number'} css={style} defaultValue={1} ref={quantityRef} />
    );
};

export default InputNumberField;