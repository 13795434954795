import React from "react";
import { css } from "@emotion/react";

import NavSubLink from "core/Components/Navigations/NavSubLink";

type Props = {
    subMenu: [StandardPage] | null,
    selectedSegments?: string[],
    mouseClickHandler: Function,
};

const NavSubLinkContainer: React.FC<Props> = ({
    subMenu,
    selectedSegments = undefined,
    mouseClickHandler
}) => {
    const hasSubMenu = subMenu && (subMenu.length > 1 || (subMenu?.length === 1 && subMenu[0].showInMenus));

    const style = css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 0 10px;
        padding: 10px 30px 10px 20px;
    `;

    return hasSubMenu ? (
        <div css={style}>
            {subMenu?.map((menu: StandardPage) => (
                menu.showInMenus ? (
                    <NavSubLink
                        key={"header" + menu.id}
                        subMenu={menu}
                        selectedSegments={selectedSegments}
                        mouseClickHandler={mouseClickHandler}
                    />
                ) : null
            ))}
        </div>
    ) : null;
};

export default NavSubLinkContainer;