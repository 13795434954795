import React from 'react';
import window from 'core/includes/window';

type Props = {
    children: any,
};

type State = {
    hasError: boolean,
};

export default class IgnoreErrorBoundary extends React.Component<Props, State> {

    state = {
        hasError: false,
    };

    /**
     * Update state on error.
     *
     * @param error
     */
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return process.env.NODE_ENV === 'development' ? null: { hasError: true };
    }

    /**
     * Reload the page.
     */
    reload = () => window.location.reload();

    /**
     * Render.
     */
    render() {
        return this.props.children;
    }
}
