import React from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    textColour?: string,
};

const ImageText: React.FC<Props> = ({
    children,
    textColour,
}) => {
    const theme = useTheme();
    const Tag = "p";
    const [fontSize, lineHeight] = useFontSize(Tag, '7');

    const style = css`
        text-align: center;
        white-space: break-spaces;
        margin-top: 26px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        
        ${textColour && css`
            color: ${textColour};
        `};
        
        ${theme.breakpoints.up("5xl")} {
            margin-top: 37px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-top: 23px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-top: 20px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-top: 19px;
        }
        
        
        ${theme.breakpoints.down("md")} {
            text-align: center;
            margin-top: 27px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 28px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 28px;
            padding: 0 40px;
        }
    `;

    return (
        <Tag css={style}>
            {children}
        </Tag>
    );
};

export default ImageText;
