import React from 'react';
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    video: SlideWithVideo,
    onPlayVideo: (video: SlideWithVideo) => void,
    borderRadius?: number,
    backgroundColour?: string,
    className?: string,
};

const VideoImage: React.FC<Props> = ({
    children,
    video,
    onPlayVideo,
    borderRadius,
    backgroundColour,
    className
}) => {
    const onClickVideoHandler = () => {
        onPlayVideo(video);
    };

    const style = css`
        width: 800px;
        overflow: hidden;
        cursor: pointer;
        
        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};

        ${borderRadius && css`
            border-radius: ${borderRadius}px;
        `};
    `;

    return (
        <div css={style} onClick={onClickVideoHandler} className={className}>
            {children}
        </div>
    );
};

export default VideoImage;
