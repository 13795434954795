import React from 'react';
import FeatureTitleBox from "elements/Components/SubscriptionPlan/components/Features/FeatureTitleBox";
import FeatureTitle from "elements/Components/SubscriptionPlan/components/Features/FeatureTitle";
import FeatureTitleWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureTitleWrapper";

type Props = {
    name: string,
    otherBoxWidth: number,
    featureBoxWidth: number,
    featureInnerBoxWidth: number,
    titleHeight: number,
    tierCategories: [TierCategory],
    otherInnerBoxWidth: number,
    gap: number,
}

const SubscriptionTitles: React.FC<Props> = ({
    tierCategories,
    name,
    otherBoxWidth,
    featureBoxWidth,
    featureInnerBoxWidth,
    titleHeight,
    otherInnerBoxWidth,
    gap,
}) => (
    <FeatureTitleWrapper gap={gap} featureBoxWidth={featureBoxWidth} otherBoxWidth={otherBoxWidth}>
        <FeatureTitleBox width={featureBoxWidth}>
            <FeatureTitle
                width={featureInnerBoxWidth}
                height={titleHeight}
                title={name}
            />
        </FeatureTitleBox>
        {tierCategories?.map((tierCategory: TierCategory) => (
            <FeatureTitleBox width={otherBoxWidth} key={`c-${tierCategory.id}`}>
                <FeatureTitle
                    width={otherInnerBoxWidth}
                    height={titleHeight}
                    title={tierCategory.name}
                    backgroundColour={tierCategory.backgroundColour}
                />
            </FeatureTitleBox>
        ))}
    </FeatureTitleWrapper>
);

export default SubscriptionTitles;