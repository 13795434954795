import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: DescriptionWithRichTextAndImage
};

const Subheader: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { subheaderColour } = colourTheme;

    const subheaderStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        margin-bottom: 30px;

        ${subheaderColour && css`
            color: ${subheaderColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 27px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-bottom: 29px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 28px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 15px;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"5"} css={subheaderStyle}>{data.subheader}</ContentCombine>
    );
};

export default Subheader;
