import React, { memo } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import errorIcon from "images/icons/error.svg";

type Props = {
    className?: string,
};

const ErrorIcon:React.FC<Props> = ({className}) => {
    const theme: Theme = useTheme();

    const errorIconStyle = css`
        background-image: url('${errorIcon}');
        position: absolute;
        height: 24px;
        width: 24px;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        z-index: ${theme.zIndex.zIndexOne};
    `;

    return (
        <div css={errorIconStyle} className={className} />
    );
};

export default memo(ErrorIcon);