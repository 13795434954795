import React from "react";
import { css } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    card: ImageVerticalCard,
};

const CardImage: React.FC<Props> = ({ card }) => {
    const imageStyle = css`
        height: unset;
    `;

    return (
        <Image image={card.image} useFocusPoint={card.useFocusPoint} css={imageStyle} />
    );
}

export default CardImage;
