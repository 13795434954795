import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";


type Props = {
    children: React.ReactNode
};

const TextSectionContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const rightSectionStyle = css`
        flex-direction: column;

        ${theme.breakpoints.up("5xl")} {
            margin-left: 229px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-left: 175px;
        }

        ${theme.breakpoints.only("xxl")} {
            margin-left: 192px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-left: 43px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-left: 60px;
        }
    `;

    return (
        <ContentSection css={rightSectionStyle}>
            {children}
        </ContentSection>
    );
}

export default TextSectionContainer;
