import React from 'react';
import { css } from "@emotion/react";

type Props = {
    index: number,
    children: React.ReactNode,
    descriptionHeight: number,
    circleSize: number,
};

const HalfContainer: React.FC<Props> = ({
    index,
    children,
    descriptionHeight,
    circleSize,
}) => {
    const isOddOrder = (index + 1) % 2;

    // Control cases when description is longer than its container and vice versa.
    // Container height 234px, and half circle height 53px will be changed according to design later.
    const gapBetweenDescAndCircle = 234 - (circleSize / 2) - descriptionHeight;
    const gapBetweenDescAndCircleStyle = gapBetweenDescAndCircle > 0 ?
        css`
            padding-top: ${gapBetweenDescAndCircle}px;
        ` :
        css`
            height: calc(234px - ${gapBetweenDescAndCircle}px);
            margin-top: ${gapBetweenDescAndCircle}px;
        `;

    const halfContainerStyle = css`
        display: grid;
        grid-template-columns: 0.75fr 1.25fr;
        width: 100%;
        height: 50%;

        ${isOddOrder && gapBetweenDescAndCircleStyle};
    `;

    return (
        <div css={halfContainerStyle}>
            {children}
        </div>
    )
}

export default HalfContainer;
