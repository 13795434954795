import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";
import { sort } from "core/includes/sort";
import SectionContainer from "core/Components/SectionContainer";

import Wrapper from "elements/Components/Timeline/components/Wrapper";
import Header from "elements/Components/Timeline/components/Header";
import TextSectionContainer from "elements/Components/Timeline/components/TextSectionContainer";
import Description from "elements/Components/Timeline/components/Description";
import TimelineItemContainer from "elements/Components/Timeline/components/TimelineItemContainer";
import TimelineItem from "elements/Components/Timeline/components/TimelineItem/TimelineItem";
import MobileViewTimelineItem from "elements/Components/Timeline/components/TimelineItem/MobileViewTimelineItem";
import TimelineSectionContainer from "elements/Components/Timeline/components/TimelineSectionContainer";

type Props = {
    data: Timeline
};

const Timeline: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const { maxWidth, minWidth } = useBreakpoint();
    const timelineItemList = sort(data.timelineItemList);

    const [
        avatarWidth,
        avatarHeight,
        circleSize,
        roundImageSize,
        squareImageWidth,
        squareImageHeight,
        sizeGap, // Round image size gap from 1440 ~ 2199 breakpoint
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return [190, 348, 160, 296, 274, 315, (198 - 296) * 1.2];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [130, 230, 107, 198, 185, 211, 0];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [84, 160, 77, 142, 132, 152, (198 - 142) * 1.2];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [84, 160, 23, 155, 120, 142, 0];
            default:
                return [82, 136, 23, 140, 110, 132, 0];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const isMobileView = maxWidth < 1024;

    const hasTextData = !!(data.header || data.text);

    return isEmpty(timelineItemList) ? null : (
        <SectionContainer>
            <Wrapper hasTextData={hasTextData}>
                {hasTextData && (
                    <TextSectionContainer>
                        <Header data={data} />
                        <Description data={data} />
                    </TextSectionContainer>
                )}

                <TimelineSectionContainer>
                    {timelineItemList.map((timelineItem: TimelineItem, index: number) => {
                        if (isMobileView) {
                            const isLastItem = timelineItemList.length === index + 1;

                            return (
                                <TimelineItemContainer
                                    index={index}
                                    key={timelineItem.id}
                                    timelineItem={timelineItem}
                                    roundImageSize={roundImageSize}
                                    squareImageHeight={squareImageHeight}
                                >
                                    <MobileViewTimelineItem
                                        index={index}
                                        timelineItem={timelineItem}
                                        circleSize={circleSize}
                                        avatarWidth={avatarWidth}
                                        avatarHeight={avatarHeight}
                                        roundImageSize={roundImageSize}
                                        squareImageWidth={squareImageWidth}
                                        squareImageHeight={squareImageHeight}
                                        sizeGap={sizeGap}
                                        isLastItem={isLastItem}
                                        isLargeTitle={data.isLargeItemTitle}
                                    />
                                </TimelineItemContainer>
                            );
                        }

                        return (
                            <TimelineItemContainer
                                index={index}
                                key={timelineItem.id}
                                timelineItem={timelineItem}
                                roundImageSize={roundImageSize}
                                squareImageHeight={squareImageHeight}
                            >
                                <TimelineItem
                                    index={index}
                                    timelineItem={timelineItem}
                                    circleSize={circleSize}
                                    avatarWidth={avatarWidth}
                                    avatarHeight={avatarHeight}
                                    roundImageSize={roundImageSize}
                                    squareImageWidth={squareImageWidth}
                                    squareImageHeight={squareImageHeight}
                                    sizeGap={sizeGap}
                                    isLargeTitle={data.isLargeItemTitle}
                                />
                            </TimelineItemContainer>
                        );
                    })}
                </TimelineSectionContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default Timeline;
