import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isHeaderMenu?: boolean,
    isTextBold?: boolean,
    colour: string,
    showUnderline: boolean,
    clickHandler: Function,
};

const BurgerNavSubTextBoxContainer: React.FC<Props> = ({
    children,
    isHeaderMenu = false,
    isTextBold = false,
    colour,
    showUnderline,
    clickHandler,
}) => {
    const theme = useTheme();

    const onClickHandler = () => {
        clickHandler();
    };

    const style = css`
        margin-top: 20px;
        width: fit-content;
        font-size: 20px;
        color: ${colour};
        
        ${!isHeaderMenu && css`
            margin-bottom: 8px;
        `}
        
        ${isTextBold && css`
            font-weight: ${theme.fonts.weights.bold};
        `}

        ${showUnderline && css`
            .nav-menu-underline {
                background-color: ${theme.colours.cornflowerBlue};
            }
        `}

        &:hover {
            color: ${theme.colours.curiousBlue};
        }
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {children}
        </div>
    );
};

export default BurgerNavSubTextBoxContainer;