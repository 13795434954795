import React from "react";
import { css, useTheme } from "@emotion/react";
import chevron from "images/icons/chevron.svg";

type Props = {
    dropped: boolean,
    className?: string,
    hasError?: boolean,
    onClick?: () => void,
};

const DropdownChevron: React.FC<Props> = ({
    dropped = false,
    className,
    hasError = false,
    onClick,
}) => {
    const theme = useTheme();

    const style = (dropped: boolean, hasError: boolean) => css`
        position: absolute;
        top: 52%;
        right: 0;
        transform: translateY(-50%);
        mask: url(${chevron}) no-repeat center/11px;
        background-color: ${hasError ? theme.colours.crimson : theme.colours.grey[920]};
        width: 20px;
        height: 20px;
    
        ${dropped && css`
            transform: translateY(-50%) scaleY(-1);
        `}
    `;

    return (
        <div css={style(dropped, hasError)} className={className} onClick={onClick}></div>
    );
}

export default DropdownChevron;