import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
};

const Text: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const textStyle = css`
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        font-weight: ${theme.fonts.weights.bold};
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 24px;
            line-height: 36px;
        }
    `;

    return (
        <ContentCombine css={textStyle}>{children}</ContentCombine>
    );
};

export default Text;
