import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const CarouselContainer: React.FC<Props> = ({
    children,
    className,
}) => {
    const theme = useTheme();

    const style = css`
        .slide {
            display: flex;
            align-items: center;
        }

        .slider-control-bottomcenter {
            position: absolute;
            display: none;
            bottom: 0;

            ${theme.breakpoints.down("md")} {
                display: block;
                bottom: -40px;
            }
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default CarouselContainer;
