import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    featureBoxWidth: number,
    topTitleRef: any,
};

const OuterFeatureTitleWrapper: React.FC<Props> = ({
    children,
    featureBoxWidth,
    topTitleRef,
}) => {
    const style = css`
        display: grid;
        grid-template-columns: ${featureBoxWidth}px 1fr;
        place-items: center;
        padding-bottom: 35px;
    `;

    return (
        <div css={style} ref={topTitleRef}>{children}</div>
    );
}

export default OuterFeatureTitleWrapper;
