import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    textColour: string,
};

const Text: React.FC<Props> = ({
    children,
    textColour,
}) => {
    const theme = useTheme();

    const style = css`
        margin-bottom: 48px;
        justify-content: flex-start;

        ${textColour && css`
            color: ${textColour};
        `}
        
        ${theme.breakpoints.only("lg")} {
            margin-bottom: 37px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 31px;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"13"} lineHeightType={"17"} css={style}>{children}</ContentCombine>
    );
};

export default Text;
