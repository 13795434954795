import React from "react";
import { useTheme } from "@emotion/react";

import { NOOP } from "core/constants";
import NavMenuUnderLine from "core/Components/Navigations/NavMenuUnderLine";
import BurgerNavSubTextBoxContainer from "core/Components/HamburgerNavigations/large/BurgerNavSubTextBoxContainer";

type Props = {
    children: React.ReactNode,
    headerMenu: StandardPage,
    currentURLSegment?: string,
    closeMenuHandler: Function,
};

const BurgerNavHeaderTextBox: React.FC<Props> = ({
    children,
    headerMenu,
    currentURLSegment = "",
    closeMenuHandler,
}) => {
    const theme = useTheme();

    return (
        <BurgerNavSubTextBoxContainer
            isTextBold
            colour={theme.colours.black}
            showUnderline={headerMenu.uRLSegment === currentURLSegment}
            clickHandler={headerMenu.isClickable ? closeMenuHandler : NOOP}
        >
            {children}
            <NavMenuUnderLine width={100} height={4} />
        </BurgerNavSubTextBoxContainer>
    );
};

export default BurgerNavHeaderTextBox;