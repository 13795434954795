import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import Fuse from "fuse.js";
import { css, Theme, useTheme } from "@emotion/react";
import { useLocation } from "react-router";

import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";
import SearchField from "core/Components/SearchField";
import { getCanRenderWebp } from "core/includes/image";
import Pagination from "core/Components/Pagination";
import Loading from "core/Components/Utils/Loading";
import BlogCard from "pages/Components/BlogListPage/components/BlogCard";
import { getBlogCategories, getBlogPosts } from "core/includes/queries";
import ContentCombine from "core/Components/ContentCombine";
import { ReactComponent as BlogIcon } from "images/icons/blog.svg";
import Banner from "pages/Components/BlogListPage/components/Banner";
import DescriptionWithSimpleText from "elements/Components/DescriptionWithText/DescriptionWithSimpleText";
import ContentWithRoundImage from "elements/Components/ContentWithRoundImage/ContentWithRoundImage";
import LinkTo from "core/Components/LinkTo";
import { getURLSegmentsString } from "core/includes/formatters";


type Props = {
    data: BlogListPage,
};

const POSTS_PER_PAGE = 9;

const BlogListPage: React.FC<Props> = ({ data }) => {
    const location = useLocation();
    const urlSegments = getURLSegmentsString(location.pathname);

    const [filterKeyword, setFilterKeyword] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState<BlogCategory>({id: 0, name: "All posts", postIds: []});

    const descriptionData = useMemo<DescriptionWithSimpleTextType|null>(() => {
        if (!data || !data.simpleSubheader) return null;

        const colourTheme = JSON.parse(data.theme.toLowerCase());
        let { headercolour, textcolour } = colourTheme;
        const newColourTheme = {
            ...colourTheme,
            headercolour: textcolour,
            textcolour: headercolour,
        }
        return {
            headerTag: 'h5',
            header: data.simpleSubheader,
            writer: data.simpleWriter,
            showDoubleQuotes: data.showDoubleQuotes,
            theme: JSON.stringify(newColourTheme),
            moduleHeightType: 'Short',
        }
    }, [data]);

    const contentData = useMemo<ContentWithRoundImageType|null>(() => {
        if (!data || !data.contentImage) return null;

        return {
            header: data.contentHeader,
            subheader: data.contentSubheader,
            text: data.contentText,
            image: data.contentImage,
        }
    }, [data]);

    const canRenderWebp = getCanRenderWebp();
    const theme: Theme = useTheme();
    const pageIndexHolder = useRef(0);

    const { data: blogPostsData, loading: blogPostsLoading } = useQuery(getBlogPosts, {
        variables: { canRenderWebp },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const { data: blogCategoriesData, loading: blogCategoriesLoading } = useQuery(getBlogCategories, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const onSearch = (filterKeyword: string) => {
        setFilterKeyword(filterKeyword)
    }

    const fuseOptions = useMemo<any>(() => {
        // https://fusejs.io/
        const options: Fuse.IFuseOptions<BlogPost> = {
            threshold: 0.2,
            keys: [ 'title' ],
        };
        return options;
    }, []);

    const latestPublishedDate = blogPostsData?.blogPosts?.length ?
        blogPostsData?.blogPosts?.reduce((a: BlogPost, b: BlogPost) => (
            a.publishedDate > b.publishedDate ? a : b
        ))?.publishedDate : null;

    const latestBlogPosts = blogPostsData?.blogPosts
        ?.filter((bp: BlogPost) => bp.publishedDate === latestPublishedDate)
        ?.sort((a: BlogPost, b: BlogPost) => a.title?.localeCompare(b.title));

    const latestBlogPost = latestBlogPosts?.[0];

    const heroBlogPost = blogPostsData?.blogPosts?.find((bp: BlogPost) => bp.useAsHero) || latestBlogPost;

    const filteredData: any = useMemo(() => {
        if (!blogPostsData?.blogPosts?.length) return [];

        let posts: any = blogPostsData?.blogPosts;

        if (selectedCategory.id) {
            posts = posts.filter((blog: BlogPost) => selectedCategory.postIds.includes(blog.id));
        }

        if (filterKeyword) {
            posts = new Fuse<BlogPost>(posts, fuseOptions).search(filterKeyword).map(blog => blog.item);
        }

        return getPaginatedDataList(posts);
    }, [blogPostsData?.blogPosts, filterKeyword, fuseOptions, selectedCategory]);

    const categories = useMemo<BlogCategory[]>(() => [
        { id: 0, name: "All posts", postIds: [] },
        ...blogCategoriesData?.blogCategories?.length ? blogCategoriesData.blogCategories : []
    ], [blogCategoriesData?.blogCategories]);

    const pageIndexMaxNext = useMemo(() => filteredData.length ?  filteredData.length - 1 : 0, [filteredData.length]);

    const setPageIndexMaxPrevious = useCallback(() => setPageIndex(0), []);
    const setPageIndexMaxNext = useCallback(() => setPageIndex(pageIndexMaxNext), [pageIndexMaxNext]);

    const decrementPageIndex = useCallback(() => {
        const currentPageIndexMinusOne = pageIndex >= 0 ? pageIndex - 1 : pageIndexHolder.current - 1;
        const newPageIndex = Math.max(currentPageIndexMinusOne, 0);
        setPageIndex(newPageIndex);
    }, [pageIndex])

    const incrementPageIndex = useCallback(() => {
        const lastPageIndex = filteredData.length - 1;
        const currentPageIndexPlusOne = pageIndex >= 0 ? pageIndex + 1 : pageIndexHolder.current + 1;
        const newPageIndex = Math.min(currentPageIndexPlusOne, lastPageIndex);
        setPageIndex(newPageIndex);
    }, [filteredData.length, pageIndex])

    const onClickArrow = useCallback((arrow: string) => {
        if (arrow === 'maxPrev') {
            setPageIndexMaxPrevious();
        } else if (arrow === 'prev') {
            decrementPageIndex();
        } else if (arrow === 'next') {
            incrementPageIndex();
        } else if (arrow === 'maxNext') {
            setPageIndexMaxNext();
        }
    }, [decrementPageIndex, incrementPageIndex, setPageIndexMaxNext, setPageIndexMaxPrevious])

    const resetIndex = useCallback(() => {
        if (!pageIndex || pageIndex < 0 || pageIndex > pageIndexMaxNext) {
            setPageIndex(pageIndexHolder.current);
        }
    }, [pageIndex, pageIndexMaxNext])

    const onPageNavigationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = parseInt(e.target.value);
        setPageIndex(currentValue - 1);
    }, [])

    const isValueWithinPageRange = useMemo(() => (
        pageIndex <= filteredData.length - 1 && pageIndex >= 0
    ), [filteredData.length, pageIndex]);

    if (isValueWithinPageRange) {
        pageIndexHolder.current = pageIndex;
    }

    const onSelectCategory = (category: BlogCategory) => {
        setSelectedCategory(category);
        setPageIndex(0);
    }

    const blogPostsContainerStyle = css`
        row-gap: 40px;
        column-gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        ${theme.breakpoints.only('xl')} {
            row-gap: 35px;
            column-gap: 35px;
        }

        ${theme.breakpoints.only('lg')} {
            row-gap: 30px;
            column-gap: 30px;
        }

        ${theme.breakpoints.down('md')} {
            grid-template-columns: 1fr 1fr;
            row-gap: 31px;
            column-gap: 31px;
        }

        ${theme.breakpoints.down('sm')} {
            grid-template-columns: 1fr;
        }

        ${theme.breakpoints.down('xs')} {
            padding: 0 8px;
        }
    `;

    const wrapperStyle = css`
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 64px 80px 0;

        ${theme.breakpoints.up('5xl')} {
            padding-left: 310px;
            padding-right: 310px;
            padding-bottom: 160px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            padding-left: 160px;
            padding-right: 160px;
            padding-top: 84px;
        }
        
        ${theme.breakpoints.between('xxl', '4xl')} {
            padding-bottom: 102px;
        }

        ${theme.breakpoints.only('xl')} {
            padding-bottom: 139px;
        }

        ${theme.breakpoints.only('lg')} {
            padding: 45px 45px 77px;
        }

        ${theme.breakpoints.between('sm', 'md')} {
            padding-top: 40px;
        }

        ${theme.breakpoints.only('md')} {
            padding-left: 78px;
            padding-right: 78px;
            padding-bottom: 102px;
        }

        ${theme.breakpoints.only('sm')} {
            padding-left: 104px;
            padding-right: 104px;
            padding-bottom: 87px;
        }

        ${theme.breakpoints.down('sm')} {
            justify-content: center;
        }

        ${theme.breakpoints.down('xs')} {
            padding-top: 24px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 63px;
        }
    `;

    const blogsContainerStyle = css`
        width: 100%;
    `;

    const paginationStyle = css`
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        ${theme.breakpoints.up('5xl')} {
            margin-left: 40px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-left: 20px;
        }

        ${theme.breakpoints.only('md')} {
            margin-bottom: 44px;
        }

        ${theme.breakpoints.down('sm')} {
            justify-content: center;
        }

        ${theme.breakpoints.down('xs')} {
            margin-bottom: 20px;

            div, input {
                font-size: 14px;
            }
        }
    `;

    const blogCategoriesContainerStyle = css`
        display: flex;
        background: ${theme.colours.alabaster3};
        box-shadow: ${theme.borderAndShadow.boxInseBoxShadow1};
        margin-bottom: 80px;
        border-radius: 58px;

        ${theme.breakpoints.up('5xl')} {
            height: 80px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            height: 60px;
        }

        ${theme.breakpoints.between('md', 'xxl')} {
            height: 52px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-bottom: 94px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-bottom: 50px;
        }

        ${theme.breakpoints.between('md', 'lg')} {
            margin-bottom: 44px;
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
            flex-direction: column;
            border-radius: ${theme.borderAndShadow.larger2Radius};
            padding: 10px 5px;
            margin-bottom: 74px;
        }
    `;

    const blogCategoryLabelStyle = (isSelected: boolean) => css`
        min-width: 296px;
        padding: 0 60px;
        border-radius: 58px;
        font-size: 18px;
        color: ${theme.colours.doveGray4};
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        ${isSelected && css`
            background: ${theme.colours.onahau};
            color: ${theme.colours.curiousBlue2};
            box-shadow: 3px 0 2px rgba(0, 0, 0, 0.11);
            font-weight: bold;
        `}

        ${theme.breakpoints.between('3xl', '4xl')} {
            min-width: 219px;
        }

        ${theme.breakpoints.between('md', 'xxl')} {
            min-width: 192px;
        }

        ${theme.breakpoints.only('sm')} {
            padding: 8px 10px;
        }

        ${theme.breakpoints.down('xs')} {
            padding: 8px 10px;
        }
    `;

    const emptyMessageStyle = css`
        color: ${theme.colours.silver4};
        font-weight: bold;
    `;

    const blogIconStyle = css`
        fill: ${theme.colours.silver4};
        width: 42px;
        height: 42px;
        margin-bottom: 20px;
    `;

    const blogContentStyle = css`
        width: 100%;

        ${theme.breakpoints.up("5xl")} {
            padding-left: 202px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding-left: 118px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding-left: 74px;
        }

        ${theme.breakpoints.only("xl")} {
            padding-left: 80px;
        }

        ${theme.breakpoints.only("lg")} {
            padding-left: 45px;
        }
    `;

    const blogDescriptionStyle = css`
        text-align: center;

        ${theme.breakpoints.up("5xl")} {
            margin: 74px auto 44px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin: 62px auto 73px;
        }

        ${theme.breakpoints.only("xxl")} {
            margin: 62px auto 84px;
        }

        ${theme.breakpoints.only("xl")} {
            margin: 175px auto 146px;
        }

        ${theme.breakpoints.only("lg")} {
            margin: 46px auto 31px;
        }

        ${theme.breakpoints.only("md")} {
            margin: 10px auto 0;
        }

        ${theme.breakpoints.only("sm")} {
            margin: 35px auto 24px;
        }

        ${theme.breakpoints.down("xs")} {
            margin: 28px auto 8px;
        }
    `;

    const searchAndBannerContainerStyle = css`
        ${theme.breakpoints.up('5xl')} {
            width: 93%;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            width: 87%;
        }

        ${theme.breakpoints.only('xxl')} {
            width: 89%;
        }

        ${theme.breakpoints.only('xl')} {
            width: 83%;
        }

        ${theme.breakpoints.only('lg')} {
            width: 100%;
        }

        ${theme.breakpoints.only('md')} {
            width: 94%;
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;

    const bannerStyle = css`
        width: 100% !important;

        ${theme.breakpoints.only('xxl')} {
            margin-top: 16px;
            margin-bottom: 140px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-top: 20px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-bottom: 64px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 12px;
            margin-bottom: 27px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 16px;
            margin-bottom: 58px;
        }
        
        :hover {
            .header {
                color: ${theme.colours.scienceBlue};
            }

            .button {
                > div {
                    background-color: ${theme.colours.scienceBlue};
                }
            }
        }
    `;

    const blogCardStyle = css`
        :hover {
            .header {
                color: ${theme.colours.scienceBlue};
            }
        }
    `;

    return (
        <>
            <SectionContainer>
                <Wrapper css={wrapperStyle}>
                    <div css={searchAndBannerContainerStyle}>
                        <SearchField onChange={onSearch} />
                        {!data.hideHero && heroBlogPost && (
                            <LinkTo to={urlSegments + '/' + heroBlogPost.uRLSegment}>
                                <Banner
                                    css={bannerStyle}
                                    post={heroBlogPost}
                                    useCurve={heroBlogPost.useCurveForBanner}
                                />
                            </LinkTo>
                        )}
                    </div>
                    {blogPostsLoading || blogCategoriesLoading ? (
                        <Loading />
                    ) : (
                        <>
                            {categories.length > 2 && (
                                <div css={blogCategoriesContainerStyle}>
                                    {categories.map((blogCategory: BlogCategory) => (
                                        <div
                                            key={`blogCategory-${blogCategory.id}`}
                                            css={blogCategoryLabelStyle(selectedCategory.id === blogCategory.id)}
                                            onClick={() => onSelectCategory(blogCategory)}
                                        >
                                            {blogCategory.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {filteredData.length ? (
                                <div css={blogsContainerStyle}>
                                    <Pagination
                                        value={pageIndex + 1 || ''}
                                        numberOfPages={filteredData.length}
                                        onClickArrow={onClickArrow}
                                        onOutsideClick={resetIndex}
                                        onChange={onPageNavigationChange}
                                        css={paginationStyle}
                                    />
                                    <div css={blogPostsContainerStyle}>
                                        {filteredData[pageIndexHolder.current].map((blogPost: BlogPost, index: number) => (
                                            <LinkTo
                                                to={urlSegments + '/' + blogPost.uRLSegment}
                                                key={index}
                                            >
                                                <BlogCard
                                                    css={blogCardStyle}
                                                    blog={blogPost}
                                                />
                                            </LinkTo>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <BlogIcon css={blogIconStyle} />
                                    <ContentCombine
                                        Tag={"h5"}
                                        css={emptyMessageStyle}
                                    >
                                        No posts to display
                                    </ContentCombine>
                                </>
                            )}
                        </>
                    )}
                </Wrapper>
            </SectionContainer>

            {descriptionData && (
                <div css={blogDescriptionStyle}>
                    <DescriptionWithSimpleText data={descriptionData} doubleQuotesColour={data?.doubleQuotesColour} />
                </div>
            )}

            {contentData && (
                <div css={blogContentStyle}>
                    <ContentWithRoundImage data={contentData} />
                </div>
            )}
        </>
    );
};

const getPaginatedDataList = (tableData: []) => {
    let tableDataArray = [];
    let tableDataArrayIndex = -1;
    for (let i = 0; i < tableData.length; i++) {
        if (i % POSTS_PER_PAGE === 0) {
            tableDataArrayIndex += 1;
            tableDataArray.push([]);
        }

        tableDataArray[tableDataArrayIndex].push(tableData[i]);
    }

    return tableDataArray;
}

export default BlogListPage;
