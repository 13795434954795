import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    data: BannerWithFullImage
};

const SecondButton: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const secondLinkURL = data.secondInnerURL || data.secondOuterURL;
    const secondColourTheme = JSON.parse(data.theme);
    const { secondButtonTextColour, secondButtonBorderColour, } = secondColourTheme;

    const secondButtonTextCustomStyle = css`
        color: ${secondButtonTextColour};
        font-size: 20px;

        ${theme.breakpoints.up("3xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    if (!data.secondButtonText) return null;

    return (
        <RoundButton
            backgroundColour={theme.colours.transparent}
            borderColour={secondButtonBorderColour}
            textColour={secondButtonTextColour}
            linkURL={secondLinkURL}
            buttonTextCustomStyle={secondButtonTextCustomStyle}
        >
            {data.secondButtonText}
        </RoundButton>
    );
}

export default SecondButton;
