import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    type1?: boolean,
    type2?: boolean,
    firstCircleColour?: string,
    secondCircleColour?: string,
};

const Circle: React.FC<Props> = ({ type1, type2, firstCircleColour, secondCircleColour }) => {
    const theme: Theme = useTheme();

    const circleStyle = css`
        border-radius: 50%;
        position: absolute;

        ${theme.breakpoints.up("5xl")} {
            width: 425px;
            height: 425px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            width: 350px;
            height: 350px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            width: 279px;
            height: 279px;
        }

        ${theme.breakpoints.only("md")} {
            width: 297px;
            height: 297px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 170px;
            height: 170px;
        }

        ${type1 && css`
            background-color: ${firstCircleColour};

            ${theme.breakpoints.up("5xl")} {
                top: -19px;
                left: -248px;
            }

            ${theme.breakpoints.between("xxl", "4xl")} {
                top: 0px;
                left: -220px;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                top: -20px;
                left: -180px;
            }

            ${theme.breakpoints.only("md")} {
                top: 0px;
                left: -60px;
            }

            ${theme.breakpoints.only("sm")} {
                top: 0px;
                left: 0px;
            }

            ${theme.breakpoints.down("xs")} {
                top: 0px;
                left: -50px;
            }
        `};

        ${type2 && css`
            background-color: ${secondCircleColour};

            ${theme.breakpoints.up("5xl")} {
                top: 290px;
                left: 430px;
            }

            ${theme.breakpoints.between("xxl", "4xl")} {
                top: 245px;
                left: 360px;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                top: 230px;
                left: 260px;
            }

            ${theme.breakpoints.only("md")} {
                top: 200px;
                left: 400px;
            }

            ${theme.breakpoints.only("sm")} {
                top: 135px;
                left: 280px;
            }

            ${theme.breakpoints.down("xs")} {
                top: 135px;
                left: 210px;
            }
        `};
    `;

    return (
        <div css={circleStyle} />
    );
}

export default Circle;
