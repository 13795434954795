import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    imageHorizontalCard: ImageHorizontalCard,
};

const Button: React.FC<Props> = ({ imageHorizontalCard }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(imageHorizontalCard.theme);
    const { buttonColour, buttonTextColour } = colourTheme;
    const linkURL = imageHorizontalCard.outerURL || imageHorizontalCard.innerURL;

    const buttonStyle = css`
        position: absolute;
        height: 34px;
        bottom: 74px;

        ${theme.breakpoints.up("5xl")} {
            height: 49px;
            bottom: 100px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            height: 38px;
            bottom: 83px;
        }

        ${theme.breakpoints.only("lg")} {
            height: 38px;
            bottom: 31px;
        }

        ${theme.breakpoints.only("md")} {
            height: 38px;
            bottom: 50px;
        }

        ${theme.breakpoints.only("sm")} {
            position: relative;
            height: 35px;
            font-size: 15px;
            bottom: unset;
            margin-top: 20px;
        }

        ${theme.breakpoints.down("xs")} {
            position: relative;
            height: 32px;
            font-size: 14px;
            line-height: 18px;
            bottom: unset;
            margin-top: 20px;
        }
    `;

    const buttonTextCustomStyle = css`
        font-size: 18px;
        line-height: 21px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 21px;
            line-height: 25px;
        }
        
        ${theme.breakpoints.between("3xl", "4xl")} {
            font-size: 20px;
            line-height: 23px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 15px;
            line-height: 18px;
        }
        
        ${theme.breakpoints.between("md", "lg")} {
            font-size: 16px;
            line-height: 18px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 15px;
            line-height: 19px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 14px;
            line-height: 18px;
        }
    `;

    return (
        imageHorizontalCard.buttonText ? (
            <RoundButton
                textColour={buttonTextColour}
                backgroundColour={buttonColour}
                linkURL={linkURL}
                css={buttonStyle}
                buttonTextCustomStyle={buttonTextCustomStyle}
            >
                {imageHorizontalCard.buttonText}
            </RoundButton>
        ) : null
    );
}

export default Button;
