import React from 'react';
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ThumbnailContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style= css`
        display: flex;
        margin-top: 26.5px;
        width: 560px;

        ${theme.breakpoints.up('5xl')} {
            margin-top: 9px;
            width: 653px;
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            margin-top: 28px;
            width: 565px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-top: 9px;
            width: 499px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-top: 7.5px;
            width: 399.29px;
        }

        ${theme.breakpoints.down('md')} {
            margin-top: 5.98px;
            width: 318.24px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default ThumbnailContainer;