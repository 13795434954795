import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';


const useElementDimensions = (element: any, { debounce = 0, disabled = false } = {}) => {
    const timeoutId = useRef<any>(-1);

    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
    // For SOME reason we need to keep a copy of the passed in element in state, for the below effect to work.
    const [el, setEl] = useState(element);
    if (element !== el) {
        setEl(element);
    }

    useEffect(() => {
        if (disabled) return;

        // If the element is not an Element, cause an update to give
        // the hook user another chance to pass in an Element.
        // Maybe the next time, the ref will be set!
        if (!(el instanceof Element)) {
            setContainerDimensions({ width: 0, height: 0 });
            return;
        }

        const resizeObserver = new ResizeObserver(entries => {
            const containerRect = entries[0].contentRect;

            const dims = { width: containerRect.width, height: containerRect.height };

            if (debounce) {
                clearTimeout(timeoutId.current);
                timeoutId.current = setTimeout(() => {
                    setContainerDimensions(dims);
                }, debounce);
            } else {
                setContainerDimensions(dims);
            }
        });

        resizeObserver.observe(el);

        return () => {
            if (resizeObserver) resizeObserver.disconnect();
        };
    }, [el, debounce, disabled]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current);
        }
    }, []);

    return containerDimensions;
};

export default useElementDimensions;
