import React from "react";

import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "elements/Components/DescriptionWithRichTextSingleColumn/components/Wrapper";
import Header from "elements/Components/DescriptionWithRichTextSingleColumn/components/Header";
import Text from "elements/Components/DescriptionWithRichTextSingleColumn/components/Text";
import TextContainer from "elements/Components/DescriptionWithRichTextSingleColumn/components/TextContainer";

type Props = {
    data: DescriptionWithRichTextSingleColumn
};

const DescriptionWithRichTextSingleColumn: React.FC<Props> = ({ data }) => (
    <SectionContainer data={data}>
        <Wrapper>
            <TextContainer>
                {data.header && (
                    <Header data={data} />
                )}
                <Text data={data} />
            </TextContainer>
        </Wrapper>
    </SectionContainer>
);

export default DescriptionWithRichTextSingleColumn;
