import React, { useEffect } from "react";
import { css, useTheme } from "@emotion/react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import VideoBackgroundLayer from "core/Components/Video/VideoBackgroundLayer";
import document from 'core/includes/document';

type Props = {
    children: React.ReactNode,
    clearPlayVideo: () => void,
    useBackground?: boolean,
    leftSectionColour?: string,
    rightSectionColour?: string,
};

const VideoViewContainer: React.FC<Props> = ({
    children,
    clearPlayVideo,
    useBackground = false,
    leftSectionColour,
    rightSectionColour,
}) => {
    const theme = useTheme();

    const style = css`
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${theme.zIndex.zIndexHighest};
        background-color: rgb(0, 0, 0, 0.6);
    `;

    const onClickHandler = () => {
        clearPlayVideo();
    }

    useEffect(() => {
        disableBodyScroll(document.body);

        return () => {
            clearAllBodyScrollLocks();
        }
    }, []);

    return (
        <>
            <div css={style} onClick={onClickHandler}>
                {children}
            </div>
            <VideoBackgroundLayer
                useBackground={useBackground}
                leftSectionColour={leftSectionColour}
                rightSectionColour={rightSectionColour}
            />
        </>
    );
};

export default VideoViewContainer;
