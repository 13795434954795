import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const Wrapper: React.FC<Props> = ({
    children,
}) => {
    const style = css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        @media (max-width: 1038px) {
            padding-top: 50px;
            align-items: center;
            flex-direction: column-reverse;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default Wrapper;
