import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    src: string,
    index: number,
    roundImageSize: number,
    squareImageWidth: number,
    sizeGap: number,
    avatarWidth: number,
    avatarHeight: number,
};

const Avatar: React.FC<Props> = ({
    src,
    index,
    roundImageSize,
    squareImageWidth,
    sizeGap,
    avatarWidth,
    avatarHeight,
}) => {
    const theme: Theme = useTheme();

    const isOddOrder = !!((index + 1) % 2);
    const isRoundMainImage = index % 10 < 5 ? (index + 1) % 2 : index % 2;
    const positionValue = (isRoundMainImage ? roundImageSize : squareImageWidth) - avatarWidth / 2;

    const imgStyle = css`
        position: absolute;
        width: ${avatarWidth}px;
        height: ${avatarHeight}px;
        left: ${positionValue}px;
        bottom: ${isOddOrder ? '-55%' : '45%'};
        z-index: ${theme.zIndex.zIndexTwo};

        ${index === 0 && css`
            left: -15%;
            bottom: 90%;
            right: 60%;
        `};

        ${theme.breakpoints.between("xxl", "4xl")} {
            ${isOddOrder && index !==0 && css`
                bottom: ${isOddOrder ? '-5%' : '45%'};
            `};
        }

        ${theme.breakpoints.between("lg", "xl")} {
            ${isOddOrder && index !==0 && css`
                bottom: ${sizeGap - 10}px;
            `};
        }

        ${theme.breakpoints.only("md")} {
            top: 8px;
            
            ${isOddOrder ? css`
                left: ${positionValue + avatarWidth / 4}px;
            ` : css `
                left: unset;
                right: 0;
            `};
        }

        ${theme.breakpoints.down("sm")} {            
            ${isOddOrder ? css`
                top: 6px;
                left: ${positionValue + avatarWidth / 4}px;
            ` : css `
                top: 0;
                left: unset;
                right: 0;
            `};
        }
    `;

    return (
        <img css={imgStyle} src={src} alt={src} className={'timeline-avatar-state-hover'} />
    );
}

export default Avatar;
