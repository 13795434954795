import React from "react";
import { css, useTheme } from "@emotion/react";
import { HiChevronDown } from "react-icons/hi";

type Props = {
    toggle: boolean,
};

const SubChevron: React.FC<Props> = ({ toggle }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        color: ${theme.colours.curiousBlue};
        transition: 0.1s;
        
        ${toggle && css`
            transform: scaleY(-1);
            transition: 0.1s;
        `}

        ${theme.breakpoints.down("sm")} {
            font-size: 34px;
        }
        
        &:hover {
            opacity: 0.7;
        }
    `;

    return (
        <div css={style}>
            <HiChevronDown cursor={"pointer"} />
        </div>
    );
};

export default SubChevron;