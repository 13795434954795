import { css } from "@emotion/react";
import fonts from "theme/fonts";

const typography = css`/* HEADERS */
    /* PARAGRAPHS */
    /* Default size depends on each page css */
    .typography  p { margin: 0; } /*font-size: 13px; line-height: 1.8; line-height: 20px; margin: 0 0 20px;*/
    .typography .intro {
        font-family: "CamboRegular", Georgia, "Times New Roman", Times, serif;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .typography em { font-style: italic; }
    .typography strong { font-weight: ${fonts.weights.bold} }

    ::selection,
    ::-moz-selection { /* Applies style to highlighted portion of a page */
        background: #b80000;
        color: #fff;
        text-shadow: none;
    }

    /* LINKS */
    .typography a,
    .typography a.intro {
        color: #1D8BDB;
        text-decoration: none;
    }
    .typography a:hover {
        color: #066AB2;
        //border-bottom: 1px dashed #B80000;
    }
    .typography a:focus {
    }

    /* LIST STYLES
    -------------------------------------------- */
    .typography ol { list-style: decimal; }
    .typography ul,
    .typography ol,
    .typography dl { margin: 0 0 10px 30px; } /*{ margin: 0 0 20px 25px; }*/
    .typography ul li { list-style-type: disc; } /* adds disc style bullet to the list */
    .typography li { margin-bottom: 5px; }

    /* TABLE STYLES
    -------------------------------------------- */
    .typography table {
        border-collapse: collapse; /* borders are collapsed into a single border when possible */
        border: 1px solid #d4d4d4;
        border-spacing: 0; /* The border-spacing property sets the distance between the borders of adjacent cells - acts as a backup to border-collapse: collapse */
        margin: 0 0 10px;
        text-align: left;
    }
    //.typography table tr:nth-child(even) {
    .typography table tr:nth-of-type(even) {
        background-color: #ededed
    }
    .typography table tr.even,
    .typography table th,
    .typography thead td {
        background-color: #ededed
    }
    .typography table td,
    .typography table th {
        padding: 2px 5px;
        border: 1px solid #d4d4d4;
        vertical-align: top;
    }
    .typography table th {
        font-weight: bold;
    }

    /* WYSIWYG EDITOR ALIGNMENT CLASSES
    -------------------------------------------- */
    .typography .text-left,
    .typography .left {
        text-align: left
    }
    .typography .text-center,
    .typography .center {
        text-align: center
    }
    .typography .text-right,
    .typography .right {
        text-align: right
    }
    .typography .text-justify {
        text-align: justify;
        text-justify: inter-word;
    }

    /* IMAGES
    -------------------------------------------- */
    .typography img {
        border: 5px solid #d7d7d7;
        height: auto; /* resets the image height so that it maintains its aspect ratio when width is set */
    }
    .typography img.left {
        float: left;
        max-width: 50%;
        margin: 5px 20px 10px 0;
    }
    .typography img.right {
        float: right;
        max-width: 50%; /* Responsive width */
        margin: 5px 0 10px 20px;
    }
    .typography img.leftAlone {
        float: left;
        margin-right: 100%;
        margin-bottom: 10px;
        clear: both;
    }
    .typography img.center {
        float: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 10px;
        clear: both;
    }
    .typography .captionImage { width: 100%; margin-top: 5px; }
    .typography .captionImage img { margin: 0; }
    .typography .captionImage.left {
        float: left;
        margin: 5px 30px 20px 0px;
    }
    .typography .captionImage.right {
        float: right;
        margin: 5px 0 20px 30px;
    }
    .typography .captionImage.left[style],
    .typography .captionImage.right[style] {
        max-width: 50%; /* Overides core width to make responsive */
    }
    .typography .captionImage.left img,
    .typography .captionImage.right img {
        float: none;
        max-width: none;
        width: 100%;
    }
    .typography .captionImage.left img {
        margin-right: -10px;
    }
    .typography .captionImage.right img {
        margin-left: -10px;
    }
    .typography .captionImage.right p {
        margin-left: -10px;
        text-align: left;
        margin-left: -10px;
    }
    .typography .captionImage.leftAlone {
        float: none;
        margin: 0 20px 20px 0px;
    }
    .typography .captionImage.center {
        margin: 0 auto 20px;
    }
    .typography .captionImage p {
        clear: both;
        margin: 5px 0;
        font-style: italic;
        color: #888;
    }

    .typography pre {
        background: #F7F7F7;
        border: 1px solid #E4E4E4;
        font-family: Courier, monospace;
        margin: 0 0 20px 0;
        padding: 15px;
        clear: both;
    }

    /* ADDRESS
    -------------------------------------------- */
    address {
        display: block;
        margin-bottom: 20px;
    }
`;

export default typography;