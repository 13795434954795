import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { css, useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";
import RoundTextButton from "core/Components/Buttons/RoundTextButton";
import CartIcon from "core/Components/Navigations/CartIcon";
import { LOGIN_URL } from "core/config/config";

type Props = {
    menuButton?: Button,
    closeBurgerMenu: ({ forcedClose }: any) => void,
    hideCart?: boolean,
};

const NavLinkButtons:React.FC<Props> = ({
    menuButton = undefined,
    closeBurgerMenu,
    hideCart = false,
}) => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();
    const linkURL = menuButton?.innerURL || menuButton?.outerURL;

    const onClickHandler = () => {
        closeBurgerMenu({ forcedClose: true });
    };

    const [
        sidePaddingGetStart,
        sidePaddingLogin,
        buttonWidth,
        buttonHeight,
        buttonFontSize,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [10, 10, 109, 36, 15];
            default:
                return [10, 10, 109, 36, 14];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const style = css`
        flex-direction: row;
        display: flex;
    `;

    return (
        <div css={style}>
            {!hideCart && (
                <CartIcon to={menuButton?.cartURL || ''} closeBurgerMenu={closeBurgerMenu} />
            )}

            {(menuButton?.buttonText && linkURL) && (
                <NavLink to={linkURL} onClick={onClickHandler} className={menuButton.trackingButtonName}>
                    <RoundTextButton
                        sidePadding={sidePaddingGetStart}
                        minWidth={buttonWidth}
                        height={buttonHeight}
                        fontSize={buttonFontSize}
                        textColour={"black"}
                        backgroundColour={theme.colours.gold}
                        backgroundHoverColour={theme.colours.mySin}
                        useBoxShadow={false}
                        noButtonBorderHover
                    >
                        {menuButton.buttonText}
                    </RoundTextButton>
                </NavLink>
            )}

            <a href={LOGIN_URL} key={"/login"} className={menuButton?.trackingLoginButtonName}>
                <RoundTextButton
                    sidePadding={sidePaddingLogin}
                    width={buttonWidth}
                    height={buttonHeight}
                    fontSize={buttonFontSize}
                    textColour={theme.colours.white}
                    backgroundColour={theme.colours.curiousBlue}
                    backgroundHoverColour={theme.colours.malibu3}
                    buttonBorder={1}
                    useBoxShadow={false}
                    noButtonBorderHover
                >
                    Log in
                </RoundTextButton>
            </a>
        </div>
    );
};

export default NavLinkButtons;