import React from "react";
import { css, useTheme } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    image: string | Image,
    useFocusPoint?: boolean,
    title?: string,
    titleColourOnImage?: string,
    showTitleOnImage?: boolean,
};

const ItemImage: React.FC<Props> = ({
    image,
    useFocusPoint,
    title = "",
    titleColourOnImage = "black",
    showTitleOnImage = false,
}) => {
    const theme = useTheme();

    const style = css`
        ${!image && css`
            box-shadow: ${theme.borderAndShadow.boxInsetBottomShadow};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            height: 194px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            height: 162px;
        }

        ${theme.breakpoints.only("xl")} {
            height: 145px;
        }

        ${theme.breakpoints.down("lg")} {
            height: 121px;
        }
    `;

    const titleStyle = css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 50px;
        height: 100%;
        font-weight: ${theme.fonts.weights.bold};
        color: ${titleColourOnImage};
        
        ${theme.breakpoints.up("5xl")} {
            margin: 0 115px;
            font-size: 23px;
            line-height: 28px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin: 0 100px;
            font-size: 20px;
            line-height: 24px;
        }

        ${theme.breakpoints.only("xl")} {
            margin: 0 95px;
            font-size: 17px;
            line-height: 20px;
        }

        ${theme.breakpoints.down("lg")} {
            margin: 0 75px;
            font-size: 14px;
            line-height: 16px;
        }
    `;

    return (
        <div css={style}>
            {image && (
                <Image image={image} useFocusPoint={useFocusPoint}/>
            )}

            {showTitleOnImage && (
                <div css={titleStyle}>{title}</div>
            )}
        </div>
    );
};

export default ItemImage;
