import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import Title from "core/Components/Title";

type Props = {
    card: ImageVerticalCard,
    headerRef?: any,
};

const CardHeader: React.FC<Props> = ({ card, headerRef }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const { headerColour } = colourTheme;
    const hasContentList = !isEmpty(card.contentList);

    const headerStyle = css`
        display: flex;
        align-items: flex-start;
        width: 100%;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};

        ${hasContentList && css`
            margin-bottom: 40px;
            
            ${theme.breakpoints.between("lg", "xl")} {
                margin-bottom: 28px;
            }

            ${theme.breakpoints.only("md")} {
                margin-bottom: 32px;
            }

            ${theme.breakpoints.down("sm")} {
                margin-bottom: 28px;
            }
        `};
    `;

    return (
        <Title Tag={"h3"} fontSizeType={"4"} css={headerStyle} containerRef={headerRef}>
            {card.title || card.header}
        </Title>
    );
}

export default CardHeader;
