import React, { useEffect, useMemo, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import FormModal from "core/Components/FormModal";
import HubspotForm from "core/Components/HubspotForm";
import Gallery from "core/Components/Item/Gallery";
import ExtraInformationContainer from "core/Components/Item/ExtraInformationContainer";
import ItemButtonGroup from "core/Components/Item/ItemButtonGroup";
import ItemChevron from "core/Components/Item/ItemChevron";
import ItemSection from "core/Components/Item/ItemSection";
import TitleAndText from "core/Components/Item/TitleAndText";
import LeftTextContainer from "core/Components/Item/LeftTextContainer";
import RightContainer from "core/Components/Item/RightContainer";
import LeftContainer from "core/Components/Item/LeftContainer";
import MainHeading from "core/Components/Item/MainHeading";
import Price from "core/Components/Item/Price";
import Quote from "core/Components/Item/Quote";
import Review from "core/Components/Item/Review";
import RightTextContainer from "core/Components/Item/RightTextContainer";
import ItemWrapper from "core/Components/Item/ItemWrapper";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import SectionContainer from "core/Components/SectionContainer";
import useBreakpoint from "core/hooks/useBreakpoint";
import TermsAndConditionsText from "core/Components/Item/TermsAndConditionsText";
import ContentCombine from "core/Components/ContentCombine";
import Writer from "core/Components/Item/Writer";
import LabelBox from "core/Components/Item/LabelBox";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    data: EventPage,
};

const EventPage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [, setAppState] = useAppState();
    const { minWidth } = useBreakpoint();
    const hasBackHistory = window.history.state.idx !== 0;

    useEffect(() => {
        setAppState({
            currentPageName: data.title
        });
    }, [data.title, setAppState]);

    const sortedScripts = useMemo(() => {
        const clickableScripts = data.googleScriptTags.filter((scriptTag: GoogleScriptTag) => scriptTag.isOnClick);
        const unclickableScripts = data.googleScriptTags.filter((scriptTag: GoogleScriptTag) => !scriptTag.isOnClick);
        return {
            clickableScripts,
            unclickableScripts
        };
    }, [data.googleScriptTags]);

    useEffect(() => {
        if ((window as any).gtag){
            sortedScripts.unclickableScripts.forEach((script: GoogleScriptTag) => {
                (window as any).gtag(script.tag)
            })
        }
    }, [sortedScripts.unclickableScripts]);

    const colourTheme = data?.theme ? JSON.parse(data?.theme) : null;

    const [showingPopup, setShowingPopup] = useState(false);
    const [videoPath, setVideoPath] = useState('');

    const onShowPopupClick = () => {
        setShowingPopup(true);
        if ((window as any).gtag){
            sortedScripts.clickableScripts.forEach((script: GoogleScriptTag) => {
                (window as any).gtag(script.tag)
            })
        }
    };

    const onClosePopupClick = () => setShowingPopup(false);
    const onShowVideoClick = (videoPath: string) => setVideoPath(videoPath);
    const onCloseVideoClick = () => setVideoPath('');

    const [chevronSize, chevronColour, chevronBackground, chevronBackgroundHover] = useMemo(() => {
        const white = theme.colours.white;
        const grey = theme.colours.grey[500];
        const hoverColour = theme.colours.azureRadiance;

        switch (true) {
            case minWidth >= theme.breakpoints.sizes['5xl']:
                return [41, white, grey, hoverColour];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [32, white, grey, hoverColour];
            default:
                return [24, white, grey, hoverColour];
        }
    }, [
        minWidth,
        theme.colours.white,
        theme.colours.grey,
        theme.breakpoints.sizes,
        theme.colours.azureRadiance
    ]);

    const extraDataInformationStyle = css`
        font-size: 20px;
        line-height: 24px;

        ${theme.breakpoints.down('sm')} {
            font-size: 18px;
            line-height: 22px;
        }
    `;

    const priceStyle = css`
        margin-top: 45px;   

        ${theme.breakpoints.between('xxl', '4xl')} {
            margin-top: 40px;
        }
        
        ${theme.breakpoints.down('md')} {
            margin-top: 35px;
        }
    `;

    return (
        <SectionContainer>
            <ItemChevron chevronSize={chevronSize}>
                {hasBackHistory && (
                    <RoundChevronButton
                        type={"left"}
                        onClick={() => navigate(-1)}
                        roundColour={chevronBackground}
                        roundHoverColour={chevronBackgroundHover}
                        chevronColour={chevronColour}
                        size={chevronSize}
                    />
                )}
            </ItemChevron>
            <ItemWrapper>
                <ItemSection fontColour={"black"}>
                    <LeftContainer>
                        {data?.imagesAndVideos && (
                            <Gallery imagesAndVideos={data.imagesAndVideos} onVideoClick={onShowVideoClick} />
                        )}
                        <LeftTextContainer>
                            {data?.specification && data?.specification.length > 0 && (
                                <TitleAndText title={data?.specificationHeader} titleColour={"black"} text={data.specification} />
                            )}
                        </LeftTextContainer>
                    </LeftContainer>
                </ItemSection>
                <ItemSection fontColour={theme.colours.white} backgroundColour={colourTheme?.colour}>
                    <RightContainer>
                        <MainHeading text={data?.title} textColour={data?.textColour} />
                        {data?.information && data?.information.length > 0 && (
                            <ExtraInformationContainer>
                                <LabelBox data={data} title={'Location'} labelColour={colourTheme?.hoveredColour} />
                                <LabelBox data={data} title={'Date'} labelColour={colourTheme?.hoveredColour} />
                                {(!data.eventSubSchedules?.length || data.useCommonTime) && (
                                    <LabelBox data={data} title={'Time'} labelColour={colourTheme?.hoveredColour} />
                                )}
                                <ContentCombine css={extraDataInformationStyle}>
                                    {data.information}
                                </ContentCombine>
                            </ExtraInformationContainer>
                        )}
                        <Price
                            price={data.price?.label || data?.price?.amount}
                            subLabel={data.price?.subLabel}
                            textColour={data.textColour}
                            css={priceStyle}
                        />
                        {data?.isShowingReviews && (
                            <Review stars={5} textColour={data?.textColour} />
                        )}
                        {(data?.isShowingCart || data?.videoPath || !!data?.hubspotFormId?.length) && (
                            <ItemButtonGroup
                                isShowingCart={data.isShowingCart}
                                videoButtonText={data.videoButtonText}
                                isShowingRegister={data?.hubspotFormId?.length > 0}
                                onRegisterClick={onShowPopupClick}
                                trackingLabel={data.trackingRegisterButtonName}
                            />
                        )}
                        <RightTextContainer>
                            <TitleAndText text={data?.description} alignment={"center"} titleColour={data?.textColour} />
                        </RightTextContainer>
                        <Quote text={data?.quote} textColour={data?.textColour} />
                        <Writer>{data?.writer}</Writer>
                        {data?.termsAndConditions && (
                             <TermsAndConditionsText text={data.termsAndConditions} textColour={data?.textColour} />
                        )}
                    </RightContainer>
                </ItemSection>
            </ItemWrapper>

            {(data?.hubspotFormId) && (
                <FormModal
                    css={formModalStyle}
                    isVisible={showingPopup}
                    onClose={onClosePopupClick}
                    isFixed
                >
                    <MainHeading text={data?.title} textColour={theme.colours.curiousBlue} />
                    <HubspotForm hubspotFormId={data.hubspotFormId} shouldLoadScript={showingPopup} />
                </FormModal>
            )}

            {videoPath && (
                <VideoViewContainer clearPlayVideo={onCloseVideoClick}>
                    <VideoViewLayer>
                        <VideoPlayer videoPath={videoPath} autoPlay />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}
        </SectionContainer>
    );
};

const formModalStyle = css`
    top: 70px;
`;

export default EventPage;
