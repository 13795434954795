import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const TimelineSectionContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const timelineSectionStyle = css`
        padding-top: 120px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 193px;

        ${theme.breakpoints.up("5xl")} {
            padding-top: 290px;
            row-gap: 440px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            padding-top: 80px;
            row-gap: 40px;
        }

        ${theme.breakpoints.only("md")} {
            display: flex;
            flex-direction: column;
            row-gap: 0;
            padding-top: 92px;
        }

        ${theme.breakpoints.down("sm")} {
            display: flex;
            flex-direction: column;
            row-gap: 0;
            padding-top: 36px;
        }
    `;

    return (
        <ContentSection css={timelineSectionStyle}>
            {children}
        </ContentSection>
    );
}

export default TimelineSectionContainer;
