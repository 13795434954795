import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    className?: string,
    children: React.ReactNode,
    padding?: string,
    fontSize?: number,
    lineHeight?: number,
};

const Disclaimer: React.FC<Props> = ({
    className = '',
    children,
    padding = '24px 50px',
    fontSize = 0,
    lineHeight = 0,
}) => {
    const theme = useTheme();

    const styles = css`
        margin: 30px 0 20px 0;
        padding: ${padding};
        font-size: ${fontSize || 16}px;
        line-height: ${lineHeight || 18}px;
        border-radius: 21px;
        background-color: ${theme.colours.onahau};
        
        ${theme.breakpoints.down('sm')} {
            font-size: ${fontSize || 12}px;
            line-height: ${lineHeight || 14}px;
        }
    `;

    return (
        <div css={styles} className={className}>{children}</div>
    )
};

export default Disclaimer;