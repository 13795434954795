import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import VideoImage from "elements/Components/SliderWithVideo/components/VideoImage";

type Props = {
    video: SlideWithVideo,
    onClickVideoHandler: (video: SlideWithVideo) => void,
    children: React.ReactNode,
};

const SlideImageAndTextContainer: React.FC<Props> = ({
    video,
    onClickVideoHandler,
    children,
}) => {
    const theme: Theme = useTheme();

    const videoImageLayoutStyle = css`
        width: 286px;
        
        ${theme.breakpoints.up("5xl")} {
            width: 388px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 328px;
        }

        ${theme.breakpoints.only("xl")} {
            width: 273px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            width: 197px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 130px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 220px;
        }
    `;

    return (
        <VideoImage
            video={video}
            onPlayVideo={onClickVideoHandler}
            borderRadius={0}
            css={videoImageLayoutStyle}
        >
            {children}
        </VideoImage>
    );
};

export default SlideImageAndTextContainer;
