import { pick } from "lodash";
import { v4 as uuidv4 } from "uuid";
import document from 'core/includes/document';

// Basic caching.
let CAN_RENDER_WEBP: any = undefined;

export const getCanRenderWebp = () => {
    if (CAN_RENDER_WEBP !== undefined) return CAN_RENDER_WEBP;

    const canvas = document?.createElement('canvas');
    // add protection for ssr, we just return true if it can't load canvas
    CAN_RENDER_WEBP = !canvas || !canvas?.toDataURL || canvas?.toDataURL('image/webp')?.indexOf('data:image/webp') === 0;
    return CAN_RENDER_WEBP;
};

export const imageSizes = Object.freeze({
    HUGE: "huge",
    BIG: "big",
    XXLARGE: "xxlarge",
    XLARGE: "xlarge",
    LARGE: "large",
    MEDIUM: "medium",
    SMALL: "small",
    THUMB: "thumb",
});

// Should match ImageService.php.
export const imageWidths: any = Object.freeze({
    [imageSizes.HUGE]: 3500,
    [imageSizes.BIG]: 2800,
    [imageSizes.XXLARGE]: 2250,
    [imageSizes.XLARGE]: 1799,
    [imageSizes.LARGE]: 1479,
    [imageSizes.MEDIUM]: 999,
    [imageSizes.SMALL]: 599,
    [imageSizes.THUMB]: 179,
});

// Biggest to largest, which is important for logic that uses it.
export const imageSizesOrder = Object.freeze([
    imageSizes.HUGE,
    imageSizes.BIG,
    imageSizes.XXLARGE,
    imageSizes.XLARGE,
    imageSizes.LARGE,
    imageSizes.MEDIUM,
    imageSizes.SMALL,
    imageSizes.THUMB,
]);

/**
 * Extract just image fields from imageData containing image URLs for different sizes.
 * @param {object} imageData e.g. { id: 123, typename: 'Image', thumb: 'thumb.png', small: 'small.png'  }
 * @param {array} blacklist Array of image sizes to skip.
 * @param {array} whitelist Array of image sizes. Skip others.
 * @param {boolean} debugMode Add size query string to URLs e.g. `?small`. Default false.
 * @param {boolean} cacheBust Add cache-busting string to URLs e.g. `?abc-123-def`. Default false.
 * @returns {{ size: url }}
 */
export const getImageUrls = (
    imageData: any,
    {
        blacklist= [],
        whitelist = [],
        debugMode = false,
        cacheBust = false
    } = {}
) => {
    if (blacklist.length && whitelist.length) {
        throw new Error("Cannot use `getImageUrls` with both a blacklist and a whitelist.");
    }

    let _imageSizesOrder = [...imageSizesOrder];

    if (blacklist.length) {
        const bl: any[] = blacklist;
        _imageSizesOrder = _imageSizesOrder.filter(key => !bl.includes(key));
    } else if (whitelist.length) {
        const wl: any[] = whitelist;
        _imageSizesOrder = _imageSizesOrder.filter(key => wl.includes(key));
    }

    const urls = pick<any>(imageData, _imageSizesOrder);

    if (debugMode || cacheBust) {
        for (const key in urls) {
            urls[key] += `?${debugMode ? key : ''}${debugMode && cacheBust ? '&' : ''}${cacheBust ? uuidv4() : ''}`;
        }
    }

    return urls;
}

/**
 * From an array of image sizes, return a new sorted array of image sizes from largest to smallest.
 * @param sizes array
 * @returns {[string]}
 */
const getSortedSizes = (sizes: any[]) => (
    [...sizes].sort((a, b) => imageSizesOrder.indexOf(a) - imageSizesOrder.indexOf(b))
);

/**
 * From an array of image sizes, return the smallest.
 * @param sizes array
 * @example
 * getSmallestImage(['thumb', 'huge']); // returns 'thumb'
 * @returns {string}
 */
export const getSmallestSize = (sizes: any[]) => {
    const sortedSizes = getSortedSizes(sizes);
    return sortedSizes[sortedSizes.length - 1];
};

/**
 * From an array of image sizes, return the largest.
 * @param sizes array
 * @example
 * getSmallestImage(['thumb', 'huge']); // returns 'huge'
 * @returns {string}
 */
export const getLargestSize = (sizes: any[]) => getSortedSizes(sizes)[0];

/**
 * From an array of image sizes, and the width that the image will show, return the appropriate image size.
 * @param theImageSizes array
 * @param width
 * @example
 * getImageForWidth(['thumb', 'huge'], 3000); // returns 'huge'
 * @returns {string}
 */
export const getImageForWidth = (theImageSizes: any[], width: number) => {
    const sortedSizes = getSortedSizes(theImageSizes);

    for (let i = 0; i < sortedSizes.length; i++) {
        const size = sortedSizes[i];

        if (width > imageWidths[size]) {
            return sortedSizes[i - 1] || size;
        }
    }

    return sortedSizes[sortedSizes.length - 1];
};

export const getImageUrlsSrcSet = (
    imageResponseData: any,
    {
        blacklist = [],
        whitelist = []
    } = {}
) => (
    Object.entries(getImageUrls(imageResponseData, { blacklist, whitelist }))
        .map(([key, url]) => `${url} ${imageWidths[key]}w`)
        .join(",")
);

const v = {
    getCanRenderWebp,
    imageSizes,
    imageWidths,
    imageSizesOrder,
    getImageUrls,
    getSmallestSize,
    getLargestSize,
    getImageForWidth,
    getImageUrlsSrcSet
};

export default v;
