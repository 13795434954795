import React from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
};

const SlideFeedback: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();
    const Tag = "p";
    const [fontSize, lineHeight] = useFontSize(Tag, '11');

    const style = css`
        font-weight: ${theme.fonts.weights.bold};
        white-space: break-spaces;
        text-align: center;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
    `;

    return (
        <Tag css={style}>
            {children}
        </Tag>
    );
};

export default SlideFeedback;
