import React from "react";
import { css } from "@emotion/react";

import { sort } from "core/includes/sort";
import SubBox from "elements/Components/CollapseList/SubBox";

type Props = {
    data: CollapseList,
};

const SubContainer: React.FC<Props> = ({ data }) => {

    const autoNumbering = data.autoNumbering;
    const collapseItems = sort(data.collapseItems);

    const style = css`
        width: 100%;
    `;

    return (
        <div css={style}>
            {collapseItems && collapseItems.map((item: CollapseItem, index: number) => (
                <SubBox
                    key={`collapseList-${item.id}`}
                    data={item}
                    autoNumbering={autoNumbering}
                    number={index}
                />
            ))}
        </div>
    );
};

export default SubContainer;