import React from "react";
import { isEmpty } from 'lodash';
import { ApolloProvider } from "@apollo/client";
import client from "pages/includes/apollo";

import SectionContainer from "core/Components/SectionContainer";
import useSetState from "core/hooks/useSetState";
import { sort } from "core/includes/sort";

import ContentSection from "core/Components/ContentSection";
import Features from "elements/Components/SubscriptionPlan/Features";
import Header from "elements/Components/SubscriptionPlan/components/Header";
import PricingCard from "elements/Components/SubscriptionPlan/components/PricingCard/PricingCard";
import PricingCardsContainer from "elements/Components/SubscriptionPlan/components/PricingCardsContainer";
import Text from "elements/Components/SubscriptionPlan/components/Text";
import Wrapper from "elements/Components/SubscriptionPlan/components/Wrapper";

type DefaultState = {
    personalCost: number,
    familyCost: number,
};

const DEFAULT_STATE = {
    personalCost: 0,
    familyCost: 0,
};

type Props = {
    data: SubscriptionPlan
};

const SubscriptionPlan: React.FC<Props> = ({ data }) => {
    const [state, setState] = useSetState<DefaultState>(DEFAULT_STATE);
    const pricingCardList = sort(data.pricingCardList);

    return isEmpty(pricingCardList) ? null : (
        <ApolloProvider client={client}>
            <SectionContainer>
                <Wrapper>
                    <ContentSection>
                        <Header>
                            {data.header}
                        </Header>
                    </ContentSection>

                    <PricingCardsContainer setState={setState}>
                        {pricingCardList.map((pricingCard: PricingCard) => (
                            <PricingCard key={pricingCard.id} data={pricingCard} stripePrices={state} />
                        ))}
                    </PricingCardsContainer>

                    <ContentSection>
                        <Text>
                            {data.text}
                        </Text>
                    </ContentSection>

                    <Features />
                </Wrapper>
            </SectionContainer>
        </ApolloProvider>
    );
}

export default SubscriptionPlan;
