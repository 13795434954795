import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
}

const BannerTitle: React.FC<Props> = ({
    children
}) => {
    const theme = useTheme();

    const style = css`
        width: 70%;
        color: ${theme.colours.white};
        filter: drop-shadow(${theme.borderAndShadow.dropShadow2});
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up("xxl")} {
            font-size: 109px;
            line-height: 115px;
            margin-bottom: 26px;
        }

        ${theme.breakpoints.between("md", "xl")} {
            font-size: 70px;
            line-height: 80px;
            margin-bottom: 10px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 45px;
            line-height: 56px;
            margin-bottom: 10px;
        }
        
        ${theme.breakpoints.down("md")} {
            width: 100%;
        }
    `;

    return (
        <h1 css={style}>{children}</h1>
    );
};

export default BannerTitle;