import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import DropdownPlaceholderContainer from "core/Components/Dropdown/HeaderCountryDropdown/DropdownPlaceholderContainer";
import DropdownPlaceholder from "core/Components/Dropdown/HeaderCountryDropdown/DropdownPlaceholder";
import DropdownChevron from "core/Components/Dropdown/HeaderCountryDropdown/DropdownChevron";
import DropdownOptions from "core/Components/Dropdown/HeaderCountryDropdown/DropdownOptions";
import DropdownOptionItem from "core/Components/Dropdown/HeaderCountryDropdown/DropdownOptionItem";

type Props = {
    countries?: [CountryItem],
    selectedCountry?: string,
    setCountryHandler: (countryCode: string) => void,
};

const DEFAULT_CHEVRON_SIZE = 15;

const DEFAULT_COUNTRY_GLOBAL: CountryItem = {
    id: '0',
    code: 'other',
    name: 'Global',
    useFocusPoint: false,
    image: undefined,
};

const NavCountryDropdown: React.FC<Props> = ({
    countries = undefined,
    selectedCountry = '',
    setCountryHandler,
}) => {
    const theme: Theme = useTheme();
    const [showOption, setShowOption] = useState(false);
    const placeholderContainerRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);

    const countryOptions = countries ? [...countries, DEFAULT_COUNTRY_GLOBAL] : [DEFAULT_COUNTRY_GLOBAL];
    const country = countryOptions?.find((item: CountryItem) => item.code === selectedCountry) ?? DEFAULT_COUNTRY_GLOBAL;

    const showOptionHandler = useCallback(() => {
        setShowOption(prevShowOptions => !prevShowOptions);
    }, [setShowOption]);

    useEffect(() => {
        const clickHandler = (e: any) => {
            if (placeholderContainerRef.current && !placeholderContainerRef.current.contains(e.target)) {
                setShowOption(false);
            }
        };

        window.addEventListener("click", clickHandler);

        return () => {
            window.removeEventListener("click", clickHandler);
        };
    }, []);

    const style = css`
        height: 100%;
        
        ${theme.breakpoints.between("sm", "lg")} {
            padding-right: ${theme.sizes.menu.sideMargin}px;
        }
    `;

    return (
        <div css={style}>
            <DropdownPlaceholderContainer
                showOptionsHandler={showOptionHandler}
                ref={placeholderContainerRef}
            >
                <DropdownPlaceholder
                    countryName={country.name}
                    showOptions={showOption}
                    ref={placeholderRef}
                />
                <DropdownChevron size={DEFAULT_CHEVRON_SIZE} showOptions={showOption} />
            </DropdownPlaceholderContainer>

            <DropdownOptions
                showOptions={showOption}
                placeholderWidth={placeholderRef?.current?.offsetWidth}
                chevronSize={DEFAULT_CHEVRON_SIZE}
            >
                {countryOptions?.map((item: CountryItem, index) => (
                    item.code === country.code
                        ? null
                        : <DropdownOptionItem
                            key={`country-code-list-${index}`}
                            countryItem={item}
                            setCountryHandler={setCountryHandler}
                        />
                ))}
            </DropdownOptions>
        </div>
    );
};

export default memo(NavCountryDropdown);