import { css, Global, useTheme } from "@emotion/react";
import { isEmpty, isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Wrapper from "core/Components/Wrapper";
import BannerForAdvertisement from "core/Components/BannerForAdvertisement/BannerForAdvertisement";
import PageSection from "pages/Components/ListPage/PageSection";
import Loading from "core/Components/Utils/Loading";
import PageHeaderAndDropdownsContainer
    from "pages/Components/ProductListPage/components/PageHeaderAndDropdownsContainer";
import PageHeader from "pages/Components/ProductListPage/components/PageHeader";
import DropdownContainer from "pages/Components/ProductListPage/components/DropdownContainer";
import DropdownButton from "core/Components/Buttons/DropdownButton/DropdownButton";
import {
    DROPDOWN_BACKGROUND_COLOUR,
    ORDER_RESPONSE_MESSAGE,
    PAYMENT_OPTIONS,
    PRODUCT_GROUP_INIT_OPTION,
    PRODUCT_PRICE_RANGE_OPTIONS,
    PRODUCT_SORT_OPTIONS, PRODUCT_SUITABILITY_INIT_OPTION,
    SORT_DROPDOWN_OPTION_BACKGROUND_COLOUR,
    SORT_DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR
} from "pages/Components/ProductListPage/includes/constants";
import ProductsSection from "pages/Components/ProductListPage/components/ProductsSection";
import NoData from "core/Components/Errors/NoData";
import ProductContainer from "pages/Components/ProductListPage/components/ProductContainer";
import DiscountedPriceAndProductImageContainer
    from "pages/Components/ProductListPage/components/DiscountedPriceAndProductImageContainer";
import DiscountedPriceImage from "pages/Components/ProductListPage/components/DiscountedPriceImage";
import ProductImageContainer from "pages/Components/ProductListPage/components/ProductImageContainer";
import ProductImage from "pages/Components/ProductListPage/components/ProductImage";
import ProductText from "pages/Components/ProductListPage/components/ProductText";
import ContentSection from "core/Components/ContentSection";
import Price from "pages/Components/ProductListPage/components/Price";
import RoundQuantityButton from "core/Components/Buttons/QuantityButton/RoundQuantityButton";
import Description from "pages/Components/ProductListPage/components/Description";
import BorderForSpaces from "pages/Components/ProductListPage/components/BorderForSpaces";
import GuidanceAndOrderButtonContainer
    from "pages/Components/ProductListPage/components/GuidanceAndOrderButtonContainer";
import GuidanceText from "pages/Components/ProductListPage/components/GuidanceText";
import OrderButton from "pages/Components/ProductListPage/components/OrderButton";
import ContentCombine from "core/Components/ContentCombine";
import Disclaimer from "pages/Components/ProductListPage/components/Disclaimer";
import OrderForm from "pages/Components/ProductListPage/components/OrderForm";
import SectionContainer from "core/Components/SectionContainer";
import useToast from "core/hooks/useToast";
import useListBreakpoint from "pages/Components/ListPage/hooks/useListBreakpoint";
import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { CREATE_RESOURCE_ORDER, CREATE_STRIPE_PAYMENT_INTENT, VALIDATE_PRODUCT_DISCOUNT } from "core/includes/queries";
import { isOnly, isUp } from "theme/breakpoints";
import useProductListPageData from "pages/Components/ProductListPage/hooks/useProductListPageData";
import { getTaxLabel, getTaxRateByCountry } from "core/includes/finance";
import { formatNumber } from "core/includes/numbers";
import { FieldList } from "core/hooks/useForm";
import { getShippingRate } from "core/includes/packing";
import { hasFeatureFlagBasedOnCountryCode } from "core/includes/site";
import { FLAG_GB_STRIPE_PAYMENTS } from "core/includes/featureFlags";
import { useAppState } from "core/contexts/AppContext";
import { improveDecimals } from "core/includes/formatters";

type DefaultState = {
    minPriceRange?: number,
    maxPriceRange?: number,
    productGroup: Option,
    productSuitability: Option,
    priceRange: Option,
    sort: Option,
    sortField?: string,
    sortDirection?: string,
    showingOrderForm: boolean,
    productListWithQty?: any,
    isSubmitting: boolean,
    showOrderGuidance: boolean,
    cardErrorMessage: string,
    discountCode?: DiscountCode,
}

const INITIAL_STATE = {
    productGroup: PRODUCT_GROUP_INIT_OPTION,
    productSuitability: PRODUCT_SUITABILITY_INIT_OPTION,
    priceRange: PRODUCT_PRICE_RANGE_OPTIONS[0],
    sort: PRODUCT_SORT_OPTIONS[0],
    sortField: PRODUCT_SORT_OPTIONS[0].value,
    sortDirection: "DESC",
    showingOrderForm: false,
    productListWithQty: {},
    isSubmitting: false,
    showOrderGuidance: false,
    cardErrorMessage: "",
    discountCode: undefined,
}

type Props = {
    page: ProductListPage,
};

type PriceRangeType = {
    value: string,
    label: string,
}

const ProductList = ({ page }: Props) => {
    const theme = useTheme();
    const [appState] = useAppState();
    const [toastSuccess, toastError] = useToast();
    const { bannerHeight } = useListBreakpoint();
    const { breakpoint } = useBreakpoint();

    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const [createResourceOrder] = useMutation(CREATE_RESOURCE_ORDER);
    const [createStripePaymentIntent] = useMutation(CREATE_STRIPE_PAYMENT_INTENT);
    const [validatePromoCode] = useMutation(VALIDATE_PRODUCT_DISCOUNT);

    const stripe: any = useStripe();
    const elements: any = useElements();

    const disclaimer = `Copyright Notice: The resources acquired from Writer's Toolbox are
        copyright protected, and any unauthorised making, usage, distribution, importation, or sale by third parties requires
        the explicit consent of the patent owner.`;

    const [image, disclaimerPadding] = useMemo(() => {
        switch (true) {
            case isUp(breakpoint, '3xl'):
                return [page.landscape || page.portrait, '35px 300px'];
            case isOnly(breakpoint, 'xxl'):
                return [page.landscape || page.portrait, '35px 270px'];
            case isOnly(breakpoint, 'xl'):
                return [page.landscape || page.portrait, '35px 240px'];
            case isOnly(breakpoint, 'lg'):
                return [page.landscape || page.portrait, '35px 159px'];
            case isOnly(breakpoint, 'md'):
                return [page.portrait || page.landscape, '35px 45px'];
            case isOnly(breakpoint, 'sm'):
                return [page.portrait || page.landscape, '35px 36px'];
            default:
                return [page.portrait || page.landscape, '24px'];
        }
    }, [breakpoint, page]);

    const useAFocusPoint = (isEqual(image, page.landscape) && page.useFocusPoint) ||
        (isEqual(image, page.portrait) && page.useFocusPointPortrait);

    const {
        productList,
        loading,
        productGroupOptions,
        productSuitabilityOptions,
        countryCode,
        selectedCountryCode,
    } = useProductListPageData(
        page,
        state.minPriceRange,
        state.maxPriceRange,
        state.productGroup,
        state.productSuitability,
        state.sortField,
        state.sortDirection,
    );

    const currency = useMemo(() => countryCode.currency || "$", [countryCode]);
    const taxPrefix = useMemo(() => page.isTaxIncluded ? 'incl.' : 'excl.', [page.isTaxIncluded]);

    useEffect(() => {
        setState({ productListWithQty: {} });
    }, [countryCode, setState]);

    const hasFeatureFlag = useMemo(
        () => hasFeatureFlagBasedOnCountryCode(countryCode?.code, appState.featureFlags, FLAG_GB_STRIPE_PAYMENTS),
        [countryCode?.code, appState.featureFlags]
    );

    // Set init productListWithQty state with 0 of each product quantity value
    const initProductListWithQty = useCallback((isReset?: boolean) => {
        if (!isEmpty(productList)) {
            const productListWithQty = productList.reduce((obj: any, product: any) => {
                obj[`${product.id}`] = { ...product, quantity: 0 };
                return obj;
            }, {});

            setState((prev: DefaultState) => {
                if (isEmpty(prev.productListWithQty) || isReset) {
                    return { productListWithQty }
                }
            });
        }
    }, [productList, setState]);

    useEffect(() => {
        initProductListWithQty();
    }, [initProductListWithQty]);

    const setPriceRange = (priceRange: Option) => {
        const { value } = priceRange;
        const minPriceRange = value.split("/")?.[0];
        const maxPriceRange = value.split("/")?.[1];

        setState({ minPriceRange, maxPriceRange, priceRange });
    };

    const setSortOption = (sort: Option) => {
        const { value } = sort;
        const sortField = value.split("/")?.[0];
        const sortDirection = value.split("/")?.[1] || "DESC";

        setState({ sort, sortField, sortDirection });
    };

    const subtotal = useMemo(() => {
        const totalPrice = Object.values(state.productListWithQty).reduce((sum: number, cur: any) => {
            sum = sum + cur?.quantity * ((cur?.shouldDiscount && cur?.price?.discountedPrice) || cur?.price?.amount);
            return sum;
        }, 0);

        if(!!totalPrice && state.showOrderGuidance) {
            setState({ showOrderGuidance: false });
        }

        return improveDecimals(totalPrice);
    }, [state.productListWithQty, setState, state.showOrderGuidance]);

    const onShowOderForm = () => {
        if(!!subtotal){
            setState({ showingOrderForm: true });
        } else {
            setState({ showOrderGuidance: true });
        }
    }

    const productsWithDiscount = useMemo(() => {
        const productsWithDiscount: ProductsWithDiscount = {};
        if (!state.discountCode) return productsWithDiscount;

        const productDiscounts: ProductDiscount[] = state.discountCode.productDiscounts ?? [];
        productDiscounts.forEach((discount: ProductDiscount) => {
            discount.products.forEach((product) => {
                productsWithDiscount[product.id] = discount.percentDiscount;
            })
        })

        return productsWithDiscount;
    }, [state.discountCode]);

    const onCloseOderForm = () => setState({ showingOrderForm: false, discountCode: undefined, cardErrorMessage: "" });

    const [orderList, products, totalDiscounts] = useMemo(() => {
        const products: OrderListItem[] = [];
        let totalDiscounts = 0;
        const orderList = Object.values(state.productListWithQty)
            .filter((item: any) => item.quantity)
            .map((item: any) => {
                const totalAmount = parseFloat(formatNumber(item.quantity * ((item.shouldDiscount && item.price.discountedPrice) || item.price.amount)));

                let discountedPrice = 0;
                if (!!productsWithDiscount[item.id]) {
                    discountedPrice = improveDecimals(totalAmount * productsWithDiscount[item.id] / 100);
                }

                const obj = {
                    id: item.id,
                    item: item.title,
                    weight: item.weight,
                    depth: item.depth,
                    width: item.width,
                    height: item.height,
                    quantity: formatNumber(item.quantity),
                    price: formatNumber((item.shouldDiscount && item.price.discountedPrice) || item.price.amount),
                    discountedPrice,
                    total: totalAmount,
                };

                products.push({
                    id: item.id,
                    name: item.title,
                    quantity: parseInt(formatNumber(item.quantity)),
                    totalAmount: totalAmount,
                    discountedPrice,
                });

                totalDiscounts += discountedPrice;

                return JSON.stringify(obj);
            });

        return [orderList, products, totalDiscounts]
    }, [productsWithDiscount, state.productListWithQty]);

    const shippingPrice = useMemo(() => (
        improveDecimals(getShippingRate(orderList?.map((obj: any) => JSON.parse(obj)), countryCode.shippingRates))
    ), [orderList, countryCode?.shippingRates]);

    useEffect(() => {
        if (hasFeatureFlag && shippingPrice < 0) {
            if (orderList?.length) {
                toastError('Error calculating shipping. Please contact us.');
            } else {
                setState({ showingOrderForm: false });
            }
        }
    }, [hasFeatureFlag, orderList, countryCode, shippingPrice, setState, toastError]);

    const resetFormData = useCallback(() => {
        setState({
            showingOrderForm: false,
            productDiscount: undefined
        });

        const reset = true;
        initProductListWithQty(reset);
    }, [setState, initProductListWithQty]);

    const getMaxNumber = (number: number) => {
        if (number > 9999) return 9999;
        return number;
    }

    const resetForm = useCallback(() => {
        resetFormData();
        toastSuccess(ORDER_RESPONSE_MESSAGE.success);
    }, [resetFormData, toastSuccess]);

    const discountedSubtotal = improveDecimals(subtotal - totalDiscounts);
    const taxAmount = hasFeatureFlag ?
        improveDecimals((discountedSubtotal + (!countryCode?.includesShippingTax ? shippingPrice : 0)) * getTaxRateByCountry(countryCode)) :
        0;
    const totalAmount = improveDecimals(discountedSubtotal + taxAmount + (shippingPrice < 0 ? 0 : shippingPrice));

    const onCardPayment = useCallback(async (fields: FieldList) => {
        const cardElement: any = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: fields.firstName + ' ' + fields.lastName,
                email: fields.contactEmail,
                address: {
                    line1: fields.streetNo,
                    line2: fields.streetName || '',
                    state: fields.suburb,
                    city: fields.city,
                    country: fields.country,
                },
            },
        });

        if (error !== undefined) {
            setState({
                cardErrorMessage: error?.message ?? error,
                isSubmitting: false
            });
            throw new Error(error?.message ?? error);
        }

        return createStripePaymentIntent({
            variables: {
                input: JSON.stringify({
                    email: fields.contactEmail,
                    paymentMethodId: paymentMethod.id,
                    amount: totalAmount,
                }),
                countryCode: selectedCountryCode
            }
        }).then((res) => {
            const result = res?.data?.createStripePaymentIntent ?? "";
            if (result && result.includes('Error: ')) {
                toastError(result.split('Error: ')[1]);
                setState({ isSubmitting: false });
                throw new Error(result.split('Error: ')[1]);
            }
        });
    }, [elements, stripe, createStripePaymentIntent, totalAmount, selectedCountryCode, setState, toastError]);

    const createOrder = useCallback((fields: FieldList) => {
        return createResourceOrder({
            variables: {
                paymentOption: fields.paymentOption,
                firstName: fields.firstName,
                lastName: fields.lastName,
                schoolName: fields.schoolName,
                streetNo: fields.streetNo,
                streetName: fields.streetName,
                suburb: fields.suburb,
                city: fields.city,
                postCode: fields.postCode,
                deliveryCountryId: countryCode.id,
                contactEmail: fields.contactEmail,
                emailToInvoice: fields.emailToInvoice,
                orderNo: fields.orderNo,
                hearFrom: fields.hearFrom.value,
                notes: fields.notes,
                promoCode: fields.promoCode,
                subtotal,
                shippingPrice,
                taxAmount,
                totalAmount,
                taxPrefix,
                taxLabel: getTaxLabel(countryCode),
                currency,
                orderList,
            },
        }).then((response) => {
            if (response.data.createResourceOrder.status) {
                const responseStatus = parseInt(response.data.createResourceOrder.status);

                if (responseStatus) {
                    // if we are checking out with card, then we need to do the payment
                    // else just reset the form
                    resetForm()

                } else {
                    toastError(ORDER_RESPONSE_MESSAGE.fail);
                }
            }
            setState({ isSubmitting: false });
        }).catch((error) => {
            setState({ isSubmitting: false });
            toastError(ORDER_RESPONSE_MESSAGE.fail);
        });
    }, [
        countryCode,
        createResourceOrder,
        resetForm,
        setState,
        currency,
        shippingPrice,
        subtotal,
        taxAmount,
        taxPrefix,
        totalAmount,
        orderList,
        toastError
    ]);

    const onSubmitOrder = useCallback(async (fields: FieldList) => {
        if (!state.isSubmitting) {
            setState({ isSubmitting: true });
            if (fields.paymentOption === PAYMENT_OPTIONS[1].name) {
                const cardElement: any = elements.getElement(CardElement);
                const { error: cardTokenError } = await stripe.createToken(cardElement);
                if (cardTokenError) {
                    setState({
                        cardErrorMessage: cardTokenError.message,
                        isSubmitting: false
                    });
                    return null;
                }
            }

            if (fields.paymentOption === PAYMENT_OPTIONS[1].name) {
                if (shippingPrice < 0) {
                    toastError('Error calculating shipping. Please contact us.');
                    setState({ isSubmitting: false });
                    return null;
                }
                return onCardPayment(fields)
                    .then(() => createOrder(fields))
                    .catch(() => setState({ isSubmitting: false }));
            } else {
                return createOrder(fields);
            }
        }
    }, [state.isSubmitting, setState, elements, stripe, shippingPrice, onCardPayment, toastError, createOrder]);

    const wrapperStyle = css`
        height: 100%;
    `;

    const altText = page.altText || '';

    const priceRangeOptions: PriceRangeType[] = useMemo(() => {
        if (currency === "$") return PRODUCT_PRICE_RANGE_OPTIONS;
        const newOptions: PriceRangeType[] = [];
        PRODUCT_PRICE_RANGE_OPTIONS.forEach((option: { value: string, label: string }) => {
            newOptions.push({
                ...option,
                label: option.label.replaceAll("$", currency)
            });
        });

        return newOptions;
    }, [currency]);

    const onPromoValidation = (promoCode: string, reset?: boolean) => {
        if (reset) {
            setState({ discountCode: undefined });
        } else {
            return validatePromoCode({
                variables: {
                    promoCode: promoCode,
                    countryCode: selectedCountryCode
                }
            }).then(res => {
                const promoCode: DiscountCode = res?.data?.validateProductDiscount;
                if (!!promoCode?.productDiscounts) {
                    const productsWithDiscounts = promoCode.productDiscounts
                        .map(discount => discount.products.map(product => product.id))
                        .flat();
                    const hasProductsByPromoCode = orderList.some((item: string) => {
                        const orderProduct = JSON.parse(item);
                        return productsWithDiscounts.includes(orderProduct.id);
                    });

                    if (hasProductsByPromoCode) {
                        setState({ discountCode: promoCode });
                    }
                }
            })
        }
    }

    const orderFormButtonLabel = useMemo(() => {
        if (hasFeatureFlag) {
            return "Submit & Pay"
        }
        return page?.orderFormButtonLabel || "Order form"
    }, [hasFeatureFlag, page.orderFormButtonLabel]);

    const bodyOverflowHidden = css`
        ${state.showingOrderForm && css`
            body {
                position: fixed;
                left: 0;
                overflow: hidden;
                pointer-events: none;
            }
        `}
    `;

    const descriptionStyle = css`
        margin-top: 50px;
    `;

    return (
        <SectionContainer data={{ backgroundColour: "white" }} overflowHidden>
            <Global styles={bodyOverflowHidden} />
            <Wrapper useMaxWidth={false} flexDirection={"column"} justifyContent={"start"} css={wrapperStyle}>
                {page.landscape && (
                    <BannerForAdvertisement
                        type={"success-story"}
                        mainBannerImage={image}
                        useMainBannerFocusPoint={useAFocusPoint}
                        bannerHeight={bannerHeight}
                        showButton={false}
                        useDivForFocusPoint={false}
                        customAltText={altText}
                    />
                )}

                <PageSection type={'success-story'}>
                    {(loading || state.isSubmitting) && (
                        <Loading onTop fitToScreen overlay overlayColour={"white"} delay={500} />
                    )}

                    <PageHeaderAndDropdownsContainer>
                        <PageHeader>
                            Resources
                        </PageHeader>

                        <DropdownContainer>
                            <DropdownButton
                                options={productGroupOptions}
                                onChange={(item) => setState({ productGroup: item })}
                                selectedValue={[state.productGroup]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                zIndex={theme.zIndex.zIndexMediumHigh}
                            />

                            <DropdownButton
                                options={priceRangeOptions}
                                onChange={setPriceRange}
                                selectedValue={[state.priceRange]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                zIndex={theme.zIndex.zIndexMediumHigh}
                            />

                            <DropdownButton
                                options={productSuitabilityOptions}
                                onChange={(item) => setState({ productSuitability: item })}
                                selectedValue={[state.productSuitability]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                            />

                            <DropdownButton
                                options={PRODUCT_SORT_OPTIONS}
                                onChange={(item) => setSortOption(item)}
                                selectedValue={[state.sort]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                optionBackgroundColour={SORT_DROPDOWN_OPTION_BACKGROUND_COLOUR}
                                optionBackgroundHoveredColour={SORT_DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR}
                            />
                        </DropdownContainer>
                    </PageHeaderAndDropdownsContainer>

                    <ProductsSection isNoData={isEmpty(productList)}>
                        {(!loading && isEmpty(productList)) && (
                            <NoData />
                        )}

                        {productList?.map((product: ProductItem, index: number) => {
                            const qty = state.productListWithQty[`${product.id}`]?.quantity || 0;
                            const onPlusClick = () => {
                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(qty + 1) }
                                    }
                                });
                            }
                            const onMinusClick = () => {
                                if (qty < 1) return;
                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(qty - 1) }
                                    }
                                });
                            }
                            const onQtyChange = (quantity: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(quantity.target.value.replace(/[^0-9]/g, ''));
                                quantity.target.value = newValue ? newValue.toString() : "0";

                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(newValue) }
                                    }
                                });
                            }

                            return (
                                <ProductContainer key={`${product.id}-${index}`} index={index}>
                                    <DiscountedPriceAndProductImageContainer>
                                        <DiscountedPriceImage
                                            price={product.price}
                                            shouldDiscount={product.shouldDiscount}
                                            currency={currency}
                                            taxLabel={countryCode?.taxLabel}
                                            taxPrefix={taxPrefix}
                                        />
                                        <ProductImageContainer>
                                            {product.imagesAndVideos[0] && (
                                                <ProductImage image={product.imagesAndVideos[0]} />
                                            )}
                                        </ProductImageContainer>
                                    </DiscountedPriceAndProductImageContainer>

                                    <ProductText text={product.title} />

                                    <ContentSection
                                        height={'unset'}
                                        alignItems={'center'}
                                        flexDirection={'column'}
                                    >
                                        <Price
                                            price={product.price}
                                            shouldDiscount={product.shouldDiscount}
                                            currency={currency}
                                            taxLabel={countryCode?.taxLabel}
                                            taxPrefix={taxPrefix}
                                        />
                                        {!product.outOfStock && (
                                            <RoundQuantityButton
                                                minusClickHandler={onMinusClick}
                                                plusClickHandler={onPlusClick}
                                                onChange={onQtyChange}
                                                quantity={qty}
                                            />
                                        )}
                                    </ContentSection>

                                    <Description
                                        isOutOfStock={product.outOfStock}
                                        expectedDate={product.expectedDate}
                                        description={product.description}
                                    />
                                </ProductContainer>
                            )
                        })}

                        <BorderForSpaces productList={productList} />
                    </ProductsSection>

                    <GuidanceAndOrderButtonContainer>
                        {state.showOrderGuidance && (
                            <GuidanceText text={"Quantity required to order"} />
                        )}

                        {!isEmpty(productList) && (
                            <OrderButton onClick={onShowOderForm}>{orderFormButtonLabel}</OrderButton>
                        )}
                    </GuidanceAndOrderButtonContainer>

                    <ContentCombine
                        Tag={'p'}
                        fontSizeType={'9'}
                        anchorColour={theme.colours.curiousBlue}
                        css={descriptionStyle}
                    >
                        {page.description}
                    </ContentCombine>

                    <Disclaimer padding={disclaimerPadding}>
                        {disclaimer}
                    </Disclaimer>
                </PageSection>

                {state.showingOrderForm && (
                    <OrderForm
                        onCloseOderForm={onCloseOderForm}
                        onSubmit={onSubmitOrder}
                        disclaimer={disclaimer}
                        products={products}
                        currency={currency}
                        taxPrefix={taxPrefix}
                        cardErrorMessage={state.cardErrorMessage}
                        countryCode={countryCode}
                        subtotal={discountedSubtotal}
                        taxAmount={taxAmount}
                        onPromoValidation={onPromoValidation}
                        hasProductDiscount={!!state.discountCode}
                        totalAmount={totalAmount}
                        shipping={shippingPrice}
                        isSubmitting={state.isSubmitting}
                        isShippingTaxIncluded={countryCode?.includesShippingTax}
                    />
                )}

            </Wrapper>
        </SectionContainer>
    )
}

export default ProductList;