import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { css, useTheme } from "@emotion/react";
import Carousel from "nuka-carousel";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import SlideContainer from "core/Components/Carousel/SlideContainer";
import SlideWrapper from "core/Components/Carousel/SlideWrapper";
import DoubleQuotes from "core/Components/Icons/DoubleQuotes";
import Image from "core/Components/Image";
import ImageContainer from "core/Components/ImageContainer";
import SectionContainer from "core/Components/SectionContainer";
import useBreakpoint from "core/hooks/useBreakpoint";
import { sort } from "core/includes/sort";

import Button from "elements/Components/SliderWithImage/components/Button";
import CarouselContainer from "elements/Components/SliderWithImage/components/CarouselContainer";
import CarouselWrapper from "elements/Components/SliderWithImage/components/CarouselWrapper";
import ChevronContainer from "elements/Components/SliderWithImage/components/ChevronContainer";
import DescriptionText from "elements/Components/SliderWithImage/components/DescriptionText";
import DoubleQuotesWrapper from "elements/Components/SliderWithImage/components/DoubleQuotesWrapper";
import Header from "elements/Components/SliderWithImage/components/Header";
import ImageHeader from "elements/Components/SliderWithImage/components/ImageHeader";
import ImageRadiusBox from "elements/Components/SliderWithImage/components/ImageRadiusBox";
import ImageText from "elements/Components/SliderWithImage/components/ImageText";
import Wrapper from "elements/Components/SliderWithImage/components/Wrapper";
import Writer from "elements/Components/SliderWithImage/components/Writer";
import { isUp } from "theme/breakpoints";
import LinkTo from "core/Components/LinkTo";
import Card from "elements/Components/SliderWithImage/components/Card";

type Props = {
    data: SliderWithImages
};

const DEFAULT_SLIDE_BORDER_RADIUS = 12;

const SliderWithImage: React.FC<Props> = ({ data }) => {
    const [selectedItem, setSelectedItem] = useState<SlideWithImage>();
    const theme = useTheme();
    const colourTheme = JSON.parse(data.theme.toLowerCase());
    const { breakpoint, minWidth } = useBreakpoint();
    const {
        headercolour,
        doublequotescolour,
        textcolour,
        writercolour,
        buttontextcolour,
        buttoncolour
    } = colourTheme;
    const imageList = sort(data.imageList);
    const dataCount = imageList.length ?? 0;
    const linkURL = data.innerURL || data.outerURL;
    const cardContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedItem && cardContainerRef.current) {
            window.scrollTo({
                top: cardContainerRef.current.offsetHeight,
                behavior: "smooth",
            });
        }
    }, [selectedItem])

    const [
        imageWidth,
        imageHeight,
        slideCount,
        chevronSize,
        carouselCellSpacing,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return [499, data.isImageSquare ? 499 : 362, 3, 50, 70];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [343, data.isImageSquare ? 343 : 257, 3, 34, 44];
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return [312, data.isImageSquare ? 312 : 233, 3, 34, 40];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [259, data.isImageSquare ? 259 : 193, 3, 34, 28];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [560, data.isImageSquare ? 560 : 399, 1, 27, 50];
            case minWidth >= theme.breakpoints.sizes["sm"]:
                return [373, data.isImageSquare ? 373 : 266, 1, 24, 28];
            default:
                return [320, data.isImageSquare ? 320 : 234, 1, 24, 0];
        }
    }, [minWidth, data.isImageSquare, theme.breakpoints.sizes]);

    const chevronButton = (type: "left" | "right", slideEvent: any) => (
        <ChevronContainer chevronDirection={type} imageHeight={imageHeight}>
            <RoundChevronButton
                type={type}
                onClick={slideEvent}
                roundColour={buttoncolour}
                chevronColour={buttontextcolour}
                size={chevronSize}
            />
        </ChevronContainer>
    );

    const disableChevrons = imageList.length <= 3 && isUp(breakpoint, "lg");

    const onLearnMore = (item: SlideWithImage) => {
        setSelectedItem(item)
    }

    const descriptionTextStyle = css`
        ${theme.breakpoints.up("lg")} {
            width: ${(imageWidth * 3) + (carouselCellSpacing * 2)}px;
        }

        ${theme.breakpoints.down("md")} {
            width: ${imageWidth}px;
        }
    `;

    const learnMoreStyle = css`
        display: flex;
        justify-content: center;
        font-weight: ${theme.fonts.weights.bold};
        margin-top: 15px;
    `;

    const imageRadiusBoxStyle = (isInteractive: boolean) => css`
        ${isInteractive && css`
            overflow: hidden;
        `}
    `;

    return isEmpty(imageList) ? null : (
        <SectionContainer data={data}>
            <Wrapper>
                <CarouselContainer>
                    {data.header && (
                        <Header
                            imageWidth={imageWidth}
                            carouselCellSpacing={carouselCellSpacing}
                            colour={headercolour}
                        >
                            {data.header}
                        </Header>
                    )}

                    {data.showDoubleQuotes && (
                        <DoubleQuotesWrapper>
                            <DoubleQuotes colour={doublequotescolour}/>
                        </DoubleQuotesWrapper>
                    )}

                    {data.descriptionText && (
                        <DescriptionText colour={textcolour} css={descriptionTextStyle}>
                            {data.descriptionText}
                        </DescriptionText>
                    )}

                    {data.writer && (
                        <Writer colour={writercolour}>{data.writer}</Writer>
                    )}

                    {data.buttonText && (
                        <Button
                            linkURL={linkURL}
                            textColour={buttontextcolour}
                            backgroundColour={buttoncolour}
                        >
                            {data.buttonText}
                        </Button>
                    )}

                    <div ref={cardContainerRef}>
                        {selectedItem && (
                            <Card item={selectedItem} />
                        )}
                    </div>

                    <CarouselWrapper imageWidth={imageWidth} carouselCellSpacing={carouselCellSpacing}>
                        <Carousel
                            slidesToShow={dataCount < slideCount ? dataCount : slideCount}
                            dragging={false}
                            autoplayInterval={6000}
                            speed={1500}
                            renderCenterLeftControls={({ previousSlide }) => (
                                chevronButton("left", previousSlide)
                            )}
                            cellSpacing={carouselCellSpacing}
                            renderCenterRightControls={({ nextSlide }) => (
                                chevronButton("right", nextSlide)
                            )}
                            defaultControlsConfig={{
                                pagingDotsStyle: {
                                    fill: buttoncolour,
                                }
                            }}
                            withoutControls={disableChevrons}
                            autoplay={!disableChevrons}
                            wrapAround
                        >
                            {imageList.map((item: SlideWithImage, index: number) => {
                                const image = item.expandedImageLandscape || item.expandedImagePortrait;
                                const text = item.expandedCardHeader || item.expandedCardSubHeader || item.expandedCardText;
                                const altText = item.expandedAltText || '';

                                return (
                                    <SlideContainer key={index} alignItem={"start"}>
                                        <SlideWrapper>
                                            <ImageContainer
                                                padding={4}
                                                width={imageWidth}
                                                height={imageHeight}
                                                backgroundColour={item.backgroundColour}
                                                {...(image || text) ? {
                                                    onClick: () => onLearnMore(item)
                                                } : {
                                                    outerURL: item.outerURL,
                                                    innerURL: item.innerURL
                                                }}
                                            >
                                                <ImageRadiusBox
                                                    radius={DEFAULT_SLIDE_BORDER_RADIUS}
                                                    isImageWithoutBoxShadow={data.isImageWithoutBoxShadow}
                                                    css={imageRadiusBoxStyle(!!image || !!text || !!item.outerURL || !!item.innerURL)}
                                                >
                                                    {item.image?.URL && (
                                                        <Image
                                                            image={item.image.URL}
                                                            borderRadius={DEFAULT_SLIDE_BORDER_RADIUS}
                                                            useFocusPoint={item.useFocusPoint}
                                                            customAltText={altText}
                                                        />
                                                    )}
                                                </ImageRadiusBox>
                                            </ImageContainer>

                                            {item.header && (
                                                <ImageHeader>
                                                    {item.header}
                                                </ImageHeader>
                                            )}

                                            {item.text && (
                                                <ImageText textColour={textcolour}>
                                                    {item.text}
                                                </ImageText>
                                            )}
                                            {(image || text || item.outerURL || item.innerURL) && (
                                                <LinkTo
                                                    {...(image || text) ? {
                                                        onClick: () => onLearnMore(item)
                                                    } : {
                                                        to: item.innerURL || item.outerURL
                                                    }}
                                                    css={learnMoreStyle}
                                                    useLinkStyle
                                                >
                                                    Learn more
                                                </LinkTo>
                                            )}
                                        </SlideWrapper>
                                    </SlideContainer>
                                )
                            })}
                        </Carousel>
                    </CarouselWrapper>
                </CarouselContainer>
            </Wrapper>
        </SectionContainer>
    );
};

export default SliderWithImage;
