import React from "react";
import { css } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    colour?: string,
    marginBetweenLines?: number,
};

const Writer: React.FC<Props> = ({
    children,
    colour,
    marginBetweenLines,
}) => {
    const Tag = "p";
    const [fontSize, lineHeight] = useFontSize(Tag, '9', '14');
    const style = css`
        white-space: break-spaces;
        text-align: center;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        
        ${colour && css`
            color: ${colour};
        `}

        ${marginBetweenLines && css`
            margin-top: ${marginBetweenLines}px;
        `}
    `;

    return (
        <Tag css={style}>{children}</Tag>
    );
};

export default Writer;
