import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ItemContent: React.FC<Props> = ({ children }) => {
    const style = css`
        display: flex;
        flex-direction: row;
        justify-content: start;
        width: 100%;
        height: 100%;
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemContent;