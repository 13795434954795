import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
};

const NavMenuTextCover: React.FC<Props> = () => {
    const theme = useTheme();

    const style = css`
        top: ${-theme.sizes.menu.heightMain}px;
        position: relative;
        width: 100%;
        height: ${theme.sizes.menu.heightMain}px;
    `;

    return (
        <div css={style} />
    );
};

export default NavMenuTextCover;