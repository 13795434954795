import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
    topBannerHeight: number,
};

const Wrapper: React.FC<Props> = ({ children, topBannerHeight }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        position: relative;
        justify-content: flex-start;
        height: ${topBannerHeight}px;
        padding-left: 93px;
        
        ${theme.breakpoints.up("5xl")} {
            padding-left: 122px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding-left: 80px;
        }

        ${theme.breakpoints.only("xl")} {
            padding-left: 80px;
        }

        ${theme.breakpoints.only("lg")} {
            padding-left: 42px;
        }

        ${theme.breakpoints.only("md")} {
            padding-left: 71px;
        }

        ${theme.breakpoints.only("sm")} {
            padding-left: 45px;
        }

        ${theme.breakpoints.down("xs")} {
            padding-left: 25px;
        }
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMaxWidth={false} useMinHeight={false}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
