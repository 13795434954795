import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    listTextColour: string,
};

const ListItemHeader: React.FC<Props> = ({ children, listTextColour }) => {
    const theme: Theme = useTheme();

    const listItemHeaderStyle = css`
        width: 90%;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        align-items: start;
        overflow: hidden;
        color: ${listTextColour};
        font-weight: ${theme.fonts.weights.bold};
    `;

    return (
        <ContentCombine Tag={"h3"} fontSizeType={"5"} css={listItemHeaderStyle}>{children}</ContentCombine>
    );
}

export default ListItemHeader;
