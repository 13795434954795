import React from "react";
import { useTheme } from "@emotion/react";

import NavMenuUnderLine from "core/Components/Navigations/NavMenuUnderLine";
import BurgerNavSubTextBoxContainer from "core/Components/HamburgerNavigations/large/BurgerNavSubTextBoxContainer";

type Props = {
    children: React.ReactNode,
    subMenu: StandardPage,
    selectedSegments?: string[],
    closeMenuHandler: Function,
};

const BurgerNavSubTextBox: React.FC<Props> = ({
    children,
    subMenu,
    selectedSegments = undefined,
    closeMenuHandler,
}) => {
    const theme = useTheme();

    return (
        <BurgerNavSubTextBoxContainer
            colour={theme.colours.doveGray3}
            showUnderline={!!selectedSegments?.includes(subMenu.uRLSegment)}
            clickHandler={closeMenuHandler}
        >
            <div>{children}</div>
            <NavMenuUnderLine width={100} height={4} />
        </BurgerNavSubTextBoxContainer>
    );
};

export default BurgerNavSubTextBox;