import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ImageText: React.FC<Props> = ({
  children,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        color: ${theme.colours.gray};
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        line-height: 35px;
        font-size: 18px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 30px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    return (
        <p css={style}>{children}</p>
    );
}

export default ImageText;
