import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
}

const FormContainer: React.FC<Props> = ({
    children,
 }) => {
    const style = css`
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `;

    return (
        <div css={style}>{children}</div>
    )
}

export default FormContainer;
