import React from "react";
import { useTheme, css } from "@emotion/react";

type Props = {
    error: string,
};

const ErrorPageSubheader: React.FC<Props> = ({ error = "404" }) => {
    const theme = useTheme();

    const subheader = error === "404" ?
        "This page doesn’t exist" :
        "Something went wrong";

    const subheaderStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 25px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 34px;
            line-height: 45px;
            margin-bottom: 30px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 20px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 18px;
        }
    `;

    return (
        <div css={subheaderStyle}>
            {subheader}
        </div>
    );
};

export default ErrorPageSubheader;
