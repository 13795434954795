import React from "react";
import { css } from "@emotion/react";
import theme from "theme/index";

type Props = {
    width?: number,
    backgroundColour?: string,
    isFeatureBox?: boolean,
};

const FeatureColourBox: React.FC<Props> = ({
    width,
    backgroundColour = theme.colours.aluminium,
    isFeatureBox = false,
}) => {
    const style = css`
        height: 100%;
        background-color: ${backgroundColour};
        opacity: 0.2;
        border-radius: 47px;
        
        ${width && css`
            width: ${width}px;
        `}
        
        ${theme.breakpoints.down("sm")} {
            border-radius:${isFeatureBox ? 25 : 36}px;
        }
    `;

    return (
        <div css={style}>&nbsp;</div>
    );
}

export default FeatureColourBox;
