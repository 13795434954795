import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom"
import { css, SerializedStyles, Theme, useTheme } from "@emotion/react";
import Tippy from "@tippy.js/react";
import Loading from "../Utils/Loading";
import defaultTippyProps from "../../includes/tippy";

const sizes = {
    "sm": "80px",
    "md": "110px",
    "lg": "124px",
    "xxl": "180px",
};

type Color = "green" | "primary" | "red" | "secondary" | "yellow" | "cancel";

type Props = {
    bordered?: boolean,
    forceLoading?: boolean,
    disabled?: boolean,
    disabledTooltipMessage?: string,
    children?: React.ReactNode,
    color?: Color,
    extraStyles?: SerializedStyles,
    onClick?: (e: React.MouseEvent) => void,
    type?: "button" | "submit" | "reset" | undefined,
    rounded?: boolean,
    size?: "sm" | "md" | "lg" | "xxl",
    className?: string,
    href?: string,
};

const LoadingButton: React.FC<Props> = ({
    bordered,
    children,
    color,
    extraStyles,
    onClick,
    type,
    forceLoading,
    disabled,
    disabledTooltipMessage,
    rounded = false,
    size = "lg",
    className,
    href,
}) => {
    const [loading, setLoading] = useState(false);
    const theme: Theme = useTheme();

    // Only add the odd colour to this.  Generally speaking we should be using "primary" and "secondary" to make
    // Redesigns easier.
    const getColorMap = {
        green: [theme.colours.green[700], theme.colours.green[800], theme.colours.white],
        primary: [theme.colours.blue[600], theme.colours.blue[620], theme.colours.white],
        red: [theme.colours.red[350], theme.colours.red[400], theme.colours.white],
        secondary: [theme.colours.curiousBlue, theme.colours.malibu, theme.colours.white],
        yellow: [theme.colours.candyCorn, theme.colours.cream, theme.colours.curiousBlue],
        cancel: [theme.colours.deepCerulean, theme.colours.deepCerulean, theme.colours.white],
    };

    const getColor = (bordered: boolean, color: Color, loading?: boolean) => {
        const [cssColor, hoverCssColor, textColour] = getColorMap[color];

        if (bordered) {
            return css`
                background: transparent;
                border: 1px solid ${cssColor};
                color: ${cssColor};
    
                ${!loading && css`
                    &:hover {
                        background: ${cssColor};
                        color: ${textColour};
                    }
                `}
            `;
        } else {
            return css`
                background: ${cssColor};
                border: 0;
                color: ${textColour};
    
                ${!loading && css`
                    &:hover {
                        background: ${hoverCssColor};
                        color: ${textColour};
                    }
                `}
            `;
        }
    };

    const styles = css`
        position: relative;
        border-radius: 3px;
        font-size: ${theme.fonts.baseSize};
        font-family: ${theme.fonts.frutiger};
        font-weight: ${theme.fonts.weights.light};
        outline: none;
        padding: 5px 10px;
        white-space: nowrap;
        min-width: ${sizes[size]};
        height: 32px;
        transition: background-color 300ms ease-in-out;
        ${!(disabled || loading || forceLoading) && css`cursor: pointer`};
        ${getColor(bordered || false, color || "primary", loading || forceLoading)};
        opacity: ${(disabled || loading || forceLoading) ? "0.5" : "1"};

        ${rounded && css`
            border-radius: 16px;
        `}

        ${href && css`
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        `}

        ${size === "xxl" && css`
            height: 50px;

            ${rounded && css`
                border-radius: 25px;
            `}
        `}

        ${extraStyles}
    `;

    const handleClick = useCallback((e: any) => {
        if (onClick) {
            e.preventDefault();
            const clickResult: any = onClick(e);
            if (clickResult instanceof Promise) {
                setLoading(true);
                clickResult.finally(() => {
                    setLoading(false);
                });
            }
        }
    }, [onClick]);

    const isDisabled = disabled || loading || forceLoading;
    const isLoading = loading || forceLoading;
    const loadingNode = useMemo(() => <Loading small overlay={false} ringIndicator onTop />, []);

    const linkWrapperStyle = (disabled?: boolean) => css`
        ${disabled && css`
            pointer-events: none;
        `}
    `;

    const renderButton = useCallback(() => (
        <button
            css={styles}
            className={className}
            type={type}
            onClick={handleClick}
            disabled={isDisabled}
        >
            {isLoading && loadingNode}
            {children}
        </button>
    ), [styles, className, type, handleClick, children, isDisabled, isLoading, loadingNode]);

    const renderLink = useCallback(() => {
        const route = href ? href : "#";
        return (
            <div css={linkWrapperStyle(isDisabled)}>
                <Link
                    css={styles}
                    className={className}
                    to={isDisabled ? "#" : route}
                    onClick={onClick}
                >
                    {isLoading && loadingNode}
                    {children}
                </Link>
            </div>
        )
    }, [styles, className, children, href, onClick, isDisabled, isLoading, loadingNode]);

    return (
        (disabled && disabledTooltipMessage)
            ? (
                <Tippy
                    content={disabledTooltipMessage}
                    hideOnClick={false}
                    {...defaultTippyProps}
                >
                    <span>{href ? renderLink() : renderButton()}</span>
                </Tippy>
            )
            : href ? renderLink() : renderButton()
    );
};

LoadingButton.defaultProps = {
    bordered: false,
    color: "primary",
    type: "button",
    forceLoading: false,
    disabled: false,
};

export default memo(LoadingButton);
