import React, { useMemo } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import moment from "moment";

import Image from "core/Components/Image";
import ContentCombine from "core/Components/ContentCombine";
import TextClampAutoLines from "core/Components/TextClampAutoLines";

type Props = {
    blog: BlogPost,
    imagePercent?: number,
    hideDateAndAuthor?: boolean,
    className?: string,
}

const BlogCard: React.FC<Props> = ({ blog, imagePercent = 55, hideDateAndAuthor = false, className }) => {
    const theme: Theme = useTheme();

    const publishedDate = useMemo(() => (
        blog.publishedDate ? moment(blog.publishedDate).format('MMM DD \'YY') : ""
    ), [blog]);

    const containerStyle = css`
        width: 400px;
        height: 400px;
        box-shadow: ${theme.borderAndShadow.boxShadow6};
        display: flex;
        flex-direction: column;
        border-radius: ${theme.borderAndShadow.largerRadius};
        overflow: hidden;

        ${theme.breakpoints.up('5xl')} {
            width: 500px;
            height: 520px;
        }

        ${theme.breakpoints.only('xl')} {
            width: 350px;
            height: 370px;
        }

        ${theme.breakpoints.down('lg')} {
            width: 290px;
            height: 345px;
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;

    const bannerImageStyle = css`
        width: 100% !important;
        min-height: ${imagePercent}% !important;
        max-height: ${imagePercent}% !important;
        height: ${imagePercent}% !important;
        object-fit: cover;
    `;

    const textContainerStyle = css`
        min-height: ${100 - imagePercent}%;
        max-height: ${100 - imagePercent}%;
        height: ${100 - imagePercent}%;
        display: flex;
        flex-grow: 1;
        padding: 13px 14px 8px 11px;
        background-color: white;
    `;

    const titleStyle = css`
        color: ${theme.colours.curiousBlue2};
        justify-content: left;
        margin-bottom: 10px;
    `;

    const descriptionStyle = css`
        position: relative;
        color: ${theme.colours.boulder2};
        font-size: 16px;
        justify-content: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        flex: 1;
    `;

    const authorContainerStyle = css`
        min-height: 24px;
        display: flex;
        font-size: 12px;
        color: ${theme.colours.gray2};
        font-style: italic;
        align-items: center;
    `;

    const authorImageStyle = css`
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 15px;
    `;

    const descriptionContainerStyle = css`
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding-left: 5px;
        overflow: hidden;
        flex: 1;
    `;

    const titleAndDescStyle = css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    `;

    return (
        <div css={containerStyle} className={className}>
            {blog.previewImage && (
                <Image image={blog.previewImage} css={bannerImageStyle} />
            )}
            <div css={textContainerStyle} className={"text-container"}>
                <div css={titleAndDescStyle}>
                    {!hideDateAndAuthor && (
                        <div css={authorContainerStyle}>
                            {blog.author?.webpWithFallbackPortrait && (
                                <Image image={blog.author.webpWithFallbackPortrait} css={authorImageStyle} />
                            )}
                            {blog.author?.name && (
                                <div>Posted by {blog.author.name},&nbsp;</div>
                            )}
                            <div>{publishedDate}</div>
                        </div>
                    )}
                    <div css={descriptionContainerStyle}>
                        <ContentCombine
                            Tag={'h6'}
                            css={titleStyle}
                            className={"header"}
                        >
                            {blog.title}
                        </ContentCombine>
                        <TextClampAutoLines
                            text={blog.shortDescription}
                            lineHeight={24}
                            css={descriptionStyle}
                            className={"description"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;