import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    moduleHeight?: number,
    textBoxWidth?: number, // Width is the percentage
    useFocusPoint?: boolean,
};

const ImageBoxContainer: React.FC<Props> = ({
    children,
    className,
    moduleHeight,
    textBoxWidth,
    useFocusPoint = false,
}) => {
    const theme = useTheme();

    const style = css`
        ${textBoxWidth && css`
            width: ${100 - textBoxWidth}%;
        `}
        
        ${moduleHeight && useFocusPoint && css`
            min-height: ${moduleHeight}px;
        `}

        ${theme.breakpoints.down("md")} {
            width: 100%;
        }
    `;

    return (
        <div css={style} className={className}>{children}</div>
    );
};

export default ImageBoxContainer;
