import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    fontSizeType?: string,
    lineHeightType?: string,
};

const Subheader: React.FC<Props> = ({ children, fontSizeType = '5', lineHeightType = '' }) => {
    const theme = useTheme();

    const style = css`
        text-align: center;
        
        ${theme.breakpoints.up("3xl")} {
            margin-top: 13px;
        }
        
        ${theme.breakpoints.only("xxl")} {
            margin-top: 17px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-top: 13px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-top: 8px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 7px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 8px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 7px;
        }
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={fontSizeType} lineHeightType={lineHeightType} css={style}>{children}</ContentCombine>
    );
};

export default Subheader;