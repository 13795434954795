import React, { useCallback } from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    heightType: string,
    imageHeight?: number,
    useFocusPoint?: boolean,
    isPortrait?: boolean,
};

const ImageContainer: React.FC<Props> = ({
    children,
    heightType,
    imageHeight = null,
    useFocusPoint = false,
    isPortrait = false
}) => {
    const theme = useTheme();
    const getHeight = useCallback((height: number) => (
        (imageHeight && useFocusPoint) ? Math.min(height, imageHeight * 0.9) : height
    ), [imageHeight, useFocusPoint]);

    const style = css`
        ${theme.breakpoints.up("5xl")} {
            min-height: ${heightType === "tall" ? getHeight(1220) : getHeight(963)}px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            min-height: ${heightType === "tall" ? getHeight(888) : getHeight(716)}px;
        }

        ${theme.breakpoints.only("xxl")} {
            min-height: ${heightType === "tall" ? getHeight(800) : getHeight(628)}px;
        }

        ${theme.breakpoints.only("xl")} {
            min-height: ${heightType === "tall" ? getHeight(714) : getHeight(572)}px;
        }

        ${theme.breakpoints.only("lg")} {
            min-height: ${heightType === "tall" ? getHeight(458) : getHeight(300)}px;
        }

        ${theme.breakpoints.down("md")} {
            display: flex;
        }
        
        ${theme.breakpoints.only("md")} {
            min-height: ${getHeight(343)}px;
        }

        ${theme.breakpoints.only("sm")} {
            min-height: ${isPortrait ? getHeight(568) : getHeight(310)}px;
        }

        ${theme.breakpoints.down("xs")} {
            min-height: ${isPortrait ? getHeight(360) : getHeight(206)}px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ImageContainer;