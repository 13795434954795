import React, { ElementType } from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    Tag?: ElementType|string,
    className?: string,
    defaultTextAlign?: string,
    justifyContent?: string,
    fontSizeType?: string,
    lineHeightType?: string,
    unsetMinHeight?: boolean,
    textColour?: string,
    anchorColour?: string,
};

const ContentCombine: React.FC<Props> = ({
    children,
    Tag = 'div',
    className,
    defaultTextAlign = null,
    justifyContent = 'center',
    fontSizeType = '',
    lineHeightType = '',
    unsetMinHeight = true,
    textColour = '',
    anchorColour = '',
}) => {
    const theme = useTheme();
    const [fontSize, lineHeight] = useFontSize(Tag, fontSizeType, lineHeightType);
    const stringText = children ? children.toString() : '';
    const isHTML = children ? /<\/?[a-z][\s\S]*>/i.test(children.toString()) : false;

    const style = css`
        ${!!fontSize && css`
            font-size: ${fontSize}px;
        `}
        
        ${!!lineHeight && css`
            line-height: ${lineHeight}px;
        `}
        
        ${anchorColour && css`
            a {
                color: ${anchorColour};
                border-bottom: 1px solid ${anchorColour};

                &:hover {
                    color: ${anchorColour};
                }
            }
        `}
        
        ${isHTML ? css`
            width: 100%;
            z-index: ${theme.zIndex.zIndexLow};

            ${defaultTextAlign && css`
                text-align: ${defaultTextAlign};
            `}
        ` : css`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: ${justifyContent};
            min-height: 29px;
            white-space: break-spaces;
            
            ${textColour && css`
                color: ${textColour};
            `}

            ${unsetMinHeight && css`
                ${theme.breakpoints.down('xl')} {
                    min-height: unset;
                }
            `}
        `}
    `;

    return isHTML ? (
        <div className={"typography"}>
            <div css={style} className={className} dangerouslySetInnerHTML={{ __html: stringText }} />
        </div>
    ) : (
        <Tag css={style} className={className}>{children}</Tag>
    );
};

export default ContentCombine;
