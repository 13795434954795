import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    className?: string,
    paymentType?: string,
    paymentTypeRef?: any,
    hasPrice?: boolean,
};

const PaymentType: React.FC<Props> = ({
    className,
    paymentType,
    paymentTypeRef,
    hasPrice = true,
}) => {
    const theme: Theme = useTheme();

    const priceUnitStyle = css`
        margin-left: 14px;
        white-space: pre;
        font-weight: ${theme.fonts.weights.bold};
        font-size: ${hasPrice ? '18px' : '32px'};
        line-height: ${hasPrice ? '23px' : '38px'};
        text-align: ${hasPrice ? 'unset' : 'center'};

        ${theme.breakpoints.up("5xl")} {
            margin-left: 18px;
            font-size: ${hasPrice ? '24px' : '48px'};
            line-height: ${hasPrice ? '36px' : '57px'};
        }

        ${theme.breakpoints.only("md")} {
            margin-left: 10px;
            font-size: ${hasPrice ? '13px' : '23px'};
            line-height: ${hasPrice ? '16px' : '27px'};
        }

        ${theme.breakpoints.down("sm")} {
            margin-left: 14px;
            font-size: ${hasPrice ? '14px' : '26px'};
            line-height: ${hasPrice ? '17px' : '31px'};
        }
    `;

    return (
        <div css={priceUnitStyle} ref={paymentTypeRef} className={className}>{paymentType}</div>
    );
}

export default PaymentType;
