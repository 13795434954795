import React from "react";
import { css, useTheme } from "@emotion/react";

import NavSubLinkContainer from "core/Components/Navigations/NavSubLinkContainer";

type Props = {
    headerMenu: StandardPage,
    selectedSegments?: string[],
    getSubMenu: Function,
    mouseClickHandler: Function,
};

const NavSubWrapper: React.FC<Props> = ({
    headerMenu,
    selectedSegments = undefined,
    getSubMenu,
    mouseClickHandler,
}) => {
    const theme = useTheme();
    const subMenu = getSubMenu(headerMenu); // Submenu list

    const style = css`
        position: absolute;
        min-width: 100%;
        z-index: -1;
        box-shadow: ${theme.borderAndShadow.dropShadow1};
        border-radius: 0 0 12px 12px;
        background-color: ${theme.colours.concrete2};
    `;

    return (
        !!subMenu?.standardPage?.length ? (
            <div css={style} className={"subMenu"}>
                <NavSubLinkContainer
                    subMenu={subMenu.standardPage}
                    selectedSegments={selectedSegments}
                    mouseClickHandler={mouseClickHandler}
                />
            </div>
        ) : null
    );
};

export default NavSubWrapper;
