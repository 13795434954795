import React from "react";
import { css, useTheme } from "@emotion/react";

import NavLinkTag from "core/Components/Navigations/NavLinkTag";

type Props = {
    data: StandardPage,
};

const FooterVerticalHeaderMenuTitle: React.FC<Props> = ({ data }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 16px;
        font-weight: ${theme.fonts.weights.bold};
        
        ${data.isClickable && css`
            &:hover { opacity: 0.7; }
        `}
    `;

    return (
        <NavLinkTag data={data}>
            <div css={style}>{data.title}</div>
        </NavLinkTag>
    );
};

export default FooterVerticalHeaderMenuTitle;