import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import classNames from "classnames";
import LinkTo from "core/Components/LinkTo";

type Props = {
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void,
    size: number,
    svgSize?: number,
    noHoverEffect?: boolean,
    roundColour?: string,
    roundHoverColour?: string,
    svgColour?: string,
    svgHoverColour?: string,
    className?: string,
    linkURL?: string,
    disabled?: boolean,
};

const CircleButton: React.FC<Props> = ({
    children,
    onClick,
    size,
    svgSize,
    noHoverEffect = false,
    roundColour,
    svgColour,
    svgHoverColour,
    roundHoverColour,
    className,
    linkURL,
    disabled = false,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        width: ${size}px;
        height: ${size}px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 200ms ease-in-out;
        z-index: ${theme.zIndex.zIndexLowerThanMenuOverlay};

        ${disabled && css`
            pointer-events: none;
            opacity: 0.66;
        `}
        
        background-color: ${roundColour
            ? css`${roundColour};`
            : css`${theme.colours.boulder};`
        };

        ${roundHoverColour && css `
            &:hover {
                background-color: ${roundHoverColour}; 
                > svg {
                    color: ${svgHoverColour};
                };
            }
        `}
        
        ${!roundHoverColour && !noHoverEffect && css`
            &:hover {
                opacity: 0.7;
            }
        `};

        > svg {
            width: ${svgSize ?? (size - 5)}px;
            height: ${svgSize ?? (size - 5)}px;
            
            color: ${svgColour
                ? css`${svgColour};`
                : css`${theme.colours.grey[50]};`
            };

            ${!roundHoverColour && !noHoverEffect && css`
                &:hover {
                    opacity: 0.7;
                }
            `};
        }
    `;

    if (linkURL) {
        return (
            <LinkTo css={style} to={linkURL} className={className}>
                {children}
            </LinkTo>
        )
    }

    return (
        <div css={style} onClick={onClick} className={classNames([className, 'circle-button'])}>
            {children}
        </div>
    );
};

export default CircleButton;
