import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    card: ImageVerticalCard,
    headerHeight?: number,
};

const HeaderContainer: React.FC<Props> = ({ card, children, headerHeight }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-height: 74px;

        ${theme.breakpoints.only("xl")} {
            min-height: 71px;
        }

        ${theme.breakpoints.only("lg")} {
            min-height: 56px;
        }

        ${headerHeight && css`
            ${theme.breakpoints.only("md")} {
                min-height: ${headerHeight + 18}px;
            }

            ${theme.breakpoints.only("sm")} {
                min-height: ${headerHeight + 10}px;
            }

            ${theme.breakpoints.down("xs")} {
                min-height: ${headerHeight + 18}px;
            }
        `};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default HeaderContainer;
