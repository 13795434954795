import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    children: React.ReactNode
};

const ButtonsContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: absolute;
        display: flex;
        bottom: 61px;
        gap: 24px;

        ${theme.breakpoints.up("5xl")} {
            bottom: 202px;
            gap: 38px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            bottom: 97.5px;
            gap: 38px;
        }

        ${theme.breakpoints.only("xl")} {
            bottom: 81px;
            gap: 24px;
        }

        ${theme.breakpoints.only("lg")} {
            bottom: 73px;
            gap: 20px;
        }

        ${theme.breakpoints.only("md")} {
            bottom: 90px;
            gap: 23px;
        }

        ${theme.breakpoints.only("sm")} {
            bottom: 79.78px;
            gap: 18.25px;
        }

        ${theme.breakpoints.down("xs")} {
            bottom: 57.78px;
            gap: 12.08px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ButtonsContainer;
