import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: SingleVideo
};

const Header: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const { headerColour } = colourTheme;
    const theme: Theme = useTheme();

    const headerStyle = css`
        white-space: break-spaces;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};
        text-align: center;
        margin-bottom: 23px;

        ${theme.breakpoints.only("xxl")} {
            margin-bottom: 10px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 24px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 23px;
        }
      
        ${theme.breakpoints.only("sm")} {
            margin-bottom: 18px;
        }
    `;

    return (
        <Title Tag={"h2"} css={headerStyle}>
            {data.header}
        </Title>
    );
}

export default Header;
