import React, { useCallback } from "react";
import { css, useTheme } from "@emotion/react";
import {
    BsFacebook,
    BsLinkedin,
    BsTwitterX,
    BsFillEnvelopeFill,
    BsLink45Deg
} from "react-icons/bs";

import useToast from "core/hooks/useToast";
import errorParser from "core/includes/errorParser";

type Props = {
    emailSubject?: string,
    isVertical?: boolean,
    className?: string,
}

const SocialShare: React.FC<Props> = ({
    emailSubject = "Writer's Toolbox Blog",
    isVertical = true,
    className,
}) => {
    const theme = useTheme();

    const url = window.location.href;
    const [successToast, errorToast] = useToast();
    const encodedUrl = encodeURI(url)
    const facebookSocialUrl = `https://facebook.com/sharer/sharer.php?u=${url}`;
    const twitterSocialUrl = `https://twitter.com/intent/tweet?text=${encodeURI(url)}`;
    const linkedinSocialUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(url)}`;

    const mailTo = `mailto:?Subject=${emailSubject} &body=Hi, I thought you ` +
        `might like to take a look at this: %0D%0D${url}`;

    const copyUrlToClipboard = useCallback(() => {
        navigator.clipboard.writeText(encodedUrl)
            .then(() => {
                successToast("Link copied to clipboard!");
            }).catch((e: any) => {
            errorToast(errorParser(e));
        });
    }, [encodedUrl, successToast, errorToast]);

    const style = css`
        display: grid;
        grid-template-${isVertical ? "rows" : "columns"}: repeat(5, 1fr);
        gap: 18px;
    `;

    const iconBaseStyle = css`
        height: 28px;
        width: 28px;
        border-radius: 50%;
        
        ${theme.breakpoints.up('5xl')} {
            height: 45px;
            width: 45px;
        }
    `;

    const iconStyle = ({
        svgSizePercent = 59,
        backgroundColour = "white",
        svgFill = "white",
    }: {
        svgSizePercent?: number,
        backgroundColour?: string,
        svgFill?: string,
    }) => css`
        position: relative;
        background-color: ${backgroundColour};
        cursor: pointer;

        svg {
            width: ${svgSizePercent}%;
            height: ${svgSizePercent}%;
            margin-left: 50%;
            margin-top: 50%;
            transform: translate(-50%, -50%);
            fill: ${svgFill};
        }

        ${iconBaseStyle};
    `;

    const whiteStyle = css`
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: 60%;
        border-radius: 3px;
        transform: translate(-50%, -50%);
        background-color: white;
    `;

    return (
        <div css={style} className={className}>
            <a
                href={facebookSocialUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={"icon"}
                css={iconStyle({
                    svgSizePercent: 100,
                    svgFill: theme.colours.blueRibbon,
                })}
            >
                <BsFacebook />
            </a>
            <a
                href={twitterSocialUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={"icon"}
                css={iconStyle({
                    backgroundColour: theme.colours.black,
                    svgSizePercent: 65,
                })}
            >
                <BsTwitterX />
            </a>
            <a
                href={linkedinSocialUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={"icon"}
                css={iconStyle({
                    svgFill: theme.colours.dodgerBlue2,
                    backgroundColour: theme.colours.dodgerBlue2,
                    svgSizePercent: 70,
                })}
            >
                {/*For colouring in the logo*/}
                <div css={whiteStyle} />
                <BsLinkedin />
            </a>
            <div
                css={iconStyle({
                    backgroundColour: theme.colours.amber,
                    svgSizePercent: 70,
                })}
                className={"icon"}
            >
                <a href={mailTo}>
                    <BsFillEnvelopeFill />
                </a>
            </div>
            <div
                css={iconStyle({
                    backgroundColour: theme.colours.conifer,
                    svgSizePercent: 90,
                })}
                className={"icon"}
                onClick={copyUrlToClipboard}
            >
                <BsLink45Deg />
            </div>
        </div>
    );
}

export default SocialShare;
