import React, { useCallback, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { isEmpty, isEqual } from "lodash";

import BannerForAdvertisement from "core/Components/BannerForAdvertisement/BannerForAdvertisement";
import DropdownButton from "core/Components/Buttons/DropdownButton/DropdownButton";
import ItemBoxContainer from "core/Components/ItemWithRibbon/ItemBoxContainer";
import ItemContainer from "core/Components/ItemWithRibbon/ItemContainer";
import ItemListContainer from "core/Components/ItemWithRibbon/ItemListContainer";
import ItemListWrapper from "core/Components/ItemWithRibbon/ItemListWrapper";
import SectionContainer from "core/Components/SectionContainer";
import Loading from "core/Components/Utils/Loading";
import Wrapper from "core/Components/Wrapper";
import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { successStoryCommonQuery, successStoryListQuery } from "core/includes/queries";

import NoData from "core/Components/Errors/NoData";
import useListBreakpoint from "pages/Components/ListPage/hooks/useListBreakpoint";
import DropdownContainer from "pages/Components/ListPage/DropdownContainer";
import HeaderContainer from "pages/Components/ListPage/HeaderContainer";
import HeaderTitle from "pages/Components/ListPage/HeaderTitle";
import IntroText from "pages/Components/ListPage/IntroText";
import PageSection from "pages/Components/ListPage/PageSection";
import {
    DEFAULT_SUCCESS_COUNTRY_CODE,
    DEFAULT_SUCCESS_SCHOOL_TYPE,
} from "pages/includes/constants";
import { getCanRenderWebp } from "core/includes/image";

const PAGE_TYPE = "success-story";

type DefaultState = {
    schoolType: Option,
    countryCode: Option,
};

const INITIAL_STATE = {
    schoolType: DEFAULT_SUCCESS_SCHOOL_TYPE,
    countryCode: DEFAULT_SUCCESS_COUNTRY_CODE,
};

type Props = {
    data: SuccessStoryListPage
};

const SuccessStoryListPage: React.FC<Props> = ({ data: page }) => {
    const theme = useTheme();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const { bannerHeight, itemBoxWidth } = useListBreakpoint();
    const { minWidth } = useBreakpoint();
    const canRenderWebp = getCanRenderWebp();

    const { data: commonData, loading: commonDataLoading } = useQuery(successStoryCommonQuery, {
        variables: { type: PAGE_TYPE, parentId: page.id, canRenderWebp },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const { data, loading } = useQuery(successStoryListQuery, {
        variables: {
            parentId: page.id,
            schoolType: state.schoolType.value,
            countryCode: state.countryCode.value,
            canRenderWebp
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const setSelectedCountry = useCallback((item: Option) => {
        setState({ countryCode: item });
    }, [setState]);

    const setSelectedSchoolType = useCallback((item: Option) => {
        setState({ schoolType: item });
    }, [setState]);

    const countryOptions = [DEFAULT_SUCCESS_COUNTRY_CODE];
    commonData?.countryCode.forEach((item: CountryCodeItem) => {
        countryOptions.push({ value: item.code, label: item.name });
    });

    const schoolTypeOptions = [DEFAULT_SUCCESS_SCHOOL_TYPE];
    commonData?.schoolType?.forEach((item: DropdownTypeOption) => {
        schoolTypeOptions.push({ value: item.id, label: item.type });
    });

    const wrapperStyle = css`
        flex-direction: column;
        align-items: center;
    `;

    const getImage = useCallback((data: SuccessStoryListPage) => {
        if (data && (data.landscape || data.portrait)) {
            if (minWidth >= theme.breakpoints.sizes["lg"]) {
                return data.landscape || data.portrait;
            }

            return data.portrait || data.landscape;
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const image = useMemo(() => {
        if (commonDataLoading) return null;

        // The landscape (or portrait) has the priority rather than commonData banner.
        if (page.landscape || page.portrait) {
            return getImage(page);
        }

        if (commonData?.successStoryBanner &&
            (commonData.successStoryBanner.portrait || commonData.successStoryBanner.landscape)
        ) {
            return getImage(commonData.successStoryBanner);
        }

        return null;
    }, [commonDataLoading, commonData?.successStoryBanner, getImage, page]);

    const getUseFocusPoint = useCallback((data: SuccessStoryListPage) => (
        data ?
            (isEqual(image, data.landscape) && data.useFocusPointLandscape) ||
            (isEqual(image, data.portrait) && data.useFocusPointPortrait)
            : true
    ), [image])

    const useAFocusPoint = getUseFocusPoint((page.landscape || page.portrait) ? page : commonData?.successStoryBanner);

    const altText = (page.landscape || page.portrait) ? page.altText : '';

    return (
        <SectionContainer data={{ backgroundColour: theme.colours.alabaster }}>
            <Wrapper useMaxWidth={false} useMinHeight={false} css={wrapperStyle}>
                {image && (
                    <BannerForAdvertisement
                        type={PAGE_TYPE}
                        mainBannerImage={image}
                        useMainBannerFocusPoint={useAFocusPoint}
                        data={commonData?.successStoryBanner}
                        bannerHeight={bannerHeight}
                        customButtonText={page.buttonText}
                        showText={false}
                        showButton={!!commonData?.successStoryBanner && page.showButtonOnBanner}
                        customAltText={altText}
                    />
                )}

                <IntroText data={page} />

                <PageSection>
                    <HeaderContainer type={PAGE_TYPE}>
                        <HeaderTitle type={PAGE_TYPE}>{page.showHeaderOfList ? page.title : ""}</HeaderTitle>
                        <DropdownContainer>
                            <DropdownButton
                                options={countryOptions}
                                onChange={setSelectedCountry}
                                selectedValue={[state.countryCode]}
                            />
                            <DropdownButton
                                options={schoolTypeOptions}
                                onChange={setSelectedSchoolType}
                                selectedValue={[state.schoolType]}
                            />
                        </DropdownContainer>
                    </HeaderContainer>

                    <ItemListContainer isMiddle={!data?.successStoryList}>
                        {loading ? (
                            <Loading onTop fitToScreen overlay overlayColour={"white"} delay={500} />
                        ) : (
                            isEmpty(data?.successStoryList) ? (
                                <NoData />
                            ) : (
                                <ItemListWrapper itemBoxWidth={itemBoxWidth}>
                                    {data?.successStoryList && data.successStoryList.map((item: SuccessStoryThumbnail, index: number) => (
                                        <ItemContainer key={`ss-thumbnail-${index}`} itemBoxWidth={itemBoxWidth}>
                                            <ItemBoxContainer
                                                type={PAGE_TYPE}
                                                data={item}
                                                showRibbon={page.showRibbon}
                                                schoolType={commonData?.schoolType}
                                                commonImage={item.previewImage}
                                                textBoxColour={"white"}
                                            />
                                        </ItemContainer>
                                    ))}
                                </ItemListWrapper>
                            )
                        )}
                    </ItemListContainer>
                </PageSection>
            </Wrapper>
        </SectionContainer>
    );
};

export default SuccessStoryListPage;
