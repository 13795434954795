import React from "react";
import { useTheme, css } from "@emotion/react";

type Props = {
    error: string,
};

const ErrorPageHeader: React.FC<Props> = ({ error = "404" }) => {
    const theme = useTheme();

    const header = error === "404" ? "Oh no!" : "Oh snap!";

    const headerStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
        font-size: 60px;
        line-height: 70px;
        margin-bottom: 2px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 70px;
            line-height: 82px;
            margin-bottom: 4px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 50px;
            line-height: 30px;
            margin-bottom: 9px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 45px;
            line-height: 30px;
            margin-bottom: 7px;
        }
    `;

    return (
        <div css={headerStyle}>
            {header}
        </div>
    );
};

export default ErrorPageHeader;
