import React, { memo } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { ReactComponent as PlayIcon } from "images/icons/play-triangle-with-circle.svg";
import Image from "core/Components/Image";

type Props = {
    onClick?: (e: React.MouseEvent) => void,
    showVideo?: boolean,
    isPlayIcon?: boolean,
    className?: string,
    image: string | Image,
    useFocusPoint?: boolean,
};

const VideoPlaceholderWithFocusPoint: React.FC<Props> = ({
    onClick,
    showVideo,
    isPlayIcon = true,
    className,
    image,
    useFocusPoint = false,
}) => {
    const theme: Theme = useTheme();

    const containerStyle = css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        
        > * {
            transition: ${theme.transitions.transition};
            transition-property: filter, transform;
        }

        &:hover > svg {
            transform: translate(-50%, -50%) scale(1.1);
        }
        
        ${showVideo && css`
            opacity: 0;
        `};
    `;

    const playIconContainerStyle = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 26%;
        z-index: ${theme.zIndex.zIndexOne};
        fill: ${theme.colours.raspberry};
        border-radius: 50%;
        will-change: transform;
    `;

    return (
        <div css={containerStyle} onClick={onClick} className={className}>
            {isPlayIcon && (
                <PlayIcon css={playIconContainerStyle} />
            )}

            <Image image={image} useFocusPoint={useFocusPoint} />
        </div>
    );
};

export default memo(VideoPlaceholderWithFocusPoint);
