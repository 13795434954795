import React, { memo } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import BreadcrumbItem from "core/Components/Breadcrumb/BreadcrumbItem";

type Props = {
    className?: string,
    currentURLSegment?: string,
    breadcrumbs: { label: string, link: string }[],
};

const BreadcrumbList: React.FC<Props> = ({
    currentURLSegment = "",
    className = "",
    breadcrumbs = [],
}) => {
    const theme:Theme = useTheme();

    const style = css`
        color: ${theme.colours.gray2};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    return (
        <div className={className}>
            <div css={style}>
                {breadcrumbs.map((breadcrumb, index) => (
                    <BreadcrumbItem
                        key={`breadcrumb-${index}`}
                        currentURLSegment={currentURLSegment}
                        link={breadcrumb.link}
                        isLast={index === breadcrumbs.length - 1}
                    >
                        {breadcrumb.label}
                    </BreadcrumbItem>
                ))}
            </div>
        </div>
    );
};

export default memo(BreadcrumbList);