import React, { useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { useAppState } from "core/contexts/AppContext";
import { retrieveValue } from "core/includes/localStorage";
import { publicKeys } from "core/config/stripeConfig";

type Props = {
    children?: React.ReactNode,
};

const StripeLoader: React.FC<Props> = ({ children }) => {
    const stripeKey = useRef('none');
    const [appState] = useAppState();
    const countryCodeSelected = retrieveValue('selected-country')?.replace(/"/g, "") || appState.countryCode?.code.toLowerCase();

    stripeKey.current = (
        publicKeys.find(keySet => keySet.country === countryCodeSelected)?.key ??
        publicKeys.find(keySet => keySet.country === 'default')?.key
    ) || '';
    const stripePromise: any = loadStripe(stripeKey.current);

    if (!stripePromise) {
        return <div />;
    }

    return (
        // key is necessary to ensure Stripe updates country on country change.
        <Elements key={stripeKey.current} stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default StripeLoader;
