import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    useCollapse?: boolean,
    toggleHandler?: Function,
};

const SubColumn: React.FC<Props> = ({
    children,
    useCollapse = false,
    toggleHandler,
}) => {
    const theme = useTheme();

    const onClickHandler = () => {
        if (toggleHandler) {
            toggleHandler();
        }
    };

    const style = css`
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1240px;
        
        ${toggleHandler && css`
            cursor: pointer;
        `}

        ${theme.breakpoints.up("5xl")} {
            padding: 45px 0;

            ${useCollapse && css`
                padding-top: 55.5px;
                padding-bottom: 57.5px;
            `}
        }
        
        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 45px 0;
            
            ${useCollapse && css`
                padding-top: 35.5px;
                padding-bottom: 43.5px;
            `}
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 45px 0;

            ${useCollapse && css`
                padding-top: 45.5px;
                padding-bottom: 47.5px;
            `}
        }

        ${theme.breakpoints.only("xl")} {
            padding: 38.5px 0;
            width: 1120px;

            ${useCollapse && css`
                padding-top: 42.5px;
                padding-bottom: 48px;
            `}
        }

        ${theme.breakpoints.only("lg")} {
            padding: 38.5px 0;
            width: 930px;

            ${useCollapse && css`
                padding-top: 41.5px;
                padding-bottom: 48px;
            `}
        }

        ${theme.breakpoints.only("md")} {
            padding: 31.5px 0;
            width: 676px;

            ${useCollapse && css`
                padding-top: 31.5px;
                padding-bottom: 33.5px;
            `}
        }

        ${theme.breakpoints.only("sm")} {
            padding: 23.5px 0;
            width: 478px;

            ${useCollapse && css`
                padding-top: 24px;
                padding-bottom: 27px;
            `}
        }

        ${theme.breakpoints.down("xs")} {
            padding: 23.5px 0;
            width: 313px;

            ${useCollapse && css`
                padding-top: 28px;
                padding-bottom: 34px;
            `}
        }
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {children}
        </div>
    );
};

export default SubColumn;
