import React from 'react';
import { css, useTheme } from '@emotion/react';

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    text?: string,
    textColour?: string,
    textAlign?: string,
    useMarginTop?: boolean,
};

const SimpleText: React.FC<Props> = ({
    text = '',
    textColour = 'white',
    textAlign = 'left',
    useMarginTop = false,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: start;
        color: ${textColour};
        
        ${textAlign && css`
            text-align: ${textAlign};
        `}
        
        ${useMarginTop && css`
            margin-top: 57px;

            ${theme.breakpoints.up('5xl')} {
                margin-top: 100px;
            }

            ${theme.breakpoints.between('3xl', '4xl')} {
                margin-top: 87px;
            }

            ${theme.breakpoints.between('lg', 'xl')} {
                margin-top: 54px;
            }

            ${theme.breakpoints.only('md')} {
                margin-top: 62px;
            }

            ${theme.breakpoints.only('sm')} {
                margin-top: 60px;
            }

            ${theme.breakpoints.down('xs')} {
                margin-top: 38px;
            }
        `}
    `;

    return (
        <div css={style}>
            <ContentCombine Tag={'p'} fontSizeType={'10'}>{text}</ContentCombine>
        </div>
    );
};


export default SimpleText;