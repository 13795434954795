import React, { useRef } from "react";

import InputNumberContainer from "core/Components/InputNumber/InputNumberContainer";
import InputNumberField from "core/Components/InputNumber/InputNumberField";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import InputNumberUpDown from "core/Components/InputNumber/InputNumberUpDown";

type Props = {
};

const InputNumber: React.FC<Props> = () => {
    const quantityRef = useRef<any>(null);

    const up = () => {
        const quantity = quantityRef.current.value ?? 1;
        if (quantityRef.current) {
            quantityRef.current.value = parseInt(quantity) + 1;
        }
    };

    const down = () => {
        const quantity = quantityRef.current.value ?? 1;
        if (quantityRef.current && parseInt(quantity) > 1) {
            quantityRef.current.value = parseInt(quantity) - 1;
        }
    };

    return (
        <InputNumberContainer>
            <InputNumberField quantityRef={quantityRef} />
            <InputNumberUpDown>
                <TiArrowSortedUp onClick={up} cursor={'pointer'} />
                <TiArrowSortedDown onClick={down} cursor={'pointer'} />
            </InputNumberUpDown>
        </InputNumberContainer>
    );
};

export default InputNumber;