import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const ImageContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const imageContainerStyle = css`
        width: 50%;

        ${theme.breakpoints.down("sm")} {
            width: 100%;
        }
    `;

    return (
        <ContentSection css={imageContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default ImageContainer;
