import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import CarouselContainerFromCore from "core/Components/Carousel/CarouselContainer";

type Props = {
    children: React.ReactNode
};

const CarouselContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const carouselContainerStyle = css`
        padding: 15px 0 30px;
        width: 100%;

        ${theme.breakpoints.up("5xl")} {
            padding: 20px 0 40px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 17px 0 35px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 12px 0 25px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 10px 0 20px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 10px 0 20px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 6px 0 20px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 10px 0 20px;
        }
    `;

    return (
        <CarouselContainerFromCore css={carouselContainerStyle}>
            {children}
        </CarouselContainerFromCore>
    );
};

export default CarouselContainer;
