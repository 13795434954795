const border = {
    smallRadius: "3px",
    largeRadius: "9px",
    largerRadius: '18px',
    larger2Radius: '20px',
}

const shadow = {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    boxShadow2: '5px 3px 6px 0 rgba(0, 0, 0, 0.3)',
    boxShadow3: '0 3px 6px 0 rgba(0, 0, 0, 0.3)',
    boxShadow4: '9px 0px 6px -5px rgba(0, 0, 0, 0.3)',
    boxShadow5: '0 3px 20px rgba(0, 0, 0, 0.13)',
    boxShadow6: '0 0 10px rgba(0, 0, 0, 0.16)',
    boxShadow7: '0px 0px 6px #00000029',
    boxInsetBottomShadow: 'inset 0 -10px 10px -15px rgba(0, 0, 0, 0.3)',
    boxInsetTopShadow1: 'inset 0 5px 6px -5px rgba(0, 0, 0, 0.16)',
    boxInseBoxShadow1: 'inset 0 0 6px rgba(0, 0, 0, 0.16)',
    dropShadow: '0 0 6px 0 rgba(0, 0, 0, 0.3)',
    dropShadow1: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    dropShadow2: '0 3px 20px rgba(0, 0, 0, 0.42)',
}

const v = {
    ...border,
    ...shadow,
}

export default v;
