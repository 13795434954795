import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    textBoxPosition?: string,
    className?: string,
};

const ContentSection: React.FC<Props> = ({
    children,
    textBoxPosition,
    className,
}) => {
    const theme = useTheme();

    const style = css`
        display: inherit;
        justify-content: unset;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: ${theme.zIndex.zIndexTwo};
        padding: 0 40px;

        ${textBoxPosition === "end" && css`
            flex-direction: row-reverse;
        `};
        
        ${theme.breakpoints.up("5xl")} {
            padding: 0 50px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 0 45px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 0 35px;
        }
        
        ${theme.breakpoints.only("lg")} {
            padding: 0 30px;
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
            padding: 0;
            align-items: center;
            height: unset;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default ContentSection;
