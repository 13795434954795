import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode
};

const QuantityContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const containerStyle = css`
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 1px solid ${theme.colours.grey[200]};
        justify-content: space-between;
        padding: 4px;
        height: 40px;
        width: 157px;
        user-select: none;
        margin-top: 14px; 
        > input {
            font-size: 15px;
        }

        ${theme.breakpoints.up("xxl")} {
            height: 45px;
            width: 183px;
            border-radius: 23px;
            > div {
                height: 34px;
                width: 34px;
                > svg {
                    height: 22px;
                    width: 22px;
                }
            }
            > input {
                font-size: 25px;
            } 
            
        }

        ${theme.breakpoints.only("xl")} {
            height: 35px;
            width: 145px;
            border-radius: 23px;
            > div {
                height: 27px;
                width: 27px;
                > svg {
                    height: 17px;
                    width: 17px;
                }
            }
            > input {
                font-size: 19px;
            }
        }
    `;

    return (
        <div css={containerStyle}>
            {children}
        </div>
    );
}

export default QuantityContainer;
