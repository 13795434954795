import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    radius?: number,
    isImageWithoutBoxShadow?: boolean,
    className?: string,
};

const ImageRadiusBox: React.FC<Props> = ({
    children,
    radius,
    isImageWithoutBoxShadow = false,
    className = '',
}) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        height: 100%;
        
        border-radius: ${radius}px;
        
        ${!isImageWithoutBoxShadow && css`
            box-shadow: ${theme.borderAndShadow.dropShadow};
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default ImageRadiusBox;
