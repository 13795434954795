import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    data: ContentWithImage,
};

const Button: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const linkURL = data.innerURL || data.outerURL;

    const colourTheme = JSON.parse(data.theme);
    const { buttonTextColour, buttonColour, } = colourTheme;

    const buttonTextCustomStyle = css`
        font-size: 20px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    const buttonContainerStyle = css`
        ${theme.breakpoints.down("sm")} {
            width: 100%
        }
    `;

    return (
        <RoundButton
            backgroundColour={buttonColour}
            textColour={buttonTextColour}
            linkURL={linkURL}
            css={buttonContainerStyle}
            buttonTextCustomStyle={buttonTextCustomStyle}
        >
            {data.buttonText}
        </RoundButton>

    );
}

export default Button;