import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    width?: number,
};

const FeatureTitleBox: React.FC<Props> = ({ children, width }) => {
    const style = css`
        display: flex;
        justify-content: center;
        
        ${width && css`
            width: ${width}px;
        `}
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default FeatureTitleBox;
