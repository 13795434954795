import React, { memo, useCallback, useEffect, useRef } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { css, Global } from "@emotion/react";
import { isEmpty } from "lodash";

import { useAppState } from "core/contexts/AppContext";
import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { getFooterMenu, getURLSegment, getURLSegments } from "core/includes/formatters";
import { persist, retrieveValue } from "core/includes/localStorage";
import { isIncludeInBreadcrumbTargets } from "core/includes/segments";
import { getFeatureFlags, pageMenuQuery } from "core/includes/queries";

import BreadcrumbContainer from "core/Components/Breadcrumb/BreadcrumbContainer";
import SectionContainer from "core/Components/SectionContainer";
import NavContainer from "core/Components/Navigations/NavContainer";
import NavCountryDropdown from "core/Components/Navigations/NavCountryDropdown";
import NavLinkButtons from "core/Components/Navigations/NavLinkButtons";
import NavLinkContainer from "core/Components/Navigations/NavLinkContainer";
import NavLogo from "core/Components/Navigations/NavLogo";
import NavShadowBox from "core/Components/Navigations/NavShadowBox";
import NavSide from "core/Components/Navigations/NavSide";
import NavWrapper from "core/Components/Navigations/NavWrapper";

import BurgerNavContainer from "core/Components/HamburgerNavigations/BurgerNavContainer";
import BurgerNavHeaderContainer from "core/Components/HamburgerNavigations/BurgerNavHeaderContainer";
import BurgerNavMainContainer from "core/Components/HamburgerNavigations/BurgerNavMainContainer";
import BurgerNavMenuIcon from "core/Components/HamburgerNavigations/BurgerNavMenuIcon";
import BurgerNavWrapper from "core/Components/HamburgerNavigations/BurgerNavWrapper";

import BurgerNavHeaderMenuBox from "core/Components/HamburgerNavigations/large/BurgerNavHeaderMenuBox";
import BurgerNavLeft from "core/Components/HamburgerNavigations/large/BurgerNavLeft";
import BurgerNavRight from "core/Components/HamburgerNavigations/large/BurgerNavRight";

import BurgerNavSmallContainer from "core/Components/HamburgerNavigations/small/BurgerNavSmallContainer";
import BurgerNavSmallCountryDropdownContainer from "core/Components/HamburgerNavigations/small/BurgerNavSmallCountryDropdownContainer";

import { isOnly } from "theme/breakpoints";
import { getCanRenderWebp } from "core/includes/image";
import CartIcon from "core/Components/Navigations/CartIcon";
import BurgerNavSmallCartWrapper from "core/Components/HamburgerNavigations/small/BurgerNavSmallCartWrapper";
import BurgerNavSmallRightButtonsContainer from "core/Components/HamburgerNavigations/small/BurgerNavSmallRightButtonsContainer";

type DefaultState = {
    selectedHeaderMenu?: StandardPage, // General: Selected header menu
    isClick: boolean, // Over 1280px only (Option to hide the submenu)
    showBurgerMenu: boolean, // Less than 1024px only (toggle)
    isBurgerMenuClick: boolean, // Less than 1280px (Option to set the underline & its colour between clicked menu & selected menu)
    // Common data (Header, Footer, Countries)
    pages?: [StandardMenu],
    headerData?: {
        headerMenuAll?: [StandardPage],
        footerMenuAll?: [StandardPage],
        menuButton?: Button, // Button next to Login button
        countries?: [CountryItem],
    }
};

const INITIAL_STATE = {
    selectedHeaderMenu: undefined,
    isClick: false,
    showBurgerMenu: false,
    isBurgerMenuClick: false,
    pages: undefined,
    headerData: undefined,
};

type Props = {
    stage: string | null,
    footerMenuHandler: (pages: any) => void,
    breadcrumbHandler: (showBreadcrumb: boolean) => void,
};

const Header: React.FC<Props> = ({ stage, footerMenuHandler, breadcrumbHandler }) => {
    const apolloClient = useApolloClient();
    const navigate = useNavigate();
    const location = useLocation();
    const currentURLSegment = getURLSegment(location.pathname);
    const urlSegments = getURLSegments(location.pathname);

    const [appState, setAppState] = useAppState();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const showBreadcrumb = isIncludeInBreadcrumbTargets(currentURLSegment);
    const headerRef = useRef<HTMLDivElement>(null);
    const canRenderWebp = getCanRenderWebp();
    const { breakpoint } = useBreakpoint();

    // Default set
    useEffect(() => {
        setAppState((prev: any) => ({
            currentURLSegment,
            countryCode: {
                defaultCode: prev.countryCode.defaultCode,
                code: prev.countryCode.code || (retrieveValue("selected-country")?.replace(/"/g, "") ?? "")
            }
        }));
    }, [currentURLSegment, setAppState]);

    const { data } = useQuery(pageMenuQuery, {
        variables: { stage, canRenderWebp },
        context: {
            headers: {
                'Selected-Country': appState.countryCode.code || (retrieveValue('selected-country')?.replace(/"/g, "") ?? "")
            }
        }
    });

    const { data: featureFlagsData } = useQuery(getFeatureFlags);

    // Re-fetch when country code is changed
    useEffect(() => {
        if (!state.pages ||
            (appState.countryCode.code && appState.countryCode.defaultCode !== appState.countryCode.code)
        ) {
            apolloClient.refetchQueries({ include: "active" });
        }
    }, [
        apolloClient,
        state.pages,
        appState.countryCode.defaultCode,
        appState.countryCode.code,
        canRenderWebp,
        stage,
    ]);

    // Get each data according to segment from common header data
    const getDataForEachSegment = useCallback((segment: string) => {
        const segmentData = data?.pageMenu?.find((p: StandardMenu) => p.segment === segment);

        switch (segment) {
            case "menuButton":
                return segmentData?.menuButton;
            case "accessed-country":
                return segmentData?.selectedCountry;
            case "countries":
                return segmentData?.countries;
            default:
                return segmentData?.standardPage;
        }
    }, [data?.pageMenu]);

    // Set state after getting data
    useEffect(() => {
        if (data) {
            const selectedCountry = getDataForEachSegment("accessed-country");

            setState({
                pages: data?.pageMenu,
                headerData: {
                    headerMenuAll: getDataForEachSegment("headerMenu"), // 1st level menu
                    footerMenuAll: getDataForEachSegment("footerMenu"), // Footer menu
                    menuButton: getDataForEachSegment("menuButton"), // Custom button next to Login
                    countries: getDataForEachSegment("countries"), // Country list
                }
            });

            // Set the selected country
            if (selectedCountry) {
                setAppState({
                    countryCode: {
                        defaultCode: selectedCountry,
                        code: selectedCountry,
                    },
                    featureFlags: featureFlagsData?.featureFlags
                });
            } else {
                setAppState({
                    featureFlags: featureFlagsData?.featureFlags
                });
            }
        }
    }, [data, featureFlagsData?.featureFlags, getDataForEachSegment, setAppState, setState]);

    // Country handler for changing country by client
    const setCountryHandler = useCallback((countryCode: string) => {
        setState((prev: any) => ({
            headerData: {
                ...prev.headerData,
            }
        }));

        if (appState.country?.code !== countryCode) {
            setAppState((prev: {
                countryCode: {
                    defaultCode: string,
                    code: string,
                }
            }) => ({
                countryCode: {
                    defaultCode: prev.countryCode.defaultCode,
                    code: countryCode,
                }
            }));

            persist('selected-country', countryCode);
        }
    }, [appState.country?.code, setState, setAppState]);

    const clearMenu = () => {
        setState({
            isClick: false,
            showBurgerMenu: false,
            isBurgerMenuClick: false,
        });
        clearCurrentPageName();
    };

    const getSubMenu = (headerMenu: StandardPage) => {
        return headerMenu ? state.pages?.find((p: StandardMenu) => p.segment === headerMenu.uRLSegment) : null;
    };

    // Get page data by URLSegment
    const getPageBySegment = useCallback((selectedSegment: string = '') => {
        const segmentsOfLocation = getURLSegments(location.pathname) ?? [];
        const defaultSegment = selectedSegment || segmentsOfLocation?.[0];
        const segmentIndex = urlSegments.indexOf(defaultSegment);
        const segment = segmentIndex === 0 ? 'headerMenu' : segmentsOfLocation[segmentIndex - 1];

        const parentPage = state.pages?.find((page: StandardMenu) => page.segment === segment); // Parent page
        return parentPage?.standardPage?.find((page: StandardPage) => page.uRLSegment === defaultSegment); // Current(Child) page
    }, [location.pathname, state.pages, urlSegments]);

    // Get breadcrumb link information by URLSegment
    const getLinkBySegment = useCallback((selectedSegment: string) => {
        const segmentIndex = urlSegments.indexOf(selectedSegment);
        const currentPage = getPageBySegment(selectedSegment);

        let link = '';
        for (let i = 0; i <= segmentIndex; i++) {
            link += '/' + urlSegments[i];
        }

        const currentPageName = appState.currentPageName;

        return {
            label: currentPage?.title
                ?? (currentURLSegment === selectedSegment && isEmpty(currentPage?.title) && currentPageName),
            link: link
        };
    }, [urlSegments, getPageBySegment, appState.currentPageName, currentURLSegment]);

    // Set the 1st level menu when browser is reloaded
    useEffect(() => {
        if (state.pages) {
            const segmentsOfLocation = getURLSegments(location.pathname);
            const defaultSegment = segmentsOfLocation[0];
            const segmentIndex = segmentsOfLocation.indexOf(defaultSegment);
            const segment = segmentIndex === 0 ? 'headerMenu' : segmentsOfLocation[segmentIndex - 1];

            const parentPage = state.pages?.find((page: StandardMenu) => page.segment === segment);
            const currentPage = parentPage?.standardPage?.find((page: StandardPage) => page.uRLSegment === defaultSegment);

            setState({
                selectedHeaderMenu: currentPage
            });
        }
    }, [
        state.pages,
        location.pathname,
        setState,
    ]);

    // Set custom menu button
    useEffect(() => {
        setState({ menuButton: state.headerData?.menuButton });
    }, [state.headerData?.menuButton, setState]);

    // Set the value that breadcrumb will be displayed or not
    useEffect(() => {
        if (currentURLSegment) {
            breadcrumbHandler(showBreadcrumb);
        }
    }, [breadcrumbHandler, showBreadcrumb, currentURLSegment]);

    const clearCurrentPageName = () => {
        setAppState({ currentPageName: '' });
    };

    const mouseOverHandler = () => {
        setState({ isClick: false });
    };

    const mouseClickHandler = () => {
        setState({ isClick: true });
        clearCurrentPageName();
    };

    // [Burger] Show and hide sub-menu
    const toggleBurgerMenu = ({ forcedClose }: any) => {
        setState((prev: DefaultState) => ({ showBurgerMenu: forcedClose ? !forcedClose : !prev.showBurgerMenu }))
    };

    // [Burger] Get sub-menu when user click the header menu
    const setSubBurgerMenu = ({ headerMenu, keepHeader }: any) => {
        setState((prev: DefaultState) => ({
            selectedHeaderMenu: keepHeader
                ? headerMenu
                : (prev.selectedHeaderMenu?.uRLSegment === headerMenu.uRLSegment ? null : headerMenu),
            isBurgerMenuClick: true,
        }));
    };

    const onMoveToHome = () => {
        navigate("/");
        toggleBurgerMenu({ forcedClose: true });
        clearCurrentPageName();
    };

    // Set footer
    useEffect(() => {
        if (state.headerData?.footerMenuAll) {
            footerMenuHandler(getFooterMenu(state.headerData.footerMenuAll));
        }
    }, [state.headerData?.footerMenuAll, footerMenuHandler]);

    const bodyOverflowHidden = css`
        body {
            position: fixed;
            overflow: hidden;
        }
    `;

    const country = retrieveValue('selected-country')?.replace(/"/g, "") || appState.countryCode?.code.toLowerCase();

    return (
        <SectionContainer>
            {/* Menu type 1: Over 1280px */}
            <NavContainer>
                <NavShadowBox>
                    <NavWrapper divRef={headerRef}>
                        <NavSide marginSide={"left"}>
                            <NavLogo onMoveToHome={onMoveToHome} />
                        </NavSide>
                        <NavLinkContainer
                            headerMenuAll={state.headerData?.headerMenuAll}
                            selectedSegments={urlSegments}
                            getSubMenu={getSubMenu}
                            mouseOverHandler={mouseOverHandler}
                            mouseClickHandler={mouseClickHandler}
                            showBreadcrumb={showBreadcrumb}
                            isClick={state.isClick}
                        />
                        <NavSide marginSide={"right"}>
                            <NavLinkButtons
                                menuButton={state.headerData?.menuButton}
                                closeBurgerMenu={toggleBurgerMenu}
                            />
                        </NavSide>
                    </NavWrapper>

                    {showBreadcrumb && (
                        <BreadcrumbContainer
                            currentURLSegment={currentURLSegment}
                            selectedSegments={urlSegments}
                            getLinkBySegment={getLinkBySegment}
                            countries={state.headerData?.countries}
                            selectedCountry={country}
                            setCountryHandler={setCountryHandler}
                            headerRef={headerRef}
                        />
                    )}
                </NavShadowBox>
            </NavContainer>

            {/* Less 1280px */}
            <BurgerNavContainer closeBurgerMenu={toggleBurgerMenu}>
                <BurgerNavHeaderContainer>
                    {/* Country code area for the xs (360px) */}
                    <BurgerNavSmallCountryDropdownContainer>
                        <NavCountryDropdown
                            countries={state.headerData?.countries}
                            selectedCountry={country}
                            setCountryHandler={setCountryHandler}
                        />
                    </BurgerNavSmallCountryDropdownContainer>

                    <BurgerNavWrapper>
                        <BurgerNavMenuIcon showBurgerMenu={state.showBurgerMenu} toggleBurgerMenu={toggleBurgerMenu} />
                        <NavLogo onMoveToHome={onMoveToHome} />
                        <BurgerNavSmallRightButtonsContainer>
                            <BurgerNavSmallCartWrapper>
                                <CartIcon
                                    to={state.headerData?.menuButton?.cartURL || ''}
                                    closeBurgerMenu={toggleBurgerMenu}
                                />
                            </BurgerNavSmallCartWrapper>
                            {isOnly(breakpoint, "lg") && (
                                <NavSide marginSide={"right"}>
                                    <NavLinkButtons
                                        menuButton={state.headerData?.menuButton}
                                        closeBurgerMenu={toggleBurgerMenu}
                                        hideCart
                                    />
                                </NavSide>
                            )}
                        </BurgerNavSmallRightButtonsContainer>
                    </BurgerNavWrapper>

                    {/* BreadcrumbContainer includes breadcrumb & country dropdown */}
                    {showBreadcrumb && (
                        <BreadcrumbContainer
                            currentURLSegment={currentURLSegment}
                            selectedSegments={urlSegments}
                            getLinkBySegment={getLinkBySegment}
                            countries={state.headerData?.countries}
                            selectedCountry={country}
                            setCountryHandler={setCountryHandler}
                            headerRef={headerRef}
                        />
                    )}
                </BurgerNavHeaderContainer>

                <BurgerNavMainContainer showBurgerMenu={state.showBurgerMenu}>
                    {/* Menu type 2: Between 1024px and 1280px */}
                    {state.showBurgerMenu && (
                        <Global styles={bodyOverflowHidden} />
                    )}
                    <BurgerNavLeft>
                        {state.headerData?.headerMenuAll && state.headerData.headerMenuAll.map((headerMenu: StandardPage) => (
                            headerMenu.showInMenus ? (
                                <BurgerNavHeaderMenuBox
                                    key={"burgerHeader" + headerMenu.id}
                                    headerMenu={headerMenu}
                                    currentURLSegment={currentURLSegment}
                                    selectedHeaderMenu={state.selectedHeaderMenu}
                                    selectedSegments={urlSegments}
                                    getSubMenu={getSubMenu}
                                    setSubMenu={setSubBurgerMenu}
                                    closeMenuHandler={clearMenu}
                                    isBurgerMenuClick={state.isBurgerMenuClick}
                                />
                            ) : null
                        ))}
                    </BurgerNavLeft>
                    <BurgerNavRight
                        headerMenu={state.selectedHeaderMenu}
                        currentURLSegment={currentURLSegment}
                        selectedSegments={urlSegments}
                        getSubMenu={getSubMenu}
                        closeMenuHandler={clearMenu}
                    />

                    {/* Menu type 3: Less 1024px */}
                    <BurgerNavSmallContainer
                        headerMenuAll={state.headerData?.headerMenuAll}
                        menuButton={state.headerData?.menuButton}
                        selectedHeaderMenu={state.selectedHeaderMenu}
                        currentURLSegment={currentURLSegment}
                        selectedSegments={urlSegments}
                        getSubMenu={getSubMenu}
                        setSubMenu={setSubBurgerMenu}
                        closeMenuHandler={clearMenu}
                    />
                </BurgerNavMainContainer>
            </BurgerNavContainer>
        </SectionContainer>
    );
};

export default memo(Header);
