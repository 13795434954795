import React from "react";
import { css } from "@emotion/react";
import theme from "theme/index";

type Props = {
    title: string,
    width: number,
    height: number,
    backgroundColour?: string,
};

const FeatureTitle: React.FC<Props> = ({
    title,
    width,
    height,
   backgroundColour = theme.colours.aluminium,
}) => {
    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${width}px;
        height: ${height}px;
        text-align: center;
        font-size: 21px;
        line-height: 25px;
        border-radius: 25px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.white};

        background-color: ${backgroundColour};
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 40px;
            line-height: 54px;
            border-radius: 44px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: ${title === "Features" ? 20 : 16}px;
            line-height: ${title === "Features" ? 24 : 19}px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 18px;
            line-height: 22px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 14px;
            line-height: 17px;
        }
    `;

    return (
        <div css={style}>{title}</div>
    );
}

export default FeatureTitle;
