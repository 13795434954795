import React from "react";

import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";

import ErrorPageContainer from "pages/Components/ErrorPage/components/ErrorPageContainer";
import ErrorPageNumber from "pages/Components/ErrorPage/components/ErrorPageNumber";
import ErrorPageText from "pages/Components/ErrorPage/components/ErrorPageText";
import ErrorPageSubheader from "pages/Components/ErrorPage/components/ErrorPageSubheader";
import ErrorPageHeader from "pages/Components/ErrorPage/components/ErrorPageHeader";
import ErrorPageButton from "pages/Components/ErrorPage/components/ErrorPageButton";

type Props = {
    error: string,
};

const ErrorPage: React.FC<Props> = ({ error }) => (
    <SectionContainer>
        <Wrapper alignItems={"flex-end"}>
            <ErrorPageContainer>
                <ErrorPageHeader error={error} />

                <ErrorPageSubheader error={error} />

                <ErrorPageText error={error} />

                <ErrorPageButton />

                <ErrorPageNumber>
                    {error}
                </ErrorPageNumber>
            </ErrorPageContainer>
        </Wrapper>
    </SectionContainer>
);

export default ErrorPage;
