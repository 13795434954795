import React from "react";
import { Theme, useTheme } from "@emotion/react";

import SectionContainer from "core/Components/SectionContainer";
import Text from "core/Components/Text";
import { sort } from "core/includes/sort";

import AddressContainer from "elements/Components/ContactUs/components/AddressContainer";
import AddressWrapper from "elements/Components/ContactUs/components/AddressWrapper";
import City from "elements/Components/ContactUs/components/City";
import HubspotForm from "elements/Components/ContactUs/components/HubspotFormContainer";
import ImageContainer from "elements/Components/ContactUs/components/ImageContainer";
import ImageSection from "elements/Components/ContactUs/components/ImageSection";
import Wrapper from "elements/Components/ContactUs/components/Wrapper";

type Props = {
    data: ContactUs
};

const ContactUs: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const addressList = sort(data.addresses);
    const addressCount = !!addressList ? Math.min(2, addressList.length) : 0;
    const addresses = addressList.filter((element: any, index: number) => index < addressCount);

    return (
        <SectionContainer>
            <Wrapper>
                <ImageSection>
                    {data.image?.id && (
                        <ImageContainer image={data.image} useFocusPoint={data.useFocusPoint} />
                    )}

                    {addresses && (
                        <AddressContainer>
                            {addresses.map((item: Address, index: number) => (
                                <AddressWrapper key={`address-${index}`} addressPosition={index % 2 === 0 ? 'left' : 'right'}>
                                    <City>{item.city}</City>
                                    <Text fontColour={theme.colours.doveGray3}>{item.address}</Text>
                                    <Text fontColour={theme.colours.doveGray3}>{item.phoneNumber}</Text>
                                </AddressWrapper>
                            ))}
                        </AddressContainer>
                    )}
                </ImageSection>
                <HubspotForm data={data} />
            </Wrapper>
        </SectionContainer>
    );
};

export default ContactUs;