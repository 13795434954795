import React, { useMemo, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import Button from "core/Components/Buttons/Button";
import RoundButton from "core/Components/Buttons/RoundButton";
import DoubleQuotes from "core/Components/Icons/DoubleQuotes";
import Image from "core/Components/Image";
import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";
import useBreakpoint from "core/hooks/useBreakpoint";

import ContentSection from "elements/Components/DescriptionWithText/components/ContentSection";
import ContentTextWrapper from "elements/Components/DescriptionWithText/components/ContentTextWrapper";
import ImageBoxContainer from "elements/Components/DescriptionWithText/components/ImageBoxContainer";
import TextBoxContainer from "elements/Components/DescriptionWithText/components/TextBoxContainer";
import Writer from "elements/Components/DescriptionWithText/components/Writer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";

type Props = {
    data: DescriptionWithSimpleTextAndImage
};

const DescriptionWithSimpleTextAndImage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const [showVideo, setShowVideo] = useState(false);
    const { minWidth } = useBreakpoint();
    const colourTheme = JSON.parse(data.theme.toLowerCase());
    const { textcolour, writercolour, buttontextcolour, buttoncolour } = colourTheme;
    const linkURL = data.innerURL || data.outerURL;

    const [
        image,
        useFocusPoint,
        useBackgroundImage,
        doubleQuoteMargin,
        writerMargin,
        buttonMargin,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes['5xl']:
                return [(data.landscape || data.portrait), data.useFocusPoint, true, 34, 45, 34];
            case minWidth >= theme.breakpoints.sizes['3xl']:
                return [(data.landscape || data.portrait), data.useFocusPoint, true, 30, 30, 30];
            case minWidth >= theme.breakpoints.sizes['xl']:
                return [(data.landscape || data.portrait), data.useFocusPoint, true, 19, 23, 23];
            case minWidth >= theme.breakpoints.sizes['lg']:
                return [(data.landscape || data.portrait), data.useFocusPoint, true, 23, 28, 28];
            case minWidth >= theme.breakpoints.sizes['md']:
                return [(data.portrait || data.landscape), data.useFocusPointPortrait, false, 16, 25, 16];
            case minWidth >= theme.breakpoints.sizes['sm']:
                return [(data.portrait || data.landscape), data.useFocusPointPortrait, false, 25, 25, 25];
            default:
                return [(data.portrait || data.landscape), data.useFocusPointPortrait, false, 20, 25, 20];
        }
    }, [
        minWidth,
        data.landscape,
        data.portrait,
        data.useFocusPoint,
        data.useFocusPointPortrait,
        theme.breakpoints.sizes
    ]);

    const wrapperStyle = css`
        min-height: ${data.moduleHeight}px;
        color: ${textcolour};

        ${theme.breakpoints.up("5xl")} {
            min-height: 620px;
        }
    `;

    const imageStyle = css`
        ${!useFocusPoint && css`
            height: unset;
            max-width: ${theme.breakpoints.sizes['xxl']}px;
            
            ${theme.breakpoints.up("5xl")} {
                max-width: ${theme.breakpoints.sizes['5xl']}px;
            }
        `}
    `;

    const hasWorkingLink = !!linkURL || !!data.videoPath;
    const altText = data.altText || '';

    return isEmpty(data.text) ? null : (
        <SectionContainer data={data}>
            {showVideo && data.videoPath && (
                <VideoViewContainer clearPlayVideo={() => setShowVideo(false)}>
                    <VideoViewLayer>
                        <VideoPlayer videoPath={data.videoPath} />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}
            <Wrapper css={wrapperStyle} useMaxWidth={false} useMinHeight={false}>
                {useBackgroundImage && !isEmpty(image?.id) && (
                    <Image
                        image={image}
                        css={imageStyle}
                        useFocusPoint={useFocusPoint}
                        divAlignItemForFocusPoint={"center"}
                        useDivForFocusPoint
                    />
                )}

                <ContentSection textBoxPosition={data.textBoxPosition}>
                    <TextBoxContainer textBoxWidth={data.textBoxWidth} flexDirection={"column"}>
                        {data.showDoubleQuotes && (
                            <DoubleQuotes colour={textcolour} marginBetweenLines={doubleQuoteMargin} />
                        )}

                        <ContentTextWrapper colour={textcolour}>{data.text}</ContentTextWrapper>

                        {data.writer && (
                            <Writer colour={writercolour} marginBetweenLines={writerMargin}>{data.writer}</Writer>
                        )}

                        {data.buttonText && hasWorkingLink && (
                            <Button margin={`${buttonMargin}px 0 0 0`}>
                                <RoundButton
                                    textColour={buttontextcolour}
                                    backgroundColour={buttoncolour}
                                    {...linkURL ?
                                        { linkURL } :
                                        { onButtonClick: () => setShowVideo(!!data.videoPath) }
                                    }
                                >
                                    {data.buttonText}
                                </RoundButton>
                            </Button>
                        )}
                    </TextBoxContainer>

                    <ImageBoxContainer
                        textBoxWidth={data.textBoxWidth}
                        moduleHeight={data.moduleHeight}
                        useFocusPoint={useFocusPoint}
                    >
                        {!useBackgroundImage && !isEmpty(image?.id) && (
                            <Image image={image} css={imageStyle} useFocusPoint={useFocusPoint} customAltText={altText} />
                        )}
                    </ImageBoxContainer>
                </ContentSection>
            </Wrapper>
        </SectionContainer>
    );
};

export default DescriptionWithSimpleTextAndImage;
