import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    imageHorizontalCard: ImageHorizontalCard,
};

const Header: React.FC<Props> = ({ imageHorizontalCard, }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(imageHorizontalCard.theme);
    const { headerColour } = colourTheme;

    const headerStyle = css`
        white-space: break-spaces;
        display: -webkit-box;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};
        flex-grow: 1;
        padding-bottom: 32px;

        ${theme.breakpoints.up("5xl")} {
            padding-bottom: 34px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding-bottom: 35px;
        }

        ${theme.breakpoints.only("xl")} {
            padding-bottom: 19px;
        }

        ${theme.breakpoints.only("lg")} {
            padding-bottom: 27px;
        }

        ${theme.breakpoints.only("md")} {
            padding-bottom: 21px;
        }

        ${theme.breakpoints.only("sm")} {
            padding-bottom: 13px;
            -webkit-line-clamp: 1;
            min-height: 25px;
        }

        ${theme.breakpoints.down("xs")} {
            padding-bottom: 10px;
            -webkit-line-clamp: 1;
            min-height: 25px;
        }
    `;

    return (
        <Title Tag={"h2"} fontSizeType={"5"} css={headerStyle}>
            {imageHorizontalCard.header}
        </Title>
    );
}

export default Header;
