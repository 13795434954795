import React from "react";
import { css, Theme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    itemBoxWidth: number,
};

const ItemContainer: React.FC<Props> = ({ children, itemBoxWidth }) => {
    const style = (theme: Theme) => css`
        position: relative;
        width: ${itemBoxWidth}px;
        
        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemContainer;