import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { HUBSPOT_PORTAL_ID } from "core/config/config";
import document from 'core/includes/document';

type Props = {
    hubspotFormId: string,
    shouldLoadScript?: boolean,
};

const HubspotForm: React.FC<Props> = ({ hubspotFormId, shouldLoadScript }) => {
    const [isFormSet, setIsFormSet] = useState(false);

    useEffect(
        () => {
            if (shouldLoadScript && !isFormSet && hubspotFormId) {
                const script = document.createElement("script");
                script.src = "https://js.hsforms.net/forms/embed/v2.js";
                document.body.appendChild(script);

                script.addEventListener('load', () => {
                    if ((window as any).hbspt) {
                        (window as any).hbspt.forms.create({
                            region: "na1",
                            portalId: HUBSPOT_PORTAL_ID,
                            formId: hubspotFormId,
                            target: "#hubspotForm",
                        });
                    }
                });

                setIsFormSet(true);
            }
        },
        [hubspotFormId, isFormSet, shouldLoadScript, setIsFormSet]
    );

    if (!hubspotFormId) {
        return null;
    }

    const style = css`
        width: 100%;
    `;

    return (
        <div id="hubspotForm" css={style}/>
    );
};

export default HubspotForm;