import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const RightContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        width: 535px;
        margin: 73px auto 215px 93px;
        text-align: center;
        
        ${theme.breakpoints.up('5xl')} {
            width: 687px;
            margin: 75.5px auto 140.2px 231px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            width: 536px;
            margin: 63.5px auto 112px 136px;
        }
        
        ${theme.breakpoints.only('xxl')} {
            width: 535px;
            margin: 73px auto 115px 92.5px;
        }

        ${theme.breakpoints.only('xl')} {
            width: 479px;
            margin: 128.86px auto 115px 81px;
        }

        ${theme.breakpoints.only('lg')} {
            width: 406px;
            margin: 61px auto 104px 54px;
        }

        ${theme.breakpoints.only('md')} {
            width: 557px;
            margin: 35px auto 84px;
        }

        ${theme.breakpoints.only('sm')} {
            width: 467px;
            margin: 40px auto 59px;
        }

        ${theme.breakpoints.down('xs')} {
            width: 323px;
            margin: 39px auto 65px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default RightContainer;