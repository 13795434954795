import React, { useCallback, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import Button from "core/Components/Buttons/Button";
import RoundButton from "core/Components/Buttons/RoundButton";
import DoubleQuotes from "core/Components/Icons/DoubleQuotes";
import SectionContainer from "core/Components/SectionContainer";
import Wrapper from "core/Components/Wrapper";

import ContentTextWrapper from "elements/Components/DescriptionWithText/components/ContentTextWrapper";
import Header from "elements/Components/DescriptionWithText/components/Header";
import TextBoxContainer from "elements/Components/DescriptionWithText/components/TextBoxContainer";
import Writer from "elements/Components/DescriptionWithText/components/Writer";
import useBreakpoint from "core/hooks/useBreakpoint";
import Image from "core/Components/Image";

type Props = {
    data: DescriptionWithSimpleText | DescriptionWithSimpleTextType,
    doubleQuotesColour?: string,
};

const DescriptionWithSimpleText: React.FC<Props> = ({
     data,
     doubleQuotesColour = undefined,
}) => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();
    const colourTheme = JSON.parse(data.theme.toLowerCase());
    const { headercolour, textcolour, writercolour, buttontextcolour, buttoncolour } = colourTheme;
    const linkURL = data.innerURL || data.outerURL;
    const isShortModuleHeight = data.moduleHeightType === 'Short';
    const isHTMLHeader = data.header ? /<\/?[a-z][\s\S]*>/i.test(data.header.toString()) : false;

    const getModuleHeight = useCallback((defaultMinHeight: number) => {
        return (
            data.moduleHeightType === 'Short'
                ? defaultMinHeight
                : (data.moduleHeightType === 'Tall' ? 450 : 300)
        );
    }, [data.moduleHeightType]);

    const [
        moduleMinHeight,
        doubleQuoteMargin,
        headerMargin,
        writerMargin,
        buttonMargin,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes['3xl']:
                return [getModuleHeight(281), 24, isShortModuleHeight ? 13 : 24, 25, 47];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [getModuleHeight(240), 23, isShortModuleHeight ? 13 : 26, 25, 47];
            case minWidth >= theme.breakpoints.sizes['xl']:
                return [getModuleHeight(240), 24, isShortModuleHeight ? 13 : 26, 25, 47];
            case minWidth >= theme.breakpoints.sizes['lg']:
                return [getModuleHeight(190), 17, isShortModuleHeight ? 14 : 17, 18, 20];
            case minWidth >= theme.breakpoints.sizes['md']:
                return [getModuleHeight(191), 23, isShortModuleHeight ? 5 : 26, 26, 55];
            case minWidth >= theme.breakpoints.sizes['sm']:
                return [getModuleHeight(257), 22, isShortModuleHeight ? 16 : 22, 22, 31];
            default:
                return [getModuleHeight(227), 10, isShortModuleHeight ? 16 : 16, 16, 20];
        }
    }, [getModuleHeight, isShortModuleHeight, minWidth, theme.breakpoints.sizes]);

    const image = useMemo(() => {
        return minWidth >= theme.breakpoints.sizes['md'] ? (data.landscape || data.portrait) : (data.portrait || data.landscape);
    }, [minWidth, data, theme.breakpoints.sizes]);

    const altText = data.altText || '';

    const wrapperStyle = css`
        color: ${textcolour};
      
        ${!isShortModuleHeight && css` min-height: ${moduleMinHeight}px; `};
        
        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
        }
    `;

    return isEmpty(data.text || data.header) ? null : (
        <SectionContainer data={data}>
            {image && !isEmpty(image?.id) && (
                <Image image={image} useFocusPoint={data.useFocusPoint} customAltText={altText} />
            )}

            <Wrapper css={wrapperStyle} useMinHeight={false}>
                <TextBoxContainer isShortModuleHeight={isShortModuleHeight}>
                    {data.showDoubleQuotes && (
                        <DoubleQuotes colour={doubleQuotesColour ?? textcolour} marginBetweenLines={doubleQuoteMargin} />
                    )}

                    {data.header && (
                        <Header
                            colour={headercolour}
                            marginBetweenLines={headerMargin}
                            Tag={data.headerTag}
                            isHTML={isHTMLHeader}
                        >
                            {data.header}
                        </Header>
                    )}
                    {data.text && (
                        <ContentTextWrapper
                            fontSizeType={'13'}
                            lineHeightType={'17'}
                            colour={textcolour}
                            isJustifyContentCenter
                        >
                            {data.text}
                        </ContentTextWrapper>
                    )}

                    {data.writer && (
                        <Writer colour={writercolour} marginBetweenLines={writerMargin}>{data.writer}</Writer>
                    )}

                    {data.buttonText && (
                        <Button margin={`${buttonMargin}px 0 0 0`}>
                            <RoundButton
                                textColour={buttontextcolour}
                                backgroundColour={buttoncolour}
                                linkURL={linkURL}
                            >
                                {data.buttonText}
                            </RoundButton>
                        </Button>
                    )}
                </TextBoxContainer>
            </Wrapper>
        </SectionContainer>
    );
};

export default DescriptionWithSimpleText;
