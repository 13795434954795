import React from "react";
import { useTheme } from "@emotion/react";
import {
    HiPlus as PlusIcon,
    HiMinus as MinusIcon,
} from "react-icons/hi";
import Input from "core/Components/Buttons/QuantityButton/components/Input";
import CircleButton from "core/Components/Buttons/CircleButton";
import QuantityContainer from "core/Components/Buttons/QuantityButton/components/QuantityContainer";

type Props = {
    quantity: string | number,
    plusClickHandler: (e: React.MouseEvent) => void,
    minusClickHandler: (e: React.MouseEvent) => void,
    onChange: (quantity: React.ChangeEvent<HTMLInputElement>) => void,
};

const BUTTON_SIZE = 32;
const BUTTON_ICON_SIZE = 20;
const MIN_QUANTITY = 0;
const MAX_QUANTITY = 9999;

const RoundQuantityButton: React.FC<Props> = ({
    quantity,
    plusClickHandler,
    minusClickHandler,
    onChange,
}) => {
    const theme = useTheme();

    const minLimit = quantity > MIN_QUANTITY;
    const maxLimit = quantity < MAX_QUANTITY;

    return (
        <QuantityContainer>
            <CircleButton
                size={BUTTON_SIZE}
                svgSize={BUTTON_ICON_SIZE}
                onClick={minusClickHandler}
                roundColour={theme.colours.grey[minLimit ? 200 : 90]}
                svgColour={minLimit ? theme.colours.doveGray : theme.colours.alto2}
                noHoverEffect={!minLimit}
                disabled={!minLimit}
                {...minLimit && {
                    roundHoverColour: theme.colours.malibu,
                    svgHoverColour: theme.colours.white,
                }}
            >
                <MinusIcon />
            </CircleButton>
            <Input
                onChange={onChange}
                quantity={quantity}
                textColour={theme.colours.doveGray}
            />
            <CircleButton
                size={BUTTON_SIZE}
                svgSize={BUTTON_ICON_SIZE}
                onClick={plusClickHandler}
                roundColour={theme.colours.grey[maxLimit ? 200 : 90]}
                svgColour={maxLimit ? theme.colours.doveGray : theme.colours.alto2}
                noHoverEffect={!maxLimit}
                disabled={!maxLimit}
                {...maxLimit && {
                    roundHoverColour: theme.colours.malibu,
                    svgHoverColour: theme.colours.white,
                }}
            >
                <PlusIcon />
            </CircleButton>
        </QuantityContainer>
    );
}

export default RoundQuantityButton;