import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    data: ContentWithImage,
    children: React.ReactNode,
};

const TextContentContainer: React.FC<Props> = ({ data, children }) => {
    const theme: Theme = useTheme();
    const layout = JSON.parse(data.layout);
    const { isImageFirst, useShadow, isBiggerImage, isHalfFullImage } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';

    const textContentSectionStyle = css`
        flex-direction: column;

        ${theme.breakpoints.up("3xl")} {
            ${isImageFirst 
                    ? css`padding-left: 54px;` 
                    : css`padding-right: 52px;`
            }
        }

        ${theme.breakpoints.only("xxl")} {
            ${isImageFirst
                    ? css`padding-left: 70px;`
                    : css`padding-right: 68px;`
            }
        }

        ${theme.breakpoints.only("xl")} {
            ${isImageFirst
                  ? css`padding-left: 48px;`
                  : css`padding-right: 49px;`
            }
        }
        ${theme.breakpoints.only("lg")} {
            ${isImageFirst
                    ? css`padding-left: 35px;`
                    : css`padding-right: 35px;`
            }
        }

        ${theme.breakpoints.only("md")} {
            padding: 32px 0 0;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 28px 0 0;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 22px 0 0;
        }

        ${theme.breakpoints.down("md")} {
            height: unset;
        }

        ${theme.breakpoints.down('xs')} {
            flex-shrink: 0;
        }

        ${isImageFirst && css`
            ${theme.breakpoints.down("md")} {
                justify-content: start;
            }
        `};
    `;

    const textContentSectionForSmallImageStyle = css`
        flex-direction: column;

        ${theme.breakpoints.up("5xl")} {
            padding: 60px 0;

            ${isImageFirst
                ? css`padding-left: 99px;`
                : css`padding-right: 99px;`
            }

            ${useShadow && css`
                padding-left: 99px;
                padding-right: 99px;
            `};
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            padding: 40px 0;

            ${isImageFirst
                ? css`padding-left: 118px;`
                : css`padding-right: 118px;`
            }

            ${useShadow && css`
                padding-left: 62px;
                padding-right: 62px;
            `};
        }

        ${theme.breakpoints.between("lg", "xl")} {
            padding: 30px 0;

            ${isImageFirst
                ? css`padding-left: 54px;`
                : css`padding-right: 54px;`
            }

            ${useShadow && css`
                padding-left: 26px;
                padding-right: 26px;
            `};
        }

        ${theme.breakpoints.only("md")} {
            padding-bottom: 37px;

            ${useShadow && css`
                padding: 25px 42px 34px;
            `};
        }

        ${theme.breakpoints.only("sm")} {
            padding-bottom: 47px;

            ${useShadow && css`
                padding: 42px 35px 70px;
            `};
        }

        ${theme.breakpoints.down("xs")} {
            padding-bottom: 31px;
            flex: 1;

            ${useShadow && css`
                padding: 24px 15px 26px;
            `};
        }

        ${theme.breakpoints.down("md")} {
            ${isImageFirst && css`
                justify-content: start;
            `}
        }
    `;

    return (
        <ContentSection
            css={imageSize === 'small' ? textContentSectionForSmallImageStyle : textContentSectionStyle}
        >
            {children}
        </ContentSection>

    );
}

export default TextContentContainer;
