import React, { useState } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import Input from "core/Components/Form/Input";
import { ReactComponent as SearchIcon } from "images/icons/search-icon.svg";

type Props = {
    onChange: (keyword: string) => void,
    className?: string,
}

const SearchField: React.FC<Props> = ({ onChange, className = "" }) => {
    const [value, setValue] = useState('');

    const theme: Theme = useTheme();

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    const searchFieldContainerStyle = css`
        position: relative;
        width: 95%;
        
        ${theme.breakpoints.down('xxl')} {
            width: 100%;
        }
        
        ${theme.breakpoints.only('sm')} {
            display: flex;
            justify-content: center;
        }
    `;

    const inputStyle = css`
        input {
            border: 3px solid ${theme.colours.onahau};
            border-radius: 58px;
            height: 44px;
            width: 397px;
            padding-left: 45px;
            color: ${theme.colours.cornflower2};
            
            ::placeholder {
                color: ${theme.colours.cornflower2};
            }
            
            ${theme.breakpoints.down('4xl')} {
                width: 264px;
            }
            
            ${theme.breakpoints.down('sm')} {
                width: 100%;
                height: 36px;
            }
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;
    
    const searchIconStyle = css`
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        fill: ${theme.colours.regentStBlue};
        width: 25px;
        height: 25px;
    `;

    return (
        <div css={searchFieldContainerStyle} className={className}>
            <Input name={'search'} value={value} onEventChange={_onChange} css={inputStyle} label={'Search'} />
            <SearchIcon css={searchIconStyle} />
        </div>
    );
}

export default SearchField;