import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    src: string,
    index: number,
    roundImageSize: number,
    squareImageWidth: number,
    squareImageHeight: number,
    sizeGap: number,
};

const MainImage: React.FC<Props> = ({
    src,
    index,
    roundImageSize,
    squareImageWidth,
    squareImageHeight,
    sizeGap
}) => {
    const theme: Theme = useTheme();
    const isOddOrder = (index + 1) % 2;
    const isRound = index % 10 < 5 ? (index + 1) % 2 : index % 2;

    const imageStyle = css`
        position: absolute;
        width: ${roundImageSize}px;
        height: ${roundImageSize}px;
        right: ${roundImageSize / -2}px;
        border-radius: 22px;
        overflow: hidden;
        background-image: url(${src});
        background-repeat: no-repeat;
        background-size: cover;

        ${isOddOrder ? css`
            bottom: 0;
            bottom: ${sizeGap}px;
        ` : css`
            top: ${sizeGap}px;
        `};

        ${isRound && css`
            border-radius: 50%;
        `};

        ${!isRound && css`
            width: ${squareImageWidth}px;
            height: ${squareImageHeight}px;
            right: ${((roundImageSize / -2) + (roundImageSize - squareImageWidth) / 2)}px;
        `};

        ${theme.breakpoints.only("md")} {
            bottom: unset;
            top: 0;
            
            ${isOddOrder ? css`
                left: ${isRound ? '33px' : '48px'};
            ` : css`
                right: ${isRound ? '40px' : '45px'};
            `};
        }

        ${theme.breakpoints.down("sm")} {
            bottom: unset;
            top: 0;
            
            ${isOddOrder ? css`
                left: ${isRound ? '23px' : '34px'};
            ` : css`
                right: ${isRound ? '28px' : '33px'};
            `};
        }
    `;

    return (
        <div css={imageStyle} className={'timeline-main-image-state-hover'} />
    );
}

export default MainImage;
