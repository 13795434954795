import React from "react";
import { css, useTheme } from "@emotion/react";
import Title from "core/Components/Title";

type Props = {
    children: React.ReactNode,
    type?: string,
};

const HeaderTitle:React.FC<Props> = ({ children, type }) => {
    const theme = useTheme();

    const style = css`
        width: 40%;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up("5xl")} {
            font-size: 40px;
            line-height: 50px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 35px;
            line-height: 30px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 30px;
            line-height: 26px;
        }

        ${theme.breakpoints.down("lg")} {
            font-size: 25px;
            line-height: 21px;
        }

        ${theme.breakpoints.only("md")} {
            ${type === "event" && css`
                align-self: start;
            `}
        }

        ${theme.breakpoints.down("md")} {
            width: unset;
        }

        ${type === "success-story" && css`
            ${theme.breakpoints.down("sm")} {
                align-self: center;
            }
        `}
    `;

    return (
        <div css={style}>
            <Title>{children}</Title>
        </div>
    );
};

export default HeaderTitle;