import React from "react";
import { css, useTheme } from "@emotion/react";

import Collapse from "core/Components/Collapse";

import FooterVerticalSubMenu from "core/Components/Footer/vertical/FooterVerticalSubMenu";

type Props = {
    headerMenu: StandardPage | null,
    selectedMenu: StandardPage | null,
    subMenu: [StandardPage]
};

const FooterVerticalSubMenuBox: React.FC<Props> = ({ headerMenu, selectedMenu, subMenu }) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        border-bottom: 1px solid ${theme.colours.alto3};

        ${theme.breakpoints.only("md")} {
            padding-left: 45px;
            padding-right: 45px;
        };

        ${theme.breakpoints.only("sm")} {
            padding-left: 80px;
            padding-right: 80px;
        };

        ${theme.breakpoints.down("xs")} {
            padding-left: 20px;
            padding-right: 20px;
        };
    `;

    return (
        <Collapse isOpen={subMenu && headerMenu?.uRLSegment === selectedMenu?.uRLSegment}>
            <div css={style}>
                {subMenu.map((menu: StandardPage) => (
                    <FooterVerticalSubMenu key={"footerSubMenu" + menu.id} subMenu={menu} />
                ))}
            </div>
        </Collapse>
    );
};

export default FooterVerticalSubMenuBox;
