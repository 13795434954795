import React from "react";
import { css } from "@emotion/react";
import { HiChevronDown } from "react-icons/hi";

type Props = {
    headerMenu: StandardPage,
    selectedMenu: StandardPage | null,
    setSelectedMenuHandler: Function,
};

const FooterVerticalHeaderMenuChevron: React.FC<Props> = ({
    headerMenu,
    selectedMenu,
    setSelectedMenuHandler,
}) => {
    const onToggleHandler = () => {
        setSelectedMenuHandler({ headerMenu, selectedMenu });
    };

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        
        ${(selectedMenu && selectedMenu.uRLSegment === headerMenu.uRLSegment) && css`
            transform: rotate(180deg);
        `}

        &:hover {
            opacity: 0.7;
        }
    `;

    return (
        <div css={style}>
            <HiChevronDown fontSize={"30px"} cursor={"pointer"} onClick={onToggleHandler} />
        </div>
    );
};

export default FooterVerticalHeaderMenuChevron;