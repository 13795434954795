export default ((): any => {
    if (typeof window !== "undefined") {
        return window;
    } else if (typeof global !== "undefined") {
        return global;
        // eslint-disable-next-line no-restricted-globals
    } else if (typeof self !== "undefined"){
        // eslint-disable-next-line no-restricted-globals
        return self;
    } else {
        return {};
    }
})();