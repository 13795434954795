import React, { useMemo } from "react";
import { Theme, useTheme } from "@emotion/react";
import { isEmpty, isEqual } from "lodash";

import SectionContainer from "core/Components/SectionContainer";
import VideoButton from "core/Components/Buttons/VideoButton";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import useSetState from "core/hooks/useSetState";

import Title from "elements/Components/BannerWithImage/components/Title";
import Wrapper from "elements/Components/BannerWithImage/components/Wrapper";
import ContentContainer from "elements/Components/BannerWithImage/components/ContentContainer";
import ImageContainer from "elements/Components/BannerWithImage/components/ImageContainer";
import ButtonContainer from "elements/Components/BannerWithImage/components/ButtonContainer";
import SubHeader from "elements/Components/BannerWithImage/components/SubHeader";
import Text from "elements/Components/BannerWithImage/components/Text";
import ButtonWrapper from "elements/Components/BannerWithImage/components/ButtonWrapper";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    data: BannerWithImage
};

type DefaultState = {
    videoPath: string,
}

const INITIAL_STATE = {
    videoPath: "",
}

const BannerWithImage: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const { minWidth } = useBreakpoint();
    const colourTheme = JSON.parse(data.theme);
    const { fromColour, toColour, headerColour, subheaderColour, textColour, buttonTextColour = "white", buttonColour } = colourTheme;
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const linkURL = data.innerURL || data.outerURL;
    const secondLinkURL = data.secondInnerURL || data.secondOuterURL;
    const justifyContent = data?.textAlignType === "centre" ? "center" : "flex-start";
    const isBoldSubheader = subheaderColour && subheaderColour.toUpperCase() === theme.colours.gold;
    const hasText = !!data.header || !!data.subheader || !!data.text;

    const onClickVideoHandler = () => {
        setState({ videoPath: data.videoPath });
    };

    const onClearVideoHandler = () => {
        setState({ videoPath: "" });
    };

    const image = useMemo(() => {
        if (minWidth >= theme.breakpoints.sizes["lg"]) {
            return data.landscape || data.portrait;
        }

        return data.portrait || data.landscape;
    }, [minWidth, data, theme.breakpoints.sizes]);

    const useAFocusPoint = (isEqual(image, data.landscape) && data.useFocusPoint) ||
        (isEqual(image, data.portrait) && data.useFocusPointPortrait);

    return isEmpty(image?.id) ? null : (
        <SectionContainer
            {...fromColour && toColour && { gradientColours: { fromColour, toColour } }}>
            <Wrapper isImageFirst={data.isImageFirst}>
                {hasText && (
                    <ContentContainer justifyContent={justifyContent}>
                        {data.header && (
                            <Title headerColour={headerColour}>
                                {data.header}
                            </Title>
                        )}
                        {data.subheader && (
                            <SubHeader isBoldSubheader={isBoldSubheader} subheaderColour={subheaderColour}>
                                {data.subheader}
                            </SubHeader>
                        )}

                        {data.text && (
                            <Text textColour={textColour}>{data.text}</Text>
                        )}

                        {!(isEmpty(data.buttonText) && isEmpty(data.videoPath)) && (
                            <ButtonContainer>
                                {!isEmpty(data.buttonText) && (
                                    <ButtonWrapper
                                        linkURL={linkURL}
                                        buttonType={"text"}
                                        buttonTextColour={buttonTextColour}
                                        buttonColour={buttonColour}
                                        {...buttonColour === theme.colours.gold && {
                                            buttonHoverColour: theme.colours.mySin
                                        }}
                                        {...data.useButtonLine && {
                                            buttonLineColour: data.buttonLineColour
                                        }}
                                        {...data.trackingButtonName && {
                                            className: data.trackingButtonName
                                        }}
                                    >
                                        {data.buttonText}
                                    </ButtonWrapper>
                                )}

                                {!isEmpty(data.secondButtonText) && (
                                    <ButtonWrapper
                                        linkURL={secondLinkURL}
                                        buttonType={"text"}
                                        buttonTextColour={"white"}
                                        buttonColour={theme.colours.curiousBlue}
                                        buttonHoverColour={theme.colours.malibu3}
                                        {...data.useSecondButtonLine && {
                                            buttonLineColour: data.secondButtonLineColour
                                        }}
                                        {...data.trackingSecondButtonName && {
                                            className: data.trackingSecondButtonName
                                        }}
                                    >
                                        {data.secondButtonText}
                                    </ButtonWrapper>
                                )}

                                <VideoButton
                                    data={data}
                                    onClickVideoHandler={onClickVideoHandler}
                                />
                            </ButtonContainer>
                        )}
                    </ContentContainer>
                )}
                {image && (
                    <ImageContainer
                        image={image}
                        useFocusPoint={useAFocusPoint}
                        opacity={data.opacity}
                        gradientColor={data.gradientColor}
                    />
                )}

                {state.videoPath && (
                    <VideoViewContainer clearPlayVideo={onClearVideoHandler}>
                        <VideoViewLayer>
                            <VideoPlayer videoPath={state.videoPath} autoPlay />
                        </VideoViewLayer>
                    </VideoViewContainer>
                )}
            </Wrapper>
        </SectionContainer>
    );
}

export default BannerWithImage;
