import { css } from "@emotion/react";
import colours from "./colours";
import fonts from "./fonts";

const PADDING_HORIZONTAL = "0";
const PADDING_VERTICAL = "5px";

const forms = {
    defaults: {
        borderColor: colours.curiousBlue,
        borderStyle: "solid",
        display: "block",
        padding: `${PADDING_VERTICAL} ${PADDING_HORIZONTAL}`,
        transition: "border-color 0.5s linear",
        outline: "none",
        "&:focus": {
            borderColor: colours.secondary,
        }
    },
    error: css`
        border-bottom: 3px solid ${colours.status.error};
    `,
    label: {
        fontSize: "16px",
        color: colours.grey[920],
        display: "block",
        padding: `0 ${PADDING_HORIZONTAL} 0 0`,
        fontWeight: fonts.weights.bold,
    },
    paddingHorizontal: PADDING_HORIZONTAL,
    paddingVertical: PADDING_VERTICAL,
    placeholder: colours.grey[300],
    spacing: {
        margin: "10px",
        marginBottom: "19px",
    }
};

export default forms;
