import React from "react";
import { css } from "@emotion/react";

import FooterLink from "core/Components/Footer/FooterLink";
import FooterMenu from "core/Components/Footer/FooterMenu";
import NavLinkTag from "core/Components/Navigations/NavLinkTag";

type Props = {
    footerMenu: [any] | undefined,
};

const FooterMenuBox: React.FC<Props> = ({ footerMenu }) => {
    const style = css`
        display: flex;
        justify-content: space-between;
        width: 100%;
    `;

    return (
        <div css={style}>
            {footerMenu && (
                Object.entries(footerMenu).map(([key, value]) => (
                    <FooterMenu key={key}>
                        {Object.entries(value).map(([, value]: any) => (
                            value.map((menu: StandardPage) => (
                                <FooterLink key={'footer' + menu.id} isMainMenu parentID={menu.parentID}>
                                    <NavLinkTag data={menu}>
                                        {menu.titleForFooter ?? menu.title}
                                    </NavLinkTag>
                                </FooterLink>
                            ))
                        ))}
                    </FooterMenu>
                ))
            )}
        </div>
    );
};

export default FooterMenuBox;