import React from "react";
import { css, useTheme } from "@emotion/react";
import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    marginBetweenLines?: number,
    colour: string,
};

const Writer: React.FC<Props> = ({
    children,
    marginBetweenLines,
    colour,
}) => {
    const theme = useTheme();
    const Tag = "p";
    const [fontSize, lineHeight] = useFontSize(Tag, '9');

    const style = css`
        text-align: center;
        margin-bottom: 52px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        
        ${colour && css`
            color: ${colour};
        `}

        ${marginBetweenLines && css`
            margin-top: ${marginBetweenLines}px;
        `};

        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 55px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 31px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 42px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 32px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 29px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 17px;
        }
    `;

    return (
        <Tag css={style}>{children}</Tag>
    );
};

export default Writer;
