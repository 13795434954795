import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const DescriptionAndButtonContainer: React.FC<Props> = ({
    children,
}) => {
    const theme: Theme = useTheme();

    const contentStyle = css`
        position: relative;
        width: 100%;
        height: 323px;
        display: flex;
        flex-direction: column;
        align-items: center;

        ${theme.breakpoints.up("5xl")} {
            height: 498px;
        }

        ${theme.breakpoints.only("md")} {
            height: 279px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 285px;
        }
    `;

    return (
        <ContentSection css={contentStyle}>
            {children}
        </ContentSection>
    );
}

export default DescriptionAndButtonContainer;
