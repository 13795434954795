import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, SerializedStyles, useTheme } from "@emotion/react";
import document from 'core/includes/document';

import DropDownList from "core/Components/Form/DropDownList";
import DropdownChevron from "core/Components/Form/DropdownChevron";

export type FormDropdownOption = {
    id: string,
    value: string,
}

type Props = {
    className?: string,
    extraChevronStyles?: SerializedStyles,
    extraOptionContainerStyles?: SerializedStyles,
    extraOptionStyles?: SerializedStyles,
    extraSelectedStyles?: SerializedStyles,
    hasBorder?: boolean,
    hasError?: boolean,
    options?: Array<FormDropdownOption>,
    placeholder?: string,
    extraPlaceholderStyles?: SerializedStyles,
    readOnly?: boolean,
    searchable: boolean,
    selected?: FormDropdownOption,
    setSelected?: Function
};

const DropDown: React.FC<Props> = ({
    hasError = false,
    hasBorder = true,
    readOnly = false,
    options = [],
    setSelected = () => {},
    className,
    extraChevronStyles,
    extraOptionContainerStyles,
    extraOptionStyles,
    extraSelectedStyles,
    placeholder,
    extraPlaceholderStyles,
    searchable,
    selected,
}) => {
    const theme = useTheme();
    const [dropped, setDropped] = useState(false);
    const dropdownRef = useRef<HTMLInputElement>(null);

    const toggle = useCallback(() => {
        if (readOnly) return;
        setDropped(dropped => !dropped);
    }, [setDropped, readOnly]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropped(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const containerStyle = css`
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 100%;
    `;

    const selectedContainerStyle = css`
        flex-grow: 1;
        display: flex;
        position: relative;
        border: 1px solid ${hasBorder ? theme.forms.defaults.borderColor : theme.colours.transparent};
        border-radius: 3px;
        justify-content: space-between;

        ${hasError && theme.forms.error};

        ${!readOnly && css`
            :hover {
                cursor: pointer;
            }
        `};
    `;

    const selectedItemStyle = css`
        margin: auto 0 auto 5px;
        font-size: 100%;

        ${extraSelectedStyles};
    `;

    const placeholderStyle = css`
        margin: auto 0 auto 5px;
        font-size: 100%;

        ${theme.mixins.placeholderAttributes()};

        ${extraPlaceholderStyles};
    `;

    const dropdownChevronStyle = css`
        right: 5px;

        ${extraChevronStyles};
    `;

    return (
        <div css={containerStyle} ref={dropdownRef} className={className}>
            <div css={selectedContainerStyle} onClick={toggle}>
                {selected
                    ? (<div css={selectedItemStyle}>{selected.value}</div>)
                    : (<div css={placeholderStyle}>{placeholder && placeholder}</div>)
                }
                {!readOnly && (
                    <DropdownChevron dropped={dropped} hasError={hasError} css={dropdownChevronStyle} />
                )}
            </div>
            {dropped && (
                <DropDownList
                    extraOptionContainerStyles={extraOptionContainerStyles}
                    extraOptionStyles={extraOptionStyles}
                    selected={selected}
                    setSelected={setSelected}
                    setDropped={setDropped}
                    options={options}
                    searchable={searchable}
                />
            )}
        </div>
    );
};

export default DropDown;
