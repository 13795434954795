import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: DescriptionWithRichTextSingleColumn
};

const Header: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const headerStyle = css`
        color: grey;
        text-align: left;
        font-weight: ${theme.fonts.weights.bold};
        margin-bottom: 47px;

      
        ${theme.breakpoints.only("lg")} {
            margin-bottom: 40px;
        }    

        ${theme.breakpoints.only("md")} {
            margin-bottom: 45px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 35px;
        }
      
        ${theme.breakpoints.down("xs")} {
            margin-bottom: 39px;
        }
    `;

    return (
        <Title Tag={"h2"} css={headerStyle}>
            {data.header}
        </Title>
    );
}

export default Header;
