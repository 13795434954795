import React, { useMemo, useState } from "react";
import { css, useTheme } from "@emotion/react";
import Carousel from "nuka-carousel";
import { isEmpty } from 'lodash';

import useBreakpoint from "core/hooks/useBreakpoint";
import { sort } from "core/includes/sort";
import CarouselContainer from "core/Components/Carousel/CarouselContainer";
import SlideContainer from "core/Components/Carousel/SlideContainer";
import SlideWrapper from "core/Components/Carousel/SlideWrapper";
import SectionContainer from "core/Components/SectionContainer";
import Title from "core/Components/Title";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoImage from "elements/Components/SliderWithVideoPopup/VideoImage";
import ChevronContainer from "core/Components/Carousel/ChevronContainer";
import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: SliderWithVideoPopup
};

const DEFAULT_USE_PAGINATION = true;
const CHEVRON_SIZE = 30;

const SliderWithVideoPopup: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { fromColour, toColour, headerColour, subheaderColour } = colourTheme;
    const { minWidth } = useBreakpoint();
    const [videoPath, setVideoPath] = useState<string>();
    const videoList = sort(data.videoList);

    const [
        carouselPaddingValue,
        carouselContainerWidth,
        videoImageWidth,
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return [420, theme.breakpoints.sizes["5xl"], 1280];
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [337, theme.breakpoints.sizes["3xl"], 882];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [258, theme.breakpoints.sizes["xxl"], 882];
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return [252, theme.breakpoints.sizes["xl"], 734];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [134, theme.breakpoints.sizes["lg"], 720];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [0, theme.breakpoints.sizes["md"], 678];
            case minWidth >= theme.breakpoints.sizes["sm"]:
                return [0, theme.breakpoints.sizes["sm"], 478];
            default:
                return [0, theme.breakpoints.sizes["xs"], 321];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const onClickVideoHandler = (videoPath: string) => {
        setVideoPath(videoPath);
    };

    const onClearVideoHandler = () => {
        setVideoPath('');
    };

    const carouselContainerStyle = css`
        padding: 60px 0 60px;
        width: ${carouselContainerWidth}px;
        
       ${theme.breakpoints.up("5xl")} {
           padding: 86px 0 86px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 43px 0 43px;
        }
        
        ${theme.breakpoints.between("lg", "xl")} {
            padding: 49px 0 52px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 130px 0 56px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 124px 0 50px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 22px 0;
        }

        .paging-dot {
            margin: 0 10px;
            width: 8px;
            height: 8px;
            
            circle {
                cx: 3;
                cy: 3;
                r: 3;
            }
        }
    `;

    const headerStyle = css`
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 35px;
        text-align: center;
        font-weight: ${theme.fonts.weights.bold};
        color: ${headerColour};
        
       ${theme.breakpoints.up("5xl")} {
           font-size: 80px;
           line-height: 90px;
           margin-bottom: 20px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            font-size: 70px;
            line-height: 80px;
            margin-bottom: 30px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 21px;
            font-size: 65px;
            line-height: 75px;
        }

        ${theme.breakpoints.down("lg")} {
            margin-bottom: 6px;
            font-size: 60px;
            line-height: 70px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 21px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 45px;
            line-height: 50px;
            margin-bottom: 17px;
        }
    `;

    const subheaderStyle = css`
        justify-content: center;
        margin-bottom: 38px;
        color: ${subheaderColour || "white"};
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 56px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 35px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 50px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 44px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 43px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 15px;
        }
    `;

    const chevronStyle = (chevronType: string) => css`
        bottom: 0;
        top: unset;
        
        ${chevronType === "left"
            ? css`left: calc(${carouselContainerWidth - videoImageWidth}px / 2);`
            : css`right: calc(${carouselContainerWidth - videoImageWidth}px / 2);`
        }
        
        ${theme.breakpoints.up("lg")} {
            display: none;
        }
    `;

    const chevronButton = (type: "left" | "right", slideEvent: any) => (
        <ChevronContainer chevronType={type} css={chevronStyle(type)}>
            <RoundChevronButton
                type={type}
                onClick={slideEvent}
                roundColour={colourTheme.TextColour}
                chevronColour={colourTheme.BackgroundColour}
                size={CHEVRON_SIZE}
            />
        </ChevronContainer>
    );

    return isEmpty(videoList) ? null : (
        <SectionContainer {...fromColour && toColour && { gradientColours: { fromColour, toColour } }}>
            <CarouselContainer
                isBottomPagination={DEFAULT_USE_PAGINATION}
                css={carouselContainerStyle}
            >
                {data.header && (
                    <Title Tag={"h1"} css={headerStyle}>
                        {data.header}
                    </Title>
                )}

                {data.subheader && (
                    <ContentCombine Tag={"h4"} css={subheaderStyle}>{data.subheader}</ContentCombine>
                )}

                <Carousel
                    wrapAround={true}
                    slidesToShow={1}
                    dragging={false}
                    autoplay
                    autoplayInterval={4500}
                    speed={4000}
                    style={{paddingLeft: carouselPaddingValue, paddingRight: carouselPaddingValue}}
                    renderCenterLeftControls={({ previousSlide }) => (
                        chevronButton("left", previousSlide)
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        chevronButton("right", nextSlide)
                    )}
                >
                    {videoList.map((video: SlideWithVideoPopup, index: number) => (
                        <SlideContainer
                            key={index}
                            isBottomPagination={DEFAULT_USE_PAGINATION}
                        >
                            <SlideWrapper>
                                <VideoImage
                                    imageURL={video.image?.URL}
                                    videoImageWidth={videoImageWidth}
                                    videoPath={video.videoPath}
                                    onPlayVideo={onClickVideoHandler}
                                    backgroundColour={video.backgroundColour}
                                />
                            </SlideWrapper>
                        </SlideContainer>
                    ))}
                </Carousel>
            </CarouselContainer>

            {videoPath && (
                <VideoViewContainer clearPlayVideo={onClearVideoHandler}>
                    <VideoViewLayer>
                        <VideoPlayer videoPath={videoPath} autoPlay />
                    </VideoViewLayer>
                </VideoViewContainer>
            )}
        </SectionContainer>
    );
};

export default SliderWithVideoPopup;
