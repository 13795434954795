import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { ReactComponent as PlayIcon } from "images/icons/play-triangle-with-circle.svg";
import ContentSection from "core/Components/ContentSection";
import ImageFromCore from "core/Components/Image";
import VideoPlayer from "core/Components/Video/VideoPlayer";
import { NOOP } from "core/constants";
import { isDown } from "theme/breakpoints";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    data: ContentWithImage
    autoplay?: boolean,
    showVideo?: boolean
    onVideoClick?: () => void,
};

const Image: React.FC<Props> = ({
    data,
    autoplay = true,
    showVideo = false,
    onVideoClick = NOOP,
}) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpoint();

    const layout = JSON.parse(data.layout);
    const { useShadow, isBiggerImage, isHalfFullImage,  } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';
    const showPlayIcon = !autoplay && !showVideo;

    const mainContainerStyle = css`
        min-width: 100%;
        min-height: 100%;
        position: relative;
        ${showPlayIcon && css`cursor: pointer;`};

        > * {
            transition: ${theme.transitions.transition};
            transition-property: filter, transform;
        }
    
        &:hover > svg {
            transform: translate(-50%, -50%) scale(1.1);
        }

        ${useShadow && css`
            border-radius: 0;
        `};
    `;

    const forLargeImageStyle = css`
        ${mainContainerStyle}
        
        ${!data.useFocusPoint && css`
            ${theme.breakpoints.up("xxl")} {
                width: 615px;
                height: 600px;
            }
    
            ${theme.breakpoints.only("xl")} {
                width: 547px;
                height: 547px;
            }
        
            ${theme.breakpoints.only("lg")} {
                width: 448px;
                height: 550px;
            }
        
            ${theme.breakpoints.only("md")} {
                width: 680px;
                height: 420px;
            }
        
            ${theme.breakpoints.only("sm")} {
                width: 500px;
                height: 340px;
            }
        
            ${theme.breakpoints.down("xs")} {
                width: 360px;
                height: 340px;
            }
        `}
    `;

    const imageStyle = css`
        ${mainContainerStyle}
        
        ${data.useFocusPoint && css`
            ${theme.breakpoints.up("5xl")} {
                width: 898px;
                height: 898px;
            }

            ${theme.breakpoints.between("xxl", "4xl")} {
                width: 614px;
                height: 614px;
            }

            ${theme.breakpoints.between("lg", "xl")} {
                width: 439px;
                height: 439px;
            }

            ${theme.breakpoints.only("md")} {
                width: 643px;
                height: 471px;
            }

            ${theme.breakpoints.only("sm")} {
                width: 479px;
                height: 401px;
            }

            ${theme.breakpoints.down("xs")} {
                width: 320px;
                height: 320px;
            }
        `}
    `;

    const videoSectionStyle = css`
        position: absolute;
        z-index: ${theme.zIndex.zIndexOne};
    `;

    const playIconStyle = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20%;
        z-index: ${theme.zIndex.zIndexOne};
        fill: ${theme.colours.raspberry};
        border-radius: 50%;
        will-change: transform;
    `;

    const selectedImageStyle = imageSize === "large" ? forLargeImageStyle : imageStyle;
    const style = css`
        height: 100%;
        width: 100%;
        > video {
            object-fit: cover;
        }
        z-index: ${theme.zIndex.zIndexHighest};
    `;

    return (
        <div css={selectedImageStyle} onClick={onVideoClick} className={data.trackingButtonName}>
            {showPlayIcon && (
                <PlayIcon css={playIconStyle} />
            )}
            {showVideo && (
                <ContentSection css={videoSectionStyle}>
                    {data.video ? (
                        <div css={style} className='player-wrapper'>
                            <video
                                src={data.video.AbsoluteURL}
                                width={"100%"}
                                height={"100%"}
                                loop={autoplay}
                                controls={!autoplay || isDown(breakpoint, 'md')}
                                muted={autoplay}
                                autoPlay
                            />
                        </div>
                    ) : (
                        <VideoPlayer
                            videoPath={data.videoPath}
                            autoPlay
                            mute={autoplay}
                        />
                    )}
                </ContentSection>
            )}
            <ImageFromCore image={data.image} useFocusPoint={data.useFocusPoint} />
        </div>
    );
}

export default Image;
