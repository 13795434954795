import React, {useCallback, useEffect} from "react";
import { useLocation, Routes, Route } from "react-router";

import AuthPage from "pages/Components/auth/AuthPage";
import Footer from "core/Components/Footer";
import Header from "core/Components/Header";
import { NOOP } from "core/constants";
import { useAppState } from "core/contexts/AppContext";
import { GOOGLE_ANALYTICS_ID } from "core/config/config";

import Main from "pages/Main";

type Props = {
    showBreadcrumb: boolean,
    breadcrumbHandler: (showBreadcrumb: boolean) => void,
    footerMenu?: { menu: [any], side: [any], bottom: [any] },
    footerMenuHandler: (pages: any) => void,
};

/**
 * Get an id to use on the script tag.
 *
 * @param {string} analyticsId
 * @returns {string}
 */
const getScriptId = (analyticsId: string) => `analytics-${analyticsId}`;

/**
 * Start the tracking.
 *
 * @param analyticsId
 */
const startTracking = (analyticsId: string) => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    script.async = true;
    script.id = getScriptId(analyticsId);
    document.getElementsByTagName('head')[0].appendChild(script);
};

const injectGA = (analyticsId: string) => {
    if (typeof window == 'undefined') {
        return;
    }
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', analyticsId);
};

const AppPage: React.FC<Props> = ({
    showBreadcrumb,
    breadcrumbHandler = NOOP,
    footerMenu = undefined,
    footerMenuHandler = NOOP,
}) => {
    const [appState] = useAppState();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const stage = params.get("stage");

    /**
     * Track a page view.
     *
     * @param location
     */
    const trackPageView = useCallback(() => {
        if (location && location.pathname && (window as any).gtag) {
            (window as any).dataLayer = (window as any).dataLayer || [];

            (window as any).gtag('event', "page_view", {
                page_path: window.location.pathname + window.location.search,
            });
        }
    }, [location]);

    useEffect(() => {
        // check if tag manager already loaded
        if (!document.getElementById(getScriptId(GOOGLE_ANALYTICS_ID))) {
            startTracking(GOOGLE_ANALYTICS_ID);
            injectGA(GOOGLE_ANALYTICS_ID);
            trackPageView();
        }
    }, [location, trackPageView]);

    return (
        <div className="App">
            <Header stage={stage} footerMenuHandler={footerMenuHandler} breadcrumbHandler={breadcrumbHandler} />
            <Routes>
                <Route path={"/"} element={<Main stage={stage} showBreadcrumb={showBreadcrumb} />} />
                <Route path={"/auth/*"} element={<AuthPage />} />
                <Route path={"*"} element={<Main stage={stage} showBreadcrumb={showBreadcrumb} />} />
            </Routes>
            {!appState.loading && (
                <Footer footerMenu={footerMenu} />
            )}
        </div>
    );
}

export default AppPage;
