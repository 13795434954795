import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    itemBoxWidth: number,
};

const ItemListWrapper:React.FC<Props> = ({ children, itemBoxWidth }) => {
    const theme = useTheme();

    const style = css`
        display: grid;
        grid-template-columns: ${itemBoxWidth}px ${itemBoxWidth}px ${itemBoxWidth}px;
        place-content: center;
        width: 100%;

        ${theme.breakpoints.up("5xl")} {
            gap: 44px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            gap: 31px;
        }

        ${theme.breakpoints.only("xl")} {
            gap: 27px;
        }

        ${theme.breakpoints.down("lg")} {
            gap: 21px;
        }

        ${theme.breakpoints.only("md")} {
            grid-template-columns: ${itemBoxWidth}px ${itemBoxWidth}px;
            gap: 42px;
        }

        ${theme.breakpoints.down("sm")} {
            grid-template-columns: ${itemBoxWidth}px;
        }
        
        ${theme.breakpoints.down('xs')} {
            gap: 28px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemListWrapper;