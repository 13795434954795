import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ImageSection: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;

        ${theme.breakpoints.up("xl")} {
            width: 650px;
            padding: 30px 40px 0 30px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 540px;
            padding: 30px;
        }

        ${theme.breakpoints.only("md")}{
            width: 100%;
            padding: 80px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 100%;
            padding: 34px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 100%;
            padding: 19px;
        }
    `;

    return (
        <div css={style}>
            <div>{children}</div>
        </div>
    );
};

export default ImageSection;
