import React from "react";
import { css } from "@emotion/react";
import theme from "theme";

type Props = {
    className?: string,
    width?: number,
    height?: number,
    isClickable?: boolean,
    useDefaultStyle?: boolean,
};

const NavMenuUnderLine: React.FC<Props> = ({
    className = 'nav-menu-underline',
    width = 90,
    height = theme.sizes.menu.heightMainUnderLine,
    isClickable = true,
    useDefaultStyle = true,
}) => {
    const style = css`
        margin: 0 auto;
        width: ${width}%;
        height: ${height}px;
        border-radius: 4px;
        
        ${isClickable && css`
            background-color: transparent;
        `}
    `;

    return (
        <div className={className} css={useDefaultStyle ? style : ''} />
    );
};

export default NavMenuUnderLine;