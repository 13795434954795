import React from "react";
import { HiChevronLeft, HiChevronRight, HiChevronUp, HiChevronDown } from "react-icons/hi";
import CircleButton from "core/Components/Buttons/CircleButton";
import { NOOP } from "core/constants";

type Props = {
    type: 'left' | 'right' | 'up' | 'down' | 'prev' | 'next',
    onClick?: (e: React.MouseEvent) => void,
    size?: number,
    roundColour?: string,
    roundHoverColour?: string,
    chevronColour?: string,
    className?: string,
    linkURL?: string,
};

const RoundChevronButton: React.FC<Props> = ({
    type,
    onClick = NOOP,
    size = 30,
    roundColour = '',
    roundHoverColour = '',
    chevronColour = '',
    className = '',
    linkURL= '',
}) => {
    return (
        <CircleButton
            size={size}
            onClick={onClick}
            roundColour={roundColour}
            roundHoverColour={roundHoverColour}
            svgColour={chevronColour}
            className={className}
            linkURL={linkURL}
        >

            {(type === 'left' || type === 'prev') && (
                <HiChevronLeft />
            )}

            {(type === 'right' || type === 'next') && (
                <HiChevronRight />
            )}

            {type === 'up' && (
                <HiChevronUp />
            )}

            {type === 'down' && (
                <HiChevronDown />
            )}

        </CircleButton>
    );
};

export default RoundChevronButton;
