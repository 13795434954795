import React from 'react';
import { css, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

type Props = {
    children: React.ReactNode,
    fontColour: string,
    backgroundColour?: string,
    backgroundGradation?: any
};

const ItemSection: React.FC<Props> = ({
    children,
    fontColour,
    backgroundColour,
    backgroundGradation,
}) => {
    const theme = useTheme();

    const style = css`
        width: 50%;
        height: 100%;
        color: ${fontColour};

        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};
        
        ${!isEmpty(backgroundGradation) && css`
            background: transparent linear-gradient(360deg, ${backgroundGradation.bottom} 80%, ${backgroundGradation.top} 100%);
        `};
        
        ${theme.breakpoints.down('md')} {
            width: 100%;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemSection;