import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isMainMenu?: boolean,
    isBottomMenu?: boolean,
    parentID?: number,
};

const FooterLink: React.FC<Props> = ({
    children,
    isMainMenu = false,
    isBottomMenu = false,
    parentID = -1,
}) => {
    const theme = useTheme();

    const style = css`
        color: ${theme.colours.doveGray};
        
        ${isMainMenu && css`
            margin-top: 20px;

            ${theme.breakpoints.down("xl")} {
                font-size: 12px;
            }
        `}
        
        ${isBottomMenu && css`
            ${theme.breakpoints.up("lg")} {
                margin-left: 55px;
            }

            ${theme.breakpoints.down("md")} {
                margin-top: 29px;
            }
        `}
        
        ${isMainMenu && parentID === 0 && css`
            margin-top: 0;
            color: ${theme.colours.black};
            font-size: 16px;
            font-weight: ${theme.fonts.weights.bold};

            ${theme.breakpoints.down("xl")} {
                font-size: 14px;
            }
        `};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default FooterLink;