import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";

type Props = {
    linkURL?: string,
    onClick?: () => void,
};

const CardChevronButton: React.FC<Props> = ({ linkURL, onClick }) => {
    const theme: Theme = useTheme();

    const roundChevronButtonStyle = css`
        overflow: hidden;
        width: 32px;
        height: 32px;

        ${theme.breakpoints.down("xl")} {
            width: 28px;
            height: 28px;
        }
    `;

    return (
        <RoundChevronButton
            size={100}
            type={'right'}
            linkURL={linkURL}
            onClick={onClick}
            roundColour={'white'}
            chevronColour={theme.colours.gray2}
            css={roundChevronButtonStyle}
        />
    );
}

export default CardChevronButton;
