import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const AddressContainer: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;

        ${theme.breakpoints.down("xs") } {
            height: 130px;
            justify-content: space-between;
            flex-direction: column;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default AddressContainer;
