import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    text: string,
    textColour?: string,
};

const Quote: React.FC<Props> = ({ text, textColour = 'white' }) => {
    const theme = useTheme();

    const style = css`
        color: ${textColour};
        
        ${theme.breakpoints.up('5xl')} {
            margin-top: 104px;
        };

        ${theme.breakpoints.between('3xl', '4xl')} {
            margin-top: 110px;
        }

        ${theme.breakpoints.only('xxl')} {
            margin-top: 105px;
        }

        ${theme.breakpoints.only('xl')} {
            margin-top: 96px;
        }

        ${theme.breakpoints.only('lg')} {
            margin-top: 43px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 45px;
        }

        ${theme.breakpoints.only('sm')} {
            margin-top: 59px;
            width: 470px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 34px;
            width: 323px;
        }
    `;

    return (
        text ? (
            <div css={style}>
                <ContentCombine Tag={'p'} fontSizeType={'20'}>{text}</ContentCombine>
            </div>
        ) : null
    );
};

export default Quote;