import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isImageFirst?: boolean,
    fromColour: string,
    toColour: string,
};

const ShadowedContainer: React.FC<Props> = ({
    children,
    isImageFirst,
    fromColour,
    toColour,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        width: 100%;
        height: 100%;
        align-self: center;
        border-radius: 22px;
        box-shadow: ${theme.borderAndShadow.boxShadow5};
        overflow: hidden;

        background: transparent linear-gradient(
                360deg,
                ${(fromColour)} 0%,
                ${(toColour)} 100%
        ) 0% 0% no-repeat padding-box;
        
        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
        
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }

        ${theme.breakpoints.down("lg")} {
            border-radius: 12px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ShadowedContainer;
