import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    paddingTop: number,
};

const FeatureWrapper: React.FC<Props> = ({ children, paddingTop }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        justify-content: center;
        padding: 40px 185px 40px;
        width: 100%;
        
        ${theme.breakpoints.only('xxl')} {
            padding-left: 84px;
            padding-right: 84px;
        }

        ${theme.breakpoints.only('xl')} {
            padding-left: 55px;
            padding-right: 55px;
        }

        ${theme.breakpoints.only('lg')} {
            padding-left: 42px;
            padding-right: 42px;
        }

        ${theme.breakpoints.between('sm', 'md')} {
            padding-left: 44px;
            padding-right: 44px;
        }

        ${theme.breakpoints.down('xs')} {
            padding-left: 10px;
            padding-right: 10px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default FeatureWrapper;
