import React from "react";
import { css, useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import RoundButton from "core/Components/Buttons/RoundButton";
import InputNumber from "core/Components/InputNumber/InputNumber";
import ButtonGroupContainer from "core/Components/Item/ButtonGroupContainer";

type Props = {
    buttonText?: string,
    buttonLink?: string,
    videoButtonText?: string,
    videoPath?: string|null,
    showVideo?: boolean,
    onVideoClick?: () => void,
    isShowingCart?: boolean,
    isShowingRegister?: boolean,
    registerButtonTitle?: string,
    buttonSidePadding?: number,
    onRegisterClick?: () => void,
    trackingLabel?: string,
};

const BG_COLOUR = "white";

const ItemButtonGroup: React.FC<Props> = ({
    buttonText = "",
    buttonLink = "",
    videoButtonText = "Watch Demo",
    videoPath = "",
    onVideoClick,
    showVideo = true,
    isShowingCart = false,
    isShowingRegister = false,
    registerButtonTitle = "Register",
    buttonSidePadding = 22,
    onRegisterClick,
    trackingLabel = '',
}) => {
    const theme = useTheme();

    const buttonBpStyle = css`
        ${theme.breakpoints.only('md')} {
            height: 30px;
            
            div {
                font-size: 16px;
            }
        }

        ${theme.breakpoints.down('sm')} {
            height: 38px;
            
            div {
                font-size: 16px;
            }
        }
    `;

    const registerStyle = css`
        ${buttonBpStyle};
        
        ${!!videoPath?.length && css`
            margin-left: 20px;
        `}; 
        
        width: 210px;
    `;

    return (
        <ButtonGroupContainer>
            {(!isEmpty(buttonText) && !isEmpty(buttonLink)) && (
                <RoundButton
                    linkURL={buttonLink}
                    textColour={theme.colours.grey[650]}
                    backgroundColour={BG_COLOUR}
                    css={buttonBpStyle}
                >
                    {buttonText}
                </RoundButton>
            )}
            {(showVideo && isEmpty(buttonLink) && !!videoPath?.length) && (
                <RoundButton
                    backgroundColour={BG_COLOUR}
                    customFontWeight={theme.fonts.weights.normal}
                    textColour={theme.colours.grey[650]}
                    css={buttonBpStyle}
                    onButtonClick={onVideoClick}
                >
                    { videoButtonText }
                </RoundButton>
            )}
            {isShowingCart && !isShowingRegister && (
                <>
                    <InputNumber />
                    <RoundButton
                        backgroundColour={BG_COLOUR}
                        customFontWeight={theme.fonts.weights.normal}
                        textColour={theme.colours.grey[650]}
                        css={buttonBpStyle}
                    >
                        Add to Cart
                    </RoundButton>
                </>
            )}
            {isShowingRegister && !isShowingCart && (
                <RoundButton
                    backgroundColour={BG_COLOUR}
                    customFontWeight={theme.fonts.weights.normal}
                    textColour={theme.colours.grey[650]}
                    buttonSidePadding={buttonSidePadding}
                    css={registerStyle}
                    onButtonClick={onRegisterClick}
                    className={trackingLabel}
                >
                    {registerButtonTitle}
                </RoundButton>
            )}
        </ButtonGroupContainer>
    );
};

export default ItemButtonGroup;
