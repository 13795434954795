import React from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    className?: string,
    imageWidth: number,
    carouselCellSpacing: number,
    colour?: string,
};

const Header: React.FC<Props> = ({
    children,
    className,
    imageWidth,
    carouselCellSpacing,
    colour,
}) => {
    const theme = useTheme();
    const Tag = "h2";
    const [fontSize, lineHeight] = useFontSize(Tag);

    const style = css`
        width: 100%;
        font-weight: ${theme.fonts.weights.bold};
        margin-bottom: 45px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;

        ${colour && css`
            color: ${colour};
        `};
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 29px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 34px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 36px;
        }

        ${theme.breakpoints.down("lg")} {
            margin-bottom: 31px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 20px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 12px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 28px;
        }
    `;

    const headerStyle = css`
        margin: 0 auto;
        text-align: center;

        ${theme.breakpoints.up("lg")} {
            width: ${(imageWidth * 3) + (carouselCellSpacing * 2)}px;
        }

        ${theme.breakpoints.down("md")} {
            width: ${imageWidth}px;
            margin-bottom: 40px;
        }
    `;

    return (
        <div css={style} className={className}>
            <Tag css={headerStyle}>
                {children}
            </Tag>
        </div>
    );
};

export default Header;
