import React from 'react';
import { css, useTheme } from "@emotion/react";
import { IoIosCloseCircle } from 'react-icons/io';

type Props = {
    children: React.ReactNode,
    isVisible: boolean,
    onClose: () => void,
    isFixed?: boolean,
    top?: number,
    className?: string,
};

const FormModal: React.FC<Props> = ({
    children,
    isVisible = false,
    onClose,
    isFixed = false,
    top,
    className = "",
}) => {
    const theme = useTheme();

    const outerOuterStyle = css`
        position: ${isFixed ? 'fixed' : 'absolute'};
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 75%;
        max-width: 1100px;
        min-width: 300px;
        height: calc(100vh - 130px);
        min-height: 500px;
        background-color: white;
        border-radius: 12px;
        overflow: hidden;
        z-index: ${theme.zIndex.zIndexHigher};
        box-shadow: ${theme.borderAndShadow.boxShadow};
        display: none;

        ${isVisible && css`
            display: block;
            pointer-events: all;
        `}

        ${top && css`
            top: ${top}px;
        `}
    `;

    const outerStyle = css`
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 20px;
    `;

    const innerStyle = css`
        width: 72%;
        max-width: 800px;
        min-width: 250px;
        margin: 0 auto;
        text-align: center;
    `;

    const overlayStyle = css`
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        z-index: ${theme.zIndex.zIndexHigh};

        ${isVisible && css`
            pointer-events: all;
            display: block;
        `}
    `;

    const closeStyle = css`
        position: absolute;
        right: 10px;
        top: 10px;
        fill: ${theme.colours.grey[500]};
        width: 42px;
        height: 42px;
        cursor: pointer;
        z-index: ${theme.zIndex.zIndexOne};
    `;

    return (
        <>
            <div css={outerOuterStyle} className={className}>
                <div css={outerStyle}>
                    <IoIosCloseCircle css={closeStyle} onClick={onClose} />
                    <div css={innerStyle}>
                        { children }
                    </div>
                </div>
            </div>
            <div css={overlayStyle} onClick={onClose} />
        </>
    );
};


export default FormModal;
