import React from "react";
import { useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const GuidanceAndOrderButtonContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        height: 100px;
        justify-content: flex-end;

        ${theme.breakpoints.up("5xl")} {
            height: 120px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 70px;
        }
    `;

    return (
        <ContentSection flexDirection={"column"} alignItems={"center"} position={"relative"} css={style}>
            {children}
        </ContentSection>
    );
};

export default GuidanceAndOrderButtonContainer;
