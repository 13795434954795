import React from 'react';
import { css, useTheme } from "@emotion/react";
import Image from "core/Components/Image";
import { ReactComponent as PlayIcon } from "images/icons/play-triangle-with-circle.svg";

type Props = {
    image: Image,
    onClick: ({ img }: any) => void,
    isVideo?: boolean,
};

const Thumbnail: React.FC<Props> = ({ image, onClick, isVideo = false, }) => {
    const theme = useTheme();

    const style= css`
        position: relative;
        width: 130px;
        height: 130px;
        cursor: pointer;
        border-radius: ${theme.borderAndShadow.smallRadius}px;

        :not(:last-child) {
            margin-right: calc(40px / 3);
        }
        
        ${theme.breakpoints.up('5xl')} {
            width: 155px;
            height: 155px;

            :not(:last-child) {
                margin-right: 10px;
            }
        }
        
        ${theme.breakpoints.between('xxl', '4xl')} {
            width: 134px;
            height: 134px;

            :not(:last-child) {
                margin-right: 9px;
            }
        }

        ${theme.breakpoints.only('xl')} {
            width: 119px;
            height: 119px;

            :not(:last-child) {
                margin-right: 7px;
            }
        }

        ${theme.breakpoints.only('lg')} {
            width: 92.8px;
            height: 87.37px;

            :not(:last-child) {
                margin-right: 9.01px;
            }
        }

        ${theme.breakpoints.down('md')} {
            width: 73.97px;
            height: 69.63px;

            :not(:last-child) {
                margin-right: 7.18px;
            }
        }
    `;

    const playIconStyle = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30%;
        z-index: ${theme.zIndex.zIndexOne};
        fill: ${theme.colours.raspberry};
        border-radius: 50%;
        will-change: transform;
    `;

    return (
        <div css={style} className={"radius-box"} onClick={onClick}>
            {isVideo && (
                <PlayIcon css={playIconStyle} />
            )}
            <Image
                image={image}
                borderRadius={theme.borderAndShadow.smallRadius}
                useFocusPoint={true}
            />
        </div>
    );
};

export default Thumbnail;
