import React from "react";
import { css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const TextContainer: React.FC<Props> = ({ children }) => {
    const containerStyle = css`
        width: 100%;
        display: block;
    `;

    return (
        <ContentSection css={containerStyle} alignItems={"start"}>
            {children}
        </ContentSection>
    );
}

export default TextContainer;
