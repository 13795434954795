import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    justifyContent?: string,
    textColour?: string,
    backgroundColour?: string,
};

const FeatureTarget: React.FC<Props> = ({
    children,
    justifyContent = "center",
    textColour,
    backgroundColour
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: ${justifyContent};
        align-items: center;
        width: 100%;
        height: 57px;
        font-size: 21px;
        line-height: 25px;
        border-radius: 47px;
        font-weight: ${theme.fonts.weights.bold};
        
        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `}
        
        ${textColour && css`
            color: ${textColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            height: 89px;
            font-size: 40px;
            line-height: 54px;
        }

        ${theme.breakpoints.only("lg")} {
            font-size: 20px;
            line-height: 24px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: ${children === "License" ? 20 : 16}px;
            line-height: ${children === "License" ? 24 : 19}px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 18px;
            line-height: 22px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 14px;
            line-height: 17px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default FeatureTarget;
