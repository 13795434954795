import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const ImageContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const imageContainerStyle = css`
        margin-top: 16px;
        border-radius: 50%;
        z-index: ${theme.zIndex.zIndexLow - 1};
        overflow: hidden;
        
        ${theme.breakpoints.up("5xl")} {
            width: 662px;
            height: 662px;
        }
        
        ${theme.breakpoints.between("xxl", "4xl")} {
            width: 545px;
            height: 545px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            width: 430px;
            height: 430px;
        }

        ${theme.breakpoints.only("md")} {
            width: 462px;
            height: 462px;
            align-self: center;
        }

        ${theme.breakpoints.down("sm")} {
            width: 280px;
            height: 280px;
            align-self: center;
        }
    `;

    return (
        <ContentSection css={imageContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default ImageContainer;
