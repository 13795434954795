import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

const DollarSign: React.FC = () => {
    const theme: Theme = useTheme();

    const dollarSignStyle = css`
        font-size: 26px;
        display: flex;
        align-self: flex-end;
        padding-bottom: 3px;
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 39px;
            padding-bottom: 6px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 19px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 22px;
            padding-bottom: 4px;
        }
    `;

    const dollarSign = '$';

    return (
        <div css={dollarSignStyle}>{dollarSign}</div>
    );
}

export default DollarSign;
