import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    data: PricingCard,
    onClick?: Function,
    target?: string,
};

const Button: React.FC<Props> = ({
    data,
    onClick,
    target = "_blank",
}) => {
    const theme: Theme = useTheme();
    const linkURL = data.outerURL || data.innerURL;

    const colourTheme = JSON.parse(data.theme);
    const { buttonTextColour, buttonColour } = colourTheme;

    const buttonStyle = css`
        width: 147px;
        height: 47px;
        justify-content: center;
        position: absolute;
        bottom: 25px;

        ${theme.breakpoints.up("5xl")} {
            width: 226px;
            height: 72px;
            bottom: 38px;
        }

        ${theme.breakpoints.only("md")} {
            width: 112px;
            height: 40px;
            bottom: 22px;
        }

        ${theme.breakpoints.down("sm")} {
            width: 132px;
            height: 47px;
            bottom: 27px;
        }
    `;

    const buttonTextStyle = css`
        font-size: 15px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 13px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
        }
    `;

    return (
        linkURL ? (
            <RoundButton
                css={buttonStyle}
                backgroundColour={buttonColour}
                textColour={buttonTextColour}
                linkURL={linkURL}
                buttonBorderRadius={33}
                buttonTextCustomStyle={buttonTextStyle}
                onLinkClick={onClick}
                target={target}
            >
                {data.buttonText}
            </RoundButton>
        ) : null
    );
}

export default Button;
