import StandardPage from "pages/Components/StandardPage";
import CareerPage from "pages/Components/CareerPage";
import CareerListPage from "pages/Components/CareerListPage";
import EventPage from "pages/Components/EventPage";
import EventListPage from "pages/Components/EventListPage";
//import ProductPage from "pages/Components/ProductPage";
import ProductListPage from "pages/Components/ProductListPage/ProductListPage";
import SuccessStoryPage from "pages/Components/SuccessStoryPage";
import SuccessStoryListPage from "pages/Components/SuccessStoryListPage";
import SignIn from "pages/Components/auth/SignIn";
import BlogListPage from "pages/Components/BlogListPage/BlogListPage";
import BlogPost from "pages/Components/BlogPost/BlogPost";

type Component = {
    [key: string]: any,
};

/**
 * Add your pages here.
 */
const page: Component = {
    StandardPage,
    BlogListPage,
    BlogPost,
    CareerPage,
    CareerListPage,
    EventPage,
    EventListPage,
    //ProductPage,
    ProductListPage,
    SuccessStoryPage,
    SuccessStoryListPage,
    SignIn,
};

export default page;
