import React, { memo } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { ReactComponent as PlayIcon } from "images/icons/play-triangle-with-circle.svg";
import { NOOP } from "core/constants";

type Props = {
    imageAltText?: string,
    imageUrl: string,
    onClick?: (e: React.MouseEvent) => void,
    showVideo?: boolean,
    isPlayIcon?: boolean,
    className?: string,
};

const VideoPlaceholder: React.FC<Props> = ({
    imageAltText,
    imageUrl,
    onClick = NOOP,
    showVideo,
    isPlayIcon = true,
    className,
}) => {
    const theme: Theme = useTheme();

    const containerStyle = css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        
        ${onClick !== NOOP  && css` cursor: pointer; `};
        
        > * {
            transition: ${theme.transitions.transition};
            transition-property: filter, transform;
        }

        &:hover > svg {
            transform: translate(-50%, -50%) scale(1.1);
        }
        
        ${showVideo && css`
            opacity: 0;
        `};
    `;

    const imageStyle = css`
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    `;

    const playIconContainerStyle = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 26%;
        z-index: ${theme.zIndex.zIndexOne};
        fill: ${theme.colours.raspberry};
        border-radius: 50%;
        will-change: transform;
    `;

    return (
        <div css={containerStyle} onClick={onClick} className={className}>
            {isPlayIcon && onClick !== NOOP && (
                <PlayIcon css={playIconContainerStyle} />
            )}
            <img src={imageUrl} css={imageStyle} alt={imageAltText} />
        </div>
    );
};

export default memo(VideoPlaceholder);
