import React from "react";
import { css, useTheme } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: React.ReactNode,
    textColour?: string,
};

const ImageHeader: React.FC<Props> = ({
    children,
    textColour,
}) => {
    const theme = useTheme();
    const Tag = "h3";
    const [fontSize, lineHeight] = useFontSize(Tag, '7');

    const style = css`
        padding: 0 20px;
        text-align: center;
        font-weight: ${theme.fonts.weights.bold};
        white-space: break-spaces;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        
        ${textColour && css`
            color: ${textColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            margin-top: 37px;
        }
        
        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-top: 41px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-top: 19px;
        }
        
        ${theme.breakpoints.only("md")} {
            margin-top: 27px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 18px;
            line-height: 24px;
            margin-top: 28px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 28px;
            padding: 0 40px;
        }
    `;

    return (
        <Tag css={style}>
            {children}
        </Tag>
    );
};

export default ImageHeader;