import React from "react";
import { css, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    children: React.ReactNode,
    listType: string,
};

const Header: React.FC<Props> = ({ children, listType }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
    `;

    const titleStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        font-size: 52px;
        line-height: 54px;
        color: ${theme.colours.curiousBlue};

        ${theme.breakpoints.down("sm")} {
            font-weight: ${theme.fonts.weights.normal};
            font-size: 30px;
            line-height: 54px;
        }
    `;

    return (
        <div css={style}>
            <Title Tag={"h1"} css={titleStyle}>{children}</Title>

            {/*{listType !== 'General' && (
                <div css={css`display: flex; gap:38px;`}>
                    <div>Terms & Conditions</div>
                    <div>Private Policy</div>
                </div>
            )}*/}
        </div>
    );
};

export default Header;