import React from "react";
import { css, useTheme } from "@emotion/react";

const DEFAULT_MESSAGE = "Sorry, there are currently no data that match your search criteria.";

type Props = {
    text?: string,
}

const NoData: React.FC<Props> = ({
    text,
}) => {
    const theme = useTheme();

    const style = css`
        padding: 50px;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        border-radius: 33px;
        box-shadow: ${theme.borderAndShadow.boxShadow5};
        
        ${theme.breakpoints.between("md", "lg")} {
            font-size: 18px;
            line-height: 25px;
            padding: 40px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
            line-height: 25px;
            padding: 30px;
        }
    `;

    return (
        <div css={style}>
            {text ? text : DEFAULT_MESSAGE}
        </div>
    );
};

export default NoData;