import React from "react";
import { css, useTheme } from "@emotion/react";

import BurgerNavLinkButtons from "core/Components/HamburgerNavigations/BurgerNavLinkButtons";

type Props = {
    closeMenuHandler: Function,
    menuButton?: Button,
    hideCustomButton?: boolean,
    buttonFontSize?: number,
};

const BurgerNavButtonContainer: React.FC<Props> = ({
    closeMenuHandler,
    menuButton = undefined,
    hideCustomButton = false,
    buttonFontSize = 18,
}) => {
    const theme = useTheme();

    const closeHandler = () => {
        closeMenuHandler();
    };

    const style = css`
        width: fit-content;
        
        ${theme.breakpoints.down("lg")} {
            margin-top: 20px;
            margin-left: 56px;
        }

        ${theme.breakpoints.down("md")} {
            margin: 40px auto 50px 55px;
        }
    `;

    return (
        <div css={style}>
            <BurgerNavLinkButtons
                menuButton={menuButton}
                closeMenuHandler={closeHandler}
                hideCustomButton={hideCustomButton}
                buttonFontSize={buttonFontSize}
            />
        </div>
    );
};

export default BurgerNavButtonContainer;