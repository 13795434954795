import React, { memo, useCallback } from "react";
import { css, SerializedStyles, Theme, useTheme } from "@emotion/react";
import Countries from "core/includes/countries";
import ErrorIcon from "./ErrorIcon";
import { ReactComponent as Tick } from 'images/icons/tick-with-circle.svg';

type Props = {
    hasError?: boolean,
    hasTick?: boolean,
    id?: string,
    innerRef?: React.RefCallback<HTMLInputElement> | React.Ref<HTMLInputElement>,
    name: string,
    label?: string,
    onChange?: any,
    onEventChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    variant?: "country" | "phone number" | "none",
    value: any,
    hasBorder?: boolean,
    hasIconOnError?: boolean,
    extraContainerStyles?: SerializedStyles,
    onFilterChange?: (e: React.FormEvent<HTMLInputElement>) => void,
    [x: string]: any,
    className?: string,
};

const Input: React.FC<Props> = ({
    hasError,
    hasTick,
    icon,
    id,
    innerRef,
    name,
    onChange,
    onEventChange,
    variant = "none",
    value,
    label,
    hasBorder,
    hasIconOnError = true,
    className,
    extraContainerStyles,
    onFilterChange,
    ...otherProps
}) => {
    const theme: Theme = useTheme();

    const handleChange = useCallback((e: any) => {
        if (onChange && variant !== "country") {
            onChange(name, e.target.value);
        }

        if (onEventChange) {
            e.persist();
            onEventChange(e);
        }

        if (onChange && variant === "country") {
            if (onFilterChange) {
                onFilterChange(e);
            }

            const filteredCountries = Countries.filter(country => country.label.toString().toLowerCase() === e.target.value.toString().toLowerCase());

            if (filteredCountries.length === 1) {
                onChange(name, filteredCountries[0].code);
            } else {
                onChange(name, e.target.value);
            }
        }
    }, [name, onChange, onEventChange, onFilterChange, variant]);

    const styles = {
        container: css`
            position: relative;
            ${theme.forms.defaults};
            ${extraContainerStyles};
        `,
        icon: {
            position: "absolute" as "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        input: css`
            border: 0;
            border-bottom: 3px solid ${hasBorder ? theme.forms.defaults.borderColor : theme.colours.transparent};
            border-radius: 0;
            height: 50px;
            padding: 0;
            padding-left: ${!!icon ? "30px" : "0"};
            background: transparent;
            outline: none;
            width: 100%;
            font-size: ${theme.forms.label.fontSize};
            font-family: ${theme.fonts.frutiger};
            font-weight: ${theme.fonts.weights.light};
            ${hasError && theme.forms.error};
            ${theme.mixins.placeholder()};
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);

            ${variant === "phone number" && css`
                padding-left: 118px;
            `};
        `,
    };

    const variantToInputType = {
        "country": "text",
        "phone number": "tel",
        "none": "text",
    }

    const tickStyle = css`
        position: absolute;
        right: 3%;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
    `;

    return (
        <div css={styles.container} className={className}>
            <input
                css={styles.input}
                id={id || name}
                name={name}
                ref={innerRef}
                value={value || ""}
                onChange={handleChange}
                placeholder={label}
                type={variantToInputType[variant]}
                {...otherProps}
            />
            {hasError && hasIconOnError && (
                <ErrorIcon />
            )}
            {hasTick && (
                <Tick css={tickStyle} />
            )}
        </div>
    );
};

Input.defaultProps = {
    hasError: false,
    type: "text",
    hasBorder: true,
}

export default memo(Input);