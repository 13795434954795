import React, { useCallback, useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { css, useTheme } from "@emotion/react";
import { isEmpty, isEqual } from "lodash";

import BannerForAdvertisement from "core/Components/BannerForAdvertisement/BannerForAdvertisement";
import DropdownButton from "core/Components/Buttons/DropdownButton/DropdownButton";
import NoData from "core/Components/Errors/NoData";
import ItemBoxContainer from "core/Components/ItemWithRibbon/ItemBoxContainer";
import ItemContainer from "core/Components/ItemWithRibbon/ItemContainer";
import ItemListContainer from "core/Components/ItemWithRibbon/ItemListContainer";
import ItemListWrapper from "core/Components/ItemWithRibbon/ItemListWrapper";
import LinkTo from "core/Components/LinkTo";
import SectionContainer from "core/Components/SectionContainer";
import Loading from "core/Components/Utils/Loading";
import Wrapper from "core/Components/Wrapper";
import { useAppState } from "core/contexts/AppContext";
import useSetState from "core/hooks/useSetState";
import useBreakpoint from "core/hooks/useBreakpoint";
import { getCanRenderWebp } from "core/includes/image";
import { retrieveValue } from "core/includes/localStorage";
import { eventListQuery, commonEventQuery } from "core/includes/queries";

import DropdownContainer from "pages/Components/ListPage/DropdownContainer";
import HeaderContainer from "pages/Components/ListPage/HeaderContainer";
import HeaderTitle from "pages/Components/ListPage/HeaderTitle";
import IntroText from "pages/Components/ListPage/IntroText";
import PageSection from "pages/Components/ListPage/PageSection";
import useListBreakpoint from "pages/Components/ListPage/hooks/useListBreakpoint";
import {
    DEFAULT_EVENT_SUITABILITY,
    DEFAULT_EVENT_GROUP,
    DEFAULT_EVENT_CITY,
    DEFAULT_EVENT_REGION,
    DEFAULT_EVENT_MONTH,
} from "pages/includes/constants";
import components from "elements/config/components";
import ContentCombine from "core/Components/ContentCombine";

type DefaultState = {
    selectedSuitabilities: Option[],
    selectedEventType: Option,
    selectedCityCode: Option,
    selectedRegion: Option,
    selectedMonth: Option,
    hasCompletedEventListQuery: boolean,
}

const INITIAL_STATE = {
    selectedSuitabilities: [],
    selectedEventType: DEFAULT_EVENT_GROUP,
    selectedCityCode: DEFAULT_EVENT_CITY,
    selectedRegion: DEFAULT_EVENT_REGION,
    selectedMonth: DEFAULT_EVENT_MONTH,
    hasCompletedEventListQuery: false,
}

const PAGE_TYPE = "event";

type Props = {
    data: EventListPage,
};

type EventTerms = {
    [key: string]: EventPage[],
};

const EventListPage: React.FC<Props> = ({ data: page }) => {
    const theme = useTheme();
    const [appState] = useAppState();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const { bannerHeight, itemBoxWidth } = useListBreakpoint();
    const { minWidth } = useBreakpoint();

    const selectedSuitabilities = useMemo(() => {
        const items = state.selectedSuitabilities.filter((item) => item.value !== '');
        return items.map((item) => item.value);
    }, [state.selectedSuitabilities]);

    const country = retrieveValue('selected-country')?.replace(/"/g, "") || appState.countryCode?.code.toLowerCase();
    const canRenderWebp = getCanRenderWebp();

    const [loadCommonData, { data: commonData, loading: loadingCommonData }] = useLazyQuery(commonEventQuery, {
        variables: { type: PAGE_TYPE, parentId: page?.id, canRenderWebp, countryCode: country },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const [loadEventListData, { data, loading }] = useLazyQuery(eventListQuery, {
        variables: {
            parentId: page?.id,
            countryCode: country,
            suitabilities: selectedSuitabilities,
            eventId: state.selectedEventType.value,
            month: state.selectedMonth.value,
            cityCodeId: state.selectedCityCode.value,
            regionId: state.selectedRegion.value,
            canRenderWebp,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onCompleted: () => setState({ hasCompletedEventListQuery: true }),
    });

    useEffect(() => {
        setState({ hasCompletedEventListQuery: false });

        loadCommonData();
        loadEventListData();
    }, [loadCommonData, loadEventListData, setState, appState.countryCode?.code]);

    // Make sure if the country changes, the filter dropdowns are reset.
    useEffect(() => {
        setState({
            selectedSuitabilities: [DEFAULT_EVENT_SUITABILITY],
            selectedEventType: DEFAULT_EVENT_GROUP,
            selectedCityCode: DEFAULT_EVENT_CITY,
            selectedRegion: DEFAULT_EVENT_REGION,
            selectedMonth: DEFAULT_EVENT_MONTH,
        })
    }, [country, setState]);

    const suitabilityOptions = [DEFAULT_EVENT_SUITABILITY];
    commonData?.schoolType?.forEach((item: SchoolTypeItem) => {
        suitabilityOptions?.push({ value: item.id, label: item.type });
    });

    const groupOptions = [DEFAULT_EVENT_GROUP];

    commonData?.eventGroup?.forEach((item: EventGroupItem) => {
        groupOptions.push({ value: item.id, label: item.name });
    });

    const cityCodes = [DEFAULT_EVENT_CITY];
    commonData?.eventCityCode?.forEach((item: EventGroupItem) => {
        cityCodes.push({ value: item.id, label: item.name });
    });

    const regions = [DEFAULT_EVENT_REGION];
    commonData?.eventRegion?.forEach((item: EventGroupItem) => {
        regions.push({ value: item.id, label: item.name });
    });

    const months = [DEFAULT_EVENT_MONTH];
    commonData?.eventMonth?.forEach((item: string) => {
        months.push({ value: item, label: item });
    });

    const dropdownContainerStyle = css`
        ${theme.breakpoints.only("md")} {
            padding-top: 21px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            place-content: space-between;
            gap: 14px 16px;
            width: 100%;
        }
    `;

    const getImage = useCallback((data: EventListPage) => {
        if (data && (data.landscape || data.portrait)) {
            if (minWidth >= theme.breakpoints.sizes["lg"]) {
                return data.landscape || data.portrait;
            }

            return data.portrait || data.landscape;
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const image = useMemo(() => {
        if (loadingCommonData) return null;

        if (commonData?.eventMain) {
            return getImage(commonData?.eventMain);
        }

        return getImage(page) || null;
    }, [loadingCommonData, commonData?.eventMain, getImage, page]);

    const getUseFocusPoint = useCallback((data: EventListPage) => (
        (isEqual(image, data.landscape) && data.useFocusPoint) ||
        (isEqual(image, data.portrait) && data.useFocusPointPortrait)
    ), [image]);

    const useAFocusPoint = !loadingCommonData && commonData?.eventMain ?
        getUseFocusPoint(commonData?.eventMain) :
        getUseFocusPoint(page);

    const altText = !commonData?.eventMain ? page.altText || '' : '';

    const showButtonOnBanner = !loadingCommonData && !!commonData?.eventMain && page.showButtonOnBanner;

    const hideAllDropdowns =
        !page.showDropdownSuitability &&
        !page.showDropdownGroup &&
        !page.showDropdownCity &&
        !page.showDropdownRegion &&
        !page.showDropdownMonth;

    const categorisedEvents: EventTerms = useMemo(() => {
        if (!data) return {};

        const categorisedEvents: EventTerms = {};
        const events: EventPage[] = [];
        data.eventList.forEach((event: EventPage) => {
            // If event does not have a term, or we do not want to categorise events, then add them to 'all' events
            if (!event.eventTerm || !page.isCategorisedByTerms) {
                events.push(event);
                return;
            }

            const term: string = event.eventTerm;
            if (categorisedEvents.hasOwnProperty(term)) {
                categorisedEvents[term].push(event);
            } else {
                categorisedEvents[term] = [event];
            }
        })

        if (events.length) {
            categorisedEvents['all'] = events;
        }

        return categorisedEvents;
    }, [data, page.isCategorisedByTerms]);

    const bannerStyles = css`
        width: 100%;
        overflow: hidden;

        > div {
            ${theme.mixins.bloop};
        }
    `;

    const categoryStyle = css`
        justify-content: start;
        font-weight: ${theme.fonts.weights.bold};
        margin-left: 89px;
        margin-bottom: 25px;

        ${theme.breakpoints.only('xxl')} {
            margin-left: 11px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            margin-left: 0;
        }

        ${theme.breakpoints.only('md')} {
            margin-left: 21px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-left: 0;
        }
    `;

    const itemsContainerStyle = css`
        display: flex;
        flex-direction: column;
        gap: 60px;
    `;

    return (
        <>
            {page?.elements && page.elements.map((ele: any, index: number) => {
                if (ele.__typename !== 'EventListItem') {
                    let Component = components.hasOwnProperty(ele.__typename) && components[ele.__typename]
                    return <Component data={ele} key={`${ele.id}-${index}`} />
                }
                return (
                    <SectionContainer key={`${ele.id}-${index}`} data={{ backgroundColour: theme.colours.alabaster }}>
                        <Wrapper useMaxWidth={false} useMinHeight={false} flexDirection={"column"}>
                            {image && data && (
                                <LinkTo to={commonData?.eventMain?.linkUrl} css={bannerStyles}>
                                    <BannerForAdvertisement
                                        type={PAGE_TYPE}
                                        data={commonData?.eventMain}
                                        mainBannerImage={image}
                                        bannerHeight={bannerHeight}
                                        customButtonText={data?.buttonText}
                                        showText={data?.showTextOnBanner}
                                        showButton={showButtonOnBanner}
                                        useMainBannerFocusPoint={useAFocusPoint}
                                        customAltText={altText}
                                    />
                                    <IntroText data={data} />
                                </LinkTo>
                            )}

                            <PageSection>
                                <HeaderContainer type={PAGE_TYPE}>
                                    <HeaderTitle type={PAGE_TYPE}>Upcoming Events</HeaderTitle>
                                    {!hideAllDropdowns && (
                                        <DropdownContainer css={dropdownContainerStyle}>
                                            {page.showDropdownSuitability && suitabilityOptions.length > 1 && (
                                                <DropdownButton
                                                    options={suitabilityOptions}
                                                    onChangeMultiple={(items) => setState({ selectedSuitabilities: items })}
                                                    selectedValue={state.selectedSuitabilities}
                                                    defaultName={DEFAULT_EVENT_SUITABILITY.label}
                                                    zIndex={theme.zIndex.zIndexMediumHigh}
                                                    useInnerScroll={false}
                                                    isMultiple
                                                />
                                            )}
                                            {page.showDropdownGroup && groupOptions.length > 1 && (
                                                <DropdownButton
                                                    options={groupOptions}
                                                    onChange={(item) => setState({ selectedEventType: item })}
                                                    selectedValue={[state.selectedEventType]}
                                                    zIndex={theme.zIndex.zIndexMediumHigh - 2}
                                                />
                                            )}
                                            {page.showDropdownRegion && regions.length > 1 && (
                                              <DropdownButton
                                                options={regions}
                                                onChange={(item) => setState({ selectedRegion: item })}
                                                selectedValue={[state.selectedRegion]}
                                                zIndex={theme.zIndex.zIndexMediumHigh - 3}
                                              />
                                            )}
                                            {page.showDropdownCity && cityCodes.length > 1 && (
                                                <DropdownButton
                                                    options={cityCodes}
                                                    onChange={(item) => setState({ selectedCityCode: item })}
                                                    selectedValue={[state.selectedCityCode]}
                                                    zIndex={theme.zIndex.zIndexMediumHigh - 4}
                                                />
                                            )}
                                            {page.showDropdownMonth && months.length > 1 && (
                                                <DropdownButton
                                                    options={months}
                                                    onChange={(item) => setState({ selectedMonth: item })}
                                                    selectedValue={[state.selectedMonth]}
                                                    zIndex={theme.zIndex.zIndexMediumHigh - 5}
                                                />
                                            )}
                                        </DropdownContainer>
                                    )}
                                </HeaderContainer>

                                <ItemListContainer isMiddle={!data?.eventList} css={itemsContainerStyle}>
                                    {loading ? (
                                        <Loading onTop fitToScreen overlay overlayColour={"white"} />
                                    ) : (
                                        (isEmpty(data?.eventList) && state.hasCompletedEventListQuery) ? (
                                            <NoData text={"More events coming soon.  Sign up below to be the first to know and for early bird offers."} />
                                        ) : (
                                            Object.entries(categorisedEvents).map(([name, events]) => (
                                                <div key={`categorised-events-${name}`}>
                                                    {name !== 'all' && (
                                                        <ContentCombine Tag={"h4"} css={categoryStyle}>{name}</ContentCombine>
                                                    )}
                                                    <ItemListWrapper itemBoxWidth={itemBoxWidth}>
                                                        {events.map((item: EventPage, index: number) => (
                                                            <ItemContainer
                                                                key={`event-thumbnail-${index}`}
                                                                itemBoxWidth={itemBoxWidth}
                                                            >
                                                                <ItemBoxContainer
                                                                    type={PAGE_TYPE}
                                                                    data={item}
                                                                    schoolType={commonData?.schoolType}
                                                                    commonImage={item.previewImage}
                                                                />
                                                            </ItemContainer>
                                                        ))}
                                                    </ItemListWrapper>
                                                </div>
                                            ))
                                        )
                                    )}
                                </ItemListContainer>
                            </PageSection>
                        </Wrapper>
                    </SectionContainer>
                )
            })}
        </>
    );
};

export default EventListPage;
