import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    data: ContentWithImage,
    children: React.ReactNode,
};

const ShadowedContainer: React.FC<Props> = ({ children, data }) => {
    const theme: Theme = useTheme();
    const layout = JSON.parse(data.layout);
    const { isImageFirst, useShadow } = layout;
    const colourTheme = JSON.parse(data.theme);
    const {
        fromColour,
        toColour,
    } = colourTheme;

    const style = css`
        display: flex;
        width: 100%;
        align-self: center;
        border-radius: 22px;
        box-shadow: ${theme.borderAndShadow.boxShadow5};
        overflow: hidden;

        ${fromColour && toColour && css`
            background: transparent linear-gradient(360deg,
            ${(fromColour)} 0%,
            ${(toColour)} 100%) 0% 0% no-repeat padding-box;
        `};

        ${isImageFirst && css`
            flex-direction: row-reverse;
        `}
        
        ${theme.breakpoints.up("sm")} {
            height: 100%;
        } 
        
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }

        ${theme.breakpoints.down("lg")} {
            border-radius: 12px;
        }
    `;

    return useShadow ? (
        <div css={style} className={"radius-box"}>
            {children}
        </div>
    ) : (
        <>
            {children}
        </>
    );
}

export default ShadowedContainer;
