import React from "react";
import { css, Global, Theme } from "@emotion/react";
import window from 'core/includes/window';
import LoadingButton from "../Buttons/LoadingButton";

type Props = {
    children: React.ReactNode,
};

type State = {
    hasError: boolean
};

/**
 * ErrorBoundary
 * Made with a class component since only class components can be error boundaries.
 * https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component<Props, State> {

    state = {
        hasError: false,
    };

    /**
     * Update state on error.
     *
     * @param error
     */
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return process.env.NODE_ENV === "development" ? null: { hasError: true };
    }

    /**
     * Reload the page.
     */
    reload = () => window.location.reload();

    /**
     * Render.
     */
    render() {
        if (this.state.hasError) {
            return (
                <div css={containerStyle}>
                    <Global styles={bodyStyle} />
                    <p css={textStyle}>Oops, something has gone wrong.</p>
                    <div css={buttonStyle}>
                        <LoadingButton onClick={this.reload} size="lg">Reload</LoadingButton>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const containerStyle = (theme: Theme) => css`
    background-color: ${theme.colours.white};
    box-shadow: ${theme.borderAndShadow.boxShadow};
    border-radius: ${theme.borderAndShadow.smallRadius};
    padding: 20px;
    max-width: 600px;
    margin: 100px auto 0 auto;
`;

const textStyle = (theme: Theme) => css`
    font-size: 1.6rem;
    font-family: ${theme.fonts.primary};
    margin: 0;
    color: ${theme.fonts.colour};
    text-align: center;
`;

const buttonStyle = css`
    margin-top: 30px;
    text-align: center;
`;

const bodyStyle = (theme: Theme) => css`
    body {
      background-color: ${theme.colours.grey[100]};
    }
`;
