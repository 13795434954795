import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    card: ImageVerticalCard,
};

const CardDescription: React.FC<Props> = ({ card }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(card.theme);
    const { textColour } = colourTheme;

    const descriptionStyle = css`
        justify-content: start;
        color: ${textColour};
        width: 100%;
        font-size: 18px;
        line-height: 25px;

        ${theme.breakpoints.only("xl")} {
            line-height: 24px;
        }

        ${theme.breakpoints.down("lg")} {
            font-size: 16px;
            line-height: 20px;
        }

        ${!card.image && css`
            ${theme.breakpoints.only('md')} {
                padding-top: 36px;
            }

            ${theme.breakpoints.only('sm')} {
                padding-top: 12px;
            }
        `};
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={!!card.image ? "7" : "18"} css={descriptionStyle}>{card.text}</ContentCombine>
    );
};

export default CardDescription;
