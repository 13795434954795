import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { NOOP } from "core/constants";

type Props = {
    to?: any,
    children: React.ReactNode,
    className?: string,
    onClick?: Function,
    target?: string,
    useLinkStyle?: boolean,
};

const LinkTo: React.FC<Props> = ({
    to,
    children,
    className = "",
    onClick = NOOP,
    target = "_blank",
    useLinkStyle = false,
}) => {
    const theme:Theme = useTheme()
    const isExternalURL = /^(http|https):\/\//.test(to);
    const internalURL =  /^\//.test(to) ? to : '/' + to;

    const callOnClick = () => {
        onClick();
    }

    const style = css`
        height: 100%;
        ${(to || onClick !== NOOP) ? css`
            cursor: pointer;
        `: css`
            cursor: unset !important;
        `}
        
        ${useLinkStyle ? css`
            text-decoration: underline;
            &:hover {
                color: ${theme.colours.malibu};
                text-decoration: underline;
            }
        ` : css`
            text-decoration: none;
        `}
    `;

    if (to && isExternalURL) {
        return (
            <a href={to} css={style} target={target} rel={"noreferrer"} className={className}>
                {children}
            </a>
        )
    }

    return (
        to ? (
            <Link to={internalURL} css={style} className={className} onClick={callOnClick}>
                {children}
            </Link>
        ) : (
            <div css={style} className={className} onClick={callOnClick}>{children}</div>
        )
    );
}

export default LinkTo;
