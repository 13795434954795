import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ListContainer: React.FC<Props> = ({ children, }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 52px 0 38px;

        ${theme.breakpoints.up("5xl")} {
            padding: 0 99px 0 91px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 0 37px 0 20px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 0 46px 0 31px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 0 43px 0 42px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 0 34px 0 32px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 0 23px 0 15px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
}

export default ListContainer;
