import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const CircleBackground: React.FC<Props> = ({children}) => {
    const theme: Theme = useTheme();

    const backgroundContainerStyle = css`
        z-index: ${theme.zIndex.zIndexLow - 2};
        position: relative;
    `;

    return (
        <div css={backgroundContainerStyle}>
            {children}
        </div>
    );
}

export default CircleBackground;
