import React from "react";
import { css } from "@emotion/react";
import { NOOP } from "core/constants";
import LinkTo from "core/Components/LinkTo";

type Props = {
    children: React.ReactNode,
    flexDirection?: string,
    justifyContent?: string,
    alignItems?: string,
    height?: string,
    position?: string,
    backgroundColour?: string,
    className?: string,
    onClick?: (e: React.MouseEvent) => void,
    linkURL?: string,
};

const ContentSection: React.FC<Props> = ({
    children,
    flexDirection = "",
    justifyContent = "center",
    alignItems = "",
    height = "",
    position = "",
    backgroundColour = "",
    className= "",
    linkURL = "",
    onClick = NOOP,
}) => {
    const style = css`
        width: 100%;
        height: 100%;
        display: flex;
        align-self: center;

        ${flexDirection && css`
            flex-direction: ${flexDirection};
        `};

        ${backgroundColour && css`
            background-color: ${backgroundColour};
        `};

        ${justifyContent && css`
            justify-content: ${justifyContent};
        `};

        ${position && css`
            position: ${position};
        `};

        ${height && css`
            height: ${height};
        `};

        ${alignItems && css`
            align-items: ${alignItems};
        `};
    `;

    if (linkURL) {
        return (
            <LinkTo to={linkURL} onClick={onClick} css={style} className={className}>
                {children}
            </LinkTo>
        )
    }

    return (
        <div css={style} className={className} {...onClick !== NOOP && { onClick }}>
            {children}
        </div>
    );
}

export default ContentSection;
