import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    addressPosition: "left" | "right",
};

const AddressWrapper: React.FC<Props> = ({
    children,
    addressPosition,
}) => {
    const theme = useTheme();

    const style = css`
        ${addressPosition === "right" && css`
            padding-left: 30px;
        `}
        width: 50%;

        ${addressPosition === "left" && css`
            padding-right: 30px;
            border-right: 1px solid ${theme.colours.silver};
        `}

        ${theme.breakpoints.down("xs") } {
            padding: 0;
            width: 100%;
            border-right: unset;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default AddressWrapper;
