export const DEFAULT_EVENT_SUITABILITY = { value: "", label: "All schools" };
export const DEFAULT_EVENT_GROUP = { value: "", label: "All event types" };
export const DEFAULT_EVENT_CITY = { value: "", label: "All cities" };
export const DEFAULT_EVENT_REGION = { value: "", label: "All regions" };
export const DEFAULT_EVENT_MONTH = { value: "", label: "All months" };

export const DEFAULT_SUCCESS_SCHOOL_TYPE = { value: "", label: "All schools"}
export const DEFAULT_SUCCESS_COUNTRY_CODE = { value: "", label: "All countries"}

export const DEFAULT_CAREER_CITY_CODE = { value: "", label: "All cities"}
