import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isOddOrder: boolean,
    containerRef: any,
};

const MobileViewDescriptionContainer: React.FC<Props> = ({
    children,
    isOddOrder,
    containerRef,
}) => {
    const theme: Theme = useTheme();

    const mobileViewDescriptionContainerStyle = css`
        height: 50%;
        position: relative;

        // Gray horizontal line for mobile view
        :before {
            position: absolute;
            content: '';
            border: 1px solid ${theme.colours.doveGray2};
            width: 100%;
        }

        // Gray ball point at the end of horizontal line for mobile view
        :after {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: ${theme.colours.doveGray2};
            top: -3px;
            
            ${!isOddOrder && css`
                right: -3px;
            `};
        }
    `;

    return (
        <div css={mobileViewDescriptionContainerStyle} ref={containerRef}>
            {children}
        </div>
    )
}

export default MobileViewDescriptionContainer;
