import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    colour?: string,
};

const PriceNumber: React.FC<Props> = ({ children, colour }) => {
    const theme: Theme = useTheme();

    const priceStyle = css`
        display: flex;
        align-items: center;
        font-size: 91px;
        height: 100%;
        font-weight: ${theme.fonts.weights.bold};
        margin-left: 12px;
        
        ${colour && css`
            color: ${colour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 138px;
            margin-left: 18px;
        }

        ${theme.breakpoints.only("md")} {
            font-size: 67px;
            margin-left: 8px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 77px;
            margin-left: 6px;
        }
    `;

    return (
        <div css={priceStyle}>{children === 0 ? '' : children}</div>

    );
}

export default PriceNumber;
