import React from "react";
import { css } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode,
    topBannerHeight: number,
};

const Wrapper: React.FC<Props> = ({ children, topBannerHeight }) => {
    const wrapperStyle = css`
        position: relative;
        align-self: center;
        height: ${topBannerHeight}px;
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMaxWidth={false} useMinHeight={false}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
