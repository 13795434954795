import React from "react";
import { css, useTheme } from "@emotion/react";

import { DROPDOWN_TRANSITION_TIME } from "core/constants";

type Props = {
    inputRef: any,
    handleInputClick: () => void,
    children: React.ReactNode,
    backgroundColour?: string,
    showMenu: boolean,
    optionBackgroundColour: string,
    zIndex: number,
};

const DropdownButtonPlaceholderContainer: React.FC<Props> = ({
    inputRef,
    handleInputClick,
    children,
    backgroundColour,
    showMenu,
    optionBackgroundColour,
    zIndex,
}) => {
    const theme = useTheme();

    const dropdownInput = css`
        position: relative;
        z-index: ${zIndex};
        padding: 5px 13px;
        border-radius: 8px;
        background-color: ${backgroundColour || theme.colours.white};
        transition: all ${DROPDOWN_TRANSITION_TIME} ease-in-out;

        ${showMenu && css`
            box-shadow: inset 0 0 0 2px ${optionBackgroundColour};
        `};
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        cursor: pointer;
        height: 50px;
        
        ${theme.breakpoints.up("5xl")} {
            height: 54px;
        }
        
        ${theme.breakpoints.only("xl")} {
            height: 46px;
        }

        ${theme.breakpoints.down("lg")} {
            height: 40px;
        }
    `;

    return (
        <div ref={inputRef} onClick={handleInputClick} css={dropdownInput}>
            {children}
        </div>
    );
}

export default DropdownButtonPlaceholderContainer;
