import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as Chevron } from 'images/icons/rounded-chevron.svg';
import { ReactComponent as ChevronWithLine } from 'images/icons/chevron-with-line.svg';

type Props = {
    onClickArrow: (arrowDirection: 'maxPrev' | 'prev' | 'next' | 'maxNext') => void,
    value: number | string,
    numberOfPages: number,
    onOutsideClick: () => void,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
}

const Pagination: React.FC<Props> = ({
    onClickArrow,
    value,
    numberOfPages,
    onOutsideClick,
    onChange,
    className = ''
}) => {
    const theme: Theme = useTheme();
    const containerStyle = css`
        display: flex;
        align-items: center;
        color: ${theme.colours.gray};
        font-size: 20px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */

        input[type=number] {
            -moz-appearance: textfield;
        }
    `;

    const chevronStyle = ({ degrees = 0, marginRight = 20 }: { degrees?: number, marginRight?: number } = {})  => css`
        width: 13px;
        height: 13px;
        margin-right: ${marginRight}px;
        cursor: pointer;

        ${degrees && css`
            transform: rotate(${degrees}deg);
        `}
    `;

    const chevronContainerStyle = (isDisabled: boolean) => css`
        display: flex;
        align-items: center;
        ${isDisabled && css`
            pointer-events: none;
            opacity: 0.5;
        `}
    `;

    const inputStyle = css`
        width: 29px;
        height: 29px;
        text-align: center;
        border: 1px solid ${theme.colours.tropicalBlue};
        border-radius: ${theme.borderAndShadow.smallRadius};
        font-size: 20px;
        font-weight: ${theme.fonts.weights.normal};
        color: ${theme.colours.gray};
    `;

    const ofStyle = css`
        margin: 0 27px;
    `;

    const numberOfPagesStyle = css`
        margin-right: 37px;
    `;

    return (
        <div css={containerStyle} className={className}>
            <div css={chevronContainerStyle(value === 1)}>
                <ChevronWithLine
                    css={chevronStyle({
                        degrees: 180
                    })}
                    onClick={() => onClickArrow('maxPrev')}
                />
            </div>
            <div css={chevronContainerStyle(value === 1)}>
                <Chevron
                    css={chevronStyle({
                        degrees: -90,
                        marginRight: 28,
                    })}
                    onClick={() => onClickArrow('prev')}
                />
            </div>
            <OutsideClickHandler onOutsideClick={onOutsideClick}>
                <div css={containerStyle}>
                    <input
                        value={value}
                        maxLength={4}
                        onChange={onChange}
                        type='number'
                        max={numberOfPages}
                        min={1}
                        css={inputStyle}
                    />
                    <div css={ofStyle}>
                        of
                    </div>
                    <div css={numberOfPagesStyle}>
                        {numberOfPages}
                    </div>
                </div>
            </OutsideClickHandler>
            <div css={chevronContainerStyle(value === numberOfPages)}>
                <Chevron
                    css={chevronStyle({
                        degrees: 90
                    })}
                    onClick={() => onClickArrow('next')}
                />
            </div>
            <div css={chevronContainerStyle(value === numberOfPages)}>
                <ChevronWithLine
                    css={chevronStyle({
                        marginRight: 0
                    })}
                    onClick={() => onClickArrow('maxNext')}
                />
            </div>
        </div>
    );
}

export default Pagination;