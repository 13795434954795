import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
};

const SubText: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        color: ${theme.colours.tundora2};
        opacity: 0.84;
    `;

    return (
        <div css={style}>
            <ContentCombine Tag={"p"} fontSizeType={"9"}>{children}</ContentCombine>
        </div>
    );
};

export default SubText;