import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    data: ContentWithImage
};

const Header: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const colourTheme = JSON.parse(data.theme);
    const { headerColour } = colourTheme;
    const layout = JSON.parse(data.layout);
    const { useShadow, isBiggerImage, isHalfFullImage } = layout;
    const imageSize = isHalfFullImage ? 'halfFull' : isBiggerImage ? 'large' : 'small';

    const headerStyle = css`
        white-space: break-spaces;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};
        margin-bottom: 40px;
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 33px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 22px;

            ${useShadow && css`
                font-size: 62px;
                line-height: 75px;
                margin-bottom: 17px;
            `};
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 22px;

            ${(useShadow || imageSize === "small") && css`
                margin-bottom: 12px;
            `};
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 26px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 24px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 19px;

            ${useShadow && css`
                margin-bottom: 7px;
            `};
        }
    `;


    return (
        <Title css={headerStyle} Tag={'h2'}>
            {data.header}
        </Title>
    );
}

export default Header;
