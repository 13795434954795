import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ButtonContainer: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        justify-content: center;
        align-self: flex-start;
        width: fit-content;
        height: fit-content;
        gap: 13px;
        margin-top: 40px;

        ${theme.breakpoints.up("5xl")} {
            margin-top: 42px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-top: 29px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-top: 29px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-top: 32px;
        }

        ${theme.breakpoints.only("md")} {
            gap: 12px;
            margin-top: 36px;
        }

        ${theme.breakpoints.down("sm")} {
            gap: 12px;
            margin-top: 21px;
        }
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default ButtonContainer;
