import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    data?: any,
    gradientColours?: { fromColour: string, toColour: string },
    backgroundImage?: string,
    className?: string,
    overflowHidden?: boolean;
};

const SectionContainer: React.FC<Props> = ({
    children,
    data,
    gradientColours,
    backgroundImage,
    className,
    overflowHidden,
}) => {
    const theme: Theme = useTheme();
    const colourTheme = data?.theme ? JSON.parse(data.theme.toLowerCase()) : null;
    const backgroundColour = data?.backgroundColour ?? colourTheme?.backgroundcolour;

    const style = css`
        width: 100%;
        min-width: ${theme.breakpoints.sizes.xs}px;
        display: flex;
        justify-content: center;
        position: relative;
        
        ${overflowHidden && css`
            overflow: hidden;
        `};

        ${gradientColours && css`
            background: transparent linear-gradient(
                360deg,
                ${(gradientColours.fromColour)} 0%,
                ${(gradientColours.toColour)} 100%
            ) 0% 0% no-repeat padding-box;
        `};

        ${(!gradientColours && backgroundColour) && css`
            background-color: ${backgroundColour};
        `};

        ${backgroundImage && css`
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                background: url(${backgroundImage}) repeat, linear-gradient(
                    ${theme.colours.quartz},
                    ${theme.colours.athensGray}
                ) repeat-x;
                opacity: 0.6;
                background-size: 120%;

                ${theme.breakpoints.down("md")} {
                    background-size: 230%;
                }
            }
        `}
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default SectionContainer;
