import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
    isUniqueDropdown?: boolean
};

const DropdownContainer: React.FC<Props> = ({children, className, isUniqueDropdown = false}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: end;

        > div:not(:last-child) {
            ${theme.breakpoints.up("5xl")} {
                margin-right: 18px;
            }
            
            ${theme.breakpoints.between("xxl", "4xl")} {
                margin-right: 10px;
            }
            
            ${theme.breakpoints.between("lg", "xl")} {
                margin-right: 7px;
            }

            ${theme.breakpoints.down("md")} {
                margin-right: 14px;
            }
        }

        ${theme.breakpoints.down("md")} {
            ${isUniqueDropdown ? css`
                width: 100%;
            ` : css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                place-content: space-between;
                gap: 14px 16px;
            `}

            > div:not(:last-child) {
                margin-right: 9px;
            }
        }

        ${theme.breakpoints.only("md")} {
            padding-top: 44px;
        }

        ${theme.breakpoints.only("sm")} {
            padding-top: 24px;
        }
        
        ${theme.breakpoints.down("xs")} {
            padding-top: 17px;

            > div:not(:last-child) {
                margin-right: 0;
            }
        }
    `;

    return (
        <div css={style} className={className}>{children}</div>
    );
};

export default DropdownContainer;
