import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void,
};

const OrderButton: React.FC<Props> = ({ children, onClick }) => {
    const theme: Theme = useTheme();
    const linkURL = "";

    const buttonTextColour = 'white';
    const buttonColour = theme.colours.curiousBlue;

    const style = css`
        justify-content: flex-end;
    `;

    const buttonTextCustomStyle = css`
        font-size: 20px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
        }
    `;

    return (
        <RoundButton
            css={style}
            backgroundColour={buttonColour}
            textColour={buttonTextColour}
            linkURL={linkURL}
            buttonTextCustomStyle={buttonTextCustomStyle}
            onButtonClick={onClick}
        >
            {children}
        </RoundButton>
    );
}

export default OrderButton;
