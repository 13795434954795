import React from "react";
import Image from "core/Components/Image";

type Props = {
    image: ProductItemImage,
};

const ProductImage: React.FC<Props> = ({ image }) => (
    <Image
        image={image.image}
        useFocusPoint={image.useFocusPoint}
    />
);

export default ProductImage;

