import React from "react";
import { css, useTheme } from "@emotion/react";
import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    children: React.ReactNode,
    linkURL?: string,
    buttonType: "text" | "video",
    buttonTextColour?: string,
    buttonColour: string,
    buttonHoverColour?: string,
    buttonWidth?: number,
    onVideoButtonClick?: (e: React.MouseEvent) => void,
    isSecondButton?: boolean,
    className?: string,
    buttonLineColour?: string,
};

const ButtonWrapper: React.FC<Props> = ({
    children,
    linkURL,
    buttonType,
    buttonTextColour,
    buttonColour,
    buttonWidth,
    onVideoButtonClick,
    isSecondButton,
    className = '',
    buttonHoverColour = '',
    buttonLineColour = '',
}) => {
    const theme = useTheme();

    const style = css`
        ${theme.breakpoints.up("5xl")} {
            font-size: 25px;
            line-height: 26px;
        }

        ${theme.breakpoints.between("md", "4xl")} {
            font-size: 20px;
            line-height: 21px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 16px;
            line-height: 21px;
        }
    `;

    const watchVideoButtonStyle = css`
        ${buttonType === "video" && buttonWidth && css`
            position: absolute;
            // For the case of the first button using "withArrow" prop, left state.buttonWidth code
            // instead of relative position & margin-left simply.
            // If the first button uses "withArrow" prop,
            // every hovering on the first button will make this component move with relative position & margin-left.
            left: ${buttonWidth + 60}px;
        `}
    `;

    return (
        buttonType === "text" ? (
            <RoundButton
                backgroundColour={isSecondButton ? theme.colours.cerulean : buttonColour}
                textColour={isSecondButton ? buttonColour : buttonTextColour}
                linkURL={linkURL}
                buttonTextCustomStyle={style}
                isTransparentButton={!!isSecondButton}
                className={className}
                buttonHoverColour={buttonHoverColour}
                borderColour={buttonLineColour}
            >
                {children}
            </RoundButton>
        ) : (
            <RoundButton
                backgroundColour={buttonColour}
                textColour={theme.colours.white}
                css={watchVideoButtonStyle}
                onButtonClick={onVideoButtonClick}
                buttonTextCustomStyle={style}
                className={className}
                buttonHoverColour={buttonHoverColour}
                borderColour={buttonLineColour}
            >
                {children}
            </RoundButton>
        )
    );
}

export default ButtonWrapper;
