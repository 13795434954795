import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode
};

const ButtonsContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const buttonContainerStyle = css`
        position: relative;
        width: fit-content;
        height: fit-content;
        align-self: flex-start;
        gap: 13px;
        
        ${theme.breakpoints.down("sm")} {
            flex-direction: column;
        }
    `;

    return (
        <ContentSection css={buttonContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default ButtonsContainer;
