import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

type Props = {
    children: React.ReactNode
};

const HeaderAndButtonsContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const headerAndButtonsContainer = css`
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 100%;
        justify-content: center;

        ${theme.breakpoints.down("md")} {
            height: 50%;
            bottom: 0;
        }
    `;

    return (
        <div css={headerAndButtonsContainer}>
            {children}
        </div>
    );
}

export default HeaderAndButtonsContainer;
