import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const ColouredContainer: React.FC<Props> = ({
    children,
}) => {
    const colouredContainerStyle = css`
        position: relative;
        border-radius: 10px 10px 0 0;
        width: 100%;
    `;

    return (
        <div css={colouredContainerStyle}>
            {children}
        </div>
    );
}

export default ColouredContainer;
