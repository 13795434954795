import React from "react";

import useBreakpoint from "core/hooks/useBreakpoint";
import CardTextContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardTextContainer";
import CardHeader from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/CardHeader";
import HalfListContainer from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/HalfListContainer";
import SectionContainer from "core/Components/SectionContainer";
import ListItem from "elements/Components/ContentWithImageVerticalCards/compopnents/Card/components/ListItem/ListItem";

type Props = {
    card: ImageVerticalCard,
    setHubspotFormId: (id: string) => void,
    setHubspotFormTitle: (title: string) => void,
    onShowPopupClick: () => void,
};

const CardWithList: React.FC<Props> = ({
    card,
    setHubspotFormId,
    setHubspotFormTitle,
    onShowPopupClick,
}) => {
    const { maxWidth, minWidth } = useBreakpoint();

    const isRowDirectionListCard = minWidth >= 568 && maxWidth < 1024;

    if (isRowDirectionListCard) {
        return (
            <CardTextContainer card={card} isRowDirectionListCard={isRowDirectionListCard}>
                <CardHeader card={card} />

                <SectionContainer>
                    <HalfListContainer>
                        {card.contentList
                            .filter((contentListItem, index) => (index < 4))
                            .map((contentListItem) => (
                                <ListItem
                                    key={contentListItem.id}
                                    contentListItem={contentListItem}
                                    card={card}
                                    setHubspotFormId={setHubspotFormId}
                                    setHubspotFormTitle={setHubspotFormTitle}
                                    onShowPopupClick={onShowPopupClick}
                                />
                            ))}
                    </HalfListContainer>

                    <HalfListContainer isRightSide>
                        {card.contentList
                            .filter((contentListItem, index) => (index >= 4))
                            .map((contentListItem) => (
                                <ListItem
                                    key={contentListItem.id}
                                    contentListItem={contentListItem}
                                    card={card}
                                    setHubspotFormId={setHubspotFormId}
                                    setHubspotFormTitle={setHubspotFormTitle}
                                    onShowPopupClick={onShowPopupClick}
                                />
                            ))}
                    </HalfListContainer>
                </SectionContainer>
            </CardTextContainer>
        );
    }

    return (
        <CardTextContainer card={card}>
            <CardHeader card={card} />

            {card.contentList.map((contentListItem) => (
                <ListItem
                    key={contentListItem.id}
                    contentListItem={contentListItem}
                    card={card}
                    setHubspotFormId={setHubspotFormId}
                    setHubspotFormTitle={setHubspotFormTitle}
                    onShowPopupClick={onShowPopupClick}
                />
            ))}
        </CardTextContainer>
    );
}

export default CardWithList;
