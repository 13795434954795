import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { Theme, useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";
import { sort } from "core/includes/sort";
import SectionContainer from "core/Components/SectionContainer";

import Wrapper from "elements/Components/TopBanner/components/Wrapper";
import ImageCarousel from "elements/Components/TopBanner/components/ImageCarousel";
import BannerImage from "elements/Components/TopBanner/components/BannerImage";
import ButtonsContainer from "elements/Components/TopBanner/components/ButtonsContainer";
import FirstButton from "elements/Components/TopBanner/components/FirstButton";
import SecondButton from "elements/Components/TopBanner/components/SecondButton";
import ImageCarouselContainer from "elements/Components/TopBanner/components/ImageCarouselContainer";

type Props = {
    data: TopBanner
};

const TopBanner: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const topBannerImages = sort(data.imageList);
    const { minWidth } = useBreakpoint();

    const [topBannerHeight, buttonHeight, buttonSidePadding] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return [888, 54, 31];
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return [619, 54, 31];
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return [560, 42, 22];
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return [489, 42, 22];
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return [490, 42, 22];
            case minWidth >= theme.breakpoints.sizes["md"]:
                return [600, 42, 22];
            default:
                return [385, 42, 22];
        }
    }, [minWidth, theme.breakpoints.sizes]);

    return isEmpty(topBannerImages) ? null : (
        <SectionContainer overflowHidden>
            <Wrapper topBannerHeight={topBannerHeight}>
                <ImageCarouselContainer>
                    <ImageCarousel topBannerHeight={topBannerHeight} bannerCount={topBannerImages.length}>
                        {topBannerImages.map((item: TopBannerImage) => (
                            <BannerImage
                                key={`tb-${item.id}`}
                                topBannerHeight={topBannerHeight}
                                topBannerImage={item}
                            />
                        ))}
                    </ImageCarousel>
                </ImageCarouselContainer>
                <ButtonsContainer>
                    <FirstButton data={data} buttonHeight={buttonHeight} buttonSidePadding={buttonSidePadding} />
                    <SecondButton data={data} buttonHeight={buttonHeight} buttonSidePadding={buttonSidePadding} />
                </ButtonsContainer>
            </Wrapper>
        </SectionContainer>
    );
}

export default TopBanner;
