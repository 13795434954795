import React, { useRef } from "react";
import { css } from "@emotion/react";

import useElementDimensions from "core/hooks/useElementDimensions";
import ContentSection from "core/Components/ContentSection";
import MainImage from "elements/Components/Timeline/components/TimelineItem/components/MainImage";
import Avatar from "elements/Components/Timeline/components/TimelineItem/components/Avatar";
import VerticalLineAndTextContainer from "elements/Components/Timeline/components/TimelineItem/components/VerticalLineAndTextContainer";
import Description from "elements/Components/Timeline/components/TimelineItem/components/Description";
import Number from "elements/Components/Timeline/components/TimelineItem/components/Number"
import HalfContainer from "elements/Components/Timeline/components/TimelineItem/components/HalfContainer";
import QuarterContainer from "elements/Components/Timeline/components/TimelineItem/components/QuarterContainer";
import TopBorderedHalfContainer from "elements/Components/Timeline/components/TimelineItem/components/TopBorderedHalfContainer";
import TimelineCircle from "elements/Components/Timeline/components/TimelineItem/components/TimelineCircle";

type Props = {
    timelineItem: TimelineItem,
    index: number,
    circleSize: number,
    avatarWidth: number,
    avatarHeight: number,
    roundImageSize: number,
    squareImageWidth: number,
    squareImageHeight: number,
    sizeGap: number,
    isLargeTitle: boolean,
};

const TimelineItem: React.FC<Props> = ({
    timelineItem,
    index,
    circleSize,
    avatarWidth,
    avatarHeight,
    roundImageSize,
    squareImageWidth,
    squareImageHeight,
    sizeGap,
    isLargeTitle,
}) => {
    const { theme, description, mainImage, avatarImage, milestoneYear, useNumbering, title } = timelineItem;
    const colourTheme = JSON.parse(theme);
    const { colour } = colourTheme;
    const descContainerRef = useRef<any>();
    const descriptionHeight = useElementDimensions(descContainerRef.current).height;
    const isOddOrder = (index + 1) % 2;

    const imageContainerStyle = css`
        width: 100%;
        position: relative;
    `;

    return isOddOrder ? (
        <>
            <HalfContainer index={index} descriptionHeight={descriptionHeight} circleSize={circleSize}>
                <QuarterContainer>
                    <Number
                        index={index}
                        colour={colour}
                        circleSize={circleSize}
                        title={title}
                        useNumbering={useNumbering}
                    />
                </QuarterContainer>
                <QuarterContainer>
                    <VerticalLineAndTextContainer index={index} containerRef={descContainerRef} circleSize={circleSize}>
                        <Description index={index}>
                            {description}
                        </Description>
                    </VerticalLineAndTextContainer>
                </QuarterContainer>
            </HalfContainer>

            <TopBorderedHalfContainer>
                <ContentSection css={imageContainerStyle}>
                    <MainImage
                        index={index}
                        src={mainImage?.URL}
                        roundImageSize={roundImageSize}
                        squareImageWidth={squareImageWidth}
                        squareImageHeight={squareImageHeight}
                        sizeGap={sizeGap}
                    />
                </ContentSection>
                {avatarImage?.URL && (
                    <Avatar
                        index={index}
                        src={avatarImage?.URL}
                        sizeGap={sizeGap}
                        roundImageSize={roundImageSize}
                        squareImageWidth={squareImageWidth}
                        avatarWidth={avatarWidth}
                        avatarHeight={avatarHeight}
                    />
                )}
            </TopBorderedHalfContainer>

            <TimelineCircle
                milestoneYear={milestoneYear}
                colour={colour}
                circleSize={circleSize}
                isLargeTitle={isLargeTitle}
            />
        </>
    ) : (
        <>
            <HalfContainer index={index} descriptionHeight={descriptionHeight} circleSize={circleSize}>
                <ContentSection css={imageContainerStyle}>
                    <MainImage
                        index={index}
                        src={mainImage.URL}
                        roundImageSize={roundImageSize}
                        squareImageWidth={squareImageWidth}
                        squareImageHeight={squareImageHeight}
                        sizeGap={sizeGap}
                    />
                </ContentSection>
                {avatarImage?.URL && (
                    <Avatar
                        index={index}
                        src={avatarImage.URL}
                        sizeGap={sizeGap}
                        roundImageSize={roundImageSize}
                        squareImageWidth={squareImageWidth}
                        avatarWidth={avatarWidth}
                        avatarHeight={avatarHeight}
                    />
                )}
            </HalfContainer>

            <TopBorderedHalfContainer>
                <QuarterContainer>
                    <Number
                        index={index}
                        colour={colour}
                        circleSize={circleSize}
                        title={title}
                        useNumbering={useNumbering}
                    />
                </QuarterContainer>
                <QuarterContainer>
                    <VerticalLineAndTextContainer index={index} containerRef={descContainerRef} circleSize={circleSize}>
                        <Description index={index}>
                            {description}
                        </Description>
                    </VerticalLineAndTextContainer>
                </QuarterContainer>
            </TopBorderedHalfContainer>

            <TimelineCircle
                milestoneYear={milestoneYear}
                colour={colour}
                circleSize={circleSize}
                isLargeTitle={isLargeTitle}
            />
        </>
    );
}

export default TimelineItem;
