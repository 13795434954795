import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: DescriptionWithRichText
};

const Text: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { textColour } = colourTheme;

    const textStyle = css`
        align-self: flex-start;
        width: 97%;
        font-weight: ${theme.fonts.weights.light};

        ${textColour && css`
            color: ${textColour};
        `}
    `;

    return (
        <ContentCombine Tag={"p"} fontSizeType={"9"} css={textStyle}>{data.text}</ContentCombine>
    );
};

export default Text;
