import React from "react";
import { isEmpty } from "lodash";

import { sort } from "core/includes/sort";
import SectionContainer from "core/Components/SectionContainer";

import Wrapper from "elements/Components/ContentWithImageHorizontalCards/components/Wrapper";
import ImageHorizontalCard from "elements/Components/ContentWithImageHorizontalCards/components/ImageHorizontalCard/ImageHorizontalCard";

type Props = {
    data: ContentWithImageHorizontalCards
};

const ContentWithImageHorizontalCards: React.FC<Props> = ({ data }) => {
    const imageCardList = sort(data.imageCardList);

    return isEmpty(imageCardList) ? null : (
        <SectionContainer>
            <Wrapper>
                {imageCardList.map((imageCard: ImageHorizontalCard, index: number) => (
                    <ImageHorizontalCard key={imageCard.id} index={index} imageHorizontalCard={imageCard} />
                ))}
            </Wrapper>
        </SectionContainer>
    );
}

export default ContentWithImageHorizontalCards;
