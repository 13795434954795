import React from "react";
import { isEmpty } from "lodash";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";
import Image from "core/Components/Image";
import SectionContainer from "core/Components/SectionContainer";
import useSetState from "core/hooks/useSetState";
import FormModal from "core/Components/FormModal";
import MainHeading from "core/Components/Item/MainHeading";
import HubspotForm from "core/Components/HubspotForm";

import Wrapper from "elements/Components/ContentWithList/components/Wrapper";
import ShadowedContainer from "elements/Components/ContentWithList/components/ShadowedContainer";
import ListContainer from "elements/Components/ContentWithList/components/ListContainer";
import TextContentContainer from "elements/Components/ContentWithList/components/TextContentContainer";
import Header from "elements/Components/ContentWithList/components/Header";
import ListItemHeader from "elements/Components/ContentWithList/components/ListItemHeader";
import Icon from "elements/Components/ContentWithList/components/Icon";

type Props = {
    data: ContentWithList
};

type DefaultState = {
    showingPopup: boolean,
    hubspotFormId: string,
    hubspotFormtitle: string,
}

const INITIAL_STATE = {
    showingPopup: false,
    hubspotFormId: "",
    hubspotFormtitle: "",
}

const ContentWithList: React.FC<Props> = ({ data }) => {
    const colourTheme = JSON.parse(data.theme);
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const {
        listTextColour,
        fromColour,
        toColour,
    } = colourTheme;
    const isImageFirst = data.isImageFirst;

    const onShowPopupClick = () => setState({ showingPopup: true });
    const onClosePopupClick = () => setState({ showingPopup: false });
    const setHubspotFormId = (id: string) => setState({ hubspotFormId: id });
    const setHubspotFormtitle = (title: string) => setState({ hubspotFormtitle: title });

    const theme: Theme = useTheme();
    const textContentContainerStyle = css`
        > div:not(:last-of-type) {
            margin-bottom: 20px;

            ${theme.breakpoints.up('5xl')} {
                margin-bottom: 45px
            }
        
            ${theme.breakpoints.down('xxl')} {
                margin-bottom: 25px
            }
        }
    `;

    return isEmpty(data.contentList) ? null : (
        <SectionContainer>
            <Wrapper data={data}>
                <ShadowedContainer isImageFirst={isImageFirst} fromColour={fromColour} toColour={toColour}>
                    <TextContentContainer>
                        <Header data={data} />

                        <div css={textContentContainerStyle}>
                            {data.contentList.map((contentListItem) => {
                                const hubspotFormId = contentListItem.hubspotFormId;
                                const setHubspot = () => {
                                    setHubspotFormId(hubspotFormId);
                                    setHubspotFormtitle(contentListItem.header);
                                    onShowPopupClick();
                                };

                                return (
                                    <ListContainer key={contentListItem.id}>
                                        <ListItemHeader
                                            listTextColour={listTextColour}
                                        >
                                            {contentListItem.header}
                                        </ListItemHeader>
                                        <Icon
                                            data={data}
                                            contentListItem={contentListItem}
                                            {...hubspotFormId && { onClick: setHubspot }}
                                        />
                                    </ListContainer>
                                )
                            })}
                        </div>
                    </TextContentContainer>

                    <ContentSection>
                        <Image image={data.image} useFocusPoint={data.useFocusPoint} />
                    </ContentSection>
                </ShadowedContainer>
            </Wrapper>

            {(state.hubspotFormId) && (
                <FormModal isVisible={state.showingPopup} onClose={onClosePopupClick}>
                    <MainHeading text={state.hubspotFormtitle} />
                    <HubspotForm hubspotFormId={state.hubspotFormId} />
                </FormModal>
            )}
        </SectionContainer>
    );
}

export default ContentWithList;
