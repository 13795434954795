import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    text: string | undefined,
    background: string,
    textColour?: string,
};

const Ribbon: React.FC<Props> = ({
    text,
    background,
    textColour = 'white',
}) => {
    const theme = useTheme();
    const replacedText = text ? text?.replace(" ", "\n") : "";

    const style = css`
        position: absolute;
        clip-path: inset(0 -100%);
        color: ${textColour};
        font-weight: ${theme.fonts.weights.bold};
        box-shadow: 0 0 0 999px ${background};
        z-index: ${theme.zIndex.zIndexOne};
        transform-origin: 80% 0;
        background: ${background};

        ${theme.breakpoints.up("5xl")} {
            font-size: 22px;
            line-height: 50px;
            inset: 10px auto auto 15px;
            transform: translate(-30.3%) rotate(-40deg);
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 20px;
            line-height: 40px;
            padding: 3px;
            inset: 8px auto auto 5px;
            transform: translate(-25.3%) rotate(-40deg);
        }

        ${theme.breakpoints.between("lg", "xl")} {
            font-size: 17px;
            line-height: 32px;
            padding: 3px;
            inset: 8px auto auto 5px;
            transform: translate(-25.3%) rotate(-40deg);
        }

        ${theme.breakpoints.down("md")} {
            font-size: 13px;
            line-height: 25px;
            padding: 3px;
            inset: 8px auto auto 6px;
            transform: translate(-20.3%) rotate(-40deg);
        }
     `;

    return (
        <div css={style} className={'ribbon'}>
            {replacedText}
        </div>
    );
};

export default Ribbon;
