import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    className?: string,
};

const Wrapper: React.FC<Props> = ({
    children,
    className,
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        ${theme.breakpoints.up("5xl")} {
            width: ${theme.breakpoints.sizes['5xl']}px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: ${theme.breakpoints.sizes["3xl"]}px;
        }

        ${theme.breakpoints.only("xxl")} {
            width: ${theme.breakpoints.sizes.xxl}px;
        }

        ${theme.breakpoints.only("xl")} {
            width: ${theme.breakpoints.sizes.xl}px;
        }

        ${theme.breakpoints.only("lg")} {
            width: ${theme.breakpoints.sizes.lg}px;
        }

        ${theme.breakpoints.only("md")} {
            width: ${theme.breakpoints.sizes.md}px;
        }

        ${theme.breakpoints.only("sm")} {
            width: ${theme.breakpoints.sizes.sm}px;
        }

        ${theme.breakpoints.down("xs")} {
            width: ${theme.breakpoints.sizes.xs}px;
        }
            
        ${theme.breakpoints.up("5xl")} {
            min-height: 600px;
            margin: 68px 207px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            min-height: 600px;
            margin: 40px 80px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            min-height: 600px;
            margin: 30px 45px;
        }

        ${theme.breakpoints.only("md")} {
            margin: 37px 45px;
        }
        
        ${theme.breakpoints.only("sm")} {
            margin: 75px 45px;
        }

        ${theme.breakpoints.down("xs")} {
            margin: 24px 20px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default Wrapper;
