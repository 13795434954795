import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: DescriptionWithRichText
};

const Subheader: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(data.theme);
    const { subheaderColour } = colourTheme;

    const subheaderStyle = css`
        align-self: center;
        min-height: unset;
        text-align: center;
        margin-bottom: 31px;

        ${subheaderColour && css`
            color: ${subheaderColour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            margin-bottom: 45px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin-bottom: 23px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-bottom: 36px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-bottom: 36px;
        }

        ${theme.breakpoints.only("md")} {
            margin-bottom: 33px;
        }

        ${theme.breakpoints.down("sm")} {
            margin-bottom: 25px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 15px;
        }
    `;

    return (
        <ContentCombine Tag={'p'} fontSizeType={'5'} css={subheaderStyle}>{data.subheader}</ContentCombine>
    );
};

export default Subheader;
