import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const ItemWrapper: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
        ${theme.breakpoints.down('md')} {
            flex-direction: column-reverse;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};


export default ItemWrapper;