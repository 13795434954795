import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Title from "core/Components/Title";

type Props = {
    children: React.ReactNode,
};

const ModalHeader: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const headerColour = theme.colours.curiousBlue;

    const headerStyle = css`
        white-space: break-spaces;
        color: ${headerColour};
        font-weight: ${theme.fonts.weights.bold};
        font-size: 43px;
        line-height: 48px;
        margin-bottom: 21px;
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 52px;
            line-height: 54px;
            margin-bottom: 23px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 19px;
        }
    `;


    return (
        <Title css={headerStyle}>
            {children}
        </Title>
    );
}

export default ModalHeader;
