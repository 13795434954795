import React, { useCallback, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { isEmpty, isEqual } from "lodash";

import BannerForAdvertisement from "core/Components/BannerForAdvertisement/BannerForAdvertisement";
import DropdownButton from "core/Components/Buttons/DropdownButton/DropdownButton";
import NoData from "core/Components/Errors/NoData";
import ItemBoxContainer from "core/Components/ItemWithRibbon/ItemBoxContainer";
import ItemContainer from "core/Components/ItemWithRibbon/ItemContainer";
import ItemListContainer from "core/Components/ItemWithRibbon/ItemListContainer";
import ItemListWrapper from "core/Components/ItemWithRibbon/ItemListWrapper";
import SectionContainer from "core/Components/SectionContainer";
import Loading from "core/Components/Utils/Loading";
import Wrapper from "core/Components/Wrapper";
import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { getCanRenderWebp } from "core/includes/image";
import { careerCommonQuery, careerListQuery } from "core/includes/queries";

import useListBreakpoint from "pages/Components/ListPage/hooks/useListBreakpoint";
import DropdownContainer from "pages/Components/ListPage/DropdownContainer";
import HeaderContainer from "pages/Components/ListPage/HeaderContainer";
import HeaderTitle from "pages/Components/ListPage/HeaderTitle";
import IntroText from "pages/Components/ListPage/IntroText";
import PageSection from "pages/Components/ListPage/PageSection";
import { DEFAULT_CAREER_CITY_CODE } from "pages/includes/constants";
import components from "elements/config/components";

const PAGE_TYPE = "career";

type DefaultState = {
    cityCode: Option,
};

const INITIAL_STATE = {
    cityCode: DEFAULT_CAREER_CITY_CODE,
};

type Props = {
    data: CareerListPage
};

const CareerListPage: React.FC<Props> = ({ data: page }) => {
    const theme = useTheme();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const { bannerHeight, itemBoxWidth } = useListBreakpoint();
    const { minWidth } = useBreakpoint();

    const canRenderWebp = getCanRenderWebp();

    const { data: commonData } = useQuery(careerCommonQuery, {
        variables: { parentId: page.id },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const { data, loading } = useQuery(careerListQuery, {
        variables: {
            parentId: page.id,
            cityCodeID: state.cityCode.value,
            canRenderWebp,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const setSelectedCityCode = useCallback((item: Option) => {
        setState({ cityCode: item });
    }, [setState]);

    const cityCodeOptions = [DEFAULT_CAREER_CITY_CODE];
    commonData?.cityCode.forEach((item: DropdownNameOption) => {
        cityCodeOptions.push({ value: item.id, label: item.name });
    });

    const wrapperStyle = css`
        flex-direction: column;
        align-items: center;
    `;

    const getImage = useCallback((data: CareerListPage) => {
        if (data && (data.landscape || data.portrait)) {
            if (minWidth >= theme.breakpoints.sizes["lg"]) {
                return data.landscape || data.portrait;
            }

            return data.portrait || data.landscape;
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const image = useMemo(() => {
        // The landscape (or portrait) has the priority rather than commonData banner.
        if (page.landscape || page.portrait) {
            return getImage(page);
        }

        return null;
    }, [getImage, page]);

    const getUseFocusPoint = useCallback((data: CareerListPage) => (
        data ?
            (isEqual(image, data.landscape) && data.useFocusPoint) ||
            (isEqual(image, data.portrait) && data.useFocusPointPortrait)
            : true
    ), [image]);

    const useAFocusPoint = getUseFocusPoint(page);
    const altText = page.altText || '';

    const getCommonImage = useCallback((item: CareerPage, index: number) => {
        if (!item?.previewImage) return index % 2 === 0 ? page.imageA : page.imageB;

        return item.previewImage;
    }, [page]);

    return (
        <>
            {page?.elements && page.elements.map((ele: any, index: number) => {
                if (ele.__typename !== 'CareerListItem') {
                    let Component = components.hasOwnProperty(ele.__typename) && components[ele.__typename]
                    return <Component data={ele} key={`${ele.id}-${index}`} />
                }

                return (
                    <SectionContainer key={`${ele.id}-${index}`} data={{ backgroundColour: theme.colours.alabaster }}>
                        <Wrapper useMaxWidth={false} useMinHeight={false} css={wrapperStyle}>
                            {image && (
                                <BannerForAdvertisement
                                    type={PAGE_TYPE}
                                    bannerTitle={page.bannerHeader}
                                    bannerText={page.bannerSubheader}
                                    mainBannerImage={image}
                                    useMainBannerFocusPoint={useAFocusPoint}
                                    bannerHeight={bannerHeight}
                                    showButton={false}
                                    customAltText={altText}
                                />
                            )}

                            <IntroText data={page} />

                            <PageSection>
                                <HeaderContainer type={PAGE_TYPE}>
                                    <HeaderTitle type={PAGE_TYPE}>{page.altTitle || page.title}</HeaderTitle>
                                    <DropdownContainer isUniqueDropdown>
                                        <DropdownButton
                                            options={cityCodeOptions}
                                            onChange={setSelectedCityCode}
                                            selectedValue={[state.cityCode]}
                                        />
                                    </DropdownContainer>
                                </HeaderContainer>

                                <ItemListContainer isMiddle={!data?.careerList}>
                                    {loading ? (
                                        <Loading onTop fitToScreen overlay overlayColour={"white"} delay={500} />
                                    ) : (
                                        isEmpty(data?.careerList) ? (
                                            <NoData />
                                        ) : (
                                            <ItemListWrapper itemBoxWidth={itemBoxWidth}>
                                                {data?.careerList && data.careerList.map((item: CareerPage, index: number) => (
                                                    <ItemContainer key={`ss-thumbnail-${index}`} itemBoxWidth={itemBoxWidth}>
                                                        <ItemBoxContainer
                                                            type={PAGE_TYPE}
                                                            data={item}
                                                            commonImage={getCommonImage(item, index)}
                                                            ribbonText={item.cityCode.name}
                                                            subheader={item.department}
                                                            headerColour={theme.colours.gray2}
                                                            subheaderColour={"black"}
                                                            textBoxColour={"white"}
                                                            titleColourOnImage={index % 2 === 0 ? page.fontColourForImageA : page.fontColourForImageB}
                                                            showRibbon
                                                            showTitleOnImage
                                                        />
                                                    </ItemContainer>
                                                ))}
                                            </ItemListWrapper>
                                        )
                                    )}
                                </ItemListContainer>
                            </PageSection>
                        </Wrapper>
                    </SectionContainer>
                );
            })}
        </>
    );
};

export default CareerListPage;
