import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    alignItem?: string,
    isBottomPagination?: boolean,
    className?: string,
};

const SlideContainer: React.FC<Props> = ({
    children,
    alignItem = 'center',
    isBottomPagination = false,
    className = '',
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: ${alignItem};
        width: 100%;
        height: 100%;
        
        ${isBottomPagination && css`
            padding-bottom: 67px;
            
           ${theme.breakpoints.up("5xl")} {
               padding-bottom: 75px;
            }

            ${theme.breakpoints.down("xs")} {
                padding-bottom: 45px;
            }
        `};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default SlideContainer;
