import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import WrapperFromCore from "core/Components/Wrapper";

type Props = {
    children: React.ReactNode
};

const Wrapper: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        flex-direction: column;
        align-items: start;
        padding: 50px 0;
      
        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
            padding-left: 182px;
            padding-right: 182px;
        }
        ${theme.breakpoints.between("xl", "xxl")} {
            padding-left: 80px;
            padding-right: 80px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            padding-left: 45px;
            padding-right: 45px;
        }

        ${theme.breakpoints.only("sm")} {
            padding-left: 47px;
            padding-right: 47px;
        }

        ${theme.breakpoints.down("xs")} {
            padding-left: 20px;
            padding-right: 20px;
        }
    `;

    return (
        <WrapperFromCore css={wrapperStyle} useMinHeight={false}>
            {children}
        </WrapperFromCore>
    );
}

export default Wrapper;
