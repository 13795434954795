import React from "react";
import { css, useTheme } from "@emotion/react";

import SectionContainer from "core/Components/SectionContainer";
import Image from "core/Components/Image";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    data: ContentWithRoundImage | ContentWithRoundImageType
}

const ContentWithRoundImage: React.FC<Props> = ({ data }) => {
    const theme = useTheme();

    const containerStyle = css`
        width: 100%;
        min-width: unset;
        display: flex;
        flex-direction: row;
        justify-content: unset;
        background-color: ${theme.colours.gold};
        
        ${theme.breakpoints.up('4xl')} {
            height: 907px;
            border-radius: 454px 0 0 454px;
        }
        
        ${theme.breakpoints.between('xxl', '3xl')} {
            height: 633px;
            border-radius: 317px 0 0 317px;
        }

        ${theme.breakpoints.only('xl')} {
            height: 599px;
            border-radius: 300px 0 0 300px;
        }

        ${theme.breakpoints.only('lg')} {
            height: 485px;
            border-radius: 243px 0 0 243px;
        }
        
        ${theme.breakpoints.down('md')} {
            flex-direction: column;
            align-items: center;
        }

        ${theme.breakpoints.only('md')} {
            width: 768px;
            margin: 0 auto;
            border-radius: 384px 384px 0 0;
        }
        
        ${theme.breakpoints.down('sm')} {
            border-radius: 49vw 49vw 0 0;
        }
    `;

    const imageContainerStyle = css`
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 50%;

        ${theme.breakpoints.up('4xl')} {
            width: 801px;
            height: 801px;
            margin: 53px 0 53px 50px;
        }

        ${theme.breakpoints.between('xxl', '3xl')} {
            height: 585px;
            width: 585px;
            margin-top: 24px;
            margin-bottom: 24px;
        }
        
        ${theme.breakpoints.only('3xl')} {
            margin-left: 29px;
        }

        ${theme.breakpoints.only('xxl')} {
            margin-left: 27px;
        }

        ${theme.breakpoints.only('xl')} {
            height: 533px;
            width: 533px;
            margin: 33px 0 33px 25px;
        }

        ${theme.breakpoints.only('lg')} {
            height: 447px;
            width: 447px;
            margin: 19px 0 19px 20px;
        }

        ${theme.breakpoints.only('md')} {
            width: 678px;
            height: 678px;
            margin-top: 44px;
        }

        ${theme.breakpoints.only('sm')} {
            width: calc(100vw - 58px);
            height: calc(100vw - 58px);
            margin-top: 22px;
        }

        ${theme.breakpoints.down('xs')} {
            width: calc(100vw - 37px);
            height: calc(100vw - 37px);
            margin-top: 15px;
        }
    `;

    const contentSectionStyle = css`
        margin: 0 6%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h2 {
            color: white;
            font-weight: ${theme.fonts.weights.bold};
            margin-bottom: 18px;
        }

        h4, h5, p {
            color: ${theme.colours.shipGray};
        }

        ${theme.breakpoints.down('md')} {
            margin-left: 6%;
            margin-right: 6%;
        }

        ${theme.breakpoints.down('md')} {
            margin-top: 6%;
            margin-bottom: 7%;
        }

        ${theme.breakpoints.down('sm')} {
            margin-top: 7%;
            margin-bottom: 8%;
        }

        ${theme.breakpoints.down('xs')} {
            margin-top: 10%;
            margin-bottom: 9%;
        }
    `;

    return data.image ? (
        <SectionContainer css={containerStyle}>
            <div css={imageContainerStyle}>
                <Image
                    image={data.image}
                    useFocusPoint={true}
                />
            </div>
            <div css={contentSectionStyle}>
                {data.header && (
                    <ContentCombine Tag={"h2"}>{data.header}</ContentCombine>
                )}
                {data.subheader && (
                    <ContentCombine Tag={"h4"}>{data.subheader}</ContentCombine>
                )}
                {data.text && (
                    <ContentCombine Tag={"p"}>{data.text}</ContentCombine>
                )}
            </div>
        </SectionContainer>
    ) : null;
}

export default ContentWithRoundImage;