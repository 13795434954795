import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
};

const UnderlinedHeaderContainer: React.FC<Props> = ({
    children,
}) => {
    const theme: Theme = useTheme();

    const headerTitleContainerStyle = css`
        width: 100%;
        height: 48px;
        padding: 0 20px;
        position: relative;

        ${theme.breakpoints.up("5xl")} {
            height: 74px;
        }

        ${theme.breakpoints.only("md")} {
            height: 42px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 60px;
        }

        :before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border-bottom: 0.5px solid ${theme.colours.white};
        }
    `;

    return (
        <ContentSection css={headerTitleContainerStyle}>
            {children}
        </ContentSection>
    );
}

export default UnderlinedHeaderContainer;
