import React from "react";
import { css } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    gap: number,
    featureBoxWidth: number,
    otherBoxWidth: number,
};

const FeatureTitleWrapper: React.FC<Props> = ({
    children,
    gap,
    featureBoxWidth,
    otherBoxWidth,
}) => {
    const style = css`
        display: grid;
        grid-template-columns: ${featureBoxWidth}px ${otherBoxWidth}px ${otherBoxWidth}px;
        gap: ${gap}px;
        place-items: center;
        margin-bottom: 30px;
    `;

    return (
        <div css={style}>{children}</div>
    );
}

export default FeatureTitleWrapper;
