import React from 'react';
import { css, useTheme } from "@emotion/react";
import ContentSection from "core/Components/ContentSection";
import VideoPlaceholder from "core/Components/Video/VideoPlaceholder";

type Props = {
    imageURL: string,
    videoImageWidth: number,
    videoPath: string,
    onPlayVideo: (videoPath: string) => void,
    backgroundColour?: string,
};

const VideoImage: React.FC<Props> = ({
    imageURL,
    videoImageWidth,
    videoPath,
    onPlayVideo,
    backgroundColour,
}) => {
    const theme = useTheme();

    const onClickVideoHandler = () => {
        onPlayVideo(videoPath);
    };

    const videoSectionStyle = css`
        position: relative;
        background: black;
        overflow: hidden;
        width: ${videoImageWidth}px;
        background: ${backgroundColour || 'transparent'};

        ${theme.breakpoints.up("5xl")} {
            height: 720px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            height: 496px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            height: 477px;
        }

        ${theme.breakpoints.only("md")} {
            height: 381px;
        }

        ${theme.breakpoints.only("sm")} {
            height: 269px;
        }

        ${theme.breakpoints.down("xs")} {
            height: 181px;
        }
    `;

    return (
        <ContentSection css={videoSectionStyle}>
            <VideoPlaceholder
                imageUrl={imageURL}
                imageAltText={imageURL}
                onClick={onClickVideoHandler}
            />
        </ContentSection>
    );
};

export default VideoImage;
