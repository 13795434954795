const breakpoints = {
    sizes: {
        'xxs': 0,
        'xs': 360,
        'sm': 568,
        'md': 768,
        'lg': 1024,
        'xl': 1280,
        'xxl': 1440,
        '3xl': 1600,
        '4xl': 1900,
        '5xl': 2200,
    },
    order: [
        'xxs',
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        'xxl',
        '3xl',
        '4xl',
        '5xl'
    ],
    breakpointsToScale: [
        '3xl',
        '4xl',
        '5xl',
    ],
    scale: {
        '3xl': 1.1,
        '4xl': 1.18,
        '5xl': 1.26
    }
};

export type BreakpointSize = keyof typeof breakpoints.sizes;
export type ScaleSize = keyof typeof breakpoints.scale;

export const isOnly = (currentBp: BreakpointSize, comparisonBp: BreakpointSize) => {
    const order = breakpoints.order;
    return order.indexOf(currentBp.toLowerCase()) === order.indexOf(comparisonBp.toLowerCase());
};

export const isDown = (currentBp: BreakpointSize, comparisonBp: BreakpointSize) => {
    const order = breakpoints.order;
    return order.indexOf(currentBp.toLowerCase()) <= order.indexOf(comparisonBp.toLowerCase());
};

export const isUp = (currentBp: BreakpointSize, comparisonBp: BreakpointSize) => {
    const order = breakpoints.order;
    return order.indexOf(currentBp.toLowerCase()) >= order.indexOf(comparisonBp.toLowerCase());
};

export const isBetween = (currentBp: BreakpointSize, min: BreakpointSize, max: BreakpointSize) => {
    const order = breakpoints.order;
    const currentBpOrder = order.indexOf(currentBp.toLowerCase());
    const comparisonBpsOrder = [min, max]
        .map(bp => order.indexOf(bp.toLowerCase())).sort();

    return currentBpOrder >= comparisonBpsOrder[0] && currentBpOrder <= comparisonBpsOrder[1];
}

export const getNextBreakpoint = (size: string) => breakpoints.order[breakpoints.order.indexOf(size) + 1];
const getBreakpointMin = (size: BreakpointSize) => breakpoints.sizes[size];
const getBreakpointMax = (size: string) => breakpoints.sizes[getNextBreakpoint(size) as BreakpointSize] - .02;

/**
 * Returns the media query for a single breakpoint.
 *
 * min represents the breakpoint matching the size prop.
 * max represents the next highest breakpoint from the size prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.down('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointOnly = (size: BreakpointSize) => {
    const min = getBreakpointMin(size);
    const max = getBreakpointMax(size);
    return `@media (min-width: ${min}px)${max ? ` and (max-width: ${max}px)` : ""}`;
};

/**
 * Returns the media query for a minimum breakpoint.
 *
 * min represents the breakpoint matching the size prop.
 *
 * Example use:
 * ${theme.breakpoints.up('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointUp = (size: BreakpointSize) => `@media (min-width: ${getBreakpointMin(size)}px)`;

/**
 * Returns the media query for a maximum breakpoint.
 *
 * max represents the next highest breakpoint from the size prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.down('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointDown = (size: BreakpointSize) => {
    const max = getBreakpointMax(size);
    return max ? `@media (max-width: ${max}px)` : `@media (min-width: 0)`;
};

/**
 * Returns the media query for a variable number of breakpoints.
 *
 * min represents the breakpoint matching the low prop.
 * max represents the breakpoint matching the next highest breakpoint from the high prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.between('lg', 'xl')} {
 *     height: 100px;
 * }
 * @param low
 * @param high
 * @returns {string}
 */
export const breakpointBetween = (low: BreakpointSize, high: BreakpointSize) => {
    const min = getBreakpointMin(low);
    const max = getBreakpointMax(high);

    return `@media (min-width: ${min}px) ${max ? ` and (max-width: ${max}px)` : ""}`;
};


const v = {
    ...breakpoints,
    only: breakpointOnly,
    between: breakpointBetween,
    up: breakpointUp,
    down: breakpointDown,
};

export default v;
