import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isHeaderMenu?: boolean,
    subMenu: StandardPage,
    selectedSegments?: string[],
};

const BurgerNavSmallSubMenuTitle: React.FC<Props> = ({
    children,
    isHeaderMenu = false,
    subMenu,
    selectedSegments = undefined,
}) => {
    const theme = useTheme();

    const style = css`
        color: ${isHeaderMenu ? theme.colours.black : theme.colours.tundora};
        
        ${isHeaderMenu && css`
            font-weight: ${theme.fonts.weights.bold};
        `}

        ${theme.breakpoints.only("md")} {
            font-size: 17px;
        }

        ${theme.breakpoints.only("sm")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("xs")} {
            font-size: 16px;
        }

        ${(!isHeaderMenu && selectedSegments?.includes(subMenu?.uRLSegment)) && css`
            opacity: 0.7;
        `}
        
        ${subMenu.isClickable && css`
            &:hover {
                color: ${theme.colours.curiousBlue};
            }
        `}
    `;

    return (
        <div css={style}>{children}</div>
    );
};

export default BurgerNavSmallSubMenuTitle;