import React, { useMemo } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { isEqual } from "lodash";

import Image from "core/Components/Image";
import useBreakpoint from "core/hooks/useBreakpoint";

type Props = {
    topBannerHeight: number,
    topBannerImage: TopBannerImage,
};

const BannerImage: React.FC<Props> = ({ topBannerHeight, topBannerImage }) => {
    const theme: Theme = useTheme();
    const { minWidth } = useBreakpoint();

    const image = useMemo(() => {
        if (minWidth >= theme.breakpoints.sizes["lg"]) {
            return topBannerImage.landscape || topBannerImage.portrait;
        }

        return topBannerImage.portrait || topBannerImage.landscape;
    }, [minWidth, topBannerImage, theme.breakpoints.sizes]);

    const useAFocusPoint = (isEqual(image, topBannerImage.landscape) && topBannerImage.useFocusPoint) ||
        (isEqual(image, topBannerImage.portrait) && topBannerImage.useFocusPointPortrait);

    const style = (theme: Theme) => css`
        width: 100vw;
        height: ${topBannerHeight}px;
        
        ${theme.breakpoints.down('sm')} {
            min-width: 360px;
        }
    `;

    const imageStyle = css`
         width: 100%;
         height: 100%;

        ${!useAFocusPoint && css`
            ${theme.breakpoints.down("md")} {
                > img {
                    display: flex;
                    min-height: unset !important;
                    max-height: unset !important;
                    height: unset !important;
                    width: 100% !important;
                    inset: unset !important;
                }
            }
        `}
    `;

    const altText = topBannerImage.altText || '';

    return (
        <div css={style} >
            <Image
                image={image}
                css={imageStyle}
                useFocusPoint={useAFocusPoint}
                customAltText={altText}
            />
        </div>
    );
}

export default BannerImage;
