import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const ExtraInformationContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        margin-top: 25px;
        background: white;
        border-radius: 24px;
        color: black;
        text-align: left;
        justify-content: left;
        padding: 20px;
        font-size: 20px;
        line-height: 24px;

        ${theme.breakpoints.up('5xl')} {
            margin-top: 30px;
        }

        ${theme.breakpoints.between('md', '4xl')} {
            margin-top: 28px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-top: 18px;
            font-size: 18px;
            line-height: 22px;
            padding: 20px 12px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};


export default ExtraInformationContainer;