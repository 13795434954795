import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    imageHorizontalCard: ImageHorizontalCard,
    children: React.ReactNode,
    index: number,
};

const CardContainer: React.FC<Props> = ({ children, imageHorizontalCard, index }) => {
    const theme: Theme = useTheme();
    const colourTheme = JSON.parse(imageHorizontalCard.theme);
    const { fromColour, toColour } = colourTheme;

    const style = css`
        border-radius: 12px;
        box-shadow: ${theme.borderAndShadow.dropShadow};
        justify-self: ${index % 2 ? "left" : "right"};
        background: transparent linear-gradient(
            360deg,
            ${(fromColour)} 0%,
            ${(toColour)} 100%
        ) 0% 0% no-repeat padding-box;
        overflow: hidden;
        width: 613px;
        height: 474px;
        
        ${imageHorizontalCard.isImageFirst && css`
            flex-direction: row-reverse;
        `}

        ${theme.breakpoints.up("5xl")} {
            width: 867px;
            height: 670px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            width: 688px;
            height: 532px;
        }

        ${theme.breakpoints.only("xl")} {
            width: 549px;
            height: 474px;
        }

        ${theme.breakpoints.only("lg")} {
            width: 458px;
            height: 346px;
        }

        ${theme.breakpoints.only("md")} {
            width: 677px;
            height: 421px;
            justify-self: center;
        }

        ${theme.breakpoints.down("sm")} {
            justify-self: center;
            flex-direction: column;
        }
        
        ${theme.breakpoints.only("sm")} {
            width: 355px;
        }

        ${theme.breakpoints.down("xs")} {
            width: 320px;
        }
        
    `;

    return (
        <ContentSection css={style}>
            {children}
        </ContentSection>
    );
}

export default CardContainer;
