import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
};

const NavShadowBox: React.FC<Props> = ({
    children,
}) => {
    const theme = useTheme();

    const style = css`
        background-color: ${theme.colours.white};
        box-shadow: ${theme.borderAndShadow.dropShadow1};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default NavShadowBox;