import React from 'react';
import { css, useTheme } from '@emotion/react';

type Props = {
    children: React.ReactNode,
};

const LeftTextContainer: React.FC<Props> = ({ children }) => {
    const theme = useTheme();

    const style = css`
        margin-top: 88.3px;

        ${theme.breakpoints.up('5xl')} {
            margin-top: 38px;
        }

        ${theme.breakpoints.between('xxl', '4xl')} {
            margin-top: 87px;
        }

        ${theme.breakpoints.between('lg', 'xl')} {
            margin-top: 98.75px;
        }

        ${theme.breakpoints.only('md')} {
            margin-top: 77.62px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-top: 36px;
        }
    `;

    return (
        <div css={style}>
            { children }
        </div>
    );
};

export default LeftTextContainer;