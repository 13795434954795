import React, { useCallback, useState } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import StandardPage from "pages/Components/StandardPage";
import FooterVerticalHeaderMenu from "core/Components/Footer/vertical/FooterVerticalHeaderMenu";
import FooterVerticalSubMenu from "core/Components/Footer/vertical/FooterVerticalSubMenuBox";

type Props = {
    footerMenu?: [any],
};

const FooterVerticalMenuBox: React.FC<Props> = ({
    footerMenu = null,
}) => {
    const theme: Theme = useTheme();
    const [selectedMenu, setSelectedMenu] = useState<StandardPage | null>(null);

    const setSelectedMenuHandler = useCallback(({ headerMenu, selectedMenu }: any) => {
        if (selectedMenu?.uRLSegment === headerMenu.uRLSegment) {
            setSelectedMenu(null);
        } else {
            setSelectedMenu(headerMenu);
        }
    }, []);

    const style = css`
        width: 100%;
        border-top: 1px solid ${theme.colours.alto3};
    `;

    return (
        <div css={style}>
            {footerMenu && (
                Object.entries(footerMenu).map(([key, value]) => (
                    <div key={key}>
                        {Object.entries(value).map(([key, value]: any) => {
                            const groupMenu = value;
                            const headerMenu = groupMenu.find((menu: StandardPage) => menu.parentID === 0);
                            const subMenu = groupMenu.filter((menu: StandardPage) => menu.parentID.toString() === headerMenu.id);
                            const hasSubMenu = subMenu?.length > 0;

                            return (
                                <div key={key}>
                                    <FooterVerticalHeaderMenu
                                        key={"footerVerticalHeaderMenu" + groupMenu.id}
                                        headerMenu={headerMenu}
                                        hasSubMenu={hasSubMenu}
                                        selectedMenu={selectedMenu}
                                        setSelectedMenuHandler={setSelectedMenuHandler}
                                    />
                                    <FooterVerticalSubMenu
                                        headerMenu={headerMenu}
                                        selectedMenu={selectedMenu}
                                        subMenu={subMenu}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ))
            )}
        </div>
    );
};

export default FooterVerticalMenuBox;
