import React from "react";
import { css, useTheme } from "@emotion/react";
import Image from "core/Components/Image";

type Props = {
    image: Image,
    useFocusPoint: boolean,
    opacity: number,
    gradientColor: string,
    className?: string,
};

const ImageContainer: React.FC<Props> = ({
    image,
    useFocusPoint,
    opacity,
    gradientColor,
    className = '',
}) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-self: center;

        ${theme.breakpoints.up("md")} {
            height: 500px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 362px;
            min-width: 360px;
        }

        ${!useFocusPoint && css`
            background-image: url(${image.URL});
            background-size: cover;
            background-position: center;
        `};
    `;

    const imageStyle = css`
        ${useFocusPoint && css`
            ${theme.breakpoints.down("md")} {
                object-fit: cover;
            }
        `}
        ${theme.breakpoints.up("5xl")} {
            height: 600px;
        }

        ${theme.breakpoints.only("md")} {
            height: 500px;
        }

        ${theme.breakpoints.down("sm")} {
            height: 362px;
        }
    `;

    return (
        <div css={style} className={className}>
            {useFocusPoint && (
                <Image
                    image={image}
                    css={imageStyle}
                    useFocusPoint={true}
                    opacity={opacity}
                    gradientColor={gradientColor}
                />
            )}
        </div>
    );
}

export default ImageContainer;
